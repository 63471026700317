import { memo } from 'react';

function FundWalletImage() {
	return (
		<div className="relative flex justify-center w-full h-full text-center">
			<div className="w-[90%] max-w-[250px] h-full bg-[url('/assets/images/fund-wallet.webp')] bg-contain bg-no-repeat bg-center"></div>
		</div>
	);
}

export default memo(FundWalletImage);
