'use client';

import { useCallback, useRef } from 'react';
import { HexString } from 'src/@types/evm';
import { notify } from 'src/utils/notifications';
import { ConfirmTransactionStep } from 'src/components/Toasts/Notification';
import {
	erc20ABI,
	useContractRead,
	useContractWrite,
	usePrepareContractWrite,
	useWaitForTransaction,
} from 'wagmi';
import { getContract } from 'wagmi/actions';

const TOAST_ID = 'evm-token-approval';
const REFETCH_INTERVAL_MS = 5_000; // this works for testnet, may need more time for mainnet

/**
 * This hook is used to approve a spending limit by the protocol for a specific token.
 */
export const useEvmTokenApproval = (
	tokenAddress: HexString,
	userAddress: HexString,
	spenderAddress: HexString,
	maxAllowance = BigInt(0)
) => {
	const numOfApproveSpendingLimitErrors = useRef(0);

	const usdcErc20Contract = getContract({
		address: tokenAddress,
		abi: erc20ABI,
	});

	// read "allowance" function from ERC20 contract
	const {
		data: currentAllowance,
		isLoading: isAllowanceLoading,
		error: allowanceError,
		refetch: refetchAllowance,
	} = useContractRead({
		address: usdcErc20Contract.address,
		abi: erc20ABI,
		functionName: 'allowance',
		args: [userAddress, spenderAddress],
	});

	if (allowanceError && !!userAddress && !!spenderAddress) {
		console.log(allowanceError);
	}

	const allowance = currentAllowance ?? BigInt(0);

	// prepare config for "approve" function from ERC20 contract
	const { config } = usePrepareContractWrite({
		address: tokenAddress,
		abi: erc20ABI,
		functionName: 'approve',
		args: [spenderAddress, maxAllowance],
	});

	// create "approve" function from ERC20 contract
	const { write, data: approveResult } = useContractWrite({
		...config,
		onError(_error) {
			notify({
				type: 'error',
				message: 'Failed to approve spending limit',
				description: '',
				id: TOAST_ID,
				updatePrevious: true,
				showUntilCancelled: false,
			});
		},
	});
	const approve = useCallback(() => {
		notify({
			type: 'info',
			message: 'Approving Spending Limit',
			description: <ConfirmTransactionStep />,
			id: TOAST_ID,
			showUntilCancelled: true,
		});
		write();
	}, [write]);

	// check if transaction is finalized
	const { isLoading: isApproving, refetch: refetchApprovalTx } =
		useWaitForTransaction({
			hash: approveResult ? approveResult.hash : undefined,
			onSuccess(_data) {
				notify({
					type: 'success',
					message: 'Spending Limit Approved',
					description: 'You can now initiate the USDC transfer.',
					id: TOAST_ID,
					updatePrevious: true,
					showUntilCancelled: false,
				});
				refetchAllowance();
			},
			onError(error) {
				retryApproveTxFetchOnce(error);
			},
		});

	function retryApproveTxFetchOnce(error: any) {
		if (numOfApproveSpendingLimitErrors.current === 0) {
			numOfApproveSpendingLimitErrors.current += 1;
			console.log(
				`Failed to fetch spending limit approval tx, retrying in ${REFETCH_INTERVAL_MS}ms for tx:`,
				approveResult.hash
			);
			setTimeout(() => {
				refetchApprovalTx();
			}, REFETCH_INTERVAL_MS);
		} else {
			notify({
				type: 'error',
				message: 'Failed to approve spending limit',
				description: '',
				id: TOAST_ID,
				updatePrevious: true,
				showUntilCancelled: false,
			});
			console.error(error);
		}
	}

	return {
		allowance,
		isAllowanceLoading,
		approve,
		isApproving,
	};
};
