'use client';

import { CaretDown, CaretUp } from '@drift-labs/icons';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariableTypes';
import useDriftTheme from 'src/hooks/useDriftTheme';
import { twMerge } from 'tailwind-merge';

export type SortDirection = 'ascending' | 'descending' | 'neutral';

export default function SortIcon({
	onClick,
	state,
	size,
	className,
}: {
	onClick: () => void;
	state: SortDirection;
	size?: number;
	className?: string;
}) {
	const theme = useDriftTheme();
	const isLightMode = theme === DriftTheme.light;

	return (
		<span className="flex items-center justify-center cursor-pointer">
			{state === 'neutral' ? (
				<CaretDown
					onClick={onClick}
					size={size}
					className={twMerge(
						isLightMode
							? '[&>svg>path]:fill-[var(--stroke-secondary)]'
							: '[&>svg>path]:fill-[var(--icon-selected)]',
						className
					)}
				/>
			) : state === 'descending' ? (
				<CaretDown
					onClick={onClick}
					size={size}
					className={twMerge(
						isLightMode
							? '[&>svg>path:nth-child(2)]:fill-[var(--stroke-secondary)]'
							: '[&>svg>path:nth-child(2)]:fill-[var(--icon-selected)]',
						className
					)}
					color={isLightMode ? 'var(--text-disabled)' : 'var(--icon-default)'}
				/>
			) : state === 'ascending' ? (
				<CaretUp
					onClick={onClick}
					size={size}
					className={twMerge(
						isLightMode
							? '[&>svg>path:nth-child(1)]:fill-[var(--stroke-secondary)]'
							: '[&>svg>path:nth-child(1)]:fill-[var(--icon-selected)]',
						className
					)}
					color={isLightMode ? 'var(--text-disabled)' : 'var(--icon-default)'}
				/>
			) : null}
		</span>
	);
}
