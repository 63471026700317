'use client';

import { memo, useCallback, useState } from 'react';
import Env from 'src/environmentVariables/EnvironmentVariables';
import Button from '../Button';
import Text from '../Text/Text';
import CheckboxInput from '../CheckboxInput';

function AcknowledgeTermsPanel({ onAccept }: { onAccept: () => void }) {
	const [acceptedLeverage, setAcceptedLeverage] = useState(false);
	const [acceptedCrossCollateral, setAcceptedCrossCollateral] = useState(false);
	const [acceptedPotentialLosses, setAcceptedPotentialLosses] = useState(false);
	const [acceptedLiquidations, setAcceptedLiquidations] = useState(false);
	const allAccepted =
		acceptedLeverage &&
		acceptedCrossCollateral &&
		acceptedPotentialLosses &&
		acceptedLiquidations;

	const handleAcceptedLeverageChange = useCallback(() => {
		setAcceptedLeverage((prevState) => !prevState);
	}, []);

	const handleAcceptedCrossCollateralChange = useCallback(() => {
		setAcceptedCrossCollateral((prevState) => !prevState);
	}, []);

	const handleAcceptedPotentialLossesChange = useCallback(() => {
		setAcceptedPotentialLosses((prevState) => !prevState);
	}, []);

	const handleAcceptedLiquidationsChange = useCallback(() => {
		setAcceptedLiquidations((prevState) => !prevState);
	}, []);

	return (
		<div className="flex flex-col items-center w-full h-full p-4 space-y-3">
			<Text.H1 className="text-text-emphasis">Acknowledge Terms</Text.H1>
			<div className="pb-6 text-center text-text-default">
				<Text.BODY2>
					By continuing, you agree to the{' '}
					<a href={Env.mainnetTermsUrl} rel="noreferrer" target="_blank">
						Drift Terms and Conditions
					</a>{' '}
					and acknowledge that you have read and understood the{' '}
					<a href={Env.mainnetDisclaimerUrl} rel="noreferrer" target="_blank">
						Drift Protocol Disclaimer
					</a>
					, and the following:
				</Text.BODY2>
			</div>
			<div className="pb-6">
				<div className="mb-4">
					<CheckboxInput
						label={
							<Text.BODY2 className="font-normal text-text-default">
								I am aware that if I use leverage, my leverage may change after
								entering a position.
							</Text.BODY2>
						}
						checked={acceptedLeverage}
						onChange={handleAcceptedLeverageChange}
						secondaryStyle
						alignStart
					/>
				</div>
				<div className="mb-4">
					<CheckboxInput
						label={
							<Text.BODY2 className="font-normal text-text-default">
								I understand the rules and risks associated with using
								cross-collateral as margin.
							</Text.BODY2>
						}
						checked={acceptedCrossCollateral}
						onChange={handleAcceptedCrossCollateralChange}
						secondaryStyle
						alignStart
					/>
				</div>
				<div className="mb-4">
					<CheckboxInput
						label={
							<Text.BODY2 className="font-normal text-text-default">
								I understand the rules and risk that can arise with settlement
								of P&L, bankruptcies, insurance, and socialized losses.
							</Text.BODY2>
						}
						checked={acceptedPotentialLosses}
						onChange={handleAcceptedPotentialLossesChange}
						secondaryStyle
						alignStart
					/>
				</div>
				<div className="mb-4">
					<CheckboxInput
						label={
							<Text.BODY2 className="font-normal text-text-default">
								I understand that my account may be partially or entirely
								liquidated if my positions breach any of the margin maintenance
								requirements.
							</Text.BODY2>
						}
						checked={acceptedLiquidations}
						onChange={handleAcceptedLiquidationsChange}
						secondaryStyle
						alignStart
					/>
				</div>
			</div>
			<Button.Secondary
				size="LARGE"
				highlight
				disabled={!allAccepted}
				onClick={onAccept}
				className={'w-full'}
				id="agree_terms_button"
			>
				Agree and Continue
			</Button.Secondary>
		</div>
	);
}

export default memo(AcknowledgeTermsPanel);
