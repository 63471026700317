'use client';

import Chart from 'chart.js/auto';
import React, { useEffect, useRef } from 'react';

const MiniChart = ({
	useMinMaxTicks = true,
	...props
}: {
	data: number[];
	useMinMaxTicks?: boolean;
	isPositive: boolean;
}) => {
	const chartHolderRef = React.createRef<HTMLCanvasElement>();
	const chartRef = useRef<Chart>(null);

	const BUFFER = 0.07;

	let dataToUse = props.data;

	if (dataToUse.length === 1) {
		dataToUse = [0, ...dataToUse];
	}

	// min/max can be distorted for markets with very small unit sizes
	dataToUse = dataToUse.map((point) => point * 100);

	useEffect(() => {
		if (!dataToUse || dataToUse?.length == 0) return;

		const max = Math.max(...dataToUse);
		const min = Math.min(...dataToUse);

		const maxIsNegative = max < 0;
		const minIsNegative = min < 0;

		const maxTick = max * (maxIsNegative ? 1 - BUFFER : 1 + BUFFER);
		const minTick = min * (minIsNegative ? 1 + BUFFER : 1 - BUFFER);

		const chartData = {
			datasets: [
				{
					data: dataToUse,
					tension: 0.4,
				},
			],
			labels: dataToUse.map(() => ''),
		};

		const chartElement = chartHolderRef.current;
		const greenGradient = chartElement
			.getContext('2d')
			.createLinearGradient(0, 0, 0, 30);

		greenGradient.addColorStop(0, 'rgba(67,194,96,.5)');
		greenGradient.addColorStop(1, 'rgba(0,0,0,0)');

		const redGradient = chartElement
			.getContext('2d')
			.createLinearGradient(0, 0, 0, 50);

		redGradient.addColorStop(0, 'rgba(255,136,127,.5)');
		redGradient.addColorStop(1, 'rgba(0,0,0,0)');

		const chart = new Chart(chartElement, {
			type: 'line',
			data: chartData,
			options: {
				layout: {
					padding: {
						right: 20,
						left: 0,
					},
				},
				animation: {
					duration: 0,
				},
				plugins: {
					legend: { display: false },
					tooltip: {
						enabled: false,
					},
				},
				elements: {
					point: {
						pointStyle: 'circle',
						borderWidth: 0,
						hoverRadius: 0,
						backgroundColor: 'rgba(0, 0, 0, 0)',
					},
					line: {
						borderWidth: 1,
						fill: {
							target: 'origin',
							above: greenGradient,
							below: redGradient,
						},
					},
				},
				borderColor: props.isPositive
					? 'rgba(67,194,96,1)'
					: 'rgba(255,136,127,1)',
				maintainAspectRatio: false,
				scales: {
					x: {
						ticks: {
							display: false,
						},
						grid: {
							display: false,
							drawBorder: false,
						},
					},
					y: {
						beginAtZero: true,
						type: 'linear',
						min: useMinMaxTicks ? (minTick == 0 ? -1 : minTick) : undefined,
						max: useMinMaxTicks ? (maxTick == 0 ? 1 : maxTick) : undefined,
						ticks: {
							display: false,
							maxTicksLimit: 3,
						},
						grid: {
							display: false,
							drawBorder: false,
						},
					},
				},
			},
		});

		chartRef.current = chart;
		return () => {
			chartRef.current.destroy();
		};
	}, [props.data]);

	return <canvas style={{ maxHeight: 50 }} ref={chartHolderRef}></canvas>;
};

export default React.memo(MiniChart);
