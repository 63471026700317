'use client';

import { useTheme } from 'next-themes';
import { useEffect } from 'react';
import { DriftTheme } from '../environmentVariables/EnvironmentVariables';

const useDriftTheme = () => {
	const themeSetting = useTheme().theme as DriftTheme;

	// This hook ensures that the icon-gradient-svg element defined in the page changes when the theme changes. It doesn't get replaced properly when optionally rendering via React
	useEffect(() => {
		// Remove previous style
		if (document.getElementById('icon-gradient-svg')) {
			const oldSvg = document.getElementById('icon-gradient-svg');

			oldSvg.parentNode.removeChild(oldSvg);
		}

		const svgNameService = 'http://www.w3.org/2000/svg';

		const newSvg = document.createElementNS(svgNameService, 'svg');

		newSvg.setAttribute('id', 'icon-gradient-svg');
		newSvg.appendChild(
			document.createElementNS(svgNameService, 'linearGradient')
		);
		newSvg.style.height = '0';
		const linearGradient = newSvg.firstChild;
		// @ts-ignore
		linearGradient.setAttribute('id', 'icon-gradient');

		if (themeSetting === DriftTheme.dark) {
			linearGradient.appendChild(
				document.createElementNS(svgNameService, 'stop')
			);
			let stop = linearGradient.lastChild;
			// @ts-ignore
			stop.setAttribute('offset', '0');
			// @ts-ignore
			stop.setAttribute('stop-color', '#E8A2A0');
			linearGradient.appendChild(
				document.createElementNS(svgNameService, 'stop')
			);
			stop = linearGradient.lastChild;
			// @ts-ignore
			stop.setAttribute('offset', '0.5');
			// @ts-ignore
			stop.setAttribute('stop-color', '#9468F1');
			linearGradient.appendChild(
				document.createElementNS(svgNameService, 'stop')
			);
			stop = linearGradient.lastChild;
			// @ts-ignore
			stop.setAttribute('offset', '1');
			// @ts-ignore
			stop.setAttribute('stop-color', '#71CCE9');
			document.documentElement.classList.add('dark'); // tailwind dark theme
		} else {
			linearGradient.appendChild(
				document.createElementNS(svgNameService, 'stop')
			);
			let stop = linearGradient.lastChild;
			// @ts-ignore
			stop.setAttribute('offset', '0');
			// @ts-ignore
			stop.setAttribute('stop-color', '#F7648F');
			linearGradient.appendChild(
				document.createElementNS(svgNameService, 'stop')
			);
			stop = linearGradient.lastChild;
			// @ts-ignore
			stop.setAttribute('offset', '0.5');
			// @ts-ignore
			stop.setAttribute('stop-color', '#9162F6');
			linearGradient.appendChild(
				document.createElementNS(svgNameService, 'stop')
			);
			stop = linearGradient.lastChild;
			// @ts-ignore
			stop.setAttribute('offset', '1');
			// @ts-ignore
			stop.setAttribute('stop-color', '#11C7E3');
			document.documentElement.classList.remove('dark'); // remove tailwind dark theme
		}

		document.body.appendChild(newSvg);
	}, [themeSetting]);

	return themeSetting;
};

export default useDriftTheme;
