'use client';

import { useMemo } from 'react';
import { useLocalStorage, useUpdate } from 'react-use';

export const useLocalStorageStringState = (
	key: string,
	defaultState: string | null = null
) => {
	const update = useUpdate();

	const [state, setState] = useLocalStorage(key, defaultState, { raw: true });

	return [
		state,
		(val: string) => {
			update();
			return setState(val);
		},
	] as [string, typeof setState];
};

export default function useLocalStorageState<T = any>(
	key: string,
	defaultState: T | null = null
): [T, (newState: T) => void] {
	const [stringState, setStringState] = useLocalStorageStringState(
		key,
		JSON.stringify(defaultState)
	);

	const isAlphabetical = /^[a-zA-Z]+$/.test(stringState); // prevents JSON.parse from parsing a string, which will throw an error
	const isBool = stringState === 'true' || stringState === 'false';

	return [
		useMemo(
			() =>
				stringState &&
				(isAlphabetical && !isBool ? stringState : JSON.parse(stringState)),
			[stringState]
		),
		(newState) => setStringState(JSON.stringify(newState)),
	];
}
