import React from 'react';
import { Loading } from '@drift-labs/icons';
import { generatedSemanticClasses } from 'src/constants/figmaGenerated';

const ArcLoadingSpinner = (props: { size?: number; color?: string }) => {
	return (
		<Loading
			color={props.color ?? `${generatedSemanticClasses['interactive-link']}`}
			size={props.size}
			className="animate-spin"
		/>
	);
};

export default ArcLoadingSpinner;
