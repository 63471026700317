'use client';

import useDriftStore from '../stores/DriftStore/useDriftStore';

const useDriftClientIsReady = () => {
	const driftClientIsReady = useDriftStore((s) => {
		return (
			s.driftClient.client &&
			s.driftClient.client.isSubscribed &&
			s.driftClient.isSubscribed
		);
	});

	return driftClientIsReady;
};

export default useDriftClientIsReady;
