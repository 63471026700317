'use client';

import { BigNum, SpotMarketConfig, WRAPPED_SOL_MINT } from '@drift-labs/sdk';
import { useMemo } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useSPLTokenBalance from './useSPLTokenBalance';

const useCurrentWalletCollateralBalance = (
	market: SpotMarketConfig
): [BigNum, boolean] => {
	const [splTokenBalance, loading] = useSPLTokenBalance(market?.mint);

	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);

	const splTokenBalanceBigNum = BigNum.fromPrint(
		splTokenBalance ? splTokenBalance.toString() : '0',
		market.precisionExp
	);

	const isSolBank = market.mint.equals(WRAPPED_SOL_MINT);

	const balanceToReturn = isSolBank ? solBalance : splTokenBalanceBigNum;
	const loadingToReturn = isSolBank ? false : loading;

	const result: [BigNum, boolean] = useMemo(
		() => [balanceToReturn, loadingToReturn],
		[balanceToReturn.toNum(), market.marketIndex, loadingToReturn]
	);

	return result;
};

export default useCurrentWalletCollateralBalance;
