'use client';

import { useEffect, useState } from 'react';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';

export default function useReferralRewardStructure() {
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const [referrerEarnPercent, setReferrerEarnPercent] =
		useState<number>(undefined);
	const [refereeDiscountPercent, setRefereeDiscountPercent] =
		useState<number>(undefined);

	useEffect(() => {
		if (driftClientIsReady) {
			const stateAccount = driftClient.getStateAccount();
			const feeTier = stateAccount?.perpFeeStructure?.feeTiers?.[0];
			setRefereeDiscountPercent(
				(feeTier.refereeFeeNumerator / feeTier.refereeFeeDenominator) * 100
			);
			setReferrerEarnPercent(
				(feeTier.referrerRewardNumerator / feeTier.referrerRewardDenominator) *
					100
			);
		}
	}, [driftClientIsReady]);

	return { referrerEarnPercent, refereeDiscountPercent };
}
