'use client';

import { ReactNode, memo, useEffect, useRef, useState } from 'react';
import Text from './Text/Text';
import { ChevronDown, IconProps } from '@drift-labs/icons';
import { twMerge } from 'tailwind-merge';
import { JSX } from 'react';

interface AccordionOption {
	header: string | ReactNode;
	content: string | ReactNode;
	containerClassNames?: string;
	headerClassNames?: string;
	contentClassNames?: string;
	key?: any;
	showIcon?: boolean;
	CustomIcon?: (props: IconProps) => JSX.Element;
	navigationStyle?: boolean;
	linkUrl?: string;
	disabled?: boolean;
}

export const AccordionPanel = memo(function AccordionPanelMemo({
	onClickHeader,
	expanded,
	header,
	content,
	containerClassNames,
	headerClassNames,
	contentClassNames,
	showIcon = true,
	CustomIcon,
	navigationStyle = false,
	disabled,
}: AccordionOption & {
	onClickHeader: () => void;
	expanded: boolean;
}) {
	const contentDivRef = useRef<HTMLDivElement>(null);
	const [maxHeight, setMaxHeight] = useState(0);

	useEffect(() => {
		if (contentDivRef?.current?.offsetHeight) {
			setMaxHeight(contentDivRef.current?.offsetHeight);
		}
	}, [contentDivRef.current?.offsetHeight]);

	return (
		<div
			className={twMerge(
				!navigationStyle &&
					'tracking-normal border border-container-border bg-main-bg',
				'text-text-default',
				containerClassNames
			)}
		>
			<div
				onClick={disabled ? undefined : onClickHeader}
				className={twMerge(
					`flex flex-row items-center justify-between cursor-pointer transition-colors duration-300`,
					navigationStyle ? 'p-2' : 'p-4',
					!navigationStyle && expanded && 'bg-container-border',
					headerClassNames
				)}
			>
				{navigationStyle ? (
					<Text.H3>{header}</Text.H3>
				) : (
					<Text.BODY3>{header}</Text.BODY3>
				)}
				{showIcon &&
					!disabled &&
					(CustomIcon ? (
						<CustomIcon />
					) : (
						<div
							className="flex items-center duration-300"
							style={{
								transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
							}}
						>
							<ChevronDown color="var(--text-label)" />
						</div>
					))}
			</div>
			<div
				className={`overflow-hidden transition-[max-height] duration-300`}
				style={{
					maxHeight: expanded ? `${maxHeight}px` : '0px',
				}}
			>
				<div
					className={twMerge(
						navigationStyle ? 'pl-10 pt-2' : 'p-4',
						contentClassNames
					)}
					ref={contentDivRef}
				>
					<Text.BODY2>{content}</Text.BODY2>
				</div>
			</div>
		</div>
	);
});

const Accordion = ({
	memoedOptions,
	className,
	expandMultiple,
	showIcon = true,
	navigationStyle = false,
	defaultExpandedIndex,
}: {
	memoedOptions: AccordionOption[]; // important to memoize the options to prevent resetting the expanded states when unnecessary
	className?: string;
	expandMultiple?: boolean;
	showIcon?: boolean;
	navigationStyle?: boolean;
	defaultExpandedIndex?: number;
}) => {
	const [multipleExpanded, setMultipleExpanded] = useState<boolean[]>(
		memoedOptions.map((_) => false)
	);
	const [expandedIndex, setExpandedIndex] =
		useState<number>(defaultExpandedIndex);

	useEffect(() => {
		setMultipleExpanded(memoedOptions.map((_) => false));
	}, [memoedOptions]);

	const handleExpand = (index: number) => {
		if (!expandMultiple) {
			if (index === expandedIndex) {
				setExpandedIndex(undefined);
			} else {
				setExpandedIndex(index);
			}
		} else {
			multipleExpanded[index] = !multipleExpanded[index];
			setMultipleExpanded([...multipleExpanded]);
		}
	};

	return (
		<div
			className={twMerge(
				navigationStyle ? 'space-y-2' : 'space-y-4',
				className
			)}
		>
			{memoedOptions.map((option, index) => (
				<AccordionPanel
					{...option}
					key={option?.key ?? index}
					expanded={
						expandMultiple ? multipleExpanded[index] : expandedIndex == index
					}
					showIcon={showIcon}
					onClickHeader={() => handleExpand(index)}
					navigationStyle={navigationStyle}
				/>
			))}
		</div>
	);
};

export default memo(Accordion);
