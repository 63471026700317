import { Opaque } from '@drift/common';
import { dlog } from '../dev';

/**
 * Quick explainer for Wallet Connection State class.
 *
 * The idea is to have a singleton, shared class which tracks the current state of the wallet connection - for the UI to react to.
 *
 * There are distinct steps in the connection process, and the UI should can read whether we have hit a particular state or not.
 *
 * Some states are "combinations" of other states, e.g. the wallet is FULLY CONNECTED when: the adapter is connected, the client is connected, the balance is loaded, and the subaccounts are subscribed.
 *
 * There is also the concept of "locking" a state ON. (can't lock it off) .. this is because some components want to do some extra processing on state changes which would otherwise progress too fast for them to handle before the UI might move past them. For example : The onboarding page does some "extra" processing to figure out which step to go to next, after the wallet has connected. There is no intermediary state for this processing so we just want to leave the "wallet is connecting" screen up until we are ready to move on.
 */

enum ConnectionStateSteps {
	NotConnected = 0,
	Connecting = 1,
	AdapterConnected = 2,
	ClientConnected = 4,
	BalanceLoaded = 8,
	SubaccountsSubscribed = 16,
}

enum ConnectionStates {
	NotConnected = ConnectionStateSteps.NotConnected,
	Connecting = ConnectionStateSteps.Connecting, // A wallet is considered CONNECTING in the period between the user selecting a wallet and before the Adapter and Client become connected
	AdapterConnected = ConnectionStateSteps.AdapterConnected,
	ClientConnected = ConnectionStateSteps.ClientConnected,
	BalanceLoaded = ConnectionStateSteps.BalanceLoaded,
	SubaccountsSubscribed = ConnectionStateSteps.SubaccountsSubscribed,
	FullyConnected = ConnectionStateSteps.AdapterConnected +
		ConnectionStateSteps.ClientConnected +
		ConnectionStateSteps.BalanceLoaded +
		ConnectionStateSteps.SubaccountsSubscribed,
}

// Weird lil hack to pull just the string values of the ConnectionStates in the enum out
const CONNECTION_STATE_KEYS = Object.keys(ConnectionStates)
	.map((key) => ConnectionStates[key])
	.filter((val) => typeof val === 'string');

type ConnectionStepString = keyof typeof ConnectionStateSteps;

type ConnectionStateString = keyof typeof ConnectionStates;

type LockKey = Opaque<string, 'LockKey'>;

class WalletConnectionState {
	readonly state: number;
	stateLocks = new Set<LockKey>();

	constructor() {
		this.state = ConnectionStates.NotConnected;
	}

	private getStepLockMatcher = (stateKey: ConnectionStepString) =>
		`${stateKey}_`;

	private getLockKey = (
		stateKey: ConnectionStepString,
		lockKey: string
	): LockKey => `${stateKey}_${lockKey}` as LockKey;

	private processLockChange = (lockKey: LockKey, change: 'on' | 'off') => {
		if (change === 'on') {
			this.stateLocks.add(lockKey);
		} else {
			this.stateLocks.delete(lockKey);
		}
	};

	private stepIsLocked = (step: ConnectionStepString) => {
		return Array.from(this.stateLocks.keys()).some((key) => {
			return key.startsWith(this.getStepLockMatcher(step));
		});
	};

	private clearLocks = () => {
		this.stateLocks.clear();
	};

	public printStates(): string {
		return CONNECTION_STATE_KEYS.filter((key) => {
			return this.is(key as ConnectionStateString);
		}).join(' | ');
	}

	/**
	 * Note : This method ignores state locks for now .. This could be confusing for users of this class later down the line, but for now I can't think of why we would want to lock the UI in some "in progress" state when we catch a disconnect.
	 */
	private handleDisconnect() {
		dlog(`wallet_debugging`, `wallet_connection_state_DISCONNECTED`);
		// @ts-ignore :: THIS METHOD IS ALLOWED TO WRITE
		this.state = ConnectionStates.NotConnected;
		this.clearLocks();
	}

	private switchOn(updateStep: ConnectionStepString, lockKey?: string) {
		if (lockKey) {
			dlog(`wallet_debugging`, `locking::${updateStep}:${lockKey}`);
			this.processLockChange(this.getLockKey(updateStep, lockKey), 'on');
		}

		// @ts-ignore :: THIS METHOD IS ALLOWED TO WRITE
		this.state |= ConnectionStates[updateStep];
	}

	private switchOff(updateStep: ConnectionStepString, lockKey?: string) {
		if (lockKey) {
			dlog(`wallet_debugging`, `locking::${updateStep}:${lockKey}`);
			this.processLockChange(this.getLockKey(updateStep, lockKey), 'off');
		}

		if (this.stepIsLocked(updateStep)) {
			dlog(`wallet_debugging`, `skipping_off_because_locked::${updateStep}`);
			// If this step is still locked on, don't change the state
			return;
		}

		// @ts-ignore :: THIS METHOD IS ALLOWED TO WRITE
		this.state &= ~ConnectionStates[updateStep];
	}

	/**
	 * Process changes after each udpate
	 */
	private updateHook() {
		// Clear "connecting" state once connected
		if (this.is('AdapterConnected') && this.is('ClientConnected')) {
			this.switchOff('Connecting');
		}
	}

	is(stateQuery: ConnectionStateString) {
		switch (stateQuery) {
			case 'NotConnected':
				return this.state === ConnectionStates.NotConnected;
			case 'Connecting':
				return (
					(this.state & ConnectionStates.Connecting) ===
					ConnectionStates.Connecting
				);
			case 'AdapterConnected':
				return (
					(this.state & ConnectionStates.AdapterConnected) ===
					ConnectionStates.AdapterConnected
				);
			case 'ClientConnected':
				return (
					(this.state & ConnectionStates.ClientConnected) ===
					ConnectionStates.ClientConnected
				);
			case 'BalanceLoaded':
				return (
					(this.state & ConnectionStates.BalanceLoaded) ===
					ConnectionStates.BalanceLoaded
				);
			case 'FullyConnected':
				return (
					(this.state & ConnectionStates.FullyConnected) ===
					ConnectionStates.FullyConnected
				);
			case 'SubaccountsSubscribed':
				return (
					(this.state & ConnectionStates.SubaccountsSubscribed) ===
					ConnectionStates.SubaccountsSubscribed
				);
			default: {
				// Throw a typescript error if we have an unhandled case
				const nothing: never = stateQuery;
				return nothing;
			}
		}
	}

	disconnect() {
		this.handleDisconnect();
	}

	progress(updateStep: ConnectionStepString) {
		dlog(`wallet_debugging`, `connection_state_progress::${updateStep}`);

		if (updateStep === 'NotConnected') {
			this.handleDisconnect();
			return;
		}

		this.switchOn(updateStep);
		this.updateHook();
	}

	update(
		updateStep: ConnectionStepString,
		complete: boolean,
		lockKey?: string
	) {
		dlog(
			`wallet_debugging`,
			`connection_state_update::${updateStep}:${complete}`
		);

		if (updateStep === 'NotConnected' && complete) {
			this.handleDisconnect();
			return;
		}

		if (complete) {
			this.switchOn(updateStep, lockKey);
		} else {
			this.switchOff(updateStep, lockKey);
		}

		this.updateHook();
	}

	get NotConnected() {
		return this.is('NotConnected');
	}
	get Connecting() {
		return this.is('Connecting');
	}
	get AdapterConnected() {
		return this.is('AdapterConnected');
	}
	get ClientConnected() {
		return this.is('ClientConnected');
	}
	get BalanceLoaded() {
		return this.is('BalanceLoaded');
	}
	get FullyConnected() {
		return this.is('FullyConnected');
	}
	get SubaccountsSubscribed() {
		return this.is('SubaccountsSubscribed');
	}
}

export default WalletConnectionState;
