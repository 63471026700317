import { memo } from 'react';
import Env from 'src/environmentVariables/EnvironmentVariables';
import Button from '../Button';
import Text from '../Text/Text';

function GeoblockedPanel({ onAccept }: { onAccept: () => void }) {
	return (
		<div className="h-full w-full flex flex-col items-center p-4 space-y-3 text-justify">
			<Text.H1 className="text-text-emphasis">Restricted Territory</Text.H1>
			<div className="pb-6 text-center text-text-default">
				<Text.BODY2>
					You are accessing Drift&apos;s UI from a restricted territory, as laid
					out in the{' '}
					<a href={Env.mainnetTermsUrl} rel="noreferrer" target="_blank">
						Drift Terms and Conditions
					</a>{' '}
					.
					<br />
					<br />
					Unfortunately, this means you will not be allowed to connect your
					wallet and use Drift Protocol.
				</Text.BODY2>
			</div>
			<Button.Secondary
				size="LARGE"
				highlight
				onClick={onAccept}
				className={'w-full'}
			>
				Close
			</Button.Secondary>
		</div>
	);
}

export default memo(GeoblockedPanel);
