'use client';

import { CCTP_ATTESTATION_API_URL } from 'src/constants/cctp';
import useSWR from 'swr';

const POLLING_INTERVAL_MS = 10_000;

/**
 * Attestation hex is the proof id of the burn on the source chain.
 * This hook polls the attestation API to check if the burn has been finalized.
 */
export const useCctpAttestation = (messageHash: string) => {
	const { data, error, isLoading } = useSWR(
		messageHash ? `${CCTP_ATTESTATION_API_URL}/${messageHash}` : undefined,
		{ refreshInterval: POLLING_INTERVAL_MS }
	);

	const isSuccessful = data?.status === 'complete';
	const attestationHex = data?.attestation ?? '';

	return {
		isSuccessful,
		attestationHex,
		error,
		isLoading,
	};
};
