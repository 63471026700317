'use client';

import React, { memo, useState } from 'react';
import { ArrowLeft } from '@drift-labs/icons';
import Text from 'src/components/Text/Text';
import { useRouter } from 'next/navigation';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import MayanWidget from '../MayanWidget/MayanWidget';
import Button from '../Button';
import { twMerge } from 'tailwind-merge';
import useSafePush from 'src/hooks/useSafePush';
import BridgePanelLayout from '../Layouts/BridgePanelLayout';

export const MayanSwapPanelBase = ({
	hideArrowBack,
}: {
	hideArrowBack?: boolean;
}) => {
	const router = useRouter();
	const { captureEvent } = usePostHogCapture();
	const [swapInfo, setSwapInfo] = useState({
		hash: '',
		toToken: '',
	});

	const safePush = useSafePush();

	const handleClickedMayan = () => {
		captureEvent('clicked_mayan_swap_docs_link');
	};

	return (
		<>
			<div className="flex flex-row items-center justify-start">
				{!hideArrowBack && (
					<button
						onClick={() => router.back()}
						className="text-text-default hover:text-text-emphasis hover:cursor-pointer p-1.5 rounded-full border border-container-border mr-4 flex items-center"
					>
						<ArrowLeft size={24} />
					</button>
				)}
				<Text.H1 className="text-text-emphasis">
					Cross-chain swap with Mayan Swap (Beta):
				</Text.H1>
			</div>
			<div className="flex">
				{!hideArrowBack && (
					<div className="min-w-[55px]">
						{/** Faux container to simulate column taken by back button */}
					</div>
				)}
				<div className="flex flex-col gap-4">
					<div className="flex flex-row items-center justify-between text-text-default">
						<Text.BODY1 className="leading-normal">
							Easily swap from EVM chains like Ethereum and Arbitrum to Solana
							with{' '}
							<a
								href="https://docs.mayan.finance/"
								rel="noreferrer"
								target="_blank"
								onClick={handleClickedMayan}
							>
								Mayan Swap
							</a>
							. Cross-chain swaps automatically include gas drop off (0.05 SOL)
							for new Solana wallets to get you started. You can always adjust
							this in the settings.
						</Text.BODY1>
					</div>
					<div className="mt-4">
						<MayanWidget setSwapInfo={setSwapInfo} />
					</div>
					<div
						className={twMerge(
							'flex justify-center gap-2 mt-4 transition-opacity duration-300 ease-in-out',
							swapInfo.hash ? 'opacity-100' : 'opacity-0 pointer-events-none'
						)}
					>
						<Button.Secondary
							size="MEDIUM"
							className="w-full"
							onClick={() => safePush('/')}
						>
							Explore app
						</Button.Secondary>
						<Button.Primary
							size="MEDIUM"
							className="w-full"
							onClick={() =>
								safePush(
									`/bridge/deposit?hash=${swapInfo.hash}&toToken=${swapInfo.toToken}`
								)
							}
						>
							Deposit funds
						</Button.Primary>
					</div>
				</div>
			</div>
		</>
	);
};

const MayanSwapPanel = () => {
	return (
		<BridgePanelLayout>
			<MayanSwapPanelBase />
		</BridgePanelLayout>
	);
};

export default memo(MayanSwapPanel);
