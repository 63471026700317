import { ZeroHash, id, keccak256, AbiCoder, BytesLike, Log } from 'ethers';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';

const defaultAbiCoder = new AbiCoder();
const LOGS_MESSAGE_TYPE = 'MessageSent(bytes)';

/**
 * Converts a Uint8Array to a hex string.
 * E.g. new Uint8Array([4, 182, 130, 247, ...]) => '0x04b682f7...'
 */
export const byteArrayToHexString = (byteArray?: Uint8Array) => {
	if (!byteArray) return ZeroHash;

	return (
		'0x' +
		Array.from(byteArray, (byte) =>
			('0' + (byte & 0xff).toString(16)).slice(-2)
		).join('')
	);
};

export const hexToBytes = (hex: string): number[] => {
	const bytes: number[] = [];
	for (let c = 0; c < hex.length; c += 2) {
		bytes.push(parseInt(hex.substring(c, c + 2), 16));
	}
	return bytes;
};

export const evmAddressToBytes32 = (address: string): string =>
	`0x000000000000000000000000${address.replace('0x', '')}`;

/**
 * Returns message bytes from decoding the event logs
 * @param logs the event logs of a transaction
 * @param topic the topic to be filter from the log
 */
export function getMessageBytesFromEventLogs(
	logs: Log[],
	topic: string
): BytesLike {
	const eventTopic = id(topic);
	const log = logs.filter((l) => l.topics[0] === eventTopic)[0];
	return defaultAbiCoder.decode(['bytes'], log.data)[0] as BytesLike;
}

/**
 * Returns message hash from the message bytes
 * @param message the message bytes
 */
export function getMessageHashFromBytes(message: BytesLike): string {
	return keccak256(message);
}

export function getMessageHashFromLogs(
	logs: Log[],
	logsMessageType = LOGS_MESSAGE_TYPE
) {
	// decode log to get messageBytes
	const messageBytes = getMessageBytesFromEventLogs(
		logs as Log[],
		logsMessageType
	);
	// hash the message bytes
	const messageHash = getMessageHashFromBytes(messageBytes);

	return { messageBytes, messageHash };
}

const metaMaskConnector = new MetaMaskConnector();
const coinbaseWalletConnector = new CoinbaseWalletConnector({
	options: { appName: 'Drift' },
});

export function getWalletIcon(walletName?: string) {
	switch (walletName) {
		case metaMaskConnector.name:
			return '/assets/icons/wallets/metamask.svg';
		case coinbaseWalletConnector.name:
			return '/assets/icons/wallets/coinbase.svg';
		default:
			return '';
	}
}
