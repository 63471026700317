import {
	ALL_DOMAINS,
	CHAIN_IDS_TO_CCTP_DOMAIN_IDS,
	CHAIN_IDS_TO_MESSAGE_TRANSMITTER_ADDRESSES,
	CHAIN_IDS_TO_TOKEN_MESSENGER_ADDRESSES,
	DOMAIN_TO_USDC_ADDRESSES,
} from 'src/constants/cctp';
import Env from 'src/environmentVariables/EnvironmentVariables';
import invariant from 'tiny-invariant';

const configEnvKey = Env.sdkEnv === 'mainnet-beta' ? 'mainnet' : 'testnet';

export const getTokenMessengerContractAddress = (domain: number): string => {
	return CHAIN_IDS_TO_TOKEN_MESSENGER_ADDRESSES[domain][configEnvKey];
};

export const getMessageTransmitterContractAddress = (
	domain: number
): string => {
	return CHAIN_IDS_TO_MESSAGE_TRANSMITTER_ADDRESSES[domain][configEnvKey];
};

export const getUsdcContractAddress = (domain: number) => {
	return DOMAIN_TO_USDC_ADDRESSES[domain][configEnvKey];
};

export const getChainFromDomain = (domain: number) => {
	const domainUiConfig = getDomainUiConfig(domain);

	return domainUiConfig.evmChain[configEnvKey];
};

export const getEstimatedTransferDurationSecs = (domain: number) => {
	const domainUiConfig = getDomainUiConfig(domain);
	return domainUiConfig.estimatedTransferDurationSecs[configEnvKey];
};

export const getDomainFromChainId = (chainId?: number | 'solana') => {
	return CHAIN_IDS_TO_CCTP_DOMAIN_IDS[chainId] ?? 0;
};

export const getDomainUiConfig = (domain: number) => {
	const domainUiConfig = ALL_DOMAINS.find((config) => config.value === domain);
	invariant(domainUiConfig, `No domain config found for domain ${domain}`);

	return domainUiConfig;
};

export const decodeNonceFromMessage = (message: string): bigint => {
	if (!message) return BigInt(0);

	const messageBytes = Buffer.from(message.replace('0x', ''), 'hex');
	const nonceIndex = 12;
	const nonceBytesLength = 8;
	const eventNonceBytes = messageBytes.subarray(
		nonceIndex,
		nonceIndex + nonceBytesLength
	);
	const eventNonceHex = `0x${eventNonceBytes.toString('hex')}`;
	return BigInt(eventNonceHex);
};
