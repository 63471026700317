import { ErrorFilled, SuccessFilled } from '@drift-labs/icons';
import { PERP_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';

const SettledPnlAlertContent = (props: {
	successfullySettledMarketIndexes: number[];
	marketIndexesWithOracleError: number[];
	marketIndexesWithPnlPoolError: number[];
}) => {
	return (
		<div className="flex flex-col space-y-3 text-text-default">
			{props.successfullySettledMarketIndexes.length > 0 && (
				<div className="flex flex-row">
					<SuccessFilled size={16} className="mr-2" />
					{`${props.successfullySettledMarketIndexes
						.map(
							(marketIndex) =>
								PERP_MARKETS_LOOKUP[marketIndex]?.symbol ?? 'Unknown Market'
						)
						.join(', ')} balance${
						props.successfullySettledMarketIndexes.length > 1 ? 's' : ''
					} settled`}
				</div>
			)}
			{props.marketIndexesWithOracleError.length > 0 && (
				<div className="flex flex-row space-x-2">
					<ErrorFilled size={16} className="mr-2" />
					{`${props.marketIndexesWithOracleError
						.map(
							(marketIndex) =>
								PERP_MARKETS_LOOKUP[marketIndex]?.symbol ?? 'Unknown Market'
						)
						.join(', ')} balance${
						props.marketIndexesWithOracleError.length > 1 ? 's' : ''
					} could not be settled because of an issue with the Oracle`}
				</div>
			)}
			{props.marketIndexesWithPnlPoolError.length > 0 && (
				<div className="flex flex-row space-x-2">
					<ErrorFilled size={16} className="mr-2" />
					{`${props.marketIndexesWithPnlPoolError
						.map((marketIndex) => PERP_MARKETS_LOOKUP[marketIndex]?.symbol)
						.join(', ')} balance${
						props.marketIndexesWithPnlPoolError.length > 1 ? 's' : ''
					} could not be settled because there's not enough liquidity in the settlement pool`}
				</div>
			)}
			{props.marketIndexesWithPnlPoolError.length > 0 ? (
				<div>
					Please try to settle the negative P&L balances in the pool first.
				</div>
			) : props.marketIndexesWithOracleError.length > 0 ? (
				<div>Please try again later to settle all balances.</div>
			) : (
				<></>
			)}
		</div>
	);
};

export { SettledPnlAlertContent };
