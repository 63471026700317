'use client';

import { SnapWalletAdapter } from '@drift-labs/snap-wallet-adapter';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariableTypes';
import useDriftTheme from 'src/hooks/useDriftTheme';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const useCurrentWalletIconSrc = () => {
	const walletState = useDriftStore((s) => s.wallet);

	const isLightTheme = useDriftTheme() === DriftTheme.light;

	if (walletState.isAppKit)
		return isLightTheme
			? '/assets/icons/reown-logo-negative.svg'
			: '/assets/icons/reown-logo.svg';

	if (walletState.isMagicAuth) return `/assets/icons/magic-auth-logo.svg`;

	if (walletState.isMetamask) return SnapWalletAdapter.icon;

	return walletState?.current?.adapter?.icon;
};

export default useCurrentWalletIconSrc;
