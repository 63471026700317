'use client';

import Env from 'src/environmentVariables/EnvironmentVariables';
import useInterval from './useInterval';

/**
 * Calls a callback function on mount and at a pre-determined interval (`Env.uiUpdateIntervalMs`) afterwards.
 * This keeps the update frequency consistent across the app.
 *
 * @param callback Callback that ideally should be wrapped in `useCallback` to prevent unnecessary re-renders.
 * @param essential If true, the callback will be called at a higher frequency (`Env.uiEssentialUpdateIntervalMs`).
 * @param runOnCallbackChange If true, the callback will be called immediately after the callback function changes.
 */
const useUiUpdateInterval = (
	callback: () => void,
	essential: boolean,
	runOnCallbackChange: boolean
) => {
	const intervalMs = essential
		? Env.uiEssentialUpdateIntervalMs
		: Env.uiNonEssentialUpdateIntervalMs;
	useInterval(callback, intervalMs, runOnCallbackChange);
};

export default useUiUpdateInterval;
