'use client';

import * as anchor from '@coral-xyz/anchor';
import {
	getTokenAccount,
	getTokenAddress,
	TOKEN_PROGRAM_ID,
} from '@drift/common';
import { IDL as MessageTransmitterIDL } from 'src/target/cctp/types/message_transmitter';
import { IDL as TokenMessengerMinterIDL } from 'src/target/cctp/types/token_messenger_minter';
import {
	decodeNonceFromMessage,
	getMessageTransmitterContractAddress,
	getTokenMessengerContractAddress,
	getUsdcContractAddress,
} from 'src/utils/cctp';
import { evmAddressToBytes32, hexToBytes } from 'src/utils/evm';
import useCurrentWalletAdapter from '../useCurrentWalletAdapter';
import {
	PublicKey,
	SystemProgram,
	TransactionInstruction,
} from '@solana/web3.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useDriftClient from '../useDriftClient';
import useDriftClientIsReady from '../useDriftClientIsReady';
import TransactionErrorHandler from 'src/utils/TransactionErrorHandler';
import { notify } from 'src/utils/notifications';
import useDriftActions from '../useDriftActions';
import { SOLANA_DOMAIN } from 'src/constants/cctp';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { createTokenAccountIx } from '@drift/common';
import usePostHogCapture from '../posthog/usePostHogCapture';
import usePriorityFeeStore from 'src/stores/usePriorityFeeStore';
import UI_UTILS from '../../utils/uiUtils';
import { ComputeUnits } from '../../@types/types';
import { ConfirmTransactionStep } from 'src/components/Toasts/Notification';

const TOAST_ID = 'cctp-receive-message';

const findProgramAddress = (
	label: string,
	programId: PublicKey,
	extraSeeds: (string | number[] | Buffer | PublicKey)[] = null
): {
	publicKey: PublicKey;
	bump: number;
} => {
	const seeds = [Buffer.from(anchor.utils.bytes.utf8.encode(label))];
	if (extraSeeds) {
		for (const extraSeed of extraSeeds) {
			if (typeof extraSeed === 'string') {
				seeds.push(Buffer.from(anchor.utils.bytes.utf8.encode(extraSeed)));
			} else if (Array.isArray(extraSeed)) {
				seeds.push(Buffer.from(extraSeed as number[]));
			} else if (Buffer.isBuffer(extraSeed)) {
				seeds.push(extraSeed);
			} else {
				seeds.push(extraSeed.toBuffer());
			}
		}
	}
	const res = PublicKey.findProgramAddressSync(seeds, programId);
	return { publicKey: res[0], bump: res[1] };
};

export const useMessageTransmitterSolana = ({
	sourceDomain,
	attestationHex,
	messageBytes,
	eventTracking,
}: {
	sourceDomain: number;
	attestationHex: string;
	messageBytes: string;
	eventTracking?: {
		depositForBurnTxHash: string; // optional tx hash for posthog event capture
	};
}) => {
	const wallet = useCurrentWalletAdapter();
	const getStoreState = useDriftStore((s) => s.get);
	const [userUsdcTokenAccount, setUserUsdcTokenAccount] = useState<PublicKey>();
	const [isTxnLoading, setIsTxnLoading] = useState(false);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const driftActions = useDriftActions();
	const connection = useDriftStore((s) => s.connection.current);
	const { captureEvent } = usePostHogCapture();
	const getPriorityFeeToUse = usePriorityFeeStore((s) => s.getPriorityFeeToUse);

	const provider = driftClient?.provider;
	anchor.setProvider(provider);

	const usdcSolanaAddressStr = getUsdcContractAddress(SOLANA_DOMAIN);
	const messageTransmitterProgramId = new PublicKey(
		getMessageTransmitterContractAddress(SOLANA_DOMAIN)
	);
	const tokenMessengerMinterProgramId = new PublicKey(
		getTokenMessengerContractAddress(SOLANA_DOMAIN)
	);

	useEffect(() => {
		(async () => {
			if (!wallet.publicKey || !usdcSolanaAddressStr) return;
			const userUsdcTokenAccount = await getTokenAddress(
				usdcSolanaAddressStr,
				wallet.publicKey?.toString()
			);
			setUserUsdcTokenAccount(userUsdcTokenAccount);
		})();
	}, [wallet.publicKey, usdcSolanaAddressStr]);

	const messageTransmitterProgram = useMemo(
		() =>
			new anchor.Program(
				MessageTransmitterIDL,
				messageTransmitterProgramId,
				provider
			),
		[provider]
	);
	const tokenMessengerMinterProgram = useMemo(
		() =>
			new anchor.Program(
				TokenMessengerMinterIDL,
				tokenMessengerMinterProgramId,
				provider
			),
		[provider]
	);

	const usdcSourceContractAddress = getUsdcContractAddress(sourceDomain);
	const usdcAddressBytes32 = evmAddressToBytes32(usdcSourceContractAddress);

	const nonce = decodeNonceFromMessage(messageBytes);

	// PDAs
	const messageTransmitterAccount = findProgramAddress(
		'message_transmitter',
		messageTransmitterProgram.programId
	);
	const tokenMessenger = findProgramAddress(
		'token_messenger',
		tokenMessengerMinterProgram.programId
	);
	const tokenMinter = findProgramAddress(
		'token_minter',
		tokenMessengerMinterProgram.programId
	);
	const localToken = findProgramAddress(
		'local_token',
		tokenMessengerMinterProgram.programId,
		[new PublicKey(usdcSolanaAddressStr)]
	);
	const remoteTokenMessengerKey = findProgramAddress(
		'remote_token_messenger',
		tokenMessengerMinterProgram.programId,
		[sourceDomain.toString()]
	);
	const remoteTokenKey = new PublicKey(hexToBytes(usdcAddressBytes32));
	const tokenPair = findProgramAddress(
		'token_pair',
		tokenMessengerMinterProgram.programId,
		[sourceDomain.toString(), remoteTokenKey]
	);
	const custodyTokenAccount = findProgramAddress(
		'custody',
		tokenMessengerMinterProgram.programId,
		[new PublicKey(usdcSolanaAddressStr)]
	);
	const authorityPda = findProgramAddress(
		'message_transmitter_authority',
		messageTransmitterProgram.programId,
		[tokenMessengerMinterProgramId]
	).publicKey;
	const tokenMessengerEventAuthority = findProgramAddress(
		'__event_authority',
		tokenMessengerMinterProgram.programId
	);
	const messageTransmitterEventAuthority = findProgramAddress(
		'__event_authority',
		messageTransmitterProgram.programId
	);

	// Calculate the nonce PDA.
	const maxNoncesPerAccount = 6400;
	const firstNonce =
		((nonce - BigInt(1)) / BigInt(maxNoncesPerAccount)) *
			BigInt(maxNoncesPerAccount) +
		BigInt(1);
	const usedNonces = findProgramAddress(
		'used_nonces',
		messageTransmitterProgram.programId,
		[sourceDomain.toString(), firstNonce.toString()]
	).publicKey;

	// Build the accountMetas list. These are passed as remainingAccounts for the TokenMessengerMinter CPI
	const accountMetas = [
		{
			isSigner: false,
			isWritable: false,
			pubkey: tokenMessenger.publicKey,
		},
		{
			isSigner: false,
			isWritable: false,
			pubkey: remoteTokenMessengerKey.publicKey,
		},
		{
			isSigner: false,
			isWritable: true,
			pubkey: tokenMinter.publicKey,
		},
		{
			isSigner: false,
			isWritable: true,
			pubkey: localToken.publicKey,
		},
		{
			isSigner: false,
			isWritable: false,
			pubkey: tokenPair.publicKey,
		},
		{
			isSigner: false,
			isWritable: true,
			pubkey: userUsdcTokenAccount,
		},
		{
			isSigner: false,
			isWritable: true,
			pubkey: custodyTokenAccount.publicKey,
		},
		{
			isSigner: false,
			isWritable: false,
			pubkey: TOKEN_PROGRAM_ID,
		},
		{
			isSigner: false,
			isWritable: false,
			pubkey: tokenMessengerEventAuthority.publicKey,
		},
		{
			isSigner: false,
			isWritable: false,
			pubkey: tokenMessengerMinterProgram.programId,
		},
	];

	async function createReceiveMessageTxn(
		withCreateAtaIfNeeded = false,
		forceVersionedTxObject = false
	) {
		// check if authority has UDSC ata, if not create createATA ix
		if (!wallet.publicKey && withCreateAtaIfNeeded) return;

		let createAtaIx: TransactionInstruction | undefined;

		if (withCreateAtaIfNeeded) {
			const authorityUsdcTokenAccount = await getTokenAccount(
				connection,
				usdcSolanaAddressStr,
				wallet.publicKey.toString()
			);

			if (!authorityUsdcTokenAccount) {
				createAtaIx = await createTokenAccountIx(
					wallet.publicKey,
					new PublicKey(usdcSolanaAddressStr),
					wallet.publicKey
				);
			}
		}

		const receiveMessageIx =
			messageTransmitterProgram.instruction.receiveMessage(
				{
					message: Buffer.from(messageBytes.replace('0x', ''), 'hex'),
					attestation: Buffer.from(attestationHex.replace('0x', ''), 'hex'),
				},
				{
					accounts: {
						payer: wallet.publicKey,
						caller: wallet.publicKey,
						authorityPda,
						messageTransmitter: messageTransmitterAccount.publicKey,
						usedNonces,
						receiver: tokenMessengerMinterProgram.programId,
						systemProgram: SystemProgram.programId,
						eventAuthority: messageTransmitterEventAuthority.publicKey,
						program: messageTransmitterProgramId,
					},
					remainingAccounts: accountMetas,
				}
			);

		const tx = await driftClient.buildTransaction(
			createAtaIx ? [createAtaIx, receiveMessageIx] : receiveMessageIx,
			UI_UTILS.getTxParams(
				(computeUnits) => getPriorityFeeToUse(undefined, computeUnits),
				600_000 as ComputeUnits
			),
			undefined,
			undefined,
			forceVersionedTxObject
		);

		return tx;
	}

	const receiveUsdc = useCallback(async () => {
		if (!driftClientIsReady || !driftClient) return;

		try {
			setIsTxnLoading(true);
			notify({
				type: 'info',
				message: 'Receiving USDC on Solana',
				description: <ConfirmTransactionStep />,
				id: TOAST_ID,
				showUntilCancelled: true,
			});
			const tx = await createReceiveMessageTxn(true);
			const { txSig } = await driftClient.sendTransaction(tx);

			const txResult = await driftActions.waitForTxnToConfirm(txSig);

			if (txResult?.value?.err) {
				notify({
					type: 'error',
					message: 'Failed to receive USDC on Solana',
					description: 'Please try again.',
					id: TOAST_ID,
					updatePrevious: true,
					showUntilCancelled: false,
				});

				return false;
			}

			notify({
				type: 'success',
				message: 'Received USDC on Solana',
				description: 'Start depositing and trading on Drift now!',
				id: TOAST_ID,
				updatePrevious: true,
				showUntilCancelled: false,
			});

			!!eventTracking && trackFundsReceived(txSig);

			return true;
		} catch (error) {
			TransactionErrorHandler.handleError({
				error,
				toastId: TOAST_ID,
				wallet: getStoreState().wallet?.current?.adapter,
			});
			return false;
		} finally {
			setIsTxnLoading(false);
		}
	}, [sourceDomain, attestationHex, messageBytes, eventTracking]);

	async function trackFundsReceived(txSig: string) {
		const tx = await driftClient.connection.getParsedTransaction(txSig, {
			maxSupportedTransactionVersion: 0,
		});

		const preTxnTokenAccount = tx.meta.preTokenBalances.find(
			(balance) =>
				balance.mint === usdcSolanaAddressStr &&
				balance.owner === wallet.publicKey.toString()
		);
		const postTxnTokenAccount = tx.meta.postTokenBalances.find(
			(balance) =>
				balance.mint === usdcSolanaAddressStr &&
				balance.owner === wallet.publicKey.toString()
		);

		if (!preTxnTokenAccount || !postTxnTokenAccount) return 0n;

		const amountReceived =
			postTxnTokenAccount.uiTokenAmount.uiAmount -
			preTxnTokenAccount.uiTokenAmount.uiAmount;

		captureEvent('cctp_receive_message_success', {
			sourceDomain,
			depositForBurnTxHash: eventTracking.depositForBurnTxHash,
			amountReceived,
			recipientWallet: wallet.publicKey.toString(),
			receiveMessageTxHash: txSig,
		});
	}

	return { createReceiveMessageTxn, receiveUsdc, isTxnLoading };
};
