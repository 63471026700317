'use client';

import { useWallet, WalletContextState } from '@solana/wallet-adapter-react';

import { useMemo } from 'react';

/**
 * 🚨 TODO
 * This hook needs to act as a black box, managing the wallet context state for both @solana/wallet-adapter-react and @web3modal/solana/react. If done correctly then the output of this hook should exactly match the functionality of the @solana/wallet-adapter-react `useWallet` hook.
 * **/

/**
 * custom useWalletContext to abstract away @solana/wallet-adapter-react
 * @returns
 */
const useWalletContext = (): WalletContextState => {
	const walletContext_ = useWallet();

	const walletContext = useMemo(() => {
		return {
			publicKey: walletContext_.publicKey,
			wallets: walletContext_.wallets,
			wallet: walletContext_.wallet,
			adapter: walletContext_.wallet?.adapter,
			select: walletContext_.select,
			disconnect: walletContext_.disconnect,
			autoConnect: walletContext_.autoConnect,
			connecting: walletContext_.connecting,
			connected: walletContext_.connected,
			disconnecting: walletContext_.disconnecting,
			sendTransaction: walletContext_.sendTransaction,
			signTransaction: walletContext_.signTransaction,
			signAllTransactions: walletContext_.signAllTransactions,
			signMessage: walletContext_.signMessage,
			signIn: walletContext_.signIn,
			connect: walletContext_.connect,
		};
	}, [walletContext_]);

	return walletContext;
};

export default useWalletContext;
