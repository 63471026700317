'use client';

import { useEffect } from 'react';

const useHotkey = (key: string, callback: (event?: KeyboardEvent) => void) => {
	useEffect(() => {
		const keyHandler = (event: KeyboardEvent) => {
			if (event.key === key) {
				callback(event);
			}
		};

		window.addEventListener('keyup', keyHandler);

		return () => {
			window.removeEventListener('keyup', keyHandler);
		};
	}, []);
};

export default useHotkey;
