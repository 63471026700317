import { isDev } from 'src/environmentVariables/EnvironmentVariables';

let devLogging = isDev();

const LoggingService = {
	setDevLogging: (value: boolean) => {
		devLogging = value;
	},
	debug: (...props: Parameters<Console['info']>) => {
		if (devLogging) {
			console.info(...props);
		}
	},
	info: (message: string, payload?: any) => {
		console.info(message, payload);
	},
	error: (message: string, payload?: any) => {
		console.error(message, payload);
	},
};

export default LoggingService;
