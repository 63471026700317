'use client';

import {
	BASE_PRECISION_EXP,
	BigNum,
	calculateInitUserFee,
	LAMPORTS_EXP,
} from '@drift-labs/sdk';
import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import {
	MIN_LEFTOVER_SOL,
	NEW_ACCOUNT_BASE_COST,
	NEW_ACCOUNT_BASE_RENT,
	NEW_ACCOUNT_DONATION,
} from '@drift/common';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useDriftClient from 'src/hooks/useDriftClient';

export type AccountCreationCostBreakdown = {
	loaded: boolean; // Whether full cost breakdown has been loaded
	totalCost: BigNum; // The total cost to create a new account
	baseAccountRent: BigNum; // The base solana network account rent component of the fee (refundable)
	newAccountDonation: BigNum; // The drift donation component of the fee (non-refundable)
	extraRent: BigNum; // The dynamic extra rent component of the fee (refundable)
	minSolToDeposit: BigNum; // The minimum amount of SOL required to make a new account
	minSolForFees: BigNum; // The minimum amount of SOL required to cover fees
};

const _useAccountCreationCost = (): AccountCreationCostBreakdown => {
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const [loaded, setLoaded] = useState(false);
	const [cost, setCost] = useState(NEW_ACCOUNT_BASE_COST);

	useEffect(() => {
		if (driftClient && driftClientIsReady) {
			const stateAccount = driftClient.getStateAccount();
			const fee = calculateInitUserFee(stateAccount);

			const newCost = NEW_ACCOUNT_BASE_COST.add(
				BigNum.from(fee, BASE_PRECISION_EXP)
			);

			setCost(newCost);
			setLoaded(true);
		}
	}, [driftClient, driftClientIsReady]);

	return {
		loaded,
		totalCost: cost,
		newAccountDonation: NEW_ACCOUNT_DONATION,
		baseAccountRent: NEW_ACCOUNT_BASE_RENT,
		extraRent: cost.sub(NEW_ACCOUNT_BASE_RENT).sub(NEW_ACCOUNT_DONATION),
		minSolToDeposit: cost.add(MIN_LEFTOVER_SOL),
		minSolForFees: MIN_LEFTOVER_SOL,
	};
};

/**
 * Cost of subaccount creation. Includes both base rent, donation (if any), and account creation rent.
 */
export const useAccountCreationCost = singletonHook(
	{
		loaded: false,
		totalCost: NEW_ACCOUNT_BASE_COST,
		newAccountDonation: NEW_ACCOUNT_DONATION,
		baseAccountRent: NEW_ACCOUNT_BASE_RENT,
		extraRent: BigNum.zero(LAMPORTS_EXP),
		minSolToDeposit: NEW_ACCOUNT_BASE_COST.add(MIN_LEFTOVER_SOL),
		minSolForFees: MIN_LEFTOVER_SOL,
	} as AccountCreationCostBreakdown,
	_useAccountCreationCost
);
