'use client';

import React, { ReactNode, useEffect, useState } from 'react';
import Checkbox from './Checkbox';
import { twMerge } from 'tailwind-merge';

const CheckboxInput = (props: {
	checked: boolean;
	onChange: () => void;
	label: string | ReactNode;
	className?: string;
	disabled?: boolean;
	secondaryStyle?: boolean;
	alignStart?: boolean;
	linkElement?: ReactNode;
	style?: React.CSSProperties;
}) => {
	const [localCheckedState, setCheckedState] = useState(props.checked);

	useEffect(() => {
		setCheckedState(props.checked);
	}, [props.checked]);

	const onChange = () => {
		setCheckedState(!localCheckedState);
		props.onChange();
	};

	if (props.secondaryStyle) {
		return (
			<div
				className={
					props.alignStart
						? twMerge(
								`flex flex-row items-stretch text-xs text-text-secondary font-thin cursor-pointer`,
								props.disabled && 'opacity-50',
								props.disabled ? `cursor-not-allowed` : `cursor-pointer`,
								props.className
						  )
						: twMerge(
								`flex items-center text-xs text-text-secondary font-thin cursor-pointer`,
								props.disabled && 'opacity-50',
								props.disabled ? `cursor-not-allowed` : `cursor-pointer`,
								props.className
						  )
				}
				onClick={() => {
					if (!props.disabled) onChange();
				}}
				style={props.style}
			>
				<div
					className={
						props.alignStart
							? 'shrink-0 select-none w-4 max-h-[21px] relative'
							: `shrink-0 select-none`
					}
				>
					<Checkbox
						checked={localCheckedState}
						className={props.alignStart ? 'absolute bottom-[2px]' : ''}
					/>
				</div>
				<div className="ml-2 shrink-1">
					{props.label}
					{props.linkElement}
				</div>
			</div>
		);
	}

	return (
		<div
			className={twMerge(
				`inline-flex items-center space-x-2 text-text-secondary text-tiny rounded-md p-2 bg-container-bg border border-container-border select-none`,
				props.disabled ? `cursor-not-allowed` : `cursor-pointer`,
				props.className
			)}
			onClick={() => {
				if (!props.disabled) onChange();
			}}
			style={props.style}
		>
			<div className="flex-grow">{props.label}</div>

			<Checkbox checked={localCheckedState} />
		</div>
	);
};

export default React.memo(CheckboxInput);
