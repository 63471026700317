import { UserSettings } from 'src/environmentVariables/EnvironmentVariables';
import { setLocalStorageItem } from 'src/utils/localStorageUtils';
import UI_UTILS from 'src/utils/uiUtils';

export const syncGetSavedData = (
	dataKey: string,
	defaultValue: any = ''
): UserSettings | undefined => {
	if (!UI_UTILS.isWindowDefined()) return undefined;

	const savedSetting = localStorage.getItem(dataKey);

	if (!savedSetting) return undefined;

	try {
		return JSON.parse(savedSetting);
	} catch {
		return defaultValue;
	}
};

export const syncGetSavedString = (dataKey: string): string | undefined => {
	if (!UI_UTILS.isWindowDefined()) return undefined;

	const savedValue = localStorage.getItem(dataKey);

	if (!savedValue) return undefined;

	return savedValue;
};

export const syncSaveData = (key: string, data: any): void => {
	if (!UI_UTILS.isWindowDefined()) return undefined;

	setLocalStorageItem(key, JSON.stringify(data));
};

export const syncDeleteSavedData = (key: string): void => {
	if (!UI_UTILS.isWindowDefined()) return undefined;

	localStorage.removeItem(key);
};
