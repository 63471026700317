import produce from 'immer';
import { create } from 'zustand';

export type FeatureFlagKey =
	| 'SKIP_TRANSACTION_PREFLIGHTS'
	| 'HIGHLIGHTED_ASSETS';

type BaseFeatureFlagDetails = {
	key: Exclude<FeatureFlagKey, 'HIGHLIGHTED_ASSETS'>;
	enabled: boolean; // Whether the flag is enabled or not
	rawPayload?: any;
};

type HighlightedAssetsFlagDetails = Pick<
	BaseFeatureFlagDetails,
	'enabled' | 'rawPayload'
> & {
	key: 'HIGHLIGHTED_ASSETS';
	assets: string[];
};

type FlagState = {
	SKIP_TRANSACTION_PREFLIGHTS: BaseFeatureFlagDetails;
	HIGHLIGHTED_ASSETS: HighlightedAssetsFlagDetails;
};

export type FeatureFlagDetails =
	| BaseFeatureFlagDetails
	| HighlightedAssetsFlagDetails;

export const FEATURE_FLAG_DEFAULTS: FlagState = {
	SKIP_TRANSACTION_PREFLIGHTS: {
		key: 'SKIP_TRANSACTION_PREFLIGHTS',
		enabled: false, // Preflights are enabled by default
	},
	HIGHLIGHTED_ASSETS: {
		key: 'HIGHLIGHTED_ASSETS',
		enabled: false,
		assets: [],
	},
};

export interface FeatureFlagStore {
	set: (x: (s: FeatureFlagStore) => void) => void;
	get: () => FeatureFlagStore;
	flags: FlagState;
}

const useFeatureFlagStore = create<FeatureFlagStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	flags: FEATURE_FLAG_DEFAULTS,
}));

export default useFeatureFlagStore;
