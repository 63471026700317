'use client';

import { memo } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Button from '../Button';
import Text from '../Text/Text';
import useSafePush from 'src/hooks/useSafePush';

function ReadyToTradePanel() {
	const [isMagicAuth, lastRouteBeforeConnect] = useDriftStore((s) => [
		s.wallet.isMagicAuth,
		s.wallet.lastRouteBeforeConnect,
	]);
	const safePush = useSafePush();

	const handleFinishOnboarding = () => {
		safePush(lastRouteBeforeConnect || '/');
	};

	return (
		<div className="w-full h-full">
			<Text.H1 className="text-text-emphasis">
				You&apos;re ready to trade!
			</Text.H1>
			<div className="py-6 text-text-default">
				<Text.BODY3>
					{isMagicAuth ? (
						<>
							You can manage the funds in your Magic Wallet any time by visiting{' '}
							<span className="font-bold">Manage Magic Wallet</span> in the
							Account menu.
						</>
					) : (
						<>You can now trade, earn, borrow, and lend on Drift.</>
					)}
				</Text.BODY3>
			</div>
			{isMagicAuth && (
				<div className="my-4 text-center">
					<img
						src="/assets/images/manage-wallet.webp"
						alt="Manage Wallet Menu"
						className="mx-auto w-[220px]"
					/>
				</div>
			)}
			<div className="my-4 mt-10">
				<Button.Secondary
					highlight
					size={'MEDIUM'}
					className={'w-full'}
					onClick={handleFinishOnboarding}
				>
					<Text.BODY2>Start Trading</Text.BODY2>
				</Button.Secondary>
			</div>
		</div>
	);
}

export default memo(ReadyToTradePanel);
