'use client';

import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import NumLib from 'src/utils/NumLib';
import Env from '../../environmentVariables/EnvironmentVariables';
import ColourCodedValue from './ColourCodedValue';
import { twMerge } from 'tailwind-merge';

type Props = {
	value: number;
	displayType:
		| 'notional'
		| 'tradePrecision'
		| 'millified'
		| 'percentageChange'
		| 'decimalPlaces';
	subType?: '$' | '$Change' | 'usdc' | 'usdcIcon';
	colourCoded?: boolean;
	keepStaleValues?: boolean;
	showSkeletonInstead?: string;
	className?: string;
	loading?: boolean;
	textOverride?: string;
	dataPuppetTag?: string;
	style?: CSSProperties;
	decimalPlaces?: number;
};

const numIsInvalid = (val: number) => {
	if (val === null) return true;
	if (typeof val === 'undefined') return true;
	if (!isFinite(val)) return true;
	if (isNaN(val)) return true;
	return false;
};

const formatValue = ({
	value,
	displayType,
	subType,
	isInvalid,
	decimalPlaces,
}: Pick<Props, 'value' | 'subType' | 'displayType' | 'decimalPlaces'> & {
	isInvalid: boolean;
}) => {
	let prefix = '';
	let formattedValue = value?.toString() ?? '';
	let suffix = '';

	if (isInvalid) {
		formattedValue = '';
	}

	switch (displayType) {
		case 'decimalPlaces':
			if (!isInvalid) {
				formattedValue = value.toFixed(decimalPlaces ?? 4);
			}
			break;
		case 'millified':
			if (!isInvalid) {
				formattedValue = NumLib.millify(value).displayString;
			}
			break;
		case 'notional':
			if (!isInvalid) {
				formattedValue = NumLib.formatNum
					.toNotionalNum(Math.abs(value))
					.toLocaleString(Env.locale, {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
			}
			break;
		case 'tradePrecision':
			if (!isInvalid) {
				formattedValue = NumLib.formatNum.toTradePrecisionString(value, true);
			}
			break;
		case 'percentageChange':
			if (!isInvalid) {
				formattedValue = Math.abs(parseFloat(value.toFixed(2))).toLocaleString(
					Env.locale,
					{ minimumFractionDigits: 2, maximumFractionDigits: 2 }
				);

				if (value < 0) {
					prefix = '-';
				} else if (value > 0) {
					prefix = '+';
				}
			}
			suffix = '%';
			break;
	}

	switch (subType) {
		case '$Change':
			prefix = '$';
			if (value < 0) prefix = `-${prefix}`;
			else prefix = `+${prefix}`;
			break;
		case '$':
			prefix = '$';
			if (value < 0) prefix = `-${prefix}`;
			break;
		case 'usdc':
			suffix = ' USDC';
			break;
	}

	return `${prefix}${formattedValue}${suffix}`;
};

const prefixIcon = (subtype: Props['subType']) => {
	switch (subtype) {
		case 'usdcIcon':
			return (
				<img
					alt=""
					width="22"
					height="22"
					src={`/assets/icons/usdc-logo-blue.svg`}
					className={`mr-2 mb-0.5`}
				/>
			);
		default:
			return null;
	}
};

const NumberDisplay = ({
	value,
	displayType,
	subType,
	colourCoded,
	keepStaleValues = true,
	showSkeletonInstead = '',
	loading = false,
	textOverride = '',
	dataPuppetTag,
	style,
	...props
}: Props) => {
	const isInvalid = useMemo(() => numIsInvalid(value), [value]);

	const [staleValue, setStaleValue] = useState(undefined);
	const [showStaleValue, setShowStaleValue] = useState(false);

	const currentFormattedValue = formatValue({
		value,
		displayType,
		subType,
		isInvalid,
		decimalPlaces: props.decimalPlaces,
	});

	useEffect(() => {
		if (!keepStaleValues) {
			setStaleValue(undefined);
			setShowStaleValue(false);
			return;
		}
		if (!isInvalid) {
			setStaleValue(value);
			setShowStaleValue(false);
		} else {
			setShowStaleValue(true);
		}
	}, [isInvalid, value]);

	let colourCodedValue = null;

	const memoedClassName = useMemo(
		() => twMerge(showStaleValue && `opacity-50`, props.className),
		[showStaleValue, props.className]
	);

	(() => {
		if (colourCoded) {
			if (isInvalid) {
				if (!showStaleValue || numIsInvalid(staleValue)) return;
				colourCodedValue = staleValue;
				return;
			}
			colourCodedValue = value;
		}
	})();

	if ((showSkeletonInstead && isInvalid && !staleValue) || loading === true) {
		return (
			<SkeletonValuePlaceholder
				className={showSkeletonInstead}
				loading={loading}
			/>
		);
	}

	return (
		<div
			className={memoedClassName}
			data-puppet-tag={dataPuppetTag}
			style={style}
		>
			<ColourCodedValue value={colourCodedValue} className="flex items-center">
				{prefixIcon(subType)}
				{textOverride
					? textOverride
					: showStaleValue
					? formatValue({
							value: staleValue,
							displayType,
							subType,
							isInvalid: numIsInvalid(staleValue),
							decimalPlaces: props.decimalPlaces,
					  })
					: currentFormattedValue}
			</ColourCodedValue>
		</div>
	);
};

export default React.memo(NumberDisplay);
