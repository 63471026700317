import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { CSSProperties } from 'react';

const SpinnerAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

type SpinnerProps = {
	size: number;
};

const SpinnerOuter = styled.div<SpinnerProps>`
	overflow: hidden;
	animation: ${SpinnerAnimation} 1s infinite linear;
`;

const GradientLoadingSpinner = ({
	size = 42,
	style,
	...props
}: {
	outerClass?: string;
	size?: number;
	style?: CSSProperties;
}) => {
	return (
		<div style={style}>
			<SpinnerOuter size={size} className={`${props.outerClass ?? ''}`}>
				<svg
					width={size}
					height={size}
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle
						cx="9"
						cy="9"
						r="6.65"
						stroke="url(#paint0_linear_11189_5483)"
						strokeWidth="1.7"
					/>
					<circle
						cx="9"
						cy="9"
						r="6.65"
						stroke="url(#paint1_linear_11189_5483)"
						strokeWidth="1.7"
					/>
					<circle
						cx="9"
						cy="9"
						r="6.65"
						stroke="url(#paint2_angular_11189_5483)"
						strokeOpacity="0.2"
						strokeWidth="1.7"
					/>
					<circle
						cx="9"
						cy="9"
						r="6.65"
						stroke="url(#paint3_angular_11189_5483)"
						strokeOpacity="0.1"
						strokeWidth="1.7"
					/>
					<circle
						cx="9"
						cy="9"
						r="6.65"
						stroke="url(#paint4_linear_11189_5483)"
						strokeOpacity="0.2"
						strokeWidth="1.7"
					/>
					<defs>
						<linearGradient
							id="paint0_linear_11189_5483"
							x1="1.25023"
							y1="9"
							x2="16.2502"
							y2="7.25509"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#FF3873" />
							<stop offset="0.473958" stopColor="#711EFF" />
							<stop offset="1" stopColor="#3FE5FF" />
						</linearGradient>
						<linearGradient
							id="paint1_linear_11189_5483"
							x1="2.42969"
							y1="2.19033"
							x2="10.5888"
							y2="11.2098"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="#F6F063" />
							<stop offset="1" stopColor="#E07774" stopOpacity="0" />
						</linearGradient>
						<radialGradient
							id="paint2_angular_11189_5483"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(1.89547 6.7729) rotate(59.4295) scale(3.5536 3.54788)"
						>
							<stop offset="0.925861" stopColor="white" />
							<stop offset="1" stopColor="white" stopOpacity="0" />
						</radialGradient>
						<radialGradient
							id="paint3_angular_11189_5483"
							cx="0"
							cy="0"
							r="1"
							gradientUnits="userSpaceOnUse"
							gradientTransform="translate(4.07401 5.38182) rotate(51.9823) scale(2.32054 3.48355)"
						>
							<stop offset="0.0864251" stopColor="white" />
							<stop offset="0.94115" stopColor="white" stopOpacity="0" />
						</radialGradient>
						<linearGradient
							id="paint4_linear_11189_5483"
							x1="3.21716"
							y1="5.66628"
							x2="10.4743"
							y2="9"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor="white" />
							<stop offset="1" stopOpacity="0" />
						</linearGradient>
					</defs>
				</svg>
			</SpinnerOuter>
		</div>
	);
};

export default GradientLoadingSpinner;
