'use client';

import { BigNum, QUOTE_PRECISION_EXP, SpotMarketConfig } from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import { useEffect } from 'react';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useWalletBalancesStore from 'src/stores/useWalletBalancesStore';
import useGetOraclePriceForMarket from './useGetOraclePriceForMarket';
import useSPLTokenBalance from './useSPLTokenBalance';
import { dlog } from '../dev';
import useDriftStore from '../stores/DriftStore/useDriftStore';

export const useSyncSpotMarketBalance = (
	spotMarketConfig: SpotMarketConfig
) => {
	const setWalletBalancesStore = useWalletBalancesStore((s) => s.set);
	const [splTokenBalance] = useSPLTokenBalance(spotMarketConfig.mint);
	const walletSolBalance = useDriftStore((s) => s.wallet.currentSolBalance);

	const marketId = MarketId.createSpotMarket(spotMarketConfig.marketIndex);

	const getOraclePriceForMarket = useGetOraclePriceForMarket();

	const updateBalances = (baseBalance: number) => {
		if (baseBalance === undefined) return;

		const priceToUse = getOraclePriceForMarket(marketId);

		setWalletBalancesStore((s) => {
			const quoteValue = baseBalance * priceToUse.toNum();

			s.balances[spotMarketConfig.symbol] = {
				base: BigNum.fromPrint(
					baseBalance.toString(),
					spotMarketConfig.precisionExp
				),
				quote: BigNum.fromPrint(quoteValue.toString(), QUOTE_PRECISION_EXP),
				loading: false,
			};
		});
	};

	// only need an update when the wallet balance changes, or on a large time interval, rather than on every price movement
	// large time interval should account for major price movements of wallet collateral
	useEffect(() => {
		const newBalance =
			spotMarketConfig.symbol === 'SOL'
				? walletSolBalance?.toNum() ?? 0
				: splTokenBalance;

		dlog(
			`spl_subscription`,
			`useSyncSpotMarketBalance :: ${spotMarketConfig.symbol} :: ${newBalance}`
		);

		updateBalances(newBalance);
	}, [splTokenBalance, spotMarketConfig.marketIndex, walletSolBalance]);
};

// export const useSyncWalletBalances = singletonHook(
// 	() => {},
// 	() => {
// 		SPOT_MARKETS_LOOKUP.map(useSyncSpotMarketBalance);
// 	}
// );

// NOTE ON WHY NOT USING SINGLETON HERE :: Once this singleton hook runs, other hooks which want to "activate" end up hooking into the existing instance in the singleton. this PREVENTS the hook from actually being re-run, which is bad for the edge-case where a user might deposit a brand new SPL-Token into their wallet while they have the Drift UI open. The singleton would prevent the hook from re-running and picking up the updated balance. This is why we're not using singleton here. Instead of using a singleton, the SPL Balance hook is efficiently reducing wasted RPC calls by re-using past values/connections if available. This therefore is a win-win. It's important to note that to pick up the new SPL balance the hook will still have to RE-RUN , e.g. the user would have to close and re-open the deposit modal for this to kick in.
export const useSyncWalletBalances = () => {
	SPOT_MARKETS_LOOKUP.map(useSyncSpotMarketBalance);
};
