import InfoMessage from '../TradeForm/InfoMessage';

export const MaxSubaccountsWarning = () => {
	return (
		<InfoMessage
			type="error"
			messageTitle="Max number of subaccounts reached"
			message={
				<div>
					Due to a huge influx of new user account creations, we&apos;ve hit
					maximum capacity of users on the Drift smart contract. The capacity
					will be upgraded soon. Please try again later.
				</div>
			}
		/>
	);
};
