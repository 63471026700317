import { MouseEventHandler, PropsWithChildren } from 'react';

export interface SwitchProps {
	checked: boolean;
	className?: string;
	onChange: (x) => void;
	showGradient?: boolean;
	disabled?: boolean;
}

const Switch = ({
	checked = false,
	className = '',
	showGradient = true,
	children,
	onChange,
	disabled = false,
}: PropsWithChildren<SwitchProps>) => {
	const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault();
		e.stopPropagation();
		onChange(!checked);
	};

	return (
		<div className={`flex items-center ${className}`}>
			<span className="mr-1">
				<span className="">{children}</span>
			</span>
			<span
				className={`inline-flex overflow-hidden border rounded-full ${
					checked
						? `${disabled ? 'border-purple-120' : 'border-purple-50'}`
						: 'border-container-border'
				}`}
			>
				<span
					className={`${
						checked && showGradient
							? 'bg-interactive-link'
							: 'bg-button-secondary-bg'
					} relative inline-flex flex-shrink-0 h-4 w-8
					${
						disabled ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer'
					} transition-colors ease-in-out duration-200 
					focus:outline-none`}
				>
					<button
						type="button"
						className={`relative inline-flex flex-shrink-0 h-4 w-8 border-2 border-transparent 
				rounded-full transition-colors ease-in-out duration-200 
				focus:outline-none ${
					disabled
						? `hover:cursor-not-allowed ${checked ? 'bg-purple-120' : ''}`
						: 'hover:cursor-pointer'
				}`}
						role="switch"
						aria-checked={checked}
						onClick={handleClick}
						disabled={disabled}
					>
						<span className="sr-only">{children}</span>
						<span
							aria-hidden="true"
							className={`${
								checked
									? `translate-x-4 ${
											disabled ? 'bg-neutrals-40' : 'bg-neutrals-10'
									  }`
									: `translate-x-0 ${
											disabled ? 'bg-neutrals-40' : 'bg-text-emphasis'
									  }`
							} absolute pointer-events-none inline-block h-3 w-3 rounded-full
				shadow transform ring-0 transition ease-in-out duration-200`}
						></span>
					</button>
				</span>
			</span>
		</div>
	);
};

export default Switch;
