import React from 'react';
import { twMerge } from 'tailwind-merge';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import Image from 'next/image';

export const getPredictionMarketImgSrc = (symbol: string) => {
	return PredictionMarketConfigs.getConfigFromMarketSymbol(symbol)?.imgSrc;
};

export const PredictionMarketIcon = ({
	symbol,
	loading,
	small,
	className,
}: {
	symbol: string;
	loading?: boolean;
	small?: boolean;
	className?: string;
}) => {
	if (loading) {
		return (
			<SkeletonValuePlaceholder
				loading
				className={small ? 'w-4 h-4' : 'w-16 h-16'}
			/>
		);
	}

	return (
		<div
			className={twMerge(
				'flex items-center justify-center rounded relative shrink-0',
				small ? 'w-4 h-4' : 'w-16 h-16',
				className
			)}
		>
			<Image
				className={twMerge('object-cover rounded')}
				src={PredictionMarketConfigs.getConfigFromMarketSymbol(symbol)?.imgSrc}
				alt="Prediction Market Icon"
				fill
			/>
		</div>
	);
};
