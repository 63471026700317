'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const useAppEventEmitter = () => {
	const eventEmitter = useDriftStore((s) => s.appEventEmitter);

	return eventEmitter;
};

export default useAppEventEmitter;
