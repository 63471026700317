"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultDepositorAccount = void 0;
const sdk_1 = require("@drift-labs/sdk");
const accountSubscribers_1 = require("../accountSubscribers");
const vaultsProgramAccount_1 = require("./vaultsProgramAccount");
const addresses_1 = require("../addresses");
class VaultDepositorAccount extends vaultsProgramAccount_1.VaultsProgramAccount {
    constructor(program, vaultDepositorPubkey, accountLoader, accountSubscriptionType = 'polling') {
        super();
        if (accountSubscriptionType === 'polling') {
            this.accountSubscriber = new accountSubscribers_1.PollingVaultDepositorSubscriber(program, vaultDepositorPubkey, accountLoader);
        }
        else {
            throw new Error('Websocket subscription not yet implemented');
        }
    }
    static getAddressSync(programId, vault, authority) {
        return (0, addresses_1.getVaultDepositorAddressSync)(programId, vault, authority);
    }
    /**
     * Calculates the percentage of a depositor's equity that will be paid as profit share fees.
     *
     * @param vaultProfitShare Vault's profit share fee
     * @param depositorEquity Vault depositor's equity amount
     */
    calcProfitShareFeesPct(vaultProfitShare, depositorEquity) {
        const accountData = this.accountSubscriber.getAccountAndSlot().data;
        const profit = depositorEquity
            .sub(accountData.netDeposits)
            .sub(accountData.cumulativeProfitShareAmount);
        if (profit.lte(new sdk_1.BN(0))) {
            return sdk_1.ZERO;
        }
        const profitShareAmount = profit
            .mul(vaultProfitShare)
            .div(sdk_1.PERCENTAGE_PRECISION);
        const profitShareProportion = profitShareAmount
            .mul(sdk_1.PERCENTAGE_PRECISION)
            .div(depositorEquity);
        return profitShareProportion;
    }
}
exports.VaultDepositorAccount = VaultDepositorAccount;
