'use client';

import {
	BaseSignerWalletAdapter,
	WalletName,
} from '@solana/wallet-adapter-base';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import { singletonHook } from 'react-singleton-hook';

const INITIAL_WALLET_STATE: Pick<
	BaseSignerWalletAdapter,
	| 'connected'
	| 'publicKey'
	| 'disconnect'
	| 'connect'
	| 'signTransaction'
	| 'name'
> = {
	name: 'blank' as WalletName<'MyCryptoWallet'>,
	connected: false,
	publicKey: undefined,
	disconnect: async () => {
		console.error('Tried disconnecting from DEFAULT WALLET');
	},
	connect: async () => {
		console.error('Tried connecting with DEFAULT WALLET');
	},
	signTransaction: async (_t) => {
		console.error('Tried signing tx with DEFAULT WALLET');
		return _t;
	},
};

const useCurrentWalletAdapter = () => {
	const walletInStore = useDriftStore((s) => s.wallet?.current?.adapter);
	const wallet = walletInStore ?? INITIAL_WALLET_STATE;

	return wallet as BaseSignerWalletAdapter;
};

export default singletonHook(INITIAL_WALLET_STATE, useCurrentWalletAdapter);
