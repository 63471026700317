import {
	Circuit,
	Delegation,
	Disclaimers,
	Discord,
	Github,
	Grafana,
	GraphCircle,
	IFStaking,
	IconProps,
	LP,
	LendBorrow,
	Overview,
	PointsMono,
	Fuel,
	SSS,
	Search,
	Settings,
	Share,
	Spot,
	Stats,
	Terms,
	Trade,
	Transfer,
	Twitter,
	Drift,
	Compare,
	Vote,
	Stake,
} from '@drift-labs/icons';
import { DriftStore } from '../../stores/DriftStore/useDriftStore';
import { MarketType } from '@drift-labs/sdk';
import {
	DRIFT_DISCORD_URL,
	DRIFT_GRAFANA_METRICS_URL,
} from '../../constants/constants';
import { JSX } from 'react';

/**
 * This file stores the base config for navigation across desktop and mobile.
 *
 * Explanation for how things are split:
 *
 * Properties of Desktop navigation items:
 * - Category
 * - Icon
 * - Name
 * - Description
 * - Destination
 *
 * Properties of Mobile navigation items:
 * - Mobile
 * - Icon
 * - Name (THESE ARE DIFFERENT FROM DESKTOP FOR THE SAME DESTINATION)
 * - Destination
 *
 * Common properties for desktop+mobile:
 * - Destination
 * - Icon
 *
 * -------
 *
 * Types of handlers:
 *  - Navigate to a hardcoded route
 *  - Do some arbitrary thing like display a modal
 *
 * -------
 *
 * ADDING A NEW PAGE:
 * - Add a new key to the NavigationOptionKey enum
 * - Add a new key to the NavigationCategory enum (if applicable)
 * - Add a new entry to the BASE_NAVIGATION_OPTIONS object
 * - Add a new entry to the DESKTOP_NAVIGATION_OPTIONS object
 * - Add a new entry to the MOBILE_OPTIONS_ICONS object (if applicable)
 */

export type NavigationOptionKey =
	| 'grafanaDashboard'
	| 'leaderboard'
	| 'perpetuals'
	| 'spot'
	| 'swap'
	| 'driftStaking'
	| 'insuranceStaking'
	| 'driftEarn'
	| 'liquidityPools'
	| 'borrowLend'
	| 'overview'
	| 'overviewBalances'
	| 'settings'
	| 'docs'
	| 'referrals'
	| 'github'
	| 'twitter'
	| 'discord'
	| 'feedback'
	| 'terms'
	| 'disclaimers'
	| 'fundingHistory'
	| 'stats'
	| 'fuel'
	| 'marketMakingVaults'
	| 'superstakesol'
	| 'insuranceFundStakingHistory'
	| 'error'
	| 'elections'
	| 'bet'
	| 'dSolStaking'
	| 'vaults'
	| 'governance';
export type NavigationCategory =
	| 'driftStaking'
	| 'overview'
	| 'trade'
	| 'earn'
	| 'bet'
	| 'reward'
	| 'stats'
	| 'more';

export enum NavigationCase {
	OPEN_WINDOW,
	NAVIGATE_TO_ROUTE,
	OPEN_MODAL,
	OPEN_WIDGET,
}

export type RouteNavigationResult = {
	type: NavigationCase.NAVIGATE_TO_ROUTE;
	route: string;
	opts?: {
		isMarketNavigation?: boolean; // This is used in the navigator state hook, which helps it know that the route should probably be re-routed to their previous favourite market
		marketNavigationType?: MarketType;
		queryOption?: string;
	};
};

export type ModalNavigationResult = {
	type: NavigationCase.OPEN_MODAL;
	modal: keyof DriftStore['modals'];
};

export type WindowNavigationResult = {
	type: NavigationCase.OPEN_WINDOW;
	href: string;
};

export type WidgetNavigationResult = {
	type: NavigationCase.OPEN_WIDGET;
	formId: number;
};

export type NavigationResult =
	| RouteNavigationResult
	| ModalNavigationResult
	| WindowNavigationResult
	| WidgetNavigationResult;

const makeRouteResult = (
	route: string,
	opts?: RouteNavigationResult['opts']
): RouteNavigationResult => ({
	type: NavigationCase.NAVIGATE_TO_ROUTE,
	route,
	opts,
});

const makeModalResult = (
	modal: keyof DriftStore['modals']
): ModalNavigationResult => ({
	type: NavigationCase.OPEN_MODAL,
	modal,
});

const makeWindowResult = (href: string): WindowNavigationResult => ({
	type: NavigationCase.OPEN_WINDOW,
	href,
});

// const makeWidgetResult = (formId: number): WidgetNavigationResult => ({
// 	type: NavigationCase.OPEN_WIDGET,
// 	formId,
// });

type NavigationOption = {
	key: NavigationOptionKey;
	icon: (props: IconProps) => JSX.Element;
	handler: (opts?: RouteNavigationResult['opts']) => NavigationResult;
	hideCondition?: boolean;
};

export type DesktopCategoryOption = {
	type: 'categoryOption';
	categoryName: string;
	options: ({
		name: string;
		description?: string;
		popOutWindow?: boolean;
		advanced?: boolean;
		new?: boolean;
		isExternalLink?: boolean;
	} & NavigationOption)[];
	hideCondition?: boolean;
	className?: string;
	hideOnMobileNav?: boolean;
};

export type DesktopStaticOption = {
	type: 'staticOption';
	categoryName: string;
	handler: () => NavigationResult;
	hideCondition?: boolean;
	className?: string;
	hideOnMobileNav?: boolean;
	isNew?: boolean;
	isAlpha?: boolean;
};

export type DesktopNavigationOption =
	| DesktopCategoryOption
	| DesktopStaticOption;

export type DesktopNavigationOptions = {
	[category in NavigationCategory]: DesktopNavigationOption;
};

export type MobileNavigationOptions = ({
	name: string;
} & NavigationOption)[];

// filter all options lists with this so we can easily use feature flags
// have to do inline for DESKTOP_NAVIGATION_OPTIONS since we use object.entries()
export const featureFlagFilter = (
	navOption: NavigationOption | DesktopNavigationOption
) => !navOption.hideCondition;

// @ts-ignore
export const BASE_NAVIGATION_OPTIONS: {
	[key in NavigationOptionKey]: NavigationOption;
} = {
	stats: {
		key: 'stats',
		handler: () => makeRouteResult('/stats'),
		icon: Stats,
	},
	grafanaDashboard: {
		key: 'grafanaDashboard',
		handler: () => makeWindowResult(DRIFT_GRAFANA_METRICS_URL),
		icon: Grafana,
	},
	perpetuals: {
		key: 'perpetuals',
		handler: () =>
			// hardcode to SOL-PERP, because navigating to / will display current selected market in DriftStore,
			// and if the market happens to be a prediction market, we will render the prediction market on the
			// trade page, which we currently don't want
			makeRouteResult('/SOL-PERP', {
				isMarketNavigation: true,
				marketNavigationType: MarketType.PERP,
			}),
		icon: Trade,
	},
	spot: {
		key: 'spot',
		handler: () =>
			makeRouteResult('/', {
				isMarketNavigation: true,
				marketNavigationType: MarketType.SPOT,
			}),
		icon: Spot,
	},
	swap: {
		key: 'swap',
		handler: () => makeRouteResult('/swap'),
		icon: Transfer,
	},
	insuranceStaking: {
		key: 'insuranceStaking',
		handler: () => makeRouteResult('/earn/stake'),
		icon: IFStaking,
	},
	liquidityPools: {
		key: 'liquidityPools',
		handler: () => makeRouteResult('/BAL'),
		icon: LP,
	},
	borrowLend: {
		key: 'borrowLend',
		handler: () => makeRouteResult('/earn/lend-borrow/deposits'),
		icon: LendBorrow,
	},
	driftEarn: {
		key: 'driftEarn',
		handler: () => makeRouteResult('/earn'),
		icon: Compare,
	},
	overview: {
		key: 'overview',
		handler: () => makeRouteResult('/overview'),
		icon: Overview,
	},
	overviewBalances: {
		key: 'overviewBalances',
		handler: () => makeRouteResult('/overview/balances'),
		icon: Overview,
	},
	settings: {
		key: 'settings',
		handler: () => makeModalResult('showSettingsModal'),
		icon: Settings,
	},
	docs: {
		key: 'docs',
		handler: () => makeWindowResult('https://docs.drift.trade'),
		icon: Search,
	},
	referrals: {
		key: 'referrals',
		handler: () => makeRouteResult('/overview/referrals'),
		icon: Delegation,
	},
	github: {
		key: 'github',
		handler: () => makeWindowResult('https://github.com/drift-labs'),
		icon: Github,
	},
	twitter: {
		key: 'twitter',
		handler: () => makeWindowResult('https://twitter.com/DriftProtocol'),
		icon: Twitter,
	},
	discord: {
		key: 'discord',
		handler: () => makeWindowResult(DRIFT_DISCORD_URL),
		icon: Discord,
	},
	feedback: {
		key: 'feedback',
		handler: () => makeWindowResult(DRIFT_DISCORD_URL),
		icon: Share,
	},
	terms: {
		key: 'terms',
		handler: () =>
			makeWindowResult(
				'https://docs.drift.trade/legal-and-regulations/terms-of-use'
			),
		icon: Terms,
	},
	disclaimers: {
		key: 'disclaimers',
		handler: () =>
			makeWindowResult(
				'https://docs.drift.trade/legal-and-regulations/disclaimer'
			),
		icon: Disclaimers,
	},
	fundingHistory: {
		key: 'fundingHistory',
		handler: () => makeRouteResult('/funding?market=SOL-PERP'),
		icon: GraphCircle,
	},
	fuel: {
		key: 'fuel',
		handler: () => makeRouteResult('/fuel'),
		icon: Fuel,
	},
	marketMakingVaults: {
		key: 'marketMakingVaults',
		handler: () => makeRouteResult('/earn/mm-vaults'),
		icon: Circuit,
	},
	superstakesol: {
		key: 'superstakesol',
		handler: () => makeWindowResult('https://superstakesol.com'),
		icon: SSS,
	},
	insuranceFundStakingHistory: {
		key: 'insuranceFundStakingHistory',
		handler: () => makeRouteResult('/overview/history/insurance-fund-staking'),
		icon: IFStaking,
	},
	driftStaking: {
		key: 'driftStaking',
		handler: () => makeRouteResult('/drift-staking'),
		icon: Drift,
	},
	elections: {
		key: 'elections',
		handler: () => makeRouteResult('/elections'),
		icon: Vote,
	},
	bet: {
		key: 'bet',
		handler: () => makeRouteResult('/bet'),
		icon: Spot,
	},
	dSolStaking: {
		key: 'dSolStaking',
		handler: () => makeRouteResult('/earn/dsol-liquid-staking'),
		icon: LP,
	},
	vaults: {
		key: 'vaults',
		handler: () => makeRouteResult('/vaults'),
		icon: Stake,
	},
	governance: {
		key: 'governance',
		handler: () => makeRouteResult('/drift-staking'),
		icon: Drift,
	},
};

export const NAVIGATION_QUERY_OPTIONS = {
	leaderboard: {
		previousResults: 'previousResults',
	},
};

export const DESKTOP_NAVIGATION_OPTIONS: DesktopNavigationOptions = {
	overview: {
		type: 'staticOption',
		categoryName: 'Overview',
		...BASE_NAVIGATION_OPTIONS.overview,
	},
	trade: {
		type: 'categoryOption',
		categoryName: 'Trade',
		options: [
			{
				...BASE_NAVIGATION_OPTIONS.perpetuals,
				name: 'Perpetuals',
				description: 'Trade the hottest perp markets with up to 20x leverage',
			},
			{
				...BASE_NAVIGATION_OPTIONS.spot,
				name: 'Spot',
				description: 'Trade assets with up to 5x leverage',
			},
			{
				...BASE_NAVIGATION_OPTIONS.swap,
				name: 'Swap',
				description: 'Swap any pair with up to 5x leverage',
			},
			{
				...BASE_NAVIGATION_OPTIONS.elections,
				name: 'Memecoin Swap',
				description: 'Quickly and safely swap election memecoins',
			},
		].filter(featureFlagFilter),
	},
	bet: {
		type: 'staticOption',
		categoryName: 'BET',
		isAlpha: true,
		...BASE_NAVIGATION_OPTIONS.bet,
	},
	earn: {
		type: 'categoryOption',
		categoryName: 'Earn',
		options: [
			{
				...BASE_NAVIGATION_OPTIONS.driftEarn,
				name: 'Explore & Compare',
				description: 'See yield across each product for all tokens',
				new: true,
			},
			{
				...BASE_NAVIGATION_OPTIONS.borrowLend,
				name: 'Lend/Borrow',
				description: 'Earn yield on your deposits through lending',
			},
			{
				...BASE_NAVIGATION_OPTIONS.vaults,
				name: 'Vaults',
				description:
					'Maximize your yields with trading strategies from leading teams across Solana',
			},
			{
				...BASE_NAVIGATION_OPTIONS.dSolStaking,
				name: 'dSOL Liquid Staking',
				description: `Liquid stake your SOL with Drift to earn yield`,
			},
			{
				...BASE_NAVIGATION_OPTIONS.insuranceStaking,
				name: 'Insurance Fund Staking',
				description:
					'Stake your assets into a vault and earn yield from exchange fees',
			},
			{
				...BASE_NAVIGATION_OPTIONS.superstakesol,
				name: 'super stake sol',
				description: 'Earn leveraged yield on SOL staking tokens with 1-click',
				isExternalLink: true,
			},
		].filter(featureFlagFilter),
	},
	reward: {
		type: 'categoryOption',
		categoryName: 'Rewards',
		options: [
			{
				...BASE_NAVIGATION_OPTIONS['fuel'],
				name: 'Fuel',
				description: 'An incentive program for Drifters',
			},
			{
				...BASE_NAVIGATION_OPTIONS.referrals,
				name: 'Referrals',
				description:
					'Refer friends to Drift and earn a share of their trading fees',
			},
		].filter(featureFlagFilter),
	},
	driftStaking: {
		type: 'staticOption',
		categoryName: 'Stake DRIFT',
		hideOnMobileNav: true,
		...BASE_NAVIGATION_OPTIONS.driftStaking,
	},
	stats: {
		type: 'staticOption',
		categoryName: 'Stats',
		hideOnMobileNav: false,
		...BASE_NAVIGATION_OPTIONS.stats,
	},
	more: {
		type: 'categoryOption',
		categoryName: 'More',
		options: [
			{
				...BASE_NAVIGATION_OPTIONS.governance,
				name: 'Governance',
				description:
					'DRIFT is a governance token powering the Drift ecosystem.',
			},
			{
				...BASE_NAVIGATION_OPTIONS.liquidityPools,
				name: 'BAL',
				description: 'Provide Backstop AMM Liquidity',
				advanced: true,
			},
			{
				...BASE_NAVIGATION_OPTIONS.grafanaDashboard,
				name: `Drift's Grafana Dashboard`,
				description: `Deep dive into the health of each market and the protocol`,
				isExternalLink: true,
			},
			{
				...BASE_NAVIGATION_OPTIONS.feedback,
				name: `Help & Feedback`,
				description: `Get help, submit product feedback and ideas`,
			},
			{
				...BASE_NAVIGATION_OPTIONS.docs,
				name: 'Docs',
				description:
					'Everything you need to know about getting started with Drift v2',
				isExternalLink: true,
			},
		].filter(featureFlagFilter),
	},
	// feedback: {
	// 	type: 'staticOption',
	// 	categoryName: 'Feedback',
	// 	className: 'bg-transparent text-feedback-button mt-0.5',
	// 	...BASE_NAVIGATION_OPTIONS.feedback,
	// 	hideCondition: !Env.enableSupportWidget,
	// 	hideOnMobileNav: true,
	// },
};

export const MOBILE_NAVIGATION_OPTIONS = DESKTOP_NAVIGATION_OPTIONS;

// The new mobile navigation has the same options but different icons mapped to the categories and static options
export const MOBILE_OPTIONS_ICONS = {
	overview: Overview,
	trade: Trade,
	elections: Vote,
	bet: Spot,
	earn: IFStaking,
	reward: PointsMono,
	more: Stats,
	stats: Stats,
	feedback: Share,
};
