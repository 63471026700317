import {
	PREDICTION_MARKET_CATEGORY,
	PredictionMarketCategoryConfig,
} from 'src/@types/predictionMarkets';
// import { USElectionsSummaryCard } from 'src/components/PredictionMarkets/SummaryCards/USElectionsSummaryCard';

const CATEGORY_IMAGE_PATH = '/assets/images/prediction-markets/categories';
const CATEGORY_IMAGE_BACKGROUND_PATH = `/assets/images/prediction-markets/category-backgrounds`;

export const PREDICTION_MARKET_CATEGORIES_LOOKUP: Record<
	PREDICTION_MARKET_CATEGORY,
	PredictionMarketCategoryConfig
> = {
	[PREDICTION_MARKET_CATEGORY.ALL]: {
		label: 'All Markets',
		imgSrc: `${CATEGORY_IMAGE_PATH}/drift-bet.webp`,
	},
	[PREDICTION_MARKET_CATEGORY.US_ELECTIONS]: {
		label: 'US Election Forecast',
		imgSrc: `${CATEGORY_IMAGE_PATH}/us-elections.webp`,
		backgroundSrc: `${CATEGORY_IMAGE_BACKGROUND_PATH}/us-elections.webp`,
		// summary: <USElectionsSummaryCard />,
	},
	[PREDICTION_MARKET_CATEGORY.FORMULA_1]: {
		label: 'Formula 1',
		imgSrc: `${CATEGORY_IMAGE_PATH}/formula-1-v2.webp`,
		// backgroundSrc: `${CATEGORY_IMAGE_BACKGROUND_PATH}/breakpoint.webp`,
	},
	[PREDICTION_MARKET_CATEGORY.SPORTS]: {
		label: 'Sports',
		imgSrc: `${CATEGORY_IMAGE_PATH}/sports.webp`,
	},
};
