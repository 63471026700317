import { memo } from 'react';
import Text from '../Text/Text';

function ScreenshotWithTweets() {
	return (
		<div className="flex flex-col justify-center w-full h-full">
			<div className="h-full min-h-[300px] max-h-[511px] relative w-full max-w-900px">
				<div className="w-[80%] h-full absolute left-[10%] bg-[url('/assets/images/trade-screenshot.webp')] bg-contain bg-no-repeat bg-center"></div>
				<div className="rounded bg-container-bg border border-container-border shadow-xl absolute top-0 left-0 text-text-emphasis max-w-[325px] p-4">
					<Text.H4>
						Bullish on{' '}
						<a
							href="https://twitter.com/driftprotocol"
							target="_blank"
							rel="noreferrer"
						>
							@DriftProtocol
						</a>{' '}
						Solana deriv DEX&apos;s really showing solid talent and shipping
						speed.
					</Text.H4>
					<div className="flex flex-row items-center mt-2">
						<img
							src="/assets/images/pfp-mckenna.webp"
							alt="McKenna"
							className="inline-block w-[20px] h-[20px] mr-2"
						/>{' '}
						<Text.BODY1>McKenna</Text.BODY1>
					</div>
				</div>
				<div className="rounded bg-container-bg border border-container-border shadow-xl absolute bottom-0 right-0 text-text-emphasis max-w-[325px] p-4">
					<Text.H4>
						Probably 1 of the best DEX&apos;s I&apos;ve used in all respects
					</Text.H4>
					<div className="mt-2">
						<img
							src="/assets/images/pfp-cryptocobean.webp"
							alt="McKenna"
							className="inline-block w-[20px] h-[20px] mr-2"
						/>{' '}
						<Text.BODY1>CryptoCoBean</Text.BODY1>
					</div>
				</div>
			</div>
		</div>
	);
}

export default memo(ScreenshotWithTweets);
