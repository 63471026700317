'use client';

import CheckboxInput from 'src/components/CheckboxInput';
import Chevron from 'src/components/Icons/Chevron';
import RoundedGradientBorderBox from 'src/components/RoundedGradientBorderBox';
import Switch from 'src/components/Switch';
import Text from 'src/components/Text/Text';
import GradientText from 'src/components/Utils/GradientText';
import React, {
	PropsWithChildren,
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { AccountData } from 'src/stores/useDriftAccountsStore';
import MarketIcon from '../Utils/MarketIcon';
import { MarketType, PerpMarketConfig } from '@drift-labs/sdk';
import { ENUM_UTILS, UIMarket } from '@drift/common';
import { Delegation } from '@drift-labs/icons';
import UI_UTILS from 'src/utils/uiUtils';
import NumLib from 'src/utils/NumLib';
import { twMerge } from 'tailwind-merge';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { MAX_LEVERAGE_OPTIONS } from 'src/constants/constants';
import useTargetedPopover from 'src/hooks/useTargetedPopover';
import PopoverWrapper from '../PopoverWrapper';
import { Placement } from '@floating-ui/react';
import { useDrawersStore } from 'src/stores/useDrawersStore';

type SelectProps = {
	id: string;
	className?: string;
	labelClassName?: string;
	optionsClassName?: string;
	selection: string | any[] | any;
	options: {
		value: any;
		label: string;
		icon?: ReactNode;
		description?: string;
		customTextClass?: string;
		selectedTextClass?: string;
		className?: string;
		selectedIcon?: ReactNode;
	}[];
	onChange: (value: string | any) => void;
	style?: React.CSSProperties;
	defaultLabel?: string; // Displays this string if value '' is selected
	noStyle?: boolean;
	small?: boolean;
	maxHeight?: number;
	center?: boolean;
	useRoundedGradient?: boolean;
	useFullWidth?: boolean;
	customRounding?: string;
	disabled?: boolean;
	customWidth?: string;
	preventPopupIfOneOptionOnly?: boolean;
	customPopupWidthClass?: string;
	useTargetWidth?: boolean;
	customHeight?: string;
	placement?: Placement;
	title?: string;
};

type MultiSelectProps = Omit<SelectProps, 'options' | 'onChange'> & {
	options: {
		label: string;
		value: any;
		checked: boolean;
		onCheck: (value: string) => void;
		switch?: boolean;
	}[];
};

type SelectStateResult = ReturnType<typeof useSelectState>;

const useSelectState = (
	props: Pick<
		SelectProps,
		'selection' | 'options' | 'preventPopupIfOneOptionOnly'
	> & {
		usingPopupWrapper?: boolean;
		showPopup: boolean;
		setShowPopup: (showPopup: boolean) => void;
	}
) => {
	const [selectedOption, setSelectedOption] = useState(
		props.options.find((option) => option.value === props.selection) ??
			props.options[0]
	);

	useEffect(() => {
		setSelectedOption(
			props.options.find((option) => option.value === props.selection)
		);
	}, [props.selection, props.options]);

	const toggleShowPopup = () => {
		if (props.preventPopupIfOneOptionOnly && props.options.length === 1) return;

		props.setShowPopup(!props.showPopup);
	};

	const popupRef = useRef<HTMLDivElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const setPopupRef = useCallback((node) => {
		popupRef.current = node;
	}, []);
	const setWrapperRef = useCallback((node) => {
		wrapperRef.current = node;
	}, []);

	const handleClick = (clickEvent: PointerEvent) => {
		if (props.usingPopupWrapper) return;
		if (!props.showPopup) return;

		const newFocus = clickEvent.target as Element;

		// if (!popupRef.current) return;

		// check if the blur occured from a click inside of wrapper
		if (
			wrapperRef.current.contains(newFocus) ||
			wrapperRef.current === newFocus
		) {
			return;
		}

		props.setShowPopup(false);
	};

	useEffect(() => {
		window.addEventListener('mousedown', handleClick);

		return () => window.removeEventListener('mousedown', handleClick);
	}, [props.showPopup]);

	return {
		selectedOption,
		setPopupRef,
		setWrapperRef,
		toggleShowPopup,
		popupRef,
		wrapperRef,
	};
};

const SelectWrapper = (
	props: PropsWithChildren<
		Pick<SelectStateResult, 'setWrapperRef'> &
			Pick<
				SelectProps,
				'useFullWidth' | 'disabled' | 'customWidth' | 'customHeight'
			>
	>
) => {
	return (
		<div
			className={twMerge(
				'relative',
				props.useFullWidth && 'w-full',
				props.customWidth && props.customWidth,
				props.customHeight && props.customHeight,
				props.disabled && `hover:cursor-not-allowed pointer-events-none`
			)}
			ref={props.setWrapperRef}
		>
			{props.children}
		</div>
	);
};
SelectWrapper.displayName = 'SelectWrapper';

const Default = ({
	id,
	selection,
	options,
	onChange,
	defaultLabel,
	maxHeight,
	useFullWidth,
	customRounding,
	disabled,
	optionsClassName,
	preventPopupIfOneOptionOnly,
	className,
	labelClassName,
	useTargetWidth,
	placement,
	customHeight,
}: SelectProps) => {
	const {
		refs,
		floatingStyles,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: placement ?? 'bottom-start',
		},
		{
			offset: 5,
			disableAutoPlacement: true,
		}
	);

	const { selectedOption, setPopupRef, setWrapperRef } = useSelectState({
		selection,
		options,
		usingPopupWrapper: true,
		preventPopupIfOneOptionOnly,
		setShowPopup: setIsPopoverOpen,
		showPopup: isPopoverOpen,
	});

	return (
		<SelectWrapper
			setWrapperRef={setWrapperRef}
			useFullWidth={useFullWidth}
			disabled={disabled}
			customHeight={customHeight}
		>
			<div
				id={id}
				ref={refs.setReference}
				onClick={() => {
					setIsPopoverOpen(!isPopoverOpen);
				}}
				className={twMerge(
					`px-2 bg-input-bg border-tooltip-bg text-text-input hover:bg-input-bg-hover border flex items-center hover:cursor-pointer rounded-sm text-sm select-none`,
					useFullWidth && 'w-full',
					customHeight ? customHeight.replace('max-', '') : 'h-8',
					customRounding,
					className
				)}
			>
				<div className="flex items-center justify-between w-full">
					<div
						className={twMerge(
							'inline-flex items-center truncate overflow-ellipsis',
							disabled && 'text-text-secondary',
							labelClassName,
							selectedOption?.selectedTextClass
						)}
					>
						{!selectedOption?.value && defaultLabel
							? defaultLabel
							: selectedOption?.label}
						{selectedOption?.icon && selectedOption.icon}
					</div>

					{!(preventPopupIfOneOptionOnly && options.length === 1) && (
						<Chevron
							direction={isPopoverOpen ? 'up' : 'down'}
							className="w-3 h-3 ml-1 text-darkBlue-30"
						/>
					)}
				</div>
			</div>

			{isPopoverOpen && (
				<PopoverWrapper
					portalId={id}
					ref={refs.setFloating}
					{...getFloatingProps()}
					style={floatingStyles}
					className={twMerge('z-[150]')}
					useTargetWidth={useTargetWidth}
				>
					<div
						className="w-full overflow-auto border bg-main-bg -bottom-2 thin-scroll border-container-border text-text-input"
						style={maxHeight ? { maxHeight: `${maxHeight}px` } : {}}
						ref={setPopupRef}
					>
						{options.map((option) => {
							return (
								<div
									key={option.label}
									className={`border-b divide-y select-none border-container-border last:border-b-0 hover:cursor-pointer`}
									onClick={() => {
										onChange(option.value);
										setIsPopoverOpen(false);
									}}
								>
									<GradientText onHover>
										<div
											className={twMerge(
												'flex items-center px-2 py-1 pt-2 text-text-input font-normal text-[14px]',
												disabled && 'text-text-secondary',
												option.customTextClass,
												optionsClassName
											)}
										>
											{option.label}
											{option.icon && option.icon}
										</div>
									</GradientText>
								</div>
							);
						})}
					</div>
				</PopoverWrapper>
			)}
		</SelectWrapper>
	);
};

const DefaultV2 = ({
	id,
	selection,
	options,
	onChange,
	defaultLabel,
	maxHeight,
	useFullWidth,
	customRounding,
	disabled,
	optionsClassName,
	preventPopupIfOneOptionOnly,
	className,
	labelClassName,
	useTargetWidth,
	placement,
	customHeight,
	title,
}: SelectProps) => {
	const {
		refs,
		floatingStyles,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: placement ?? 'bottom-start',
		},
		{
			offset: 5,
			disableAutoPlacement: true,
		}
	);

	const { selectedOption, setPopupRef, setWrapperRef } = useSelectState({
		selection,
		options,
		usingPopupWrapper: true,
		preventPopupIfOneOptionOnly,
		setShowPopup: setIsPopoverOpen,
		showPopup: isPopoverOpen,
	});

	return (
		<SelectWrapper
			setWrapperRef={setWrapperRef}
			useFullWidth={useFullWidth}
			disabled={disabled}
			customHeight={customHeight}
		>
			<div
				id={id}
				ref={refs.setReference}
				onClick={() => {
					setIsPopoverOpen(!isPopoverOpen);
				}}
				className={twMerge(
					`px-2 bg-input-bg border-tooltip-bg text-text-input hover:bg-input-bg-hover border flex items-center hover:cursor-pointer rounded-sm text-sm select-none`,
					useFullWidth && 'w-full',
					customHeight ? customHeight.replace('max-', '') : 'h-8',
					customRounding,
					className
				)}
			>
				<div className="flex items-center justify-between w-full">
					<div
						className={twMerge(
							'inline-flex items-center truncate overflow-ellipsis',
							disabled && 'text-text-secondary'
						)}
					>
						<Text.P1 className="mr-2 text-text-label">{title}</Text.P1>
					</div>
					<div
						className={twMerge(
							'inline-flex items-center truncate overflow-ellipsis text-text-emphasis',
							disabled && 'text-text-secondary',
							labelClassName,
							selectedOption?.selectedTextClass
						)}
					>
						{!selectedOption?.value && defaultLabel
							? defaultLabel
							: selectedOption?.label}
						{selectedOption?.icon && selectedOption.icon}
					</div>

					{!(preventPopupIfOneOptionOnly && options.length === 1) && (
						<Chevron
							direction={isPopoverOpen ? 'up' : 'down'}
							className="w-3 h-3 ml-1 text-text-emphasis"
						/>
					)}
				</div>
			</div>

			{isPopoverOpen && (
				<PopoverWrapper
					portalId={id}
					ref={refs.setFloating}
					{...getFloatingProps()}
					style={floatingStyles}
					className={twMerge('z-[150]')}
					useTargetWidth={useTargetWidth}
				>
					<div
						className="w-full overflow-auto border bg-main-bg -bottom-2 thin-scroll border-container-border text-text-input"
						style={maxHeight ? { maxHeight: `${maxHeight}px` } : {}}
						ref={setPopupRef}
					>
						{options.map((option) => {
							return (
								<div
									key={option.label}
									className={`border-b divide-y select-none border-container-border last:border-b-0 hover:cursor-pointer`}
									onClick={() => {
										onChange(option.value);
										setIsPopoverOpen(false);
									}}
								>
									<GradientText onHover>
										<div
											className={twMerge(
												'flex items-center px-2 py-1 pt-2 text-text-input font-normal text-[14px]',
												disabled && 'text-text-secondary',
												option.customTextClass,
												optionsClassName
											)}
										>
											{option.label}
											{option.icon && option.icon}
										</div>
									</GradientText>
								</div>
							);
						})}
					</div>
				</PopoverWrapper>
			)}
		</SelectWrapper>
	);
};

const PerpSelectorV2 = ({
	id,
	selection,
	options,
	onChange,
	defaultLabel,
	maxHeight,
	useFullWidth,
	customRounding,
	disabled,
	optionsClassName,
	preventPopupIfOneOptionOnly,
	className,
	labelClassName,
	useTargetWidth,
	placement,
	customHeight,
	title,
}: SelectProps) => {
	const {
		refs,
		floatingStyles,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: placement ?? 'bottom-start',
		},
		{
			offset: 5,
			disableAutoPlacement: true,
		}
	);

	const { selectedOption, setPopupRef, setWrapperRef } = useSelectState({
		selection,
		options,
		usingPopupWrapper: true,
		preventPopupIfOneOptionOnly,
		setShowPopup: setIsPopoverOpen,
		showPopup: isPopoverOpen,
	});

	const isMobile = useIsMobileScreenSize();

	return (
		<SelectWrapper
			setWrapperRef={setWrapperRef}
			useFullWidth={useFullWidth}
			disabled={disabled}
			customHeight={customHeight}
		>
			<div
				id={id}
				ref={refs.setReference}
				onClick={() => {
					setIsPopoverOpen(!isPopoverOpen);
				}}
				className={twMerge(
					`px-2 bg-input-bg border-tooltip-bg text-text-input hover:bg-input-bg-hover border flex items-center hover:cursor-pointer rounded-sm text-sm select-none`,
					useFullWidth && 'w-full',
					customHeight ? customHeight.replace('max-', '') : 'h-8',
					customRounding,
					className
				)}
			>
				<div className="flex items-center justify-between w-full">
					<div
						className={twMerge(
							'inline-flex items-center truncate overflow-ellipsis',
							disabled && 'text-text-secondary'
						)}
					>
						<Text.P1 className="mr-2 text-text-label">{title}</Text.P1>
					</div>
					<div
						className={twMerge(
							'inline-flex items-center truncate overflow-ellipsis text-text-emphasis',
							disabled && 'text-text-secondary',
							labelClassName,
							selectedOption?.selectedTextClass
						)}
					>
						<MarketIcon
							marketSymbol={selectedOption?.label ?? 'SOL-PERP'}
							sizeClass="w-[18px] h-[18px] mr-1"
						/>
						{!selectedOption?.value && defaultLabel
							? defaultLabel
							: selectedOption?.label}
						{selectedOption?.icon && selectedOption.icon}
					</div>

					{!(preventPopupIfOneOptionOnly && options.length === 1) && (
						<Chevron
							direction={isPopoverOpen ? 'up' : 'down'}
							className="w-3 h-3 ml-1 text-text-emphasis"
						/>
					)}
				</div>
			</div>

			{isPopoverOpen && (
				<PopoverWrapper
					portalId={id}
					ref={refs.setFloating}
					{...getFloatingProps()}
					style={floatingStyles}
					className={twMerge(
						'thin-scroll z-[150]',
						!useTargetWidth && 'w-[220px]',
						isMobile ? 'max-h-[150px]' : 'max-h-[250px]'
					)}
					useTargetWidth={useTargetWidth}
				>
					<div
						className="w-full overflow-auto border bg-main-bg -bottom-2 thin-scroll border-container-border text-text-input"
						style={maxHeight ? { maxHeight: `${maxHeight}px` } : {}}
						ref={setPopupRef}
					>
						{options.map((option) => {
							return (
								<div
									key={option.label}
									className={`border-b divide-y select-none border-container-border last:border-b-0 hover:cursor-pointer`}
									onClick={() => {
										onChange(option.value);
										setIsPopoverOpen(false);
									}}
								>
									<GradientText onHover>
										<div
											className={twMerge(
												'flex items-center px-2 py-1 pt-2 text-text-input font-normal text-[14px]',
												disabled && 'text-text-secondary',
												option.customTextClass,
												optionsClassName
											)}
										>
											{option.value !== 'all' && (
												<MarketIcon
													marketSymbol={option.label}
													sizeClass="w-[18px] h-[18px] mr-1"
												/>
											)}
											{option.label}
											{option.icon && option.icon}
										</div>
									</GradientText>
								</div>
							);
						})}
					</div>
				</PopoverWrapper>
			)}
		</SelectWrapper>
	);
};

const MarketSelector = ({
	id,
	selection,
	options,
	onChange,
	defaultLabel,
	maxHeight,
	useFullWidth,
	customWidth,
	customRounding,
	marketType,
	disabled,
	placement,
}: SelectProps & { marketType: MarketType }) => {
	const {
		refs,
		floatingStyles,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: placement ?? 'bottom-start',
		},
		{
			offset: 5,
			disableAutoPlacement: true,
		}
	);

	const { selectedOption, setPopupRef, setWrapperRef } = useSelectState({
		selection,
		options,
		usingPopupWrapper: true,
		setShowPopup: setIsPopoverOpen,
		showPopup: isPopoverOpen,
	});
	return (
		<SelectWrapper
			setWrapperRef={setWrapperRef}
			useFullWidth={useFullWidth}
			customWidth={customWidth}
			disabled={disabled}
		>
			<div
				id={id}
				ref={refs.setReference}
				onClick={() => {
					setIsPopoverOpen(!isPopoverOpen);
				}}
				className={twMerge(
					`px-2 bg-input-bg text-text-input border h-8 flex items-center hover:cursor-pointer hover:bg-darkBlue-70 hover:border-darkBlue-20 rounded-sm w-full`,
					isPopoverOpen ? 'border-darkBlue-20' : 'border-container-border',
					customRounding
				)}
			>
				<div className="flex items-center justify-between w-full">
					<div className="flex items-center space-x-1">
						{!isNaN(Number(selection)) && (
							<MarketIcon
								marketSymbol={new UIMarket(
									Number(selection),
									marketType
								).baseAssetSymbol()}
								sizeClass="w-[18px] h-[18px]"
								isSpot={ENUM_UTILS.match(marketType, MarketType.SPOT)}
							/>
						)}

						<div
							className={`text-sm truncate overflow-ellipsis text-text-input`}
						>
							{!selectedOption?.value && defaultLabel
								? defaultLabel
								: selectedOption?.label}
						</div>
					</div>

					<Chevron
						direction={isPopoverOpen ? 'up' : 'down'}
						className={`w-3 h-3 ml-1 text-text-input`}
					/>
				</div>
			</div>

			{isPopoverOpen && (
				<PopoverWrapper
					portalId={id}
					ref={refs.setFloating}
					{...getFloatingProps()}
					style={floatingStyles}
					className={twMerge('z-[150]')}
					useTargetWidth
				>
					<div
						className="w-full overflow-auto border bg-main-bg -bottom-2 thin-scroll border-container-border text-text-input"
						style={maxHeight ? { maxHeight: `${maxHeight}px` } : {}}
						ref={setPopupRef}
					>
						{options.map((option) => {
							return (
								<div
									key={option.label}
									className="flex items-center px-2 py-1 pt-2 space-x-1 border-b select-none border-container-border hover:bg-input-bg-hover text-text-input hover:text-text-emphasis last:border-b-0 hover:cursor-pointer"
									onClick={() => {
										onChange(option.value);
										setIsPopoverOpen(false);
									}}
								>
									{option.value !== 'all' && (
										<MarketIcon
											marketSymbol={option.label}
											sizeClass="w-[18px] h-[18px]"
										/>
									)}
									<div>{option.label}</div>
								</div>
							);
						})}
					</div>
				</PopoverWrapper>
			)}
		</SelectWrapper>
	);
};

const CollateralSelector = ({
	id,
	selection,
	options,
	onChange,
	defaultLabel,
	maxHeight,
	useFullWidth,
	customRounding,
	customWidth,
	customPopupWidthClass,
	useTargetWidth,
	placement,
	customHeight,
}: Omit<SelectProps, 'options'> & {
	options: (SelectProps['options'][number] & {
		baseValue?: number;
		quoteValue?: number;
	})[];
}) => {
	const {
		refs,
		floatingStyles,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: placement ?? 'bottom-start',
		},
		{
			offset: 5,
			disableAutoPlacement: true,
		}
	);

	const { selectedOption, setPopupRef, setWrapperRef } = useSelectState({
		selection,
		options,
		usingPopupWrapper: true,
		setShowPopup: setIsPopoverOpen,
		preventPopupIfOneOptionOnly: true,
		showPopup: isPopoverOpen,
	});
	const isMobile = useIsMobileScreenSize();

	return (
		<SelectWrapper setWrapperRef={setWrapperRef} useFullWidth={useFullWidth}>
			<div
				id={id ?? ''}
				ref={refs.setReference}
				onClick={() => {
					options.length > 1 && setIsPopoverOpen(!isPopoverOpen);
				}}
				className={twMerge(
					`px-2 bg-input-bg text-text-input border flex items-center hover:cursor-pointer hover:bg-input-bg-hover hover:border-input-border-hover rounded-sm`,
					customHeight ?? 'h-8',
					customWidth ?? 'w-[130px]',
					isPopoverOpen ? 'border-darkBlue-20' : 'border-container-border',
					useFullWidth && 'w-full',
					customRounding
				)}
			>
				<div className="flex items-center justify-between w-full">
					<div className="flex items-center space-x-1.5">
						<MarketIcon
							marketSymbol={selection as string}
							sizeClass="w-[18px] h-[18px]"
						/>

						<div
							className={`mt-0.5 text-sm truncate overflow-ellipsis text-text-input`}
						>
							{!selectedOption?.value && defaultLabel
								? defaultLabel
								: selectedOption?.label}
						</div>
					</div>

					{options.length > 1 && (
						<Chevron
							direction={isPopoverOpen ? 'up' : 'down'}
							className={`w-3 h-3 ml-1 text-text-input`}
						/>
					)}
				</div>
			</div>
			{isPopoverOpen && (
				<PopoverWrapper
					portalId={id}
					ref={refs.setFloating}
					{...getFloatingProps()}
					style={floatingStyles}
					useTargetWidth={useTargetWidth}
					className={twMerge(
						'thin-scroll z-[150]',
						!useTargetWidth && 'w-[220px]',
						isMobile ? 'max-h-[150px]' : 'max-h-[250px]',
						customPopupWidthClass
					)}
				>
					<div
						className="w-full overflow-auto border bg-main-bg -bottom-2 thin-scroll border-container-border text-text-input"
						style={maxHeight ? { maxHeight: `${maxHeight}px` } : {}}
						ref={setPopupRef}
					>
						{options.map((option) => {
							const baseValueString =
								option.baseValue >= 0.01
									? NumLib.millify(option.baseValue).displayString
									: '< 0.01';
							const quoteValueString =
								option.quoteValue >= 0.01
									? `$${NumLib.millify(option.quoteValue).displayString}`
									: '< $0.01';
							return (
								<div
									key={option.label}
									className="flex items-center justify-between px-2 py-1 border-b select-none border-container-border text-text-input hover:text-text-emphasis last:border-b-0 hover:cursor-pointer hover:bg-container-bg-hover"
									onClick={() => {
										onChange(option.value);
										setIsPopoverOpen(false);
									}}
								>
									<div className="flex items-center space-x-1.5">
										<MarketIcon
											marketSymbol={option.label}
											sizeClass="w-[18px] h-[18px]"
										/>
										<div className="mt-1">{option.label}</div>
									</div>
									{!!option.quoteValue && (
										<div className="flex items-center space-x-1.5 mt-0.5 text-static-default">
											<Text.BODY3>{baseValueString}</Text.BODY3>
											<Text.BODY3>({quoteValueString})</Text.BODY3>
										</div>
									)}
								</div>
							);
						})}
					</div>
				</PopoverWrapper>
			)}
		</SelectWrapper>
	);
};

const Subaccount = (props: {
	options: AccountData[];
	onChange: (accountId: string) => void;
	initialSelection?: string;
	id?: string;
	includeDelegates?: boolean;
	customHeight?: string;
}) => {
	const initialAccountKey =
		props.initialSelection ?? props.options?.[0]?.userKey;

	const [currentSelection, setCurrentSelection] = useState(initialAccountKey);

	const selectOptions = props.options
		.filter((account) =>
			props.includeDelegates ? true : !account.isDelegatedTo
		)
		.map((account) => ({
			value: account.userKey,
			label: account.isDelegatedTo
				? `${UI_UTILS.abbreviateAddress(account.authority)} (${account.userId})`
				: account.name,
			icon: account.isDelegatedTo ? (
				<Delegation className="ml-1 mt-0.5" />
			) : undefined,
		}));

	const handleChange = (accountKey: string) => {
		setCurrentSelection(accountKey);
		props.onChange(accountKey);
	};

	useEffect(() => {
		// If the current selection isn't in the new options, reset the state to the first option
		if (!props.options.find((option) => option.userKey === currentSelection)) {
			handleChange(initialAccountKey);
		}
	}, [props.options]);

	return (
		<Default
			id={props.id ?? 'subAccountSelector'}
			selection={currentSelection}
			onChange={handleChange}
			options={selectOptions}
			useFullWidth
			useTargetWidth
			disabled={props.options.length === 0}
			customHeight={props.customHeight}
		/>
	);
};

const MultiSelector = (props: MultiSelectProps) => {
	const {
		refs,
		floatingStyles,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: props.placement ?? 'bottom-start',
		},
		{
			offset: 5,
			disableAutoPlacement: true,
		}
	);

	const { setPopupRef, setWrapperRef } = useSelectState({
		selection: props.selection,
		options: props.options,
		usingPopupWrapper: true,
		setShowPopup: setIsPopoverOpen,
		showPopup: isPopoverOpen,
	});

	return (
		<SelectWrapper setWrapperRef={setWrapperRef}>
			<RoundedGradientBorderBox
				borderColour={`${
					isPopoverOpen ? `var(--app-gradient)` : `var(--darkBlue-70)`
				}`}
				borderWidth="1px"
				borderRadius="0.1rem"
				onClick={() => setIsPopoverOpen(!isPopoverOpen)}
				ref={refs.setReference}
				className="flex items-center h-8 text-text-input hover:border-container-border hover:cursor-pointer hover:bg-input-bg-hover"
			>
				<div
					id={props.id ?? ''}
					className="flex items-center justify-between w-full h-full px-2 bg-input-bg"
				>
					<div className="text-sm truncate overflow-ellipsis text-text-input">
						{props.defaultLabel ?? 'Select option'}
					</div>

					<Chevron
						direction={isPopoverOpen ? 'up' : 'down'}
						className="w-3 h-3 ml-1 text-darkBlue-30"
					/>
				</div>
			</RoundedGradientBorderBox>

			{isPopoverOpen && (
				<PopoverWrapper
					portalId={props.id}
					ref={refs.setFloating}
					{...getFloatingProps()}
					style={floatingStyles}
					className={twMerge('z-[150]')}
					useTargetWidth
				>
					<div
						className="block w-full overflow-auto border bg-main-bg -bottom-2 thin-scroll border-container-border text-text-input"
						style={props.maxHeight ? { maxHeight: `${props.maxHeight}px` } : {}}
						ref={setPopupRef}
					>
						{props.options.map((option, index) => {
							return option.switch ? (
								<div
									key={`${option.label}_${index}`}
									className={`border-b divide-y select-none border-container-border last:border-b-0 hover:cursor-pointer hover:bg-input-bg-hover`}
									onClick={() => {
										option.onCheck(option.value);
									}}
								>
									<div className="inline-flex items-center justify-between w-full h-full p-2 bg-main-bg">
										<div>
											<Text.BODY3 className="text-darkBlue-30">
												{option.label}
											</Text.BODY3>
										</div>
										<div>
											<Switch
												checked={option.checked}
												onChange={() => {
													option.onCheck(option.value);
												}}
											></Switch>
										</div>
									</div>
								</div>
							) : (
								<div
									key={option.label}
									className={`border-b divide-y select-none border-container-border last:border-b-0 hover:cursor-pointer`}
									onClick={() => {
										option.onCheck(option.value);
									}}
								>
									<div className="inline-flex items-center w-full h-full p-2 bg-main-bg text-darkBlue-30 hover:text-text-emphasis">
										<CheckboxInput
											label={option.label}
											checked={option.checked}
											onChange={() => {
												option.onCheck(option.value);
											}}
											secondaryStyle
										/>
									</div>
								</div>
							);
						})}
					</div>
				</PopoverWrapper>
			)}
		</SelectWrapper>
	);
};

const MaxLeverageSelector = (props: {
	onChange: (value: number | undefined) => any;
	initialSelection: number | undefined;
	className?: string;
}) => {
	const [selection, setSelection] = useState(props.initialSelection);

	const handleChange = (value: number | undefined) => {
		setSelection(value);
		props.onChange(value);
	};

	return (
		<Select.Default
			id="max_lev_selector"
			className={twMerge('w-[100px]', props.className)}
			selection={selection}
			options={MAX_LEVERAGE_OPTIONS}
			onChange={handleChange}
		/>
	);
};

const TraderProfileSelector = ({
	id,
	selection,
	options,
	onChange,
	maxHeight,
	useFullWidth,
	customRounding,
	disabled,
	className,
	selectedIndex,
	placement,
}: SelectProps & { selectedIndex: number }) => {
	const {
		refs,
		floatingStyles,
		getFloatingProps,
		setIsPopoverOpen,
		isPopoverOpen,
	} = useTargetedPopover(
		{
			strategy: 'fixed',
			placement: placement ?? 'bottom-start',
		},
		{
			offset: 5,
			disableAutoPlacement: true,
		}
	);

	const { setPopupRef, setWrapperRef } = useSelectState({
		selection,
		options,
		usingPopupWrapper: true,
		setShowPopup: setIsPopoverOpen,
		showPopup: isPopoverOpen,
	});

	const selectedOption = options[selectedIndex];

	return (
		<SelectWrapper
			setWrapperRef={setWrapperRef}
			useFullWidth={useFullWidth}
			disabled={disabled}
			customHeight="h-[60px]"
		>
			{/* <div
				id={id ?? ''}
				onClick={toggleShowPopup}
				className={twMerge(
					`px-2 bg-input-bg border-input-border text-text-input hover:bg-input-bg-hover border h-full flex items-center hover:cursor-pointer rounded-sm`,
					useFullWidth && 'w-full',
					customRounding,
					className
				)}
			> */}
			<RoundedGradientBorderBox
				id={id ?? ''}
				borderWidth="1px"
				borderRadius="0.25rem"
				onClick={() => {
					setIsPopoverOpen(!isPopoverOpen);
				}}
				ref={refs.setReference}
			>
				<div
					className={twMerge(
						`py-2 px-3 bg-input-bg text-text-input hover:bg-input-bg-hover w-full h-full flex items-center hover:cursor-pointer`,
						useFullWidth && 'w-full',
						customRounding,
						className
					)}
				>
					<div className="flex items-center justify-between w-full">
						<div
							className={`${
								selectedOption?.selectedTextClass
									? selectedOption?.selectedTextClass
									: 'text-text-input'
							} inline-flex items-center text-sm truncate overflow-ellipsis`}
						>
							<div className="block">
								<div>
									<Text.BODY2 className="text-text-emphasis">
										{selectedOption?.label}
									</Text.BODY2>
								</div>
								<div>
									<Text.MICRO1 className="text-text-secondary">
										{selectedOption?.description}
									</Text.MICRO1>
								</div>
							</div>
						</div>

						<Chevron
							direction={isPopoverOpen ? 'up' : 'down'}
							className="w-3 h-3 ml-1 text-darkBlue-30"
						/>
					</div>
				</div>
			</RoundedGradientBorderBox>

			{/* </div> */}

			{isPopoverOpen && (
				<PopoverWrapper
					portalId={id}
					ref={refs.setFloating}
					{...getFloatingProps()}
					style={floatingStyles}
					className={twMerge('z-[150]')}
					useTargetWidth
				>
					<div
						className="w-full overflow-auto border bg-main-bg -bottom-2 thin-scroll border-container-border text-text-input"
						style={maxHeight ? { maxHeight: `${maxHeight}px` } : {}}
						ref={setPopupRef}
					>
						{options.map((option) => {
							return (
								<div
									key={option.label}
									className={`border-b divide-y select-none border-container-border last:border-b-0 hover:cursor-pointer hover:bg-container-bg-hover`}
									onClick={() => {
										onChange(option.value);
										setIsPopoverOpen(false);
									}}
								>
									<div className="block px-2 py-1">
										<GradientText onHover>
											<div
												className={`text-text-default inline-flex items-center`}
											>
												<Text.BODY3>{option.label}</Text.BODY3>
											</div>
										</GradientText>
										<div>
											<Text.MICRO1 className="text-text-secondary">
												{option?.description}
											</Text.MICRO1>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</PopoverWrapper>
			)}
		</SelectWrapper>
	);
};

const Settle = (props: {
	options: PerpMarketConfig[];
	id?: string;
	className?: string;
}) => {
	const setDrawerStore = useDrawersStore((s) => s.set);

	const selectOptions = props.options.map((option) => ({
		value: option.marketIndex,
		label: `Settle ${option.symbol} P&L Pool`,
	}));

	// dont actually select it, just open the market details panel
	const handleChange = (newSelection: number) => {
		setDrawerStore((s) => {
			s.marketDetails.uiMarket = UIMarket.createPerpMarket(newSelection);
			s.marketDetails.show = true;
			s.marketDetails.goToPnl = true;
		});
	};

	return (
		<Default
			id={props.id ?? 'settleMarketSelector'}
			selection={undefined}
			onChange={handleChange}
			options={selectOptions}
			useFullWidth
			disabled={props.options.length === 0}
			defaultLabel="Settle P&L Pool"
			className={props.className}
			customHeight="max-h-[26px]"
		/>
	);
};

const Select = {
	Default,
	Form: React.memo(Default),
	MultiSelector,
	MarketSelector,
	Subaccount,
	CollateralSelector,
	MaxLeverageSelector,
	TraderProfileSelector,
	Settle,
	DefaultV2,
	PerpSelectorV2,
};

export default Select;
