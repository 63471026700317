'use client';

import useCurrentSettings from './useCurrentSettings';

const useShowAccountValues = () => {
	const [currentSettings] = useCurrentSettings();
	return currentSettings.showAccountValues;
};

export default useShowAccountValues;
