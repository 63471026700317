import { BulkAccountLoader } from '@drift-labs/sdk';
import { Connection } from '@solana/web3.js';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';
import Env from 'src/environmentVariables/EnvironmentVariables';

export const generateAccountLoader = (connection: Connection) => {
	return new BulkAccountLoader(
		connection,
		DEFAULT_COMMITMENT_LEVEL,
		Env.pollingFrequencyMs
	);
};
