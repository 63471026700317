'use client';

import { BigNum, PRICE_PRECISION_EXP, ZERO } from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import useMarketStateStore from '../stores/useMarketStateStore';
import useOraclePriceStore from '../stores/useOraclePriceStore';
import { useCallback } from 'react';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';

const USDC_SPOT_MARKET_ID = MarketId.createSpotMarket(0);

const useGetOraclePriceForMarket = () => {
	const getOraclePriceData = useOraclePriceStore((s) => s.getMarketPriceData);
	const oraclePriceSymbolMap = useOraclePriceStore((s) => s.symbolMap); // used as a ticker for change
	const getMarketData = useMarketStateStore((s) => s.getMarketDataForMarket);
	const marketDataState = useMarketStateStore((s) => s.marketDataState);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	const getOraclePriceFromOracleStore = useCallback(
		(marketId: MarketId) => {
			const rawOraclePrice = getOraclePriceData(marketId)?.rawPriceData.price;

			if (!rawOraclePrice) return undefined;

			const oraclePrice = BigNum.from(rawOraclePrice, PRICE_PRECISION_EXP);

			return oraclePrice;
		},
		[getOraclePriceData, oraclePriceSymbolMap]
	);

	const getOraclePriceFromMarketDataStore = useCallback(
		(marketId: MarketId) => {
			const oraclePrice = getMarketData(marketId)?.oracle?.price;

			return oraclePrice;
		},
		[getMarketData, marketDataState]
	);

	const getOraclePriceFromDriftClient = useCallback(
		(marketId: MarketId) => {
			try {
				if (driftClient && driftClientIsReady) {
					if (marketId.isPerp) {
						return BigNum.from(
							driftClient.getOracleDataForPerpMarket(marketId.marketIndex)
								.price,
							PRICE_PRECISION_EXP
						);
					} else {
						return BigNum.from(
							driftClient.getOracleDataForSpotMarket(marketId.marketIndex)
								.price,
							PRICE_PRECISION_EXP
						);
					}
				}
			} catch (err) {
				return undefined;
			}

			return undefined;
		},
		[driftClient, driftClientIsReady]
	);

	const getOraclePriceForMarket = useCallback(
		(marketId: MarketId) => {
			return (
				getOraclePriceFromOracleStore(marketId) ??
				getOraclePriceFromMarketDataStore(marketId) ??
				getOraclePriceFromDriftClient(marketId) ??
				(marketId.equals(USDC_SPOT_MARKET_ID)
					? BigNum.fromPrint('1', PRICE_PRECISION_EXP)
					: BigNum.from(ZERO, PRICE_PRECISION_EXP))
			);
		},
		[getOraclePriceFromOracleStore, getOraclePriceFromMarketDataStore]
	);

	return getOraclePriceForMarket;
};

export default useGetOraclePriceForMarket;
