'use client';

import { singletonHook } from 'react-singleton-hook';
import Env from 'src/environmentVariables/EnvironmentVariables';
import UI_UTILS from '../utils/uiUtils';
import useCurrentSettings from './useCurrentSettings';

const useIsMainnet = () => {
	const [currentSettings] = useCurrentSettings();

	const isMainnet =
		Env.sdkEnv === 'mainnet-beta' ||
		currentSettings?.mainnetOverride ||
		UI_UTILS.urlHasParam('useMainnet');

	return isMainnet;
};

export default singletonHook(false, useIsMainnet);
