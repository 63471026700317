/********************************************************************/
/* These colors/semantic classes are generated from Figma Variables */
/********************************************************************/

const allColors = {
	neutrals: {
		0: '#ffffff',
		10: '#fafafa',
		20: '#d4d4d8',
		30: '#a1a1aa',
		40: '#787883',
		50: '#555562',
		60: '#353541',
		70: '#27272f',
		80: '#19191f',
		90: '#121217',
		100: '#0d0d12',
		110: '#000000',
	},
	purple: {
		0: '#f1e8ff',
		10: '#e2d2ff',
		20: '#d4bbff',
		30: '#c6a5ff',
		40: '#b58aff',
		50: '#9162f6',
		60: '#711eff',
		70: '#5a18cc',
		80: '#441299',
		90: '#2d0c66',
		100: '#22094c',
		110: '#160433',
		120: '#3b265f',
	},
	darkBlue: {
		0: '#dae8fa',
		10: '#97b1d2',
		20: '#6683a7',
		30: '#465e7a',
		40: '#2e4665',
		50: '#152a44',
		60: '#132236',
		70: '#111d2e',
		80: '#101a27',
		90: '#0b141f',
		100: '#080f18',
		110: '#030a13',
	},
	lightBlue: {
		0: '#f3f9fe',
		10: '#e4edf7',
		20: '#cfddef',
		30: '#93abce',
		40: '#6b8bb7',
		50: '#436aa1',
		60: '#1b498b',
		70: '#163a6f',
		80: '#102c53',
		90: '#0b1d38',
		100: '#08162a',
		110: '#050f1c',
	},
	blueGrey: {
		0: '#f0f4f8',
		10: '#dae0e7',
		20: '#c1ccd7',
		30: '#a8b7c7',
		40: '#8fa2b7',
		50: '#768da7',
		60: '#5f7895',
		70: '#50647c',
		80: '#405064',
		90: '#303c4b',
		100: '#202832',
		110: '#101419',
	},
	green: {
		0: '#d6f5e7',
		10: '#c2efdb',
		20: '#a5e3c8',
		30: '#85e0b8',
		40: '#5dd5a0',
		50: '#34cb88',
		60: '#29b577',
		70: '#209d66',
		80: '#1f7a52',
		90: '#155136',
		100: '#11352e',
		110: '#0a291b',
	},
	red: {
		0: '#ffe7e5',
		10: '#ffdbd9',
		20: '#ffb8b2',
		30: '#ffa099',
		40: '#ff887f',
		50: '#ff615c',
		60: '#e54d48',
		70: '#c94641',
		80: '#a13c39',
		90: '#742e2b',
		100: '#502523',
		110: '#3d2624',
	},
	yellow: {
		0: '#fff6d9',
		10: '#fff1c5',
		20: '#ffecb2',
		30: '#ffe38c',
		40: '#ffdd75',
		50: '#f2c94c',
		60: '#e3bb42',
		70: '#b99b3f',
		80: '#9a7f2e',
		90: '#6d5a20',
		100: '#413511',
		110: '#2b2209',
	},
	pink: {
		0: '#ffebf1',
		10: '#ffd7e3',
		20: '#ffc3d5',
		30: '#ffafc7',
		40: '#ff88ab',
		50: '#f76490',
		60: '#ff3873',
		70: '#cf2d5d',
		80: '#9f2247',
		90: '#6f1631',
		100: '#571126',
		110: '#3f0b1b',
	},
};

const brandGradientStops = {
	0: '#e8a2a0',
	10: '#c187c5',
	20: '#b982cd',
	25: '#b47ed2',
	30: '#aa77db',
	40: '#a070e5',
	50: '#9468f1',
	60: '#8d7cef',
	70: '#8987ee',
	75: '#8690ee',
	80: '#7fa4ec',
	90: '#78b8eb',
	100: '#71cce9',
};

const generatedSemanticClasses = {
	// background
	'main-bg': 'var(--main-bg)',
	'container-bg': 'var(--container-bg)',
	'container-bg-hover': 'var(--container-bg-hover)',
	'container-bg-selected': 'var(--container-bg-selected)',
	'container-bg-tweet': 'var(--container-bg-tweet)',
	'container-border': 'var(--container-border)',
	'tooltip-bg': 'var(--tooltip-bg)',
	'tab-selected-bg': 'var(--tab-selected-bg)',
	'card-border-hover': 'var(--card-border-hover)',
	'fuel-table-header-bg': 'var(--fuel-table-header-bg)',
	'fuel-table-row-bg': 'var(--fuel-table-row-bg)',

	// button-tab
	'button-border': 'var(--button-border)',
	'button-disabled': 'var(--button-disabled)',
	'border-2': 'var(--border-2)',
	'button-secondary-bg': 'var(--button-secondary-bg)',
	'button-secondary-bg-hover': 'var(--button-secondary-bg-hover)',
	'button-secondary-stroke-hover': 'var(--button-secondary-stroke-hover)',
	'button-secondary-selected': 'var(--button-secondary-selected)',
	'tabs-selected': 'var(--tabs-selected)',
	'tabs-hover': 'var(--tabs-hover)',
	'tabs-default-fill': 'var(--tabs-default-fill)',
	'chip-default-bg': 'var(--chip-default-bg)',
	'chip-default-text': 'var(--chip-default-text)',
	'connect-button-icon-background': 'var(--connect-button-icon-background)',
	'connect-button-icon-border': 'var(--connect-button-icon-border)',
	'status-neutral-bg': 'var(--status-neutral-bg)',
	'status-neutral-text': 'var(--status-neutral-text)',
	'feedback-button': 'var(--feedback-button)',
	'button-border-hover': 'var(--button-border-hover)',

	// input
	'input-bg': 'var(--input-bg)',
	'input-bg-hover': 'var(--input-bg-hover)',
	'input-border': 'var(--input-border)',

	// divider-scroll
	'stroke-secondary': 'var(--stroke-secondary)',
	'scroll-bar': 'var(--scroll-bar)',

	// text
	'text-default': 'var(--text-default)',
	'text-selected': 'var(--text-selected)',
	'text-inverted-selected': 'var(--text-inverted-selected)',
	'text-secondary': 'var(--text-secondary)',
	'text-tertiary': 'var(--text-tertiary)',
	'text-disabled': 'var(--text-disabled)',
	'text-emphasis': 'var(--text-emphasis)',
	'text-interactive': 'var(--text-interactive)',
	'interactive-link': 'var(--interactive-link)',
	'text-secondary-button': 'var(--text-secondary-button)',
	'text-featured-chip': 'var(--text-featured-chip)',
	'text-gradient-button': 'var(--text-gradient-button)',
	'text-negative-red-button': 'var(--text-negative-red-button)',
	'text-positive-green-button': 'var(--text-positive-green-button)',
	'text-label': 'var(--text-label)',
	'text-input': 'var(--text-input)',
	'text-default-2': 'var(--text-default-2)',
	'text-primary-button': 'var(--text-primary-button)',
	'static-emphasized': 'var(--static-emphasized)',
	'static-default': 'var(--static-default)',

	// color
	'positive-green': 'var(--positive-green)',
	'positive-green-hover': 'var(--positive-green-hover)',
	'positive-green-pressed': 'var(--positive-green-pressed)',
	'negative-red': 'var(--negative-red)',
	'negative-red-hover': 'var(--negative-red-hover)',
	'negative-red-pressed': 'var(--negative-red-pressed)',
	'brand-yellow': 'var(--brand-yellow)',
	'brand-yellow-hover': 'var(--brand-yellow-hover)',
	'brand-yellow-pressed': 'var(--brand-yellow-pressed)',
	'warn-yellow': 'var(--warn-yellow)',
	'warn-yellow-hover': 'var(--warn-yellow-hover)',
	'warn-yellow-pressed': 'var(--warn-yellow-pressed)',
	'label-secondary-bg': 'var(--label-secondary-bg)',
	'buy-hover': 'var(--buy-hover)',
	'buy-pressed': 'var(--buy-pressed)',
	'sell-hover': 'var(--sell-hover)',
	'sell-pressed': 'var(--sell-pressed)',
	white: 'var(--white)',
	'positive-green-secondary-bg': 'var(--positive-green-secondary-bg)',
	'negative-red-secondary-bg': 'var(--negative-red-secondary-bg)',
	'brand-yellow-secondary-bg': 'var(--brand-yellow-secondary-bg)',
	'interactive-secondary-bg': 'var(--interactive-secondary-bg)',
};

module.exports = {
	allColors,
	generatedSemanticClasses,
	brandGradientStops,
};
