import produce from 'immer';
import { create } from 'zustand';
import {
	NavigationCategory,
	NavigationOptionKey,
} from '../components/Navigation';
import { PREDICTION_MARKET_CATEGORY } from 'src/@types/predictionMarkets';

/**
 * The navigation store serves to track the current state of navigation within the app. It should track all information required for navigation components to render properly.
 *
 * The reason for putting this into a zustand store is because it was the best way to have a global shared instance (because the useNavigationState hook needs to use the NextRouter, which isn't compatible with the singleton hook).
 */

export interface NavigationStore {
	set: (x: (s: NavigationStore) => void) => void;
	get: () => NavigationStore;
	currentNavigationSelection: NavigationOptionKey;
	currentNavigationCategory: NavigationCategory;
	showMobileNavMenu: boolean;
	betMarket: {
		homePageCategory: PREDICTION_MARKET_CATEGORY;
	};
}

const useNavigationStore = create<NavigationStore>(
	(set, get): NavigationStore => {
		return {
			set: (fn) => set(produce(fn)),
			get: () => get(),
			currentNavigationSelection: 'perpetuals',
			currentNavigationCategory: 'trade',
			showMobileNavMenu: false,
			betMarket: {
				homePageCategory: PREDICTION_MARKET_CATEGORY.ALL,
			},
		};
	}
);

export default useNavigationStore;
