'use client';

// import { useEffect } from 'react';
// import { useLocalStorageStringState } from './useLocalStorageState';
import { singletonHook } from 'react-singleton-hook';
import { useLocalStorageStringState } from './useLocalStorageState';

// const legacyToNewValuesMap = {
// 	default: 'default',
// 	chartOnLeft: 'default',
// 	chartOnRight: 'mirrored',
// 	mirrored: 'split',
// 	defaultLite: 'defaultLite',
// 	mirroredLite: 'defaultLite',
// };

// const useLayoutConfigSetting = () => {
// const [legacyValue] = useLocalStorageStringState('layoutSetting', 'default');
// const [value, set] = useLocalStorageStringState(
// 	'layoutSetting2',
// 	(!!legacyValue && legacyToNewValuesMap[legacyValue]) || 'default'
// );

// // Remove legacy value and set new default for past users
// useEffect(() => {
// 	if (legacyValue) {
// 		localStorage.removeItem('layoutSetting');
// 	}
// }, [legacyValue]);

// return [value, set];
// };

const useLayoutConfigSetting = () =>
	useLocalStorageStringState('layoutSetting2', 'default');

export default singletonHook([undefined, undefined], useLayoutConfigSetting);
