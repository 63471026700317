'use client';

import { useCallback, useEffect, useMemo } from 'react';
import { singletonHook } from 'react-singleton-hook';
import useLocalStorageState from '../useLocalStorageState';
import { ONE_DAY_MS } from '@drift/common';

const useNumberOfAccountsCreatedLast24h = () => {
	const [numberOfAccountsCreatedLast24h, setNumberOfAccountsCreatedLast24h] =
		useLocalStorageState<number>('numberOfAccountsCreatedLast24h', 0);
	const [lastCreatedAccountTimestamp, setLastCreatedAccountTimestamp] =
		useLocalStorageState<number>('lastCreatedAccountTimestamp', 0);

	// Reset number of accounts created when loading the ui if >34 hours has passed
	useEffect(() => {
		if (Date.now() - lastCreatedAccountTimestamp >= ONE_DAY_MS) {
			setNumberOfAccountsCreatedLast24h(0);
		}
	}, []);

	// Update timestamp when new account is added
	useEffect(() => {
		if (numberOfAccountsCreatedLast24h > 0) {
			setLastCreatedAccountTimestamp(Date.now());
		}
	}, [numberOfAccountsCreatedLast24h]);

	const incrementNumberOfAccountsCreated = useCallback(() => {
		setNumberOfAccountsCreatedLast24h(numberOfAccountsCreatedLast24h + 1);
	}, [numberOfAccountsCreatedLast24h]);

	const result = useMemo(() => {
		return {
			numberOfAccountsCreatedLast24h,
			lastCreatedAccountTimestamp,
			incrementNumberOfAccountsCreated,
		};
	}, [numberOfAccountsCreatedLast24h, lastCreatedAccountTimestamp]);

	return result;
};

const DEFAULT_STATE = {
	numberOfAccountsCreatedLast24h: 0,
	lastCreatedAccountTimestamp: 0,
	incrementNumberOfAccountsCreated: () => {},
};

export default singletonHook(DEFAULT_STATE, useNumberOfAccountsCreatedLast24h);
