'use client';

import React, { memo, useCallback, useEffect, useRef } from 'react';
import { ArrowLeft, Close } from '@drift-labs/icons';
import useDriftClient from 'src/hooks/useDriftClient';
import useHasAcknowledgedTerms from 'src/hooks/useHasAcknowledgedTerms';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useCurrentWalletAdapter from '../../hooks/useCurrentWalletAdapter';
import useCurrentWalletConnectionState from '../../hooks/useCurrentWalletConnectionState';
import AcknowledgeTermsPanel from './AcknowledgeTermsPanel';
import FundWalletImage from './FundWalletImage';
import FundYourAccountPanel from './FundYourAccountPanel';
import GeoblockedPanel from './GeoblockedPanel';
import HelpfulResources from './HelpfulResources';
import LedgerImage from './LedgerImage';
import MagicAuthLoginPanel from './MagicAuthLoginPanel';
import ReadyToTradePanel from './ReadyToTradePanel';
import ScreenshotWithTweets from './ScreenshotWithTweets';
import useHasConnectedWalletBefore from '../../hooks/useHasConnectedWalletBefore';
import Text from '../Text/Text';
import Utility from '../Inputs/Utility';
import useSafePush from 'src/hooks/useSafePush';
import useHotkey from 'src/hooks/useHotkey';
import useIsTabletOrSmallerSize from 'src/hooks/useIsTabletOrSmallerSize';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { twMerge } from 'tailwind-merge';
import useIsSafari from 'src/hooks/useIsSafari';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import ConnectWalletButtonsList from '../ConnectWalletButtonsList';
import YourWalletPanel from './YourWalletPanel';
import FundYourWalletFromNonSolanaChain from './FundYourWalletFromNonSolanaChain';
import DepositToDriftPanel from './DepositToDriftPanel';
import UI_UTILS from 'src/utils/uiUtils';
import { COMMON_UI_UTILS } from '@drift/common';
import { dlog } from '../../dev';
import useHandleSelectWalletProvider from 'src/hooks/useHandleSelectWalletProvider';
import {
	getHistoricallySelectedWallets,
	HISTORICALLY_SELECTED_WALLETS_STORAGE_KEY,
} from 'src/hooks/useWalletsToDisplay';
import { DriftWalletName } from 'src/constants/wallets';
import { DRIFT_CUSTOM_WALLET_OPTIONS } from 'src/constants/wallets';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';

const CONNECTION_STEP_LOCK_KEY = 'ONBOARDING_CONNECTING_LOCK';

export enum ONBOARDING_STEP {
	EXIT = 'EXIT',
	GEOBLOCKED = 'GEOBLOCKED',
	AGREE_TO_TERMS = 'AGREE_TO_TERMS',
	CONNECT = 'CONNECT',
	SIGN_UP_MAGIC_WALLET = 'SIGN_UP_MAGIC_WALLET',
	LOGIN_MAGIC_WALLET = 'LOGIN_MAGIC_WALLET',
	YOUR_MAGIC_WALLET = 'YOUR_MAGIC_WALLET',
	YOUR_METAMASK_WALLET = 'YOUR_METAMASK_WALLET',
	FUND_YOUR_WALLET = 'FUND_YOUR_WALLET',
	METAMASK_DEPOSIT_TO_DRIFT = 'METAMASK_DEPOSIT_TO_DRIFT',
	FUND_YOUR_ACCOUNT = 'FUND_YOUR_ACCOUNT',
	COMPLETE = 'COMPLETE',
}

const STEPS_PROGRESS = {
	[ONBOARDING_STEP.GEOBLOCKED]: 0,
	[ONBOARDING_STEP.AGREE_TO_TERMS]: 0,
	[ONBOARDING_STEP.CONNECT]: 1,
	[ONBOARDING_STEP.SIGN_UP_MAGIC_WALLET]: 1,
	[ONBOARDING_STEP.LOGIN_MAGIC_WALLET]: 1,
	[ONBOARDING_STEP.YOUR_MAGIC_WALLET]: 2,
	[ONBOARDING_STEP.YOUR_METAMASK_WALLET]: 2,
	[ONBOARDING_STEP.FUND_YOUR_WALLET]: 3,
	[ONBOARDING_STEP.METAMASK_DEPOSIT_TO_DRIFT]: 3,
	[ONBOARDING_STEP.FUND_YOUR_ACCOUNT]: 3,
	[ONBOARDING_STEP.COMPLETE]: 4,
};

export const ONBOARDING_PATHS = {
	'/onboarding/connect': ONBOARDING_STEP.CONNECT,
	'/onboarding/signup-with-email': ONBOARDING_STEP.SIGN_UP_MAGIC_WALLET,
	'/onboarding/login-with-email': ONBOARDING_STEP.LOGIN_MAGIC_WALLET,
	'/onboarding/your-magic-wallet': ONBOARDING_STEP.YOUR_MAGIC_WALLET,
	'/onboarding/your-metamask-wallet': ONBOARDING_STEP.YOUR_METAMASK_WALLET,
	'/onboarding/fund-your-wallet': ONBOARDING_STEP.FUND_YOUR_WALLET,
	'/onboarding/deposit-to-drift': ONBOARDING_STEP.METAMASK_DEPOSIT_TO_DRIFT,
	'/onboarding/fund-your-account': ONBOARDING_STEP.FUND_YOUR_ACCOUNT,
	'/onboarding/complete': ONBOARDING_STEP.COMPLETE,
};

// New onboarding steps bar
const ConnectStepsBarMemo = React.memo(function ConnectStepsBar({
	step,
}: {
	step: ONBOARDING_STEP;
}) {
	const barWidth = `${(STEPS_PROGRESS[step] / 4) * 100}%`;

	return (
		<div className="h-2 mb-10 w-full max-w-[393px] overflow-hidden bg-container-bg-selected relative onboarding-steps-mask flex-shrink-0">
			<div
				className="absolute h-2 transition-all bg-brand-gradient "
				style={{ width: barWidth }}
			></div>
		</div>
	);
});

const FirstTimeConnectingWalletOptions = (props: {
	onChooseEmail: () => void;
}) => {
	const walletConnectionState = useCurrentWalletConnectionState();

	const { handleSelectProvider } = useHandleSelectWalletProvider();

	const handleAddToHistoricallySelectedWallets = (name: string) => {
		const previouslySelectedWallets = getHistoricallySelectedWallets();
		if (!previouslySelectedWallets.includes(name)) {
			previouslySelectedWallets.push(name);
			localStorage.setItem(
				HISTORICALLY_SELECTED_WALLETS_STORAGE_KEY,
				JSON.stringify(previouslySelectedWallets)
			);
		}
	};

	const onSelectedWallet = (walletName: DriftWalletName) => {
		dlog(`wallet_debugging`, `handleSelectWallet: ${walletName}`);

		handleAddToHistoricallySelectedWallets(walletName);

		switch (walletName) {
			case DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME:
				props.onChooseEmail();
				break;
			default:
				handleSelectProvider(walletName);
				walletConnectionState.instance.update(
					'Connecting',
					true,
					CONNECTION_STEP_LOCK_KEY
				);
		}
	};

	return (
		<>
			<Text.H2>How would you like to connect to Drift?</Text.H2>
			<Utility.VERTSPACERXL />
			<ConnectWalletButtonsList
				showMetamaskIfDetected={Env.enableMmSnap}
				onSelectedWallet={onSelectedWallet}
				showEmail
			/>
		</>
	);
};

const LeftPanelContent = (props: {
	currentStep: ONBOARDING_STEP;
	safePush: ReturnType<typeof useSafePush>;
	setStep: (step: ONBOARDING_STEP) => void;
	goBack: () => void;
}) => {
	const [hasPreviouslyConnectedWallet] = useHasConnectedWalletBefore();
	let mainContent = <></>;

	const handleEmailSignup = useCallback(() => {
		if (hasPreviouslyConnectedWallet) {
			props.setStep(ONBOARDING_STEP.LOGIN_MAGIC_WALLET);
		} else {
			props.setStep(ONBOARDING_STEP.SIGN_UP_MAGIC_WALLET);
		}
	}, []);

	const handleClickedBridgeCta = useCallback(() => {
		props.setStep(ONBOARDING_STEP.FUND_YOUR_WALLET);
	}, []);

	const handleFundYourAccount = useCallback(() => {
		props.setStep(ONBOARDING_STEP.FUND_YOUR_ACCOUNT);
	}, []);

	const handleFundYourWalletWithMayan = useCallback(() => {
		props.setStep(ONBOARDING_STEP.FUND_YOUR_WALLET);
	}, []);

	const handleDepositToDrift = useCallback(() => {
		props.setStep(ONBOARDING_STEP.METAMASK_DEPOSIT_TO_DRIFT);
	}, []);

	const handleSwitchToConnectPage = useCallback(() => {
		props.setStep(ONBOARDING_STEP.CONNECT);
	}, []);

	const handleSwitchToLoginPage = useCallback(() => {
		props.setStep(ONBOARDING_STEP.LOGIN_MAGIC_WALLET);
	}, []);

	const handleSwitchToSignupPage = useCallback(() => {
		props.setStep(ONBOARDING_STEP.SIGN_UP_MAGIC_WALLET);
	}, []);

	switch (props.currentStep) {
		case ONBOARDING_STEP.YOUR_MAGIC_WALLET:
			mainContent = (
				<YourWalletPanel isMagicAuth handleNext={handleFundYourAccount} />
			);
			break;
		case ONBOARDING_STEP.YOUR_METAMASK_WALLET:
			mainContent = (
				<YourWalletPanel
					isMetamask
					handleNext={handleFundYourWalletWithMayan}
				/>
			);
			break;
		case ONBOARDING_STEP.FUND_YOUR_WALLET:
			mainContent = (
				<FundYourWalletFromNonSolanaChain
					handleNext={handleDepositToDrift}
					handleFundFromAnotherWallet={handleFundYourAccount}
				/>
			);
			break;
		case ONBOARDING_STEP.METAMASK_DEPOSIT_TO_DRIFT:
			mainContent = <DepositToDriftPanel />;
			break;
		case ONBOARDING_STEP.FUND_YOUR_ACCOUNT:
			mainContent = (
				<FundYourAccountPanel onClickBridgeCta={handleClickedBridgeCta} />
			);
			break;
		case ONBOARDING_STEP.COMPLETE:
			mainContent = <ReadyToTradePanel />;
			break;
		case ONBOARDING_STEP.SIGN_UP_MAGIC_WALLET:
		case ONBOARDING_STEP.LOGIN_MAGIC_WALLET:
			mainContent = (
				<MagicAuthLoginPanel
					createAccount={
						props.currentStep === ONBOARDING_STEP.SIGN_UP_MAGIC_WALLET
					}
					onSwitchToConnectionPage={handleSwitchToConnectPage}
					onSwitchToLogin={handleSwitchToLoginPage}
					onSwitchToSignup={handleSwitchToSignupPage}
				/>
			);
			break;
		case ONBOARDING_STEP.CONNECT:
		default:
			mainContent = (
				<FirstTimeConnectingWalletOptions onChooseEmail={handleEmailSignup} />
			);
			break;
	}

	return (
		<div className="relative flex justify-center w-full gap-4">
			<div className="hidden sm:block">
				<button
					onClick={props.goBack}
					className="text-text-default hover:text-text-emphasis hover:cursor-pointer p-1.5 rounded-full border border-container-border relative top-[38px] flex items-center justify-center"
				>
					<ArrowLeft size={24} />
				</button>
			</div>

			<div className="flex flex-col w-full max-w-[420px]">
				<ConnectStepsBarMemo step={props.currentStep} />

				{mainContent}
			</div>

			<div className="hidden sm:block w-[38px] min-w-[38px]">
				{/** Faux container to replicate left arrow container width */}
			</div>
		</div>
	);
};

const RightPanelContent = (props: { currentStep: ONBOARDING_STEP }) => {
	const isTabletOrSmaller = useIsTabletOrSmallerSize();

	let mainContent = <></>;

	switch (props.currentStep) {
		case ONBOARDING_STEP.YOUR_MAGIC_WALLET:
		case ONBOARDING_STEP.YOUR_METAMASK_WALLET:
			if (isTabletOrSmaller) {
				break;
			}
			mainContent = <LedgerImage />;
			break;
		case ONBOARDING_STEP.FUND_YOUR_ACCOUNT:
		case ONBOARDING_STEP.FUND_YOUR_WALLET:
		case ONBOARDING_STEP.METAMASK_DEPOSIT_TO_DRIFT:
			if (isTabletOrSmaller) {
				break;
			}
			mainContent = <FundWalletImage />;
			break;
		case ONBOARDING_STEP.COMPLETE:
			if (isTabletOrSmaller) {
				break;
			}
			mainContent = <HelpfulResources />;
			break;
		case ONBOARDING_STEP.CONNECT:
		case ONBOARDING_STEP.SIGN_UP_MAGIC_WALLET:
			if (isTabletOrSmaller) {
				break;
			}
			mainContent = <ScreenshotWithTweets />;
			break;
		default:
			mainContent = <ScreenshotWithTweets />;
	}

	return (
		<div className="relative flex flex-col items-center justify-center w-full h-full">
			{mainContent}
		</div>
	);
};

const OnboardingPage = () => {
	const driftClient = useDriftClient();
	const wallet = useCurrentWalletAdapter();

	const isMagicAuth = useDriftStore((s) => s.wallet.isMagicAuth);
	const lastRouteBeforeConnect = useDriftStore(
		(s) => s.wallet.lastRouteBeforeConnect
	);
	const countryCode = useDriftStore((s) => s.countryCode);

	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);
	const [hasAcknowledgedTerms, updateHasAcknowledgedTerms] =
		useHasAcknowledgedTerms();
	const router = useRouter();
	const pathname = usePathname();
	const searchParams = useSearchParams();
	const safePush = useSafePush();
	const routerPath = pathname;
	const keepCurrentStep = searchParams.get('source') === 'tradeform';
	// const [_, _currentPage, path, _subPath] = routerPath.split('/');

	const walletConnectionState = useCurrentWalletConnectionState();
	const walletIsFullyConnected = walletConnectionState.instance.FullyConnected;
	const walletIsFullyConnectedRef = useRef(walletIsFullyConnected);
	const [_hasConnectedWalletBefore, setHasConnectedWalletBefore] =
		useHasConnectedWalletBefore();

	const isMobileScreenSize = useIsMobileScreenSize();
	const isSafari = useIsSafari();

	const showTerms =
		!hasAcknowledgedTerms.hasPreviouslyAcknowledgedTerms ||
		hasAcknowledgedTerms.shouldShowTermsReminder;

	const isUsa = countryCode === 'US';
	const currentStep =
		isGeoblocked && !isUsa
			? ONBOARDING_STEP.GEOBLOCKED
			: showTerms
			? ONBOARDING_STEP.AGREE_TO_TERMS
			: ONBOARDING_PATHS[routerPath];

	const setCurrentStep = (newStep) => {
		safePush(UI_UTILS.getOnboardingPath(newStep));
	};

	const goBack = () => {
		if (currentStep === ONBOARDING_STEP.EXIT) {
			safePush(lastRouteBeforeConnect ?? '/');
		} else {
			// const newStep = previousStepsStack[0];
			// setPreviousStep(previousStepsStack.slice(1));
			router.back();
		}
	};

	// const hasSetInitialStep = useRef(false);

	const displayStyle = 'emphasisLeft';

	// useEffect(() => {
	// 	if (isGeoblocked) {
	// 		setPreviousStep[0];
	// 		setCurrentStep(ONBOARDING_STEP.GEOBLOCKED);
	// 		hasSetInitialStep.current = true;
	// 		return;
	// 	}
	// 	if (
	// 		!hasAcknowledgedTerms.hasPreviouslyAcknowledgedTerms ||
	// 		hasAcknowledgedTerms.shouldShowTermsReminder
	// 	) {
	// 		setCurrentStep(ONBOARDING_STEP.AGREE_TO_TERMS);
	// 		hasSetInitialStep.current = true;
	// 		return;
	// 	}

	// 	hasSetInitialStep.current = true;
	// }, [path, hasConnectedWalletBefore]);

	const showCloseButton = true;

	const handleConnected = async () => {
		dlog(`wallet_debugging`, `onboarding_page_handle_connected`);

		const isMetamask = wallet.name.toLowerCase().includes('connect by drift');
		const subAccounts = await driftClient.getUserAccountsForAuthority(
			wallet.publicKey
		);
		const accountExists = await COMMON_UI_UTILS.userExists(
			driftClient,
			subAccounts[0]?.subAccountId,
			wallet.publicKey
		);
		setHasConnectedWalletBefore(true);

		if (!walletIsFullyConnectedRef.current) return; // prevents race condition where wallet disconnects during the async events above

		if (accountExists) {
			safePush(lastRouteBeforeConnect || '/');
		} else {
			if (isMagicAuth) {
				// safePush('/onboarding/your-magic-wallet');
				setCurrentStep(ONBOARDING_STEP.YOUR_MAGIC_WALLET);
			} else if (isMetamask) {
				// safePush('/onboarding/your-metamask-wallet');
				setCurrentStep(ONBOARDING_STEP.YOUR_METAMASK_WALLET);
			} else {
				// safePush('/onboarding/fund-your-account');
				setCurrentStep(ONBOARDING_STEP.FUND_YOUR_ACCOUNT);
			}
		}

		walletConnectionState.instance.update(
			'Connecting',
			false,
			CONNECTION_STEP_LOCK_KEY
		);
	};

	useEffect(() => {
		walletIsFullyConnectedRef.current = walletIsFullyConnected;

		if (walletIsFullyConnected && !keepCurrentStep) {
			handleConnected();
		}
	}, [walletIsFullyConnected]);

	useHotkey('Escape', () => {
		safePush(lastRouteBeforeConnect ?? '/');
	});

	return (
		<>
			{showCloseButton && (
				<div
					id="onboarding_close_button"
					className="absolute top-[-42px] right-0 sm:top-[-16px] sm:right-4 z-10"
				>
					<button
						onClick={() => {
							safePush(lastRouteBeforeConnect ?? '/');
						}}
						className="text-text-default hover:text-text-emphasis hover:cursor-pointer p-1.5 rounded-full border border-container-border bg-container-bg absolute top-[-8px] left-[-60px] flex items-center justify-center"
					>
						<Close size={24} />
					</button>
				</div>
			)}
			<div className="w-full h-full overflow-hidden">
				<div
					className={twMerge(
						'w-full h-full overflow-x-hidden overflow-y-auto thin-scroll',
						isMobileScreenSize && isSafari && 'pb-[80px]'
					)}
				>
					<div className="flex flex-row w-full min-h-full md:h-full xs:p-4 sm:p-12 text-text-default">
						{currentStep === ONBOARDING_STEP.GEOBLOCKED ? (
							<div className="max-w-[420px] mx-auto ms:h-[60vh]">
								<GeoblockedPanel onAccept={() => router.back()} />
							</div>
						) : currentStep === ONBOARDING_STEP.AGREE_TO_TERMS ? (
							<div className="max-w-[393px] mx-auto ms:h-[60vh]">
								<AcknowledgeTermsPanel
									onAccept={() => {
										if (!hasAcknowledgedTerms.hasPreviouslyAcknowledgedTerms) {
											updateHasAcknowledgedTerms();
											setCurrentStep(ONBOARDING_STEP.CONNECT);
										} else {
											updateHasAcknowledgedTerms();
											setCurrentStep(ONBOARDING_STEP.CONNECT);
										}
									}}
								/>
							</div>
						) : (
							<>
								<div className="w-full h-full flex xs:space-y-4 sm:space-y-0 flex-col-reverse md:flex-row items-center md:items-start justify-end md:justify-around max-w-[1800px] mx-auto sm:space-x-8">
									<div
										className={`w-full md:w-[50%] h-full flex justify-center overflow-auto thin-scroll xs:mt-4 sm:mt-0 ${
											displayStyle === 'emphasisLeft' &&
											`bg-container-bg rounded-xl px-4 py-12`
										}`}
									>
										<LeftPanelContent
											currentStep={currentStep}
											safePush={safePush}
											setStep={setCurrentStep}
											goBack={goBack}
										/>
									</div>
									<div className="hidden w-full md:flex md:h-full md:w-[50%] xs:mt-4 md:mt-0">
										<RightPanelContent currentStep={currentStep} />
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(OnboardingPage);
