'use client';

import { useContext } from 'react';
import { SafePushContext } from '../providers/safePushProvider';

const useSafePush = () => {
	const safePush = useContext(SafePushContext);

	return safePush;
};

export default useSafePush;
