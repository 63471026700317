import {
	BigNum,
	CandleResolution,
	MarketType,
	QUOTE_PRECISION_EXP,
	UserAccount,
	decodeUser,
} from '@drift-labs/sdk';
import {
	ENUM_UTILS,
	HistoryResolution,
	MarketDetails24H,
	PnlSnapshotOrderOption,
	Serializer,
	SnapshotEpochResolution,
	UIMatchedOrderRecordAndAction,
	UISerializableCandle,
	UISerializableDepositRecord,
	UISerializableFundingPaymentRecord,
	UISerializableFundingRateRecord,
	UISerializableLeaderboardResult,
	UISerializableLiquidationRecord,
	UISerializableOrderActionRecord,
	UISerializableSettlePnlRecord,
	UISerializableSwapRecord,
	UISnapshotHistory,
	UISerializableLPRecord,
	UISerializableInsuranceFundRecord,
	MarketMakerRewardRecord,
	UISerializableInsuranceFundStakeRecord,
	DownloadRecordType,
	MarketId,
	DownloadFile,
} from '@drift/common';
import { PublicKey } from '@solana/web3.js';
import Env from 'src/environmentVariables/EnvironmentVariables';
import captureException from './captureException';
import LoggingService from './LoggingService';
import UI_UTILS from './uiUtils';
import { DailyAllTimePnlSnapshot } from 'src/@types/types';
import useNavigationStore from 'src/stores/useNavigationStore';

enum RESOURCE {
	HISTORY_SERVER,
	DLOB_SERVER,
}

function throwUnhandled(eventType: never) {
	throw new Error('Unknown resource: ' + eventType);
}

type ClientResponse<T = void> = Promise<{
	success: boolean;
	body?: T;
	message?: string;
	status?: number;
}>;

type VolumeHistory = {
	cumulativeTotal: number;
	data: string;
	dateTs: number;
	perpCumulativeTotal: number;
	perpVolume: number;
	spotCumulativeTotal: number;
	spotVolume: number;
	volume: number;
};

const joinKeys = (pubKeys: PublicKey[]) =>
	pubKeys.map((pubKey) => pubKey.toString()).join(',');

const API_SECRET = process.env.NEXT_PUBLIC_INTERNAL_SECRET ?? '';

const DEFAULT_PAGE_SIZE = 20;

const trackHistoryServerRequestMetric = (requestUrl: string) => {
	const historyServerRoute = requestUrl.split('?')[0];
	const currentNavigationCategory =
		// This is how to access the state of navigation store outside of React context
		useNavigationStore.getState().currentNavigationCategory;

	if (!window.UNCONSUMED_GRAFANA_METRICS) {
		window.UNCONSUMED_GRAFANA_METRICS = {};
	}

	if (
		window.UNCONSUMED_GRAFANA_METRICS?.HISTORY_SERVER_REQUESTS_TARGET?.[
			historyServerRoute
		]
	) {
		window.UNCONSUMED_GRAFANA_METRICS.HISTORY_SERVER_REQUESTS_TARGET[
			historyServerRoute
		] += 1;
	} else {
		window.UNCONSUMED_GRAFANA_METRICS.HISTORY_SERVER_REQUESTS_TARGET = {
			...(window.UNCONSUMED_GRAFANA_METRICS?.HISTORY_SERVER_REQUESTS_TARGET ??
				{}),
			[historyServerRoute]:
				(window.UNCONSUMED_GRAFANA_METRICS?.HISTORY_SERVER_REQUESTS_TARGET?.[
					historyServerRoute
				] ?? 0) + 1,
		};
	}

	if (
		window.UNCONSUMED_GRAFANA_METRICS?.HISTORY_SERVER_REQUESTS_SOURCE?.[
			currentNavigationCategory
		]
	) {
		window.UNCONSUMED_GRAFANA_METRICS.HISTORY_SERVER_REQUESTS_SOURCE[
			currentNavigationCategory
		] += 1;
	} else {
		window.UNCONSUMED_GRAFANA_METRICS.HISTORY_SERVER_REQUESTS_SOURCE = {
			...(window.UNCONSUMED_GRAFANA_METRICS?.HISTORY_SERVER_REQUESTS_SOURCE ??
				{}),
			[currentNavigationCategory]:
				(window.UNCONSUMED_GRAFANA_METRICS?.HISTORY_SERVER_REQUESTS_SOURCE?.[
					currentNavigationCategory
				] ?? 0) + 1,
		};
	}
};

class ExchangeHistoryClient {
	private static get(url: string, resource = RESOURCE.HISTORY_SERVER) {
		const serverUrl = (() => {
			switch (resource) {
				case RESOURCE.DLOB_SERVER:
					return Env.dlobServerHttpUrl;
				case RESOURCE.HISTORY_SERVER:
					return Env.historyServerUrl;
				default:
					throwUnhandled(resource);
			}
		})();

		if (resource === RESOURCE.HISTORY_SERVER) {
			trackHistoryServerRequestMetric(url);
		}

		return new Promise<{ success: boolean; body: any; status: number }>(
			(res) => {
				const headers = new Headers();

				if (API_SECRET) {
					headers.append('Authorization', API_SECRET);
				}

				fetch(`${serverUrl}${url}`, {
					headers,
				})
					.then(async (response) => {
						if (!response.ok) {
							res({
								success: false,
								body: response.body,
								status: response.status,
							});
							return;
						}
						res({
							success: true,
							body: await response.json(),
							status: response.status,
						});
					})
					.catch((err) => {
						res({ success: false, body: err, status: 0 });
					});
			}
		);
	}

	private static post(url: string, bodyObject: any) {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(bodyObject),
		};

		return new Promise<{ success: boolean; body: any; status: number }>(
			(res) => {
				if (API_SECRET) {
					requestOptions.headers['Authorization'] = API_SECRET;
				}

				const postRequest = new Request(
					`${Env.historyServerUrl}${url}`,
					requestOptions
				);

				fetch(postRequest)
					.then(async (response) => {
						if (!response.ok) {
							res({
								success: false,
								body: response.body,
								status: response.status,
							});
							return;
						}
						res({
							success: true,
							body: await response.json(),
							status: response.status,
						});
					})
					.catch((err) => {
						res({ success: false, body: err, status: 0 });
					});
			}
		);
	}

	public static async getTradesForUsers(
		userPubKeys: PublicKey[],
		pageIndex: number,
		oldPageIndex?: number,
		beforeTs?: number,
		afterTs?: number,
		marketIndex?: number,
		marketType?: MarketType,
		pageSize = DEFAULT_PAGE_SIZE,
		predictionMarketsOnly = false
	): ClientResponse<{
		data: {
			records: UISerializableOrderActionRecord[][];
			totalCounts: number[];
		};
	}> {
		try {
			const baseQuery = `/${
				predictionMarketsOnly ? 'predictionTrades' : 'trades'
			}/userAccounts/?userPublicKeys=${joinKeys(userPubKeys)}&marketIndex=${
				marketIndex ?? ''
			}&marketType=${ENUM_UTILS.toStr(marketType) ?? ''}`;

			const queryString = UI_UTILS.getPaginationQueryString({
				baseQuery,
				newPage: pageIndex,
				pageSize,
				oldPage: oldPageIndex,
				beforeTs,
				afterTs,
			});

			const result = await this.get(queryString);

			if (!result.success) {
				return { success: false, message: `Couldn't get trades for user` };
			}

			const resultData = result.body.data as {
				records: any[][];
				totalCounts: number[];
			};

			const formattedTrades = resultData.records.map((records) =>
				records.map((record) =>
					Serializer.Deserialize.UIOrderActionRecord(record)
				)
			);

			return {
				success: true,
				body: {
					data: {
						records: formattedTrades,
						totalCounts: resultData.totalCounts,
					},
				},
			};
		} catch (e) {
			captureException(e);
			return {
				success: false,
				message: `Caught Error getting trades for users`,
			};
		}
	}

	public static async getCumFundingForUser(
		userPubKey: PublicKey,
		marketIndex?: number
	): ClientResponse<{ data: number }> {
		try {
			const url = `/cumFunding/userAccount?userPublicKey=${userPubKey.toString()}${
				!Number.isNaN(marketIndex) ? `&marketIndex=${marketIndex}` : ''
			}`;
			return await this.get(url);
		} catch (e) {
			captureException(e);
			console.log(`Caught Error getting cumulative funding for user: ${e}`);
			return {
				success: false,
				message: `Caught Error getting cumulative funding for user`,
			};
		}
	}

	/**
	 *
	 * @param resolution
	 * @param marketIndex
	 * @param from - unix timestamp ms since epoch
	 * @param to - unix timestamp ms since epoch
	 * @returns
	 */
	public static async getMarketHistory(
		resolution: CandleResolution,
		marketIndex: number,
		marketType: MarketType,
		to: number,
		from?: number,
		countBack?: number
	): ClientResponse<{
		candles: UISerializableCandle[];
		lastTradeSeen: UISerializableOrderActionRecord;
		beyondMaxLookback?: boolean;
	}> {
		try {
			const result = await (this.get(
				`/tv/history?marketIndex=${marketIndex}&marketType=${ENUM_UTILS.toStr(
					marketType
				)}&resolution=${resolution}&from=${from}&to=${to}&countBack=${countBack}`
			) as ClientResponse<{
				candles: Record<string, unknown>[];
				lastTradeSeen?: Record<string, unknown>;
				beyondMaxLookback?: boolean;
			}>);

			if (!result.success) {
				return {
					success: false,
					message: `Couldn't get market trade history`,
					status: result.status,
				};
			}

			const deserializedCandles = result.body.candles.map((candle) => {
				return Serializer.Deserialize.UICandle(candle);
			});

			return {
				success: true,
				body: {
					candles: deserializedCandles,
					lastTradeSeen: result.body.lastTradeSeen
						? Serializer.Deserialize.UIOrderActionRecord(
								result.body.lastTradeSeen
						  )
						: undefined,
					beyondMaxLookback: !!result.body.beyondMaxLookback,
				},
			};
		} catch (e) {
			console.error(`Caught error getting market trade history ${e}`);
			throw e;
		}
	}

	/**
	 *
	 * @param marketIndex
	 * @param from - unix timestamp ms since epoch
	 * @param to - unix timestamp ms since epoch
	 * @returns
	 */
	public static async getFundingRates(
		marketIndex: number,
		from: number,
		to: number
	): ClientResponse<{
		data: UISerializableFundingRateRecord[];
	}> {
		try {
			const result = await this.get(
				`/fundingRates?marketIndex=${marketIndex}&from=${from}&to=${to}`
			);

			if (!result.success || result.body.status !== 'ok') {
				return { success: false, message: `Couldn't get market trade history` };
			}

			return {
				success: true,
				body: {
					data: result.body.fundingRates.map((record) =>
						Serializer.Deserialize.UIFundingRate(record)
					),
				},
			};
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting funding history ${e}`);
			return {
				success: false,
				message: `Caught Error getting funding rates`,
			};
		}
	}

	/**
	 *
	 * @param marketIndex
	 * @returns
	 */
	public static async getPrice24hrsAgo(
		marketIndex: number,
		marketType: MarketType
	): ClientResponse<{
		data: {
			price: number;
		};
	}> {
		if (process.env.NEXT_PUBLIC_IGNORE_STATS) {
			return {
				success: true,
				body: {
					data: {
						price: 0,
					},
				},
			};
		}

		try {
			const result = await (this.get(
				`/stats/price24hrsAgo?marketIndex=${marketIndex}&marketType=${ENUM_UTILS.toStr(
					marketType
				)}`
			) as ClientResponse<{
				data: {
					price: number;
				};
			}>);

			if (!result.success) {
				return { success: false, message: `Error` };
			}

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught Error price 24hrs ago ${e}`);
			return {
				success: false,
				message: `Caught Error price 24hrs ago`,
			};
		}
	}

	public static async getRecentTrades(marketId: MarketId): ClientResponse<{
		data: {
			trades: UISerializableOrderActionRecord[];
			mostRecentTradeScore: number;
		};
	}> {
		try {
			const result = await this.get(
				`/trades?marketIndex=${
					marketId.marketIndex
				}&marketType=${ENUM_UTILS.toStr(marketId.marketType)}`
			);

			const formattedTrades = result.body.data.trades.map((record) =>
				Serializer.Deserialize.UIOrderActionRecord(JSON.parse(record))
			);

			const mostRecentTradeScore = result.body.data.mostRecentTradeScore;

			LoggingService.debug(
				`Initial most recent trade score : ${mostRecentTradeScore}`
			);

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							trades: formattedTrades,
							mostRecentTradeScore,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			LoggingService.error(`Caught error getting recent trades`);
			return { success: false };
		}
	}

	public static async getNewTrades(
		marketIndex: number,
		marketType: MarketType,
		mostRecentTradeScore: number
	): ClientResponse<{
		data: {
			trades: UISerializableOrderActionRecord[];
			mostRecentTradeScore: number;
			requestWasStale: boolean;
		};
	}> {
		try {
			const result = await this.get(
				`/newTrades?marketIndex=${marketIndex}&marketType=${ENUM_UTILS.toStr(
					marketType
				)}&mostRecentTradeScore=${mostRecentTradeScore}`
			);

			const formattedTrades = result.body.data.trades.map((record) =>
				Serializer.Deserialize.UIOrderActionRecord(JSON.parse(record))
			);

			const newMostRecentTradeScore = result.body.data.mostRecentTradeScore;

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							trades: formattedTrades,
							mostRecentTradeScore: newMostRecentTradeScore,
							requestWasStale: result.body.data.requestWasStale,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			LoggingService.error(`Caught error getting new trades`);
			return { success: false };
		}
	}

	public static async getFundingForUsers(
		userPubKeys: PublicKey[],
		pageIndex: number,
		oldPageIndex?: number,
		beforeTs?: number,
		afterTs?: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			records: UISerializableFundingPaymentRecord[][];
			totalCounts: number[];
		};
	}> {
		try {
			const queryString = UI_UTILS.getPaginationQueryString({
				baseQuery: `/fundingRatePayments?userPublicKeys=${joinKeys(
					userPubKeys
				)}`,
				newPage: pageIndex,
				pageSize,
				oldPage: oldPageIndex,
				beforeTs,
				afterTs,
			});

			const result = await this.get(queryString);

			const resultData = result.body.data as {
				records: any[][];
				totalCounts: number[];
			};

			const parsedRecords = resultData?.records?.map((records) =>
				records.map(Serializer.Deserialize.UIFundingPayment)
			);

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							records: parsedRecords,
							totalCounts: resultData?.totalCounts,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting recent trades`);
			return { success: false };
		}
	}

	public static async getLiquidationHistoryForUsers(
		userPubKeys: PublicKey[],
		pageIndex: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			liquidations: UISerializableLiquidationRecord[][];
			totalCounts: number[];
		};
	}> {
		try {
			const result = await this.get(
				`/liquidations/userAccount/?userPublicKey=${joinKeys(
					userPubKeys
				)}&targetPageIndex=${pageIndex}&pageSize=${pageSize}`
			);

			const { liquidations: allUserLiquidations, totalCounts } = result.body
				.data as { liquidations: any[]; totalCounts: number[] };

			const parsedLiquidations = allUserLiquidations.map((userLiquidations) =>
				userLiquidations.map((liquidation) =>
					Serializer.Deserialize.UILiquidation(liquidation)
				)
			);

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							liquidations: parsedLiquidations,
							totalCounts,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting liquidation history`);
			return { success: false };
		}
	}

	public static async getLiquidationCountForUser(
		userPubKey: PublicKey
	): ClientResponse<{ data: number }> {
		try {
			const result = await this.get(
				`/liquidations/userAccountLiqCount/?userPublicKey=${userPubKey.toString()}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting liquidation history`);
			return { success: false };
		}
	}

	public static async getLiquidationHistoryForLiquidator(
		userAccountPubKey: PublicKey,
		pageIndex: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{ data: UISerializableLiquidationRecord[] }> {
		try {
			const result = await this.get(
				`/liquidations/userAccount/?liquidator=${userAccountPubKey.toString()}&targetPageIndex=${pageIndex}&pageSize=${pageSize}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting liquidation history`);
			return { success: false };
		}
	}

	public static async getLiquidations(
		pageIndex: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			liquidations: UISerializableLiquidationRecord[];
			totalCount: number;
		};
	}> {
		try {
			const result = await this.get(
				`/liquidations/?targetPageIndex=${pageIndex}&pageSize=${pageSize}`
			);

			const { liquidations: liquidations, totalCount } = result.body.data as {
				liquidations: any[];
				totalCount: number;
			};

			const parsedLiquidations = liquidations.map((liquidation) =>
				Serializer.Deserialize.UILiquidation(liquidation)
			);

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							liquidations: parsedLiquidations,
							totalCount,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting liquidations history`);
			return { success: false };
		}
	}

	public static async getBankruptcies(
		pageIndex: number,
		oldPageIndex?: number,
		beforeTs?: number,
		afterTs?: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			bankruptcies: UISerializableLiquidationRecord[];
			totalCount: number;
		};
	}> {
		try {
			const queryString = UI_UTILS.getPaginationQueryString({
				baseQuery: '/bankruptcies',
				newPage: pageIndex,
				pageSize,
				oldPage: oldPageIndex,
				beforeTs,
				afterTs,
			});

			const result = await this.get(queryString);

			const { bankruptcies: bankruptcies, totalCount } = result.body.data as {
				bankruptcies: any[];
				totalCount: number;
			};

			const parsedBankruptcies = bankruptcies.map((bankruptcy) =>
				Serializer.Deserialize.UILiquidation(bankruptcy)
			);

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							bankruptcies: parsedBankruptcies,
							totalCount,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting bankruptcies history`);
			return { success: false };
		}
	}

	public static async getDepositHistoryForUsers(
		userPubKeys: PublicKey[],
		pageIndex: number,
		oldPageIndex?: number,
		beforeTs?: number,
		afterTs?: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			records: UISerializableDepositRecord[][];
			totalCounts: number[];
			maxRecordLimit: number;
		};
	}> {
		try {
			const queryString = UI_UTILS.getPaginationQueryString({
				baseQuery: `/deposits/userAccounts/?userPublicKeys=${joinKeys(
					userPubKeys
				)}`,
				newPage: pageIndex,
				pageSize,
				oldPage: oldPageIndex,
				beforeTs,
				afterTs,
			});

			const result = await this.get(queryString);

			const deserializedDeposits = result.body.data.records.map((records) =>
				records.map((record) => Serializer.Deserialize.UIDeposit(record))
			);

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							records: deserializedDeposits,
							totalCounts: result.body.data.totalCounts,
							maxRecordLimit: result.body.data.maxRecordLimit,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting deposit history`);
			return { success: false };
		}
	}

	public static async getVolumeData(
		marketIndex?: number,
		marketType?: MarketType
	): ClientResponse<{
		data: {
			W: { date: string; volume: number }[];
			D: { date: string; volume: number }[];
			H: { date: string; volume: number }[];
		};
	}> {
		try {
			const result = await this.get(
				`/stats/volume?marketIndex=${marketIndex ?? ''}&marketType=${
					ENUM_UTILS.toStr(marketType) ?? ''
				}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting volume data`);
			return { success: false };
		}
	}

	public static async get24HourMarketsData(): ClientResponse<{
		data: MarketDetails24H[];
	}> {
		try {
			const result = await this.get(`/markets24h`);
			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting 24 hour volume`);
			return { success: false };
		}
	}

	public static async get24HourPricePoints(): ClientResponse<{
		data: {
			marketIndex: number;
			pricePoints: number[];
			marketType: MarketType;
		}[];
	}> {
		try {
			const result = await this.get(`/stats/24hAgoPricePoints`);
			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting 24 hour volume`);
			return { success: false };
		}
	}

	public static async get24HourVolume(
		marketIndex?: number,
		marketType?: MarketType
	): ClientResponse<{
		data: { volume: number };
	}> {
		try {
			const result = await this.get(
				`/stats/24HourVolume?marketIndex=${marketIndex ?? ''}&marketType=${
					ENUM_UTILS.toStr(marketType) ?? ''
				}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting 24 hour volume`);
			return { success: false };
		}
	}

	public static async get1WVolume(
		marketIndex?: number,
		marketType?: MarketType
	): ClientResponse<{
		data: { volume: number };
	}> {
		try {
			const result = await this.get(
				`/stats/1WVolume?marketIndex=${marketIndex ?? ''}&marketType=${
					ENUM_UTILS.toStr(marketType) ?? ''
				}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting 1W volume`);
			return { success: false };
		}
	}

	public static async getRolling24HourVolume(
		marketIndex?: number,
		marketType?: MarketType
	): ClientResponse<{
		data: { volume: string; hour: number }[];
	}> {
		try {
			const result = await this.get(
				`/stats/24HourRollingVolume?marketIndex=${
					marketIndex ?? ''
				}&marketType=${ENUM_UTILS.toStr(marketType) ?? ''}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting rolling 24 hour volume`);
			return { success: false };
		}
	}

	public static async getAllTimeCumulativeVolume(
		marketIndex?: number,
		marketType?: MarketType
	): ClientResponse<{
		data: {
			allTimeTotalCumulativeVolumeData: {
				cumulativeVolume: number;
				volumeHistory: VolumeHistory[];
			};
			volumeDataForMarkets: {
				marketSymbol: string;
				marketType: MarketType;
				marketIndex: number;
				cumulativeVolume: {
					cumulativeVolume: number;
					volumeHistory: VolumeHistory[];
				};
			}[];
		};
	}> {
		try {
			const result = await this.get(
				`/stats/allTimeCumulativeVolume?marketIndex=${
					marketIndex ?? ''
				}&marketType=${ENUM_UTILS.toStr(marketType) ?? ''}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting cumulative volume`);
			return { success: false };
		}
	}

	public static async getTotalTraders(): ClientResponse<{
		data: { cumulative: number };
	}> {
		try {
			const result = await this.get(`/traders`);
			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting total trader count`);
			return { success: false };
		}
	}

	public static async get24hrAvgFundingRate(
		marketIndex: string
	): ClientResponse<{ data: { long: string; short: string } }> {
		try {
			const result = await this.get(
				`/24HrAvgFundingRate?marketIndex=${marketIndex}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting 24hr funding rate`);
			return { success: false };
		}
	}

	public static async getAvgFundingRates(): ClientResponse<{
		data: {
			[marketIndex: number]: {
				'24h': number;
				'7d': number;
				'30d': number;
				'1y': number;
			};
		};
	}> {
		try {
			const result = await this.get(`/stats/avgFundingRates`);

			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting avg funding rates`);
			return { success: false };
		}
	}

	public static async getExternalFundingRates(): ClientResponse<{
		data: {
			[marketIndex: number]: { [externalSource: string]: number };
		};
	}> {
		try {
			const result = await this.get(`/stats/externalFundingRates`);

			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting external funding rates`);
			return { success: false };
		}
	}

	public static async getUserLpPnl(userPubKey: PublicKey): ClientResponse<{
		data: { poolPnls: { pnl: string; marketIndex: number }[] };
	}> {
		try {
			const result = await this.get(
				`/liquidityProviderPnl/userAccount/?userPublicKey=${userPubKey.toString()}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting lp pnl for user`);
			return { success: false };
		}
	}

	public static async getLatestFeeData(marketIndex: number): ClientResponse<{
		data: {
			feeData: {
				marketIndex: number;
				startTs: number;
				endTs: number;
				fees: { feeType: string; feeAmount: string }[];
				protocolLpShares?: string;
				userLpShares?: string;
			}[];
		};
	}> {
		try {
			const result = await this.get(`/feeData?marketIndex=${marketIndex}`);
			return result;
		} catch (e) {
			captureException(e);
			console.error(e, `Caught error getting fee data`);
			return { success: false };
		}
	}

	public static async getOrdersForUsers(
		userPubKeys: PublicKey[],
		pageIndex: number,
		marketType?: MarketType,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			orders: UIMatchedOrderRecordAndAction[][];
			counts: { perp: number; spot: number }[];
			maxRecordLimit: number;
		};
	}> {
		try {
			const url = `/orders/userAccounts?userPublicKeys=${joinKeys(
				userPubKeys
			)}&targetPageIndex=${pageIndex}&pageSize=${pageSize}&marketType=${
				marketType ? ENUM_UTILS.toStr(marketType) : ''
			}`;

			const result = await this.get(url);

			if (!result.success) {
				return { success: false, message: `Couldn't get orders for user` };
			}

			const orderRecords = result.body.data.orders as any[];

			const deserializedOrderRecords = orderRecords.map((orders) =>
				orders.map((order) =>
					Serializer.Deserialize.UIMatchedOrderAction(order)
				)
			);

			return {
				success: true,
				body: {
					data: {
						orders: deserializedOrderRecords,
						counts: result.body.data.counts,
						maxRecordLimit: result.body.data.maxRecordLimit,
					},
				},
			};
		} catch (e) {
			captureException(e);
			console.log(`Caught Error getting orders for user: ${e}`);
			return {
				success: false,
				message: `Caught Error getting orders for user`,
			};
		}
	}

	public static async getPredictionOrdersForUsers(
		userPubKeys: PublicKey[],
		pageIndex: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			orders: UIMatchedOrderRecordAndAction[][];
			counts: number[];
			maxRecordLimit: number;
		};
	}> {
		try {
			const url = `/predictionOrders/userAccounts?userPublicKeys=${joinKeys(
				userPubKeys
			)}&targetPageIndex=${pageIndex}&pageSize=${pageSize}`;

			const result = await this.get(url);

			if (!result.success) {
				return {
					success: false,
					message: `Couldn't get prediction orders for user`,
				};
			}

			const predictionOrderRecords = result.body.data.orders as any[];

			const deserializedPredictionOrderRecords = predictionOrderRecords.map(
				(orders) =>
					orders.map((order) =>
						Serializer.Deserialize.UIMatchedOrderAction(order)
					)
			);

			return {
				success: true,
				body: {
					data: {
						orders: deserializedPredictionOrderRecords,
						counts: result.body.data.counts,
						maxRecordLimit: result.body.data.maxRecordLimit,
					},
				},
			};
		} catch (e) {
			captureException(e);
			console.log(`Caught Error getting prediction orders for user: ${e}`);
			return {
				success: false,
				message: `Caught Error getting prediction orders for user`,
			};
		}
	}

	public static async getOrderActionRecordsForUserOrder(
		userPubKey: PublicKey,
		orderId: number,
		targetPageIndex: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			orderActionRecords: UISerializableOrderActionRecord[];
		};
	}> {
		try {
			const url = `/orderActions/userAccounts?userPublicKey=${userPubKey.toString()}&orderId=${orderId}&pageSize=${pageSize}&targetPageIndex=${targetPageIndex}`;
			const result = await this.get(url);

			if (!result.success) {
				return { success: false, message: `Couldn't get orders for user` };
			}

			const orderActionRecords = result.body.data.orderActionRecords as any[];

			const deserializedOrderActionRecords = orderActionRecords.map(
				(actionRecord) => {
					return Serializer.Deserialize.UIOrderActionRecord(actionRecord);
				}
			);

			return {
				success: true,
				body: {
					data: {
						orderActionRecords: deserializedOrderActionRecords,
					},
				},
			};
		} catch (e) {
			captureException(e);
			console.log(
				`Caught Error getting order action records for user order: ${e}`
			);
			return {
				success: false,
				message: `Caught Error getting order action records for user order`,
			};
		}
	}

	public static async getSettlePnlRecordsForUsers(
		userPubKeys: PublicKey[],
		pageIndex: number,
		expiredPositionsOnly: boolean,
		oldPageIndex?: number,
		beforeTs?: number,
		afterTs?: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: { records: UISerializableSettlePnlRecord[][]; totalCounts: number[] };
	}> {
		try {
			const baseQuery = `/settlePnlRecords/userAccounts?userPublicKeys=${joinKeys(
				userPubKeys
			)}&expiredPositionsOnly=${expiredPositionsOnly ? 'true' : 'false'}`;

			const queryString = UI_UTILS.getPaginationQueryString({
				baseQuery,
				newPage: pageIndex,
				pageSize,
				oldPage: oldPageIndex,
				beforeTs,
				afterTs,
			});

			const result = await this.get(queryString);

			if (!result.success) {
				return {
					success: false,
					message: `Couldn't get settle pnl records for user`,
				};
			}

			const data = result.body.data as {
				records: any[][];
				totalCounts: number[];
			};

			const deserializedSettlePnlRecords = data.records.map((records) =>
				records.map((record) => Serializer.Deserialize.UISettlePnl(record))
			);

			return {
				success: true,
				body: {
					data: {
						records: deserializedSettlePnlRecords,
						totalCounts: data.totalCounts,
					},
				},
			};
		} catch (e) {
			captureException(e);
			console.log(`Caught Error getting settle pnl records for user: ${e}`);
			return {
				success: false,
				message: `Caught Error getting settle pnl records for user`,
			};
		}
	}

	public static async getLpRecordsForUsers(
		userPubKeys: PublicKey[],
		pageIndex: number,
		oldPageIndex?: number,
		beforeTs?: number,
		afterTs?: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: { records: UISerializableLPRecord[][]; totalCounts: number[] };
	}> {
		try {
			const queryString = UI_UTILS.getPaginationQueryString({
				baseQuery: `/lpRecords/userAccounts?userPublicKeys=${joinKeys(
					userPubKeys
				)}`,
				newPage: pageIndex,
				pageSize,
				oldPage: oldPageIndex,
				beforeTs,
				afterTs,
			});

			const result = await this.get(queryString);

			if (!result.success) {
				return {
					success: false,
					message: `Couldn't get lp records for user`,
				};
			}

			const data = result.body.data as {
				records: any[][];
				totalCounts: number[];
			};

			const deserializedLpRecords = data.records.map((records) =>
				records.map((record) => Serializer.Deserialize.UILPRecord(record))
			);

			return {
				success: true,
				body: {
					data: {
						records: deserializedLpRecords,
						totalCounts: data.totalCounts,
					},
				},
			};
		} catch (e) {
			captureException(e);
			console.log(`Caught Error getting lp records for user: ${e}`);
			return {
				success: false,
				message: `Caught Error getting lp records for user`,
			};
		}
	}

	public static async getSnapshotsForUsers(
		userPubKeys: PublicKey[]
	): ClientResponse<{
		success: boolean;
		data: UISnapshotHistory[];
	}> {
		try {
			const result = await this.get(
				`/userSnapshots/?userPubKeys=${joinKeys(userPubKeys)}`
			);

			if (!result.success) {
				return {
					success: false,
				};
			} else {
				const rawData = result.body.data as {
					[HistoryResolution.DAY]: Record<string, unknown>[];
					[HistoryResolution.WEEK]: Record<string, unknown>[];
					[HistoryResolution.MONTH]: Record<string, unknown>[];
					[HistoryResolution.ALL]: Record<string, unknown>[];
					dailyAllTimePnls: Record<string, unknown>[];
				}[];

				const formattedData = rawData.map((snapshotHistory) => ({
					[HistoryResolution.DAY]: snapshotHistory.DAY.map(
						Serializer.Deserialize.UIAccountSnapshotHistory
					),
					[HistoryResolution.WEEK]: snapshotHistory.WEEK.map(
						Serializer.Deserialize.UIAccountSnapshotHistory
					),
					[HistoryResolution.MONTH]: snapshotHistory.MONTH.map(
						Serializer.Deserialize.UIAccountSnapshotHistory
					),
					[HistoryResolution.ALL]: snapshotHistory.ALL.map(
						Serializer.Deserialize.UIAccountSnapshotHistory
					),
					dailyAllTimePnls: snapshotHistory.dailyAllTimePnls?.map(
						Serializer.Deserialize.UIAllTimePnlData
					),
				}));

				return {
					success: true,
					body: {
						success: true,
						data: formattedData,
					},
				};
			}
		} catch (e) {
			LoggingService.error(`Caught error getting snapshots for users`, e);
			return { success: false };
		}
	}

	public static async getDailyAllTimeSnapshotsForUsers(
		userPubKeys: PublicKey[]
	): ClientResponse<{
		success: boolean;
		data: DailyAllTimePnlSnapshot[][];
	}> {
		try {
			const result = await this.get(
				`/dailyAllTimeUserSnapshots?userPubKeys=${joinKeys(userPubKeys)}`
			);

			if (!result.success) {
				return {
					success: false,
				};
			} else {
				const rawData = result.body.data as DailyAllTimePnlSnapshot[][];

				return {
					success: true,
					body: {
						success: true,
						data: rawData,
					},
				};
			}
		} catch (e) {
			LoggingService.error(
				`Caught error getting daily all time snapshots for users`,
				e
			);
			return { success: false };
		}
	}

	public static async getSwapHistoryForUsers(
		userPubKeys: PublicKey[],
		pageIndex: number,
		oldPageIndex?: number,
		beforeTs?: number,
		afterTs?: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			records: UISerializableSwapRecord[][];
			totalCounts: number[];
			maxRecordLimit: number;
		};
	}> {
		try {
			const baseQuery = `/swaps/userAccounts?userPublicKeys=${joinKeys(
				userPubKeys
			)}`;

			const queryString = UI_UTILS.getPaginationQueryString({
				baseQuery,
				newPage: pageIndex,
				pageSize,
				oldPage: oldPageIndex,
				beforeTs,
				afterTs,
			});

			const result = await this.get(queryString);

			if (!result.success) {
				return {
					success: false,
					message: `Couldn't get swap history for user`,
				};
			}

			const data = result.body.data as {
				records: any[][];
				totalCounts: number[];
				maxRecordLimit: number;
			};

			const deserializedSwapRecords = data.records.map((records) =>
				records.map((record) => Serializer.Deserialize.UISwapRecord(record))
			);

			return {
				success: true,
				body: {
					data: {
						records: deserializedSwapRecords,
						totalCounts: data.totalCounts,
						maxRecordLimit: data.maxRecordLimit,
					},
				},
			};
		} catch (e) {
			captureException(e);
			console.log(`Caught Error getting swap history for user: ${e}`);
			return {
				success: false,
				message: `Caught Error getting swap history for user`,
			};
		}
	}

	public static async getPnlLeaderboardResults(
		userAuthorities?: PublicKey[],
		orderBy?: PnlSnapshotOrderOption,
		resolution?: SnapshotEpochResolution,
		gatedMainnetComp?: boolean
	): ClientResponse<{
		success: boolean;
		data: UISerializableLeaderboardResult;
	}> {
		const userGroupString = userAuthorities
			?.map((pubKey) => pubKey.toString())
			?.join(',');

		const url = `/leaderboards/pnl?authorities=${
			userGroupString ?? ''
		}&orderBy=${orderBy ?? ''}&resolution=${
			resolution ? ENUM_UTILS.toStr(resolution) : ''
		}&gatedMainnetComp=${gatedMainnetComp ? 'true' : ''}`;

		try {
			const result = await this.get(url);

			if (!result.success) {
				return {
					success: false,
				};
			} else {
				const rawData = result.body.data;
				const deserializedData =
					Serializer.Deserialize.UISerializableLeaderboardResult(rawData);
				return {
					success: true,
					body: { success: true, data: deserializedData },
				};
			}
		} catch (e) {
			captureException(e);
			LoggingService.error(
				`Caught error getting snapshots for user group ${userGroupString.toString()}`
			);
			return { success: false };
		}
	}

	public static async getLatestDeployment(): ClientResponse<{
		data: { deploymentId: string };
	}> {
		try {
			const result = await this.get(`/latestDeployment`);
			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting latest deployment`);
			return { success: false };
		}
	}

	public static async getLpPerformanceData(
		marketIndex: number,
		type: 'raw' | 'pct',
		daysAgo: number
	): ClientResponse<{
		data: [number, string][];
	}> {
		try {
			const result = await this.get(
				`/lpPerformance?marketIndex=${marketIndex}&type=${type}&daysAgo=${daysAgo}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(
				`Caught error getting lp performance data for market ${marketIndex}`
			);
			return { success: false };
		}
	}

	public static async getLpAprs(): ClientResponse<{
		data: {
			marketIndex: number;
			aprs: {
				all: number;
				'30d': number;
				'7d': number;
				'24h': number;
			};
		}[];
	}> {
		try {
			const result = await this.get(`/lpAprs`);

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting lp apr data`);
			return { success: false };
		}
	}

	public static async getInsuranceFundRecords(
		marketIndex: number
	): ClientResponse<{
		data: {
			insuranceFundRecords: UISerializableInsuranceFundRecord[];
		};
	}> {
		try {
			const result = await this.get(
				`/insuranceFundRecords?marketIndex=${marketIndex}`
			);

			const formattedInsuranceFundRecords =
				result.body.data.insuranceFundRecords.map((record) =>
					Serializer.Deserialize.UIInsuranceFundRecord(record)
				);

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							insuranceFundRecords: formattedInsuranceFundRecords,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			LoggingService.error(`Caught error getting insurance fund records`);
			return { success: false };
		}
	}

	public static async getBankruptcyStats(daysAgo = 1): ClientResponse<{
		data: {
			bankruptcyStats: {
				totalAmount: number;
				ifPayment: number;
				socialLoss: number;
			};
			totalCount: number;
			lastPerpTs: number;
			lastSpotTs: number;
		};
	}> {
		try {
			const result = await this.get(`/stats/bankruptcies?daysAgo=${daysAgo}`);

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting bankruptcy stats`);
			return { success: false };
		}
	}

	public static async getLiquidationStats(): ClientResponse<{
		data: {
			'24h': { count: number; amount: number };
			'30d': { count: number; amount: number };
		};
	}> {
		try {
			const result = await this.get(`/stats/liquidations`);

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting bankruptcy stats`);
			return { success: false };
		}
	}

	public static async getFullFundingRecordsForUser(
		user: PublicKey,
		marketIndex?: number
	): ClientResponse<{
		data: {
			fundingPaymentRecords: UISerializableFundingPaymentRecord[];
		};
	}> {
		try {
			const result = await this.get(
				`/fundingHistory/userAccount?userPublicKey=${user.toString()}${
					marketIndex != undefined ? `&marketIndex=${marketIndex}` : ``
				}`
			);

			const fundingPaymentRecords = result.body.data.fundingPaymentRecords.map(
				(record) => Serializer.Deserialize.UIFundingPayment(record)
			);

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							fundingPaymentRecords,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			LoggingService.error(`Caught error getting funding payment records`);
			return { success: false };
		}
	}

	public static async getServerStatus(): ClientResponse<{
		data: {
			maintenanceMode: boolean;
			maintenanceModeEstimateTs?: number;
		};
	}> {
		try {
			const result = await this.get(`/status`);

			const serverStatus = result.body;

			if (result.success) {
				return {
					success: true,
					body: {
						data: serverStatus,
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			LoggingService.error(`Caught error getting history server status`);
			return { success: false };
		}
	}

	public static async getMarketMakerRewards(): ClientResponse<{
		data: {
			lastUpdated: number;
			records: MarketMakerRewardRecord[];
		};
	}> {
		try {
			const result = await this.get(`/marketMakerRewards`);

			if (result.success) {
				return {
					success: true,
					body: {
						data: {
							lastUpdated: result.body.data.lastUpdated,
							records: result.body.data.records,
						},
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			LoggingService.error(`Caught error getting market maker reward records`);
			return { success: false };
		}
	}

	public static async getTopMakersKeys({
		marketIndex,
		marketType,
		side,
		limit,
	}: {
		marketIndex: number;
		marketType: MarketType;
		side: 'bid' | 'ask';
		limit: number;
	}): Promise<PublicKey[]> {
		try {
			const queryParams = UI_UTILS.encodeQueryParams({
				marketIndex,
				marketType: ENUM_UTILS.toStr(marketType),
				side,
				limit,
				includeAccounts: false,
			});

			const result = await this.get(
				`/topMakers?${queryParams}`,
				RESOURCE.DLOB_SERVER
			);

			if (result.success) {
				return result.body.map((addr) => new PublicKey(addr));
			}

			return [];
		} catch (e) {
			captureException(e);
			LoggingService.error(
				`Caught error fetching best makers from dlob server`
			);
			return [];
		}
	}

	public static async getTopMakersKeysAndAccounts({
		marketIndex,
		marketType,
		side,
		limit,
	}: {
		marketIndex: number;
		marketType: MarketType;
		side: 'bid' | 'ask';
		limit: number;
	}): Promise<
		{
			userAccountPubKey: PublicKey;
			userAccount: UserAccount;
		}[]
	> {
		try {
			const queryParams = UI_UTILS.encodeQueryParams({
				marketIndex,
				marketType: ENUM_UTILS.toStr(marketType),
				side,
				limit,
				includeAccounts: true,
			});

			const result = await this.get(
				`/topMakers?${queryParams}`,
				RESOURCE.DLOB_SERVER
			);

			if (result.success) {
				return result.body.map(
					(value: { userAccountPubKey: string; accountBase64: string }) => {
						return {
							userAccountPubKey: new PublicKey(value.userAccountPubKey),
							userAccount: decodeUser(
								Buffer.from(value.accountBase64, 'base64')
							),
						};
					}
				);
			}

			return [];
		} catch (e) {
			captureException(e);
			LoggingService.error(
				`Caught error fetching best makers from dlob server`
			);
			return [];
		}
	}

	public static async getUnsettledPnlUsersForMarket(
		marketIndex: number
	): Promise<{
		marketIndex: number;
		gainers: { userPubKey: PublicKey; pnl: BigNum }[];
		losers: { userPubKey: PublicKey; pnl: BigNum }[];
	}> {
		try {
			const queryParams = UI_UTILS.encodeQueryParams({
				marketIndex,
			});

			const result = await this.get(
				`/unsettledPnlUsers?${queryParams}`,
				RESOURCE.DLOB_SERVER
			);

			if (result.success) {
				const resp = result.body;

				return {
					marketIndex: resp.marketIndex,
					gainers:
						resp.gainers
							?.filter((gainer) => gainer.pnl > 0)
							.map((gainer) => {
								return {
									userPubKey: new PublicKey(gainer.userPubKey),
									pnl: BigNum.fromPrint(
										gainer.pnl.toString(),
										QUOTE_PRECISION_EXP
									),
								};
							}) ?? [],
					losers:
						resp.losers
							?.filter((gainer) => gainer.pnl < 0)
							.map((loser) => {
								return {
									userPubKey: new PublicKey(loser.userPubKey),
									pnl: BigNum.fromPrint(
										loser.pnl.toString(),
										QUOTE_PRECISION_EXP
									),
								};
							}) ?? [],
				};
			}

			return { marketIndex, gainers: [], losers: [] };
		} catch (e) {
			captureException(e);
			LoggingService.error(
				`Caught error fetching unsettled pnl users from dlob server`
			);
			return { marketIndex, gainers: [], losers: [] };
		}
	}

	public static async getInsuranceFundStats(): ClientResponse<{
		data: {
			totalRevenue: number;
			perpLiqsTotal: number;
			spotLiqsTotal: number;
		};
	}> {
		try {
			const result = await this.get(`/stats/insuranceFundStats`);

			if (result.success) {
				return {
					success: true,
					body: {
						data: result.body.data,
					},
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			LoggingService.error(`Caught error getting insurance fund stats`);
			return { success: false };
		}
	}

	public static async fetchInsuranceFundStakeHistoryForUser(
		userAuthority: PublicKey,
		pageIndex: number,
		oldPageIndex?: number,
		beforeTs?: number,
		afterTs?: number,
		pageSize = DEFAULT_PAGE_SIZE
	): ClientResponse<{
		data: {
			records: UISerializableInsuranceFundStakeRecord[];
			totalCount: number;
			maxRecordLimit: number;
		};
	}> {
		try {
			const baseQuery = `/insurance-funds-stake/user?userAuthority=${userAuthority.toString()}`;

			const queryString = UI_UTILS.getPaginationQueryString({
				baseQuery,
				newPage: pageIndex,
				pageSize,
				oldPage: oldPageIndex,
				beforeTs,
				afterTs,
			});

			const result = await this.get(queryString);

			if (!result.success) {
				return {
					success: false,
					message: `Couldn't get insurance fund stake history for user`,
				};
			}

			const data = result.body.data as {
				records: any[];
				totalCount: number;
				maxRecordLimit: number;
			};
			const deserializedIFStakeRecords = data.records.map((record) =>
				Serializer.Deserialize.UIInsuranceFundStakeRecord(record)
			);

			return {
				success: true,
				body: {
					data: {
						records: deserializedIFStakeRecords,
						totalCount: data.totalCount,
						maxRecordLimit: data.maxRecordLimit,
					},
				},
			};
		} catch (e) {
			captureException(e);
			console.log(`Caught Error getting IF stake history for user: ${e}`);
			return {
				success: false,
				message: `Caught Error getting IF stake history for user`,
			};
		}
	}

	public static async getRateHistory(
		marketIndex: number,
		type: 'deposit' | 'borrow',
		daysAgo: number
	): ClientResponse<{
		data: [number, string][];
	}> {
		try {
			const result = await this.get(
				`/rateHistory?marketIndex=${marketIndex}&type=${type}&daysAgo=${daysAgo}`
			);

			return result;
		} catch (e) {
			captureException(e);
			console.error(
				`Caught error getting ${type} rate history data for spot market ${marketIndex}`
			);
			return { success: false };
		}
	}

	public static async requestFileDownload({
		fileType,
		from,
		to,
		userPubKeys,
		authority,
		isEmulationMode,
		marketSymbol,
	}: {
		fileType: DownloadRecordType;
		from: string;
		to: string;
		userPubKeys: PublicKey[];
		authority: string;
		isEmulationMode: boolean;
		marketSymbol?: string;
	}): ClientResponse<{
		data: {
			file: string;
			fileTitle: string;
		};
	}> {
		try {
			const url = `/requestFileDownload?fileType=${fileType}&from=${from}&to=${to}&userPublicKeys=${joinKeys(
				userPubKeys
			)}&authority=${
				authority ?? ''
			}&isEmulationMode=${isEmulationMode}&market=${marketSymbol ?? ''}`;

			const result = await this.get(url);

			if (!result.success) {
				return {
					success: false,
					message:
						result.status === 429
							? 'Too many requests. Please wait before trying again.'
							: 'No data available for the selected time/record type.',
				};
			}

			return result;
		} catch (e) {
			console.log(`Caught error requesting file download`);
			return {
				success: false,
				message: `Error requesting file download.`,
			};
		}
	}

	public static async getDownloadRequests(
		authority: PublicKey,
		isEmulationMode: boolean
	): ClientResponse<{
		data: {
			records: {
				pending: DownloadFile[];
				available: DownloadFile[];
			};
			counts: {
				pending: number;
				available: number;
			};
		};
	}> {
		try {
			const result = await this.get(
				`/authorityDownloads/?authority=${authority.toString()}&isEmulationMode=${isEmulationMode}`
			);

			if (!result.success) {
				console.error(`Caught error getting download records`);
				return {
					success: false,
				};
			}

			return result;
		} catch (e) {
			captureException(e);
			console.error(`Caught error getting download records`);
			return { success: false };
		}
	}

	/**
	 * @param startTs Start timestamp in seconds.
	 * @param endTs End timestamp in seconds.
	 * @param userAccounts User accounts to fetch volume for.
	 * @returns Volume data for the given user accounts.
	 */
	public static async fetchUserVolume({
		startTs,
		endTs,
		userAccounts,
	}: {
		startTs: number;
		endTs: number;
		userAccounts: PublicKey[];
	}): ClientResponse<{
		data: { volumes: number[] };
	}> {
		try {
			const url = `/userVolume?startTs=${startTs}&endTs=${endTs}&userAccounts=${userAccounts
				.map((acc) => acc.toString())
				.join(',')}`;

			const result = await this.get(url);

			return result;
		} catch (err) {
			console.log(`Caught error fetching user volume`);
			return {
				success: false,
				message: `Error fetching user volume.`,
			};
		}
	}
}

export default ExchangeHistoryClient;
