import { useMemo } from 'react';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useFeatureFlagStore from 'src/stores/useFeatureFlagStore';

const useHighlightedSpotMarkets = () => {
	const highlightedMarketsFeatureFlagState = useFeatureFlagStore(
		(s) => s.flags.HIGHLIGHTED_ASSETS
	);

	const highlightedSpotMarkets = useMemo(() => {
		if (!highlightedMarketsFeatureFlagState.enabled) return [];

		return highlightedMarketsFeatureFlagState.assets
			.map((assetSymbol) => {
				return SPOT_MARKETS_LOOKUP.find(
					(market) => market.symbol === assetSymbol
				);
			})
			.filter((market) => !!market); // Filter non-matching markets
	}, [highlightedMarketsFeatureFlagState]);

	return highlightedSpotMarkets;
};

export default useHighlightedSpotMarkets;
