'use client';

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import GradientLoadingSpinner from 'src/components/Icons/LoadingSpinner/GradientLoadingSpinner';
import { Close, Info, SuccessFilled } from '@drift-labs/icons';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariableTypes';
import useDriftTheme from 'src/hooks/useDriftTheme';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { twMerge } from 'tailwind-merge';
import { PointsMono } from '@drift-labs/icons';
import { NotificationStore } from '../../stores/useNotificationStore';

const ToastBox = ({
	onClick,
	onMouseEnter,
	onMouseLeave,
	borderClass,
	...props
}: PropsWithChildren<{
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	borderClass: string;
}>) => {
	const isMobile = useIsMobileScreenSize();

	return (
		<div
			className={twMerge([
				`px-4 py-3 pointer-events-auto overflow-hidden font-display rounded text-text-default relative z-10`,
				isMobile ? 'max-w-full w-full' : 'w-[343px]',
				onClick || onMouseEnter ? `hover:cursor-pointer` : ``,
				borderClass,
			])}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
		>
			{props.children}
		</div>
	);
};

const Toast = ({
	type,
	onClick,
	onMouseEnter,
	onMouseLeave,
	...props
}: PropsWithChildren<{
	type?: NotificationStore['notifications'][0]['type'];
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}>) => {
	const driftTheme = useDriftTheme();
	const isDarkTheme = driftTheme === DriftTheme.dark;

	return (
		<ToastBox
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			borderClass={twMerge(
				'bg-container-bg',
				type === 'announcement'
					? // border-none because the rainbow-gradient-border style is a "fake border", 4px rounded because the gradient border is 4px too.
					  'rainbow-gradient-border bg-container-bg border-none rounded-[4px] brand-box-glow'
					: type === 'prize'
					? // border-none because the prize-gradient-border style is a "fake border", 4px rounded because the gradient border is 4px too.
					  'prize-gradient-border bg-container-bg border-none rounded-[4px] prize-box-glow'
					: type === 'points'
					? // border-none because the drift-points-border style is a "fake border", 4px rounded because the gradient border is 4px too.
					  twMerge(
							'drift-points-border border-none rounded-[4px]',
							isDarkTheme ? 'points-toast-bg-dark' : 'points-toast-bg-light'
					  )
					: `border border-stroke-secondary`
			)}
			{...props}
		>
			<div className="z-10">{props.children}</div>

			<div
				className={twMerge(
					'absolute w-[260px] h-[260px] rounded-full -translate-x-1/2 -translate-y-1/2 top-1/2 -z-10',
					type === 'success'
						? `bg-[radial-gradient(50%_50%_at_50%_50%,rgba(49,203,158,0.20)_0%,rgba(0,209,140,0.00)_100%)]`
						: type === 'error'
						? `bg-[radial-gradient(50%_50%_at_50%_50%,rgba(229,77,72,0.20)_0%,rgba(229,77,72,0.00)_100%)]`
						: type === 'warning' || type === 'prize'
						? `bg-[radial-gradient(50%_50%_at_50%_50%,rgba(242,201,76,0.20)_0%,rgba(242,201,76,0.00)_100%)]`
						: `bg-[radial-gradient(50%_50%_at_50%_50%,rgba(153,69,255,0.16)_0%,rgba(153,69,255,0.00)_100%)]`
				)}
			>
				{/** Radial Gradient */}
			</div>
		</ToastBox>
	);
};

const IconAnimation = keyframes`
	0% {
		height: 0;
		width: 0;
	}
	100% {
		height: 100%;
		width: 100%;
	}
`;

const AnimatedIcon = styled.div`
	animation: ${IconAnimation} 250ms forwards ease-out;
`;

const InfoIcon = () => {
	return <Info size={24} className="text-text-emphasis" />;
};

const ErrorIcon = () => {
	return (
		<AnimatedIcon>
			<div className="flex items-center justify-center w-full h-full rounded-full bg-negative-red">
				<Close size={20} color="white" />
			</div>
		</AnimatedIcon>
	);
};

const SuccessIcon = () => {
	return (
		<div className="w-5 h-5 rounded-full bg-text-emphasis -p-1">
			<SuccessFilled size={24} className="relative -top-[2px] -left-[2px]" />
		</div>
	);
};

const LoadingIcon = () => {
	return <GradientLoadingSpinner size={20} style={{ padding: '5px' }} />;
};

const PointsIcon = () => {
	return (
		<PointsMono style={{ width: '100%', height: '100%' }} color={'#007bff'} />
	);
};

Toast.InfoIcon = InfoIcon;
Toast.LoadingIcon = LoadingIcon;
Toast.SuccessIcon = SuccessIcon;
Toast.ErrorIcon = ErrorIcon;
Toast.PointsIcon = PointsIcon;

export default Toast;
