'use client';

import posthog, { Properties } from 'posthog-js';
import { useCallback, useEffect, useRef } from 'react';
import Env from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useAccountExists from '../useAccountExists';
import useCurrentWalletAdapter from '../useCurrentWalletAdapter';
import useWalletIsConnected from '../useWalletIsConnected';
import { singletonHook } from 'react-singleton-hook';
import { POSTHOG_UTILS } from '../../providers/posthog/postHogProvider';
import { dlog } from '../../dev';
import { PosthogEvent } from 'src/providers/posthog/posthogConfig';
import { POSTHOG_CONFIG } from '../../providers/posthog/posthogConfig';
import { DriftWindow } from 'src/window/driftWindow';

const DEV_LOG_POST_HOG_EVENTS = false;

/**
 * Wrapped posthog event capture function, to keep common properties like utm params, authority, wallet name consistent across all event captures
 */
const usePostHogCapture = () => {
	const connected = useWalletIsConnected();
	const authority = useDriftStore((s) => s.wallet?.currentPublicKeyString);
	const isMagicAuth = useDriftStore((s) => s.wallet?.isMagicAuth);
	const walletAdapter = useCurrentWalletAdapter();
	const accountExists = useAccountExists();
	const walletName = isMagicAuth
		? 'Magic Auth'
		: walletAdapter?.name?.toString();

	const isE2eWallet = walletAdapter.name === Env.E2E_WALLET.name;
	const enabledBySamplingAndFlag = useRef(POSTHOG_CONFIG.enabled);

	/**
	 * @param event  The name of the event. This can be anything the user does - 'Button Click', 'Sign Up', 'Item Purchased', etc.
	 * @param properties  A set of properties to include with the event you're sending. These describe the user who did the event or details about the event itself.
	 */
	const captureEvent = useCallback(
		(event: PosthogEvent, properties?: Properties) => {
			if (isE2eWallet) {
				// Skip capturing events for the e2e tests
				return;
			}

			if (DEV_LOG_POST_HOG_EVENTS) {
				dlog(`posthog`, `dev_capture_event: ${event}::`, {
					env: Env.sdkEnv,
					connected,
					account_exists: accountExists === undefined ? null : accountExists,
					authority: connected ? authority : null,
					wallet_name: connected ? walletName : null,
					...(properties || {}),
				});
			}

			const enabledByGlobalOverride = POSTHOG_CONFIG.overrideEnableAllEvents;
			const enabledBySamplingIndependentHardcodedConfig =
				POSTHOG_CONFIG.samplingIndependentEnabledEvents.has(event);
			const enabledBySampling = enabledBySamplingAndFlag.current;
			const enabledByWhitelistedWalletAndEvents =
				POSTHOG_CONFIG.wallet.walletAdapterNames.has(walletAdapter.name) &&
				POSTHOG_CONFIG.wallet.eventsToTrack.has(event);
			const enabledByWhitelistedPageViews =
				event === '$pageview' &&
				POSTHOG_CONFIG.pageViewWhitelist.includes(window.location.pathname);

			const isEventEnabled =
				enabledByGlobalOverride ||
				enabledBySamplingIndependentHardcodedConfig ||
				enabledBySampling ||
				enabledByWhitelistedWalletAndEvents ||
				enabledByWhitelistedPageViews;

			if (!isEventEnabled) {
				dlog(`posthog`, `capture_event_disabled: ${event}`);
				return;
			}

			const isLocalHost = window.location.href.includes('localhost');

			dlog(`posthog`, `capture_event: ${event}`);

			posthog.capture(event, {
				local_env: isLocalHost ? 'localhost' : Env.localEnv,
				env: Env.sdkEnv,
				connected,
				account_exists: accountExists === undefined ? null : accountExists,
				authority: connected ? authority : null,
				wallet_name: connected ? walletName : null,
				user_agent: navigator.userAgent,
				is_mobile_app: POSTHOG_UTILS.isMobileWalletApp(),
				...(properties || {}),
			});
		},
		[connected, authority, walletName, isE2eWallet]
	);

	// Listen for changes to event capture setting
	useEffect(() => {
		DriftWindow.devEventEmitter.on('set_event_capture', (enabled: boolean) => {
			dlog(`posthog`, `set_event_capture: ${enabled}`);
			enabledBySamplingAndFlag.current = enabled;
		});
	}, []);

	return { captureEvent };
};

export type PostHogCapture = ReturnType<
	typeof usePostHogCapture
>['captureEvent'];

export default singletonHook({ captureEvent: () => {} }, usePostHogCapture);
