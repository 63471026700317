//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// TokenMessenger EVM Contract
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xd0c3da58f55358142b8d3e06c1c30c5c6114efe8)
 */
export const tokenMessengerABI = [
	{
		stateMutability: 'nonpayable',
		type: 'constructor',
		inputs: [
			{ name: '_messageTransmitter', internalType: 'address', type: 'address' },
			{ name: '_messageBodyVersion', internalType: 'uint32', type: 'uint32' },
		],
	},
	{
		type: 'event',
		anonymous: false,
		inputs: [
			{ name: 'nonce', internalType: 'uint64', type: 'uint64', indexed: true },
			{
				name: 'burnToken',
				internalType: 'address',
				type: 'address',
				indexed: true,
			},
			{
				name: 'amount',
				internalType: 'uint256',
				type: 'uint256',
				indexed: false,
			},
			{
				name: 'depositor',
				internalType: 'address',
				type: 'address',
				indexed: true,
			},
			{
				name: 'mintRecipient',
				internalType: 'bytes32',
				type: 'bytes32',
				indexed: false,
			},
			{
				name: 'destinationDomain',
				internalType: 'uint32',
				type: 'uint32',
				indexed: false,
			},
			{
				name: 'destinationTokenMessenger',
				internalType: 'bytes32',
				type: 'bytes32',
				indexed: false,
			},
			{
				name: 'destinationCaller',
				internalType: 'bytes32',
				type: 'bytes32',
				indexed: false,
			},
		],
		name: 'DepositForBurn',
	},
	{
		type: 'event',
		anonymous: false,
		inputs: [
			{
				name: 'localMinter',
				internalType: 'address',
				type: 'address',
				indexed: false,
			},
		],
		name: 'LocalMinterAdded',
	},
	{
		type: 'event',
		anonymous: false,
		inputs: [
			{
				name: 'localMinter',
				internalType: 'address',
				type: 'address',
				indexed: false,
			},
		],
		name: 'LocalMinterRemoved',
	},
	{
		type: 'event',
		anonymous: false,
		inputs: [
			{
				name: 'mintRecipient',
				internalType: 'address',
				type: 'address',
				indexed: true,
			},
			{
				name: 'amount',
				internalType: 'uint256',
				type: 'uint256',
				indexed: false,
			},
			{
				name: 'mintToken',
				internalType: 'address',
				type: 'address',
				indexed: true,
			},
		],
		name: 'MintAndWithdraw',
	},
	{
		type: 'event',
		anonymous: false,
		inputs: [
			{
				name: 'previousOwner',
				internalType: 'address',
				type: 'address',
				indexed: true,
			},
			{
				name: 'newOwner',
				internalType: 'address',
				type: 'address',
				indexed: true,
			},
		],
		name: 'OwnershipTransferStarted',
	},
	{
		type: 'event',
		anonymous: false,
		inputs: [
			{
				name: 'previousOwner',
				internalType: 'address',
				type: 'address',
				indexed: true,
			},
			{
				name: 'newOwner',
				internalType: 'address',
				type: 'address',
				indexed: true,
			},
		],
		name: 'OwnershipTransferred',
	},
	{
		type: 'event',
		anonymous: false,
		inputs: [
			{
				name: 'domain',
				internalType: 'uint32',
				type: 'uint32',
				indexed: false,
			},
			{
				name: 'tokenMessenger',
				internalType: 'bytes32',
				type: 'bytes32',
				indexed: false,
			},
		],
		name: 'RemoteTokenMessengerAdded',
	},
	{
		type: 'event',
		anonymous: false,
		inputs: [
			{
				name: 'domain',
				internalType: 'uint32',
				type: 'uint32',
				indexed: false,
			},
			{
				name: 'tokenMessenger',
				internalType: 'bytes32',
				type: 'bytes32',
				indexed: false,
			},
		],
		name: 'RemoteTokenMessengerRemoved',
	},
	{
		type: 'event',
		anonymous: false,
		inputs: [
			{
				name: 'newRescuer',
				internalType: 'address',
				type: 'address',
				indexed: true,
			},
		],
		name: 'RescuerChanged',
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [],
		name: 'acceptOwnership',
		outputs: [],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [
			{ name: 'newLocalMinter', internalType: 'address', type: 'address' },
		],
		name: 'addLocalMinter',
		outputs: [],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [
			{ name: 'domain', internalType: 'uint32', type: 'uint32' },
			{ name: 'tokenMessenger', internalType: 'bytes32', type: 'bytes32' },
		],
		name: 'addRemoteTokenMessenger',
		outputs: [],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [
			{ name: 'amount', internalType: 'uint256', type: 'uint256' },
			{ name: 'destinationDomain', internalType: 'uint32', type: 'uint32' },
			{ name: 'mintRecipient', internalType: 'bytes32', type: 'bytes32' },
			{ name: 'burnToken', internalType: 'address', type: 'address' },
		],
		name: 'depositForBurn',
		outputs: [{ name: '_nonce', internalType: 'uint64', type: 'uint64' }],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [
			{ name: 'amount', internalType: 'uint256', type: 'uint256' },
			{ name: 'destinationDomain', internalType: 'uint32', type: 'uint32' },
			{ name: 'mintRecipient', internalType: 'bytes32', type: 'bytes32' },
			{ name: 'burnToken', internalType: 'address', type: 'address' },
			{ name: 'destinationCaller', internalType: 'bytes32', type: 'bytes32' },
		],
		name: 'depositForBurnWithCaller',
		outputs: [{ name: 'nonce', internalType: 'uint64', type: 'uint64' }],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [
			{ name: 'remoteDomain', internalType: 'uint32', type: 'uint32' },
			{ name: 'sender', internalType: 'bytes32', type: 'bytes32' },
			{ name: 'messageBody', internalType: 'bytes', type: 'bytes' },
		],
		name: 'handleReceiveMessage',
		outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
	},
	{
		stateMutability: 'view',
		type: 'function',
		inputs: [],
		name: 'localMessageTransmitter',
		outputs: [
			{
				name: '',
				internalType: 'contract IMessageTransmitter',
				type: 'address',
			},
		],
	},
	{
		stateMutability: 'view',
		type: 'function',
		inputs: [],
		name: 'localMinter',
		outputs: [
			{ name: '', internalType: 'contract ITokenMinter', type: 'address' },
		],
	},
	{
		stateMutability: 'view',
		type: 'function',
		inputs: [],
		name: 'messageBodyVersion',
		outputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
	},
	{
		stateMutability: 'view',
		type: 'function',
		inputs: [],
		name: 'owner',
		outputs: [{ name: '', internalType: 'address', type: 'address' }],
	},
	{
		stateMutability: 'view',
		type: 'function',
		inputs: [],
		name: 'pendingOwner',
		outputs: [{ name: '', internalType: 'address', type: 'address' }],
	},
	{
		stateMutability: 'view',
		type: 'function',
		inputs: [{ name: '', internalType: 'uint32', type: 'uint32' }],
		name: 'remoteTokenMessengers',
		outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [],
		name: 'removeLocalMinter',
		outputs: [],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [{ name: 'domain', internalType: 'uint32', type: 'uint32' }],
		name: 'removeRemoteTokenMessenger',
		outputs: [],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [
			{ name: 'originalMessage', internalType: 'bytes', type: 'bytes' },
			{ name: 'originalAttestation', internalType: 'bytes', type: 'bytes' },
			{
				name: 'newDestinationCaller',
				internalType: 'bytes32',
				type: 'bytes32',
			},
			{ name: 'newMintRecipient', internalType: 'bytes32', type: 'bytes32' },
		],
		name: 'replaceDepositForBurn',
		outputs: [],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [
			{
				name: 'tokenContract',
				internalType: 'contract IERC20',
				type: 'address',
			},
			{ name: 'to', internalType: 'address', type: 'address' },
			{ name: 'amount', internalType: 'uint256', type: 'uint256' },
		],
		name: 'rescueERC20',
		outputs: [],
	},
	{
		stateMutability: 'view',
		type: 'function',
		inputs: [],
		name: 'rescuer',
		outputs: [{ name: '', internalType: 'address', type: 'address' }],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
		name: 'transferOwnership',
		outputs: [],
	},
	{
		stateMutability: 'nonpayable',
		type: 'function',
		inputs: [{ name: 'newRescuer', internalType: 'address', type: 'address' }],
		name: 'updateRescuer',
		outputs: [],
	},
] as const;

/**
 * [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xd0c3da58f55358142b8d3e06c1c30c5c6114efe8)
 */
export const tokenMessengerAddress = {
	5: '0xD0C3da58f55358142b8d3e06C1C30c5C6114EFE8',
} as const;

/**
 * [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xd0c3da58f55358142b8d3e06c1c30c5c6114efe8)
 */
export const tokenMessengerConfig = {
	address: tokenMessengerAddress,
	abi: tokenMessengerABI,
} as const;
