import { memo, ReactNode } from 'react';
import { ChevronRight } from '@drift-labs/icons';
import Text from '../Text/Text';

const ResourceButton = ({
	children,
	href,
}: {
	children: ReactNode;
	href: string;
}) => (
	<a href={href} target="_blank" rel="noreferrer">
		<button className="hover:cursor-pointer p-3 pr-5 rounded border border-container-border w-full bg-container-bg flex flex-row justify-between items-center min-w-[433px] my-4 text-text-emphasis tracking-normal">
			{children}
		</button>
	</a>
);

function HelpfulResources() {
	return (
		<div className="relative flex flex-col items-center justify-center w-full h-full max-w-900px">
			<div className="max-w-[433px]">
				<Text.H2 className="w-full text-left text-text-emphasis">
					Helpful Resources
				</Text.H2>
				<ResourceButton href="https://www.drift.trade/updates/drift-v1-to-v2">
					<img
						src="/assets/images/thumbnails/drift-v2-vs-v1.webp"
						alt="Drift v1 to v2"
						className="w-[100px]"
					/>
					<div className="w-full px-4 text-left">
						<div className="text-text-secondary">
							<Text.BODY3>Learn more about secure trading on Drift</Text.BODY3>
						</div>
						<Text.H4 className="leading-3">
							Drift v1 to v2: What&apos;s Changed?
						</Text.H4>
					</div>
					<ChevronRight size={24} />
				</ResourceButton>
				<ResourceButton href="https://www.drift.trade/blog/centralised-exchange-vs-decentralised-exchange">
					<img
						src="/assets/images/thumbnails/what-cex-traders-can-expect.webp"
						alt="What CEX Traders Can Expect"
						className="w-[100px]"
					/>
					<div className="w-full px-4 text-left">
						<div className="text-text-secondary">
							<Text.BODY3>New to Decentralized Exchanges?</Text.BODY3>
						</div>
						<Text.H4 className="leading-3">
							What CEX traders can expect when trading on a DEX
						</Text.H4>
					</div>
					<ChevronRight size={24} />
				</ResourceButton>
				<ResourceButton href="https://www.drift.trade/learn/order-types">
					<img
						src="/assets/images/thumbnails/order-types.webp"
						alt="Order Type Guide"
						className="w-[100px]"
					/>
					<div className="w-full px-4 text-left">
						<div className="text-text-secondary">
							<Text.BODY3>Guide</Text.BODY3>
						</div>
						<Text.H4 className="leading-3">Understanding Order Types</Text.H4>
					</div>
					<ChevronRight size={24} />
				</ResourceButton>
				<ResourceButton href="https://www.drift.trade/learn/dex-account-overview">
					<img
						src="/assets/images/thumbnails/account-overview-page.webp"
						alt="Account Overview Guide"
						className="w-[100px]"
					/>
					<div className="w-full px-4 text-left">
						<div className="text-text-secondary">
							<Text.BODY3>Beginners Guide</Text.BODY3>
						</div>
						<Text.H4 className="leading-3">Account Overview Page</Text.H4>
					</div>
					<ChevronRight size={24} />
				</ResourceButton>
			</div>
		</div>
	);
}

export default memo(HelpfulResources);
