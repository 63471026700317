import { ENUM_UTILS, Opaque } from '@drift/common';
import Env from 'src/environmentVariables/EnvironmentVariables';
import {
	MarketStatus,
	MAX_PREDICTION_PRICE,
	PerpMarketAccount,
	ZERO,
} from '@drift-labs/sdk';
import dayjs from 'dayjs';

const DEFAULT_ENDING_SOON_THRESHOLD_DAYS = 3;

export type PredictionMarketId = Opaque<'PredictionMarketId', string>;

export enum PREDICTION_MARKET_CATEGORY {
	ALL = 'All',
	US_ELECTIONS = 'US Elections',
	FORMULA_1 = 'F1',
	SPORTS = 'Sports',
}

export type PredictionMarketCategoryConfig = {
	label: string;
	imgSrc: string;
	comingSoon?: boolean;
	backgroundSrc?: string;
	summary?: React.ReactNode;
};

export interface IPredictionMarketConfig {
	title: string;
	shortTitle: string;
	resolutionDateUnix: number;
	category?: PREDICTION_MARKET_CATEGORY;
	symbol: string;
	imgSrc: string;
	previewImgSrc?: string;
	devnetMarketIndex?: number;
	mainnetMarketIndex?: number;
	endingSoonThresholdDays?: number;
	resolutionDateUnknown?: boolean;
}

export enum ResolutionOutcome {
	Unresolved,
	Yes,
	No,
}

export class PredictionMarketConfig
	implements Omit<IPredictionMarketConfig, 'resolutionDateUnix'>
{
	title: string;
	shortTitle: string;
	resolutionDate: Date;
	category?: PREDICTION_MARKET_CATEGORY;
	symbol: string;
	imgSrc: string;
	previewImgSrc?: string;
	devnetMarketIndex?: number;
	mainnetMarketIndex?: number;
	resolutionDateUnknown?: boolean;

	/**
	 * Days before resolution date to show ending soon label
	 */
	endingSoonThresholdDays?: number;

	constructor(config: IPredictionMarketConfig) {
		this.title = config.title;
		this.shortTitle = config.shortTitle;
		this.resolutionDate = new Date(config.resolutionDateUnix);
		this.category = config.category;
		this.symbol = config.symbol;
		this.imgSrc = config.imgSrc;
		this.previewImgSrc = config.previewImgSrc;
		this.devnetMarketIndex = config.devnetMarketIndex;
		this.mainnetMarketIndex = config.mainnetMarketIndex;
		this.endingSoonThresholdDays = config.endingSoonThresholdDays;
		this.resolutionDateUnknown = config.resolutionDateUnknown;
	}

	get isLive() {
		if (Env.sdkEnv === 'devnet') {
			return this.devnetMarketIndex !== undefined;
		} else {
			return this.mainnetMarketIndex !== undefined;
		}
	}

	get marketIndex() {
		return Env.sdkEnv === 'mainnet-beta'
			? this.mainnetMarketIndex
			: this.devnetMarketIndex;
	}

	get isEndingSoon() {
		return dayjs(this.resolutionDate).isBefore(
			dayjs().add(
				this.endingSoonThresholdDays ?? DEFAULT_ENDING_SOON_THRESHOLD_DAYS,
				'day'
			)
		);
	}

	static isResolving(perpMarketAccount?: PerpMarketAccount) {
		return ENUM_UTILS.match(
			perpMarketAccount?.status,
			MarketStatus.REDUCE_ONLY
		);
	}

	static isResolved(perpMarketAccount?: PerpMarketAccount) {
		return (
			ENUM_UTILS.match(perpMarketAccount?.status, MarketStatus.SETTLEMENT) ||
			ENUM_UTILS.match(perpMarketAccount?.status, MarketStatus.DELISTED)
		);
	}

	static isActive(perpMarketAccount?: PerpMarketAccount) {
		return ENUM_UTILS.match(perpMarketAccount?.status, MarketStatus.ACTIVE);
	}

	static getResolvedOutcome(perpMarketAccount?: PerpMarketAccount) {
		if (
			!perpMarketAccount ||
			!PredictionMarketConfig.isResolved(perpMarketAccount)
		)
			return ResolutionOutcome.Unresolved;

		const tickSize = perpMarketAccount.amm.orderTickSize;

		// markets do not always resolved to exactly $0 or $1, there is usually a buffer of up to a tick size
		if (perpMarketAccount.expiryPrice.gte(MAX_PREDICTION_PRICE.sub(tickSize))) {
			return ResolutionOutcome.Yes;
		}

		if (perpMarketAccount.expiryPrice.lte(ZERO.add(tickSize))) {
			return ResolutionOutcome.No;
		}

		return ResolutionOutcome.Unresolved;
	}
}
