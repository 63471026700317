import Env, { isDev } from '../environmentVariables/EnvironmentVariables';
import captureException from './captureException';
import { getTimeoutNotificationData, notify } from './notifications';
import { DriftErrors } from '@drift/common';
import { Ref } from '@drift/common';
import phantomErrors from '../constants/phantomErrors';
import { JupV4Errors, JupV6Errors } from '@drift/common';
import UI_UTILS from './uiUtils';
import { TransactionErrorMetric } from '../providers/metrics/metricsTypes';
import { LangErrorMessage } from '@coral-xyz/anchor';
import Text from '../components/Text/Text';
import Button from 'src/components/Button';
import { DriftWindow } from '../window/driftWindow';
import { Adapter } from '@solana/wallet-adapter-base';
import { DriftAppEventEmitter } from 'src/stores/DriftStore/useDriftStore';
import { PosthogEvent } from 'src/providers/posthog/posthogConfig';
import { Properties } from 'posthog-js';
import { PostHogCapture } from 'src/hooks/posthog/usePostHogCapture';

export type AnchorError = { code?: number; logs?: string[] } & Error & {
		error: { code: number; message: string };
	};

type LocalErroMetricValue = { class: 'non_issue_error' };
type ErrorMetricValue = TransactionErrorMetric['value'] | LocalErroMetricValue;

type NonDriftProgramErrorHandlerConfig = {
	errorsList: {
		[name: string]: {
			code: number;
			name: string;
			msg: string;
		};
	};
	errorToastTitle: string;
};

const NON_DRIFT_PROGRAMS_ERROR_HANDLER_CONFIGS: NonDriftProgramErrorHandlerConfig[] =
	[
		{
			errorsList: JupV4Errors.errorsList,
			errorToastTitle: 'Jupiter Swap Error',
		},
		{
			errorsList: JupV6Errors.errorsList,
			errorToastTitle: 'Jupiter Swap Error',
		},
	];

const tryGetErrorCodeFromMessage = (message: string) => {
	try {
		const errorCodeString = message.match(
			/custom program error: (0x[0-9a-f]+)/
		)[1];
		if (errorCodeString) {
			const errorCode = parseInt(errorCodeString, 16);
			if (isNaN(errorCode)) return null;
			return errorCode;
		}
	} catch (e) {
		return null;
	}
};

export const tryGetDriftErrorCode = (error: AnchorError) => {
	const errorCode = error.code ?? tryGetErrorCodeFromMessage(error.message);

	if (!errorCode) return null;

	const errorName = DriftErrors.errorCodesMap[`${errorCode}`];
	const mappedError: PrettyError = DriftErrors.errorsList[errorName];
	return mappedError;
};

export const tryGetAnchorError = (error: AnchorError) => {
	const errorCode = error.code ?? tryGetErrorCodeFromMessage(error.message);

	if (!errorCode) return null;

	const langErrorMessage = LangErrorMessage.get(errorCode);

	if (!langErrorMessage) return null;

	return {
		code: errorCode,
		message: langErrorMessage,
	};
};

type NotificationProps = Parameters<typeof notify>[0];
const handleNotify = (
	notificationProps: NotificationProps,
	error: AnchorError
) => {
	const isDevMode = window?.localStorage?.getItem('devswitch') === 'true';

	if (!isDevMode || !error?.logs?.length) {
		notify(notificationProps);
		return;
	}

	const notificationPropsToUse: NotificationProps = {
		...notificationProps,
	};

	console.debug('dev_notification :: Error', error);

	notify(notificationPropsToUse);
	notify({
		type: 'info',
		showUntilCancelled: true,
		bottomContent: (
			<>
				<Text.BODY3 className="w-full">LOGS FOR PREVIOUS TX ERROR</Text.BODY3>
				<Text.BODY3 className="flex flex-col w-full">
					{error?.logs?.map((logLine, index) => (
						<span className="w-full break-all" key={index}>
							{logLine}
						</span>
					))}
				</Text.BODY3>
				<Text.BODY3 className="w-full text-yellow-50">{`see 'dev_notification' debug logs in console for more`}</Text.BODY3>
			</>
		),
	});
};

type ErrorHandlerProps = {
	error: AnchorError;
	fallbackDescription?: string;
	toastId?: string;
	customErrorHandler?: (error: AnchorError) => boolean;
	isHandled: () => boolean;
	setHandled: (errorMetric?: ErrorMetricValue) => void;
	wallet: Adapter;
	appEventEmitter: DriftAppEventEmitter;
	captureEvent: (event: PosthogEvent, properties?: Properties) => void;
};

const checkAnchorProgramErrors = ({
	error,
	toastId,
	setHandled,
}: ErrorHandlerProps) => {
	const anchorError = tryGetAnchorError(error);

	if (anchorError) {
		setHandled({
			class: 'transaction_other_error',
			id: `AnchorProgramError::${anchorError.code}`,
		});

		handleNotify(
			{
				type: 'error',
				id: toastId,
				updatePrevious: true,
				message: `Program Error: ${anchorError.code}`,
				description: anchorError.message,
			},
			error
		);
	}

	return;
};

const checkNonDriftProgramsErrors = ({
	error,
	toastId,
	setHandled,
}: ErrorHandlerProps) => {
	if (!error.logs) return;

	const errorLog = error.logs.find(
		(log) => log.includes('AnchorError') && log.includes('Error Code')
	);

	if (!errorLog) return;

	// example log that we can expect -> Program log: AnchorError occurred. Error Code: SlippageToleranceExceeded. Error Number: 6001. Error Message: Slippage tolerance exceeded.
	const errorCodeMatch = errorLog.match(/Error Code: (\w+)/);
	const errorCode = errorCodeMatch ? errorCodeMatch[1] : undefined;

	if (!errorCode) return;

	const programErrorConfig = NON_DRIFT_PROGRAMS_ERROR_HANDLER_CONFIGS.find(
		(config) => config.errorsList[errorCode]
	);

	if (!programErrorConfig) return;

	const description = UI_UTILS.lowerCaseNonFirstWords(
		UI_UTILS.splitByCapitalLetters(programErrorConfig.errorsList[errorCode].msg)
	);

	handleNotify(
		{
			type: 'error',
			id: toastId,
			updatePrevious: true,
			message: programErrorConfig.errorToastTitle,
			description,
		},
		error
	);

	setHandled({
		class: 'transaction_other_error',
		id: `NonDriftProgramError::${errorCode}`,
	});
};

const checkCustomHandlerErrors = (props: ErrorHandlerProps) => {
	const isErrorHandled = props?.customErrorHandler?.(props?.error) ?? false;

	if (isErrorHandled) {
		props.setHandled();

		return;
	}
};

const checkGenericErrors = ({
	error,
	toastId,
	setHandled,
	appEventEmitter,
}: ErrorHandlerProps) => {
	if (error?.message?.match(/already been processed/)) {
		// Actually not sure we can "improve" on explaining these messages, but at least marking as "handled" for the sake of error metrics

		handleNotify(
			{
				type: 'error',
				description:
					'Transaction simulation failed: This transaction has already been processed',
				id: toastId,
				updatePrevious: true,
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'AlreadyProcessed',
		});

		return;
	}

	// # Compute Budget Exceeded case
	if (
		error?.logs?.find((log) =>
			log.includes('exceeded CUs meter at BPF instruction')
		)
	) {
		handleNotify(
			{
				type: 'error',
				message: 'Transaction Failed (Compute Budget Exceeded)',
				description: `This is a known error that may unpredictably occur, particularly during periods of high activity. Please try again.`,
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: true,
			},
			error
		);

		Env.computeUnitBufferMultiplier += 0.1; // Bump the buffer multiplier by 10% of the simulated value.

		setHandled({
			class: 'transaction_cu_budget_exceeded',
			id: 'CuBudgetExceeded',
		});

		// # Disable our simulated compute budget optimisation once the user has seen one of these errors
		Env.useSimulatedComputeUnitBudget = false;
		Env.useSimulatedComputeUnitBudgetForFees = false;

		try {
			const programInstruction = error?.logs
				?.filter?.((log) => log.match(/Instruction:/))?.[0]
				?.match(/(Instruction:) (.+)/)?.[2];
			const clientMethod = error?.stack?.match?.(/(DriftClient\.)(\S+)/)?.[0];

			DriftWindow.metricsBus?.next({
				type: 'transaction_cu_budget_exceeded_metadata',
				value: {
					clientMethod,
					programInstruction,
				},
			});
		} catch {
			// Do nothing
		}

		return;
	}

	/*
		 # RPC 429 / exceeded limit case
		 
		 Example message we're trying to match : 
		 `429 : {"jsonrpc":"2.0","error":{"code":-32429,"message":"exceeded limit for sendTransaction"}}`
		*/
	if (
		error.message?.match(/exceeded limit for sendTransaction/) || // Text description of the error
		error.message?.match(/^429 /) // Matches 429 code at the front of the error
	) {
		handleNotify(
			{
				type: 'error',
				description:
					'There was an error sending the transaction to the RPC Provider. Try using another RPC provider available from the settings menu.',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: true,
				action: {
					label: 'Change RPC Provider',
					callback: () => appEventEmitter.emit('showModal', 'showNetworkModal'),
				},
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'Rpc429',
		});

		return;
	}

	// # Blockhash not found case
	if (
		error?.message?.match(/Blockhash not found/) ||
		(error?.message?.match(/BlockhashNotFound/) && true)
	) {
		handleNotify(
			{
				type: 'error',
				description:
					'There was an error sending the transaction. You likely need to refresh or try using another RPC provider available from the settings menu.',
				id: toastId,
				updatePrevious: true,
				subDescription: undefined,
				action: {
					label: 'Change RPC Provider',
					callback: () => appEventEmitter.emit('showModal', 'showNetworkModal'),
				},
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'BlockhashNotFound',
		});

		return;
	}

	// # No SOL found in account
	if (
		error?.message?.match(
			/Attempt to debit an account but found no record of a prior credit/
		) ||
		error?.message?.match(/insufficient funds for rent/)
	) {
		handleNotify(
			{
				type: 'error',
				description:
					'You need to have SOL in your wallet to pay for the transaction/account fees.',
				id: toastId,
				updatePrevious: true,
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'NoSolInAccount',
		});

		return;
	}

	// # not subscribed case
	if (error.name === 'NotSubscribedError') {
		// check if just on local or not
		if (isDev()) {
			// this error is known to happen on localhost during hot refreshes etc. .. but shouldn't happen on any other environments
			captureException(error);
		}

		handleNotify(
			{
				type: 'error',
				description:
					'Sorry, there was an error performing that transaction, please try again. You may need to try refreshing the page.',
				id: toastId,
				updatePrevious: true,
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'NotSubscribed',
		});

		return;
	}

	// # Insufficient funds case
	if (
		error?.message?.match(/custom program error: 0x1\./) ||
		(error?.message?.match(/custom program error: 0x1$/) && true)
	) {
		handleNotify(
			{
				type: 'error',
				description:
					"The wallet didn't have enough balance to complete the transaction",
				id: toastId,
				updatePrevious: true,
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'InsufficientFunds',
		});

		return;
	}

	// # User Rejected
	const userRejectedRegexes = [
		/User rejected the request/,
		'Transaction rejected',
		'Transaction canceled',
		'Approval Denied',
		'User rejected.',
	];

	for (const regex of userRejectedRegexes) {
		if (error?.message?.match(regex)) {
			handleNotify(
				{
					type: 'error',
					message: 'Transaction signature request rejected',
					id: toastId,
					updatePrevious: true,
					subDescription: undefined,
				},
				error
			);

			setHandled(undefined); // Undefined because we don't want to log this as a failed transaction in the metrics

			return;
		}
	}

	// # Transaction size too large
	if (error?.message?.match(/\(max: encoded\/raw \d+\/\d+\)/) && true) {
		handleNotify(
			{
				type: 'error',
				message: 'Transaction size too large.',
				id: toastId,
				updatePrevious: true,
				subDescription: undefined,
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'TransactionSizeTooLarge',
		});

		return;
	}

	if (error?.message?.toLowerCase()?.match(/block height exceeded/)) {
		const txSignature = error?.message?.match(
			/Signature (.+) has expired/
		)?.[1];

		handleNotify(
			{
				type: 'warning',
				message: 'Transaction Confirmation Timeout',
				description: `Your transaction was sent, but didn't get confirmed in time. You can use the Solana explorer to check its status. If you experience this frequently, you should try changing your Priority Fee Settings.`,
				action: {
					type: 'custom',
					content: (
						<div className="flex items-center justify-between">
							<Button.Secondary
								size="SMALL"
								onClick={() => {
									appEventEmitter.emit('showModal', 'showNetworkModal');
								}}
							>
								Priority Fee Settings
							</Button.Secondary>

							<a
								href={UI_UTILS.getUrlForTx(txSignature)}
								target="_blank"
								rel="noreferrer noopener"
								className="flex items-center gap-1"
							>
								<Text.BODY3>View Transaction</Text.BODY3>
							</a>
						</div>
					),
				},
				updatePrevious: true,
				showUntilCancelled: true,
				subDescription: undefined,
				id: toastId,
				overrideBlockSubsequentToasts: false,
			},
			error
		);

		setHandled({
			class: 'transaction_timeout',
			id: 'TransactionTimeout',
		});

		return;
	}

	// # slow tx case
	if (
		error?.message
			?.toLowerCase()
			?.match(/transaction was not confirmed in .+ seconds/) &&
		true
	) {
		const txSignature = error?.message?.match(
			/Check signature (.+) using/
		)?.[1];

		handleNotify(
			{
				...getTimeoutNotificationData(appEventEmitter),
				action: {
					type: 'txnLink',
					txnSig: txSignature,
				},
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: true,
				subDescription: undefined,
				overrideBlockSubsequentToasts: false,
			},
			error
		);

		setHandled({
			class: 'transaction_timeout',
			id: 'TransactionTimeout',
		});

		return;
	}

	if (error?.message?.match(/0x1850/) && true) {
		handleNotify(
			{
				type: 'error',
				message: 'Max Global User Accounts Reached',
				description: `Due to a huge influx of new user account creations, we've hit maximum capacity of users on the Drift smart contract.`,
				subDescription:
					'The capacity will be upgraded soon. Please try again later.',
				id: toastId,
				updatePrevious: true,
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'MaxUserCapacityReached',
		});

		return;
	}
};

const checkDriftErrors = ({
	error,
	toastId,
	setHandled,
}: ErrorHandlerProps) => {
	const mappedError = tryGetDriftErrorCode(error);

	if (mappedError) {
		if (mappedError.toast) {
			handleNotify(
				{
					type: 'error',
					id: toastId,
					updatePrevious: true,
					...mappedError.toast,
				},
				error
			);
		} else {
			handleNotify(
				{
					type: 'error',
					id: toastId,
					updatePrevious: true,
					description: `Error Code ${mappedError.code}: ${mappedError.msg}`,
					subDescription: undefined,
				},
				error
			);
		}

		setHandled({
			class: 'transaction_other_error',
			id: `DriftError::${mappedError?.code}_${mappedError?.name}`,
		});

		return;
	}
};

const checkPhantomErrors = ({
	error,
	toastId,
	setHandled,
}: ErrorHandlerProps) => {
	const errorCode = error?.error?.code;

	if (errorCode === undefined) return;

	const phantomError = phantomErrors[errorCode];

	if (!phantomError) return;

	handleNotify(
		{
			type: 'error',
			id: toastId,
			updatePrevious: true,
			message: `Phantom Wallet Error ${errorCode}`,
			description: `${phantomError.name} : ${phantomError.msg}`,
			subDescription: ``,
			action: {
				href: `https://docs.phantom.app/solana/integrating-phantom/errors`,
				type: 'link',
			},
			lengthMs: 10 * 1000,
		},
		error
	);

	setHandled({
		class: 'transaction_other_error',
		id: `PhantomError::${errorCode}`,
	});
};

const checkJupiterErrors = ({
	error,
	toastId,
	setHandled,
}: ErrorHandlerProps) => {
	if (
		!!error?.message?.match(/error from Jupiter/) ||
		!!error?.message?.match(/AMM was not found/)
	) {
		handleNotify(
			{
				type: 'error',
				message: 'An error with Jupiter occurred',
				description: 'Please try again.',
				id: toastId,
				updatePrevious: true,
				subDescription: undefined,
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'JupiterError',
		});

		return;
	}
};

const checkMiscErrors = ({ error, toastId, setHandled }: ErrorHandlerProps) => {
	if (error?.message?.match(/Plugin Closed/)) {
		handleNotify(
			{
				type: 'error',
				message: 'Wallet Plugin Closed',
				id: toastId,
				updatePrevious: true,
				subDescription: undefined,
			},
			error
		);

		setHandled({
			class: 'non_issue_error',
		});

		return;
	}

	if (
		error?.message?.match(/sign request declined/) ||
		error?.message?.match(/User canceled request/) ||
		error?.message?.match(/Cancelled by User/)
	) {
		handleNotify(
			{
				type: 'error',
				message: 'Transaction signature request declined',
				id: toastId,
				updatePrevious: true,
				subDescription: undefined,
			},
			error
		);

		setHandled({
			class: 'non_issue_error',
		});

		return;
	}

	if (
		error?.message?.match(/Failed to fetch blockhash after maximum retries/) ||
		error?.message?.match(/Load failed/)
	) {
		handleNotify(
			{
				type: 'error',
				message: 'There was a network issue when creating the transaction.',
				description:
					'Please try again. You may need to refresh the page or try changing the RPC provider setting.',
				id: toastId,
				updatePrevious: true,
				subDescription: undefined,
			},
			error
		);

		setHandled({
			class: 'transaction_other_error',
			id: 'network_error',
		});

		return;
	}

	if (error?.message?.match(/交易已取消/)) {
		handleNotify(
			{
				type: 'error',
				message: error.message,
				id: toastId,
				updatePrevious: true,
				subDescription: undefined,
			},
			error
		);

		setHandled({
			class: 'non_issue_error',
		});

		return;
	}
};

const errorMatchingChain = (
	props: ErrorHandlerProps,
	handlers: ((props: ErrorHandlerProps) => void)[]
) => {
	for (const handler of handlers) {
		handler(props);
		if (props.isHandled()) {
			break;
		}
	}
};

const tryMatchError = (props: ErrorHandlerProps) => {
	if (!props.error) return;

	// # Run through the error matching chain
	errorMatchingChain(props, [
		checkCustomHandlerErrors,
		checkGenericErrors,
		checkAnchorProgramErrors,
		checkNonDriftProgramsErrors,
		checkDriftErrors,
		checkPhantomErrors,
		checkJupiterErrors,
		checkMiscErrors,
	]);

	if (props?.isHandled()) {
		// # If we've handled the error, we don't need to do anything else
		return;
	}

	// # Otherwise we know it's an unknown error
	const errorCode = props?.error?.code;

	captureException(props?.error);

	handleNotify(
		{
			type: 'error',
			description:
				props?.fallbackDescription ??
				`There was an error processing the transaction ${
					errorCode ? `- Error Code ${errorCode}` : ''
				}`,
			subDescription: undefined,
			lengthMs: 10000,
			id: props?.toastId,
			updatePrevious: true,
		},
		props.error
	);

	props.setHandled({
		class: 'transaction_other_error',
		id: 'UnmatchedError',
	});

	try {
		props.captureEvent('unmatched_error', {
			error_message: props.error?.message,
			error_stack: props.error?.stack,
			error_raw: props.error,
			wallet: props?.wallet?.name,
		});
	} catch {
		// do nothing
	}
};

/**
 * Steps of transaction error handler:
 * - Setup
 * - Try match and handle error
 * - Fallback handling
 * - Metrics
 */
class TransactionErrorHandler {
	static appEventEmitter: DriftAppEventEmitter;
	static captureEvent: PostHogCapture;

	static setAppEventEmitter(appEventEmitter: DriftAppEventEmitter) {
		this.appEventEmitter = appEventEmitter;
	}

	static setCaptureEvent(captureEvent: PostHogCapture) {
		this.captureEvent = captureEvent;
	}

	static handleError = ({
		error,
		fallbackDescription,
		toastId,
		customErrorHandler,
		wallet,
	}: {
		error: AnchorError;
		fallbackDescription?: string;
		toastId?: string;
		customErrorHandler?: (error: AnchorError) => boolean;
		wallet: Adapter;
	}) => {
		// # Refs to track whether we've handled the error and which error metric type to log
		const txErrorHandledRef = new Ref(false);
		const txErrorMetricToLogRef = new Ref<ErrorMetricValue>(undefined);

		// # Dev Stuff
		if (isDev() || Env.showErrorLogs) {
			console.error(`TransactionErrorHandler:`);
			console.error(error);
			if (error.logs) {
				console.error(error.logs);
			}
		}

		const isHandled = () => txErrorHandledRef.get();
		const setHandled = (errorMetric: ErrorMetricValue) => {
			txErrorHandledRef.set(true);
			txErrorMetricToLogRef.set(errorMetric);
		};

		try {
			if (!this.appEventEmitter) {
				throw new Error('App Event Emitter not set');
			}

			// # Try match against error matchers
			tryMatchError({
				error,
				fallbackDescription,
				toastId,
				customErrorHandler,
				isHandled,
				setHandled,
				wallet,
				appEventEmitter: this.appEventEmitter,
				captureEvent: this.captureEvent,
			});
		} catch (e) {
			captureException(e);

			handleNotify(
				{
					type: 'error',
					description:
						fallbackDescription ??
						`There was an error processing the transaction.`,
					lengthMs: 10000,
					id: toastId,
					updatePrevious: true,
				},
				error
			);

			txErrorMetricToLogRef.set({
				class: 'transaction_other_error',
				id: 'ErrorTryingToHandleTxError',
			});
		}

		// # Send Transaction Error Metrics
		const txErrorClassMetricToLog = txErrorMetricToLogRef.get();
		if (
			txErrorClassMetricToLog &&
			txErrorClassMetricToLog.class !== 'non_issue_error'
		) {
			DriftWindow.metricsBus?.next({
				type: 'transaction_error_metric',
				value: {
					class: txErrorClassMetricToLog.class,
					id: txErrorClassMetricToLog.id,
				},
			});
		}
	};
}

export default TransactionErrorHandler;
