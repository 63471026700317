import produce from 'immer';
import { create } from 'zustand';
import {
	BN,
	BigNum,
	QUOTE_PRECISION_EXP,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';

export interface WalletBalancesStore {
	set: (x: (s: WalletBalancesStore) => void) => void;
	get: () => WalletBalancesStore;
	balances: {
		[symbol: string]: {
			base: BigNum; // base is the amount of the asset that you have
			quote: BigNum; // quote is the USDC value of  amount of the asset that you have
			loading: boolean;
		};
	};
	getBiggestAssetMarket: () => SpotMarketConfig;
	getBalance: (
		symbol: string,
		marketPrecisionExp: BN
	) => {
		base: BigNum;
		quote: BigNum;
		loading: boolean;
	};
}

const useWalletBalancesStore = create<WalletBalancesStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	balances: {},
	getBiggestAssetMarket: () => {
		const symbols = Object.keys(get().balances);
		let biggestAssetSymbol = symbols[0];

		symbols.forEach((symbol) => {
			if (
				get().balances[symbol].quote.gt(
					get().balances[biggestAssetSymbol].quote
				)
			) {
				biggestAssetSymbol = symbol;
			}
		});

		const spotMarketConfig =
			SPOT_MARKETS_LOOKUP.find(
				(market) => market.symbol === biggestAssetSymbol
			) ?? SPOT_MARKETS_LOOKUP[0];

		return spotMarketConfig;
	},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getBalance: (symbol: string, marketPrecisionExp: BN) =>
		get().balances[symbol] ?? {
			base: BigNum.zero(marketPrecisionExp),
			quote: BigNum.zero(QUOTE_PRECISION_EXP),
			loading: false,
		},
}));

export default useWalletBalancesStore;
