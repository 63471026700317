'use client';

import { useWeb3ModalProvider } from '@web3modal/solana/react';
import React, {
	PropsWithChildren,
	createContext,
	useEffect,
	useRef,
	useState,
} from 'react';

type Web3ModalProviderContextState = ReturnType<typeof useWeb3ModalProvider>;

const DEFAULT_CONTEXT_VALUE: Web3ModalProviderContextState = {
	walletProvider: undefined,
	connection: undefined,
};

export const Web3ModalProviderContext =
	createContext<Web3ModalProviderContextState>(DEFAULT_CONTEXT_VALUE);

/**
 * Wrapper provider for useWeb3ModalProvider() to prevent unnecessary re-renders
 * @param props
 * @returns
 */
const Web3ModalProvider = (props: PropsWithChildren<any>) => {
	const [value, setValue] = useState(DEFAULT_CONTEXT_VALUE);

	const web3ModalWalletProvider = useWeb3ModalProvider();

	const previousProvider =
		useRef<Web3ModalProviderContextState['walletProvider']>(null);

	const previousConnection =
		useRef<Web3ModalProviderContextState['connection']>(null);

	/**
	 * Verbose way to memoize the result of useWeb3ModalProvider in our own provider
	 */
	useEffect(() => {
		if (
			previousProvider.current === web3ModalWalletProvider.walletProvider &&
			previousConnection.current === web3ModalWalletProvider.connection
		) {
			return;
		}

		setValue(web3ModalWalletProvider);

		previousProvider.current = web3ModalWalletProvider.walletProvider;
		previousConnection.current = web3ModalWalletProvider.connection;
	}, [web3ModalWalletProvider]);

	return (
		<Web3ModalProviderContext value={value}>
			{props.children}
		</Web3ModalProviderContext>
	);
};

export default React.memo(Web3ModalProvider);
