const locale =
	(typeof window !== 'undefined' && window.navigator?.languages?.[0]) ||
	'en-US';

const delimiter = '.';
const splitter = ',';
// const delimiter = (1.1).toLocaleString(locale).slice(1, 2) || '.';
// const splitter = (1000).toLocaleString(locale).slice(1, 2) || ',';

export { locale, delimiter, splitter };
