'use client';
import { PublicKey } from '@solana/web3.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { useObservable } from 'react-use';
import { BehaviorSubject, Observable } from 'rxjs';
import { OptimisedSubscriptionsContext } from 'src/providers/optimisedDataSubscriptions/optimisedSubscriptionsProvider';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { dlog } from '../dev';
import { notify } from '../utils/notifications';
import useCurrentWalletAdapter from './useCurrentWalletAdapter';
import { getAssociatedTokenAddress } from '@solana/spl-token';

/**
 * This is the newer version of this code but it's temporarily reverted for a quick patch because there's something wrong with it in mainnet. Need to come back to review.
 */

// 🚨 NOTE ON NULL VS UNDEFINED : The usage of undefined and null have very different + important differences in this hook. We're using undefined to denote "it hasn't been loaded or processed yet" and null to denote "we know it's not there" .. e.g. if things are NULL, then we know they're not still in a "loading" state

const SHOWN_BAD_TOKEN_ACCOUNT_WARNING = { current: false };

const TOKEN2022_MINTS = [
	'2b1kV6DkPAnxd5ixfnxCpjxmKwqjjaYmCZfHsFu24GXo', // PYUSD
];

const TOKEN2022_PROGRAM_ADDRESS = new PublicKey('TokenzQdBNbLqP5VEhdkAS6EPFLC1PHnBqCXEpPxuEb');

/**
 * Listen to the SPL balance of a given token. Listens to the currently connected wallet by default, otherwise an optional target wallet can be passed in
 * @param tokenMintAddress
 * @param targetTokenAccountAddress
 * @param forceUpdate
 * @returns
 */
const useSPLTokenBalance = (
	tokenMintAddress: PublicKey,
	targetTokenAccountAddress?: string
): [number, boolean] => {
	const wallet = useCurrentWalletAdapter();
	const connection = useDriftStore((s) => s?.connection?.current);
	const [tokenAccountAddress, setTokenAccountAddress] = useState<string>(
		targetTokenAccountAddress ?? undefined
	);

	// Listen to SOL balance because when the SOL balance is zero, other SPL listeners don't work.
	//// When the SOL balance updates (and the user may have deposited SOL) we want to refresh the SPL token listeners
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);
	const solBalanceGreaterThanZero = solBalance?.gtZero?.() ?? false;
	const previousSolBalanceGreaterThanZero = useRef(solBalanceGreaterThanZero);

	const _showBadTokenAccountWarning = () => {
		if (SHOWN_BAD_TOKEN_ACCOUNT_WARNING.current) return;
		notify({
			type: 'warning',
			message: 'Detected Bad Token Account',
			description: `We have detected that you have some balance in a non-standard token account, which may lead to incorrect wallet balances being detected. If you experience difficulties please contact Drift for assistance.`,
			lengthMs: 10_000,
		});
		SHOWN_BAD_TOKEN_ACCOUNT_WARNING.current = true;
	};

	const getTokenAccountAddress = async () => {
		// # <OLD LOGIC>
		// if (targetTokenAccountAddress) {
		// 	return targetTokenAccountAddress;
		// }

		// if (!wallet?.publicKey) return undefined;
		// if (!connection) return undefined;

		// const tokenAccountResponse = await COMMON_UI_UTILS.getTokenAccount(
		// 	connection,
		// 	tokenMintAddress,
		// 	wallet?.publicKey
		// );

		// // This is a strong caveat : If we try to load the balance for a token account which doesn't exist yet, the following condition will be bet. We're returning null to denote that we KNOW the account doesn't exist, so it's not in a "loading" state. See the note at the top of the file.
		// if (!tokenAccountResponse?.tokenAccount?.pubkey) return null;

		// if (tokenAccountResponse.tokenAccountWarning) {
		// 	showBadTokenAccountWarning();
		// }

		// return tokenAccountResponse.tokenAccount?.pubkey?.toBase58();

		/** # <NEW LOGIC FOR PATCH>
		 * We added the above logic for two reasons:
		 * 1. Some users were deleting their primary token accounts, causing some deposits / transfers to go to OTHER associated token accounts (not the index-0 one, as standard). So we added a warning to catch these
		 * 2. Extra safety for catching when a user doesn't actually have a token account for the token at all. (We haven't deeply tested if this is necessary or any extra safe - just done as a precaution)
		 *
		 * The extra network requests this requires is causing helius to be rate-limit us when we try to subscribe to all SPL token balances for the user.
		 */

		const isToken2022 = TOKEN2022_MINTS.includes(
			tokenMintAddress.toBase58()
		);

		const associatedAddress = await getAssociatedTokenAddress(
			tokenMintAddress,
			wallet?.publicKey,
			true,
			isToken2022 ? TOKEN2022_PROGRAM_ADDRESS : undefined
		);

		return associatedAddress.toBase58();
	};

	useEffect(() => {
		setTokenAccountAddress(undefined);

		if (!connection) {
			dlog(
				`spl_subscription`,
				`skipping_spl_account_fetch_while_no_connection`
			);
			return;
		}

		if (!wallet?.publicKey) {
			dlog(`spl_subscription`, `skipping_spl_account_fetch_while_no_wallet`);
			return;
		}

		getTokenAccountAddress().then((address) => {
			setTokenAccountAddress(address);
		});
	}, [
		connection,
		wallet?.publicKey,
		tokenMintAddress,
		targetTokenAccountAddress,
	]);

	const [tokenBalanceObservable, setTokenBalanceObservable] = useState<
		Observable<number>
	>(new BehaviorSubject<number>(undefined));

	const optimisedDataSubscriptionsProvider = useContext(
		OptimisedSubscriptionsContext
	);

	/**
	 * Hook which manages the state of the SPL Balance Observable that we are listening to
	 *
	 * - If we don't know the traget token account address yet (this can be true on the first loop if one hasn't manually been passed in, while we asyncronously calculate it) then we set the observable to a default observable with value 0
	 * - If the wallet newly has a SOL balance greater than zero, then we reset the observable to a new observable - because token balance subscriptions are known to fail if doing so for a wallet with zero SOL balance
	 * - Otherwise, we generate a new observable if one doesn't exist, or return the existing observable
	 */
	useEffect(() => {
		if (!connection || !wallet?.publicKey) {
			dlog(
				`spl_subscription`,
				`skipping_spl_balance_subscription_because_no_connection_or_wallet`
			);
			return;
		}

		let newObservable: Observable<number>;

		if (tokenAccountAddress === undefined) {
			dlog(
				`spl_subscription`,
				`blank_observable_while_loading_token_account_address`
			);
			newObservable = new BehaviorSubject<number>(undefined);
		} else if (tokenAccountAddress === null) {
			dlog(`spl_subscription`, `blank_observable_for_no_token_account_address`);
			newObservable = new BehaviorSubject<number>(0);
		} else {
			dlog(
				`spl_subscription`,
				`returning_good_observable :: ${tokenAccountAddress}`
			);

			newObservable =
				optimisedDataSubscriptionsProvider.subscriptionProvider.getTokenBalanceObservable(
					tokenAccountAddress,
					connection
				);
		}

		setTokenBalanceObservable(newObservable);
		previousSolBalanceGreaterThanZero.current = solBalanceGreaterThanZero;
	}, [connection, tokenAccountAddress, solBalanceGreaterThanZero]);

	const tokenBalance = useObservable(tokenBalanceObservable);
	const isLoading =
		tokenBalance === undefined || tokenAccountAddress === undefined;

	return [tokenBalance, isLoading];
};

export default useSPLTokenBalance;
