const DESIGN_SYSTEM_COLORS = require('./src/constants/designSystemColors');

module.exports = {
	content: [
		'./src/app/**/*.{js,ts,jsx,tsx}',
		'./src/pages/**/*.{js,ts,jsx,tsx}',
		'./src/components/**/*.{js,ts,jsx,tsx}',
		'./src/constants/dialectThemeVariables.tsx',
	],
	darkMode: 'class',
	theme: {
		fontSize: {
			'2xs': '.5rem',
			xs: '.75rem',
			tiny: '.813rem',
			sm: '.875rem',
			base: '1rem',
			lg: '1.125rem',
			xl: '1.25rem',
			'2xl': '1.5rem',
			'3xl': '1.875rem',
			'4xl': '2.25rem',
			'5xl': '3rem',
			'6xl': '4rem',
			'7xl': '5rem',
			'8xl': '6rem',
			'body-01': [
				'14px',
				{
					lineHeight: '16px',
					fontWeight: 400,
				},
			],
			'body-01-medium': [
				'14px',
				{
					lineHeight: '16px',
					fontWeight: 500,
				},
			],
			'body-02': [
				'13px',
				{
					lineHeight: '16px',
					fontWeight: 400,
				},
			],
			'body-02-medium': [
				'13px',
				{
					lineHeight: '16px',
					fontWeight: 500,
				},
			],
			'body-03': [
				'12px',
				{
					lineHeight: '14px',
					fontWeight: 400,
				},
			],
			'body-03-medium': [
				'12px',
				{
					lineHeight: '14px',
					fontWeight: 500,
				},
			],
			'header-xxl': [
				'32px',
				{
					lineHeight: '44px',
					fontWeight: 400,
					letterSpacing: '0.1px',
				},
			],
			'header-xl': [
				'24px',
				{
					lineHeight: '36px',
					fontWeight: 400,
					letterSpacing: '0.1px',
				},
			],
			'header-1': [
				'20px',
				{
					lineHeight: '24px',
					fontWeight: 500,
					letterSpacing: '0.1px',
				},
			],
			'header-2': [
				'18px',
				{
					lineHeight: '22px',
					fontWeight: 500,
				},
			],
			'header-3': [
				'16px',
				{
					lineHeight: '20px',
					fontWeight: 400,
				},
			],
			'header-3-medium': [
				'16px',
				{
					lineHeight: '20px',
					fontWeight: 500,
				},
			],
			'header-4': [
				'14px',
				{
					lineHeight: '18px',
					fontWeight: 700,
				},
			],
			'header-5': [
				'13px',
				{
					lineHeight: '18px',
					fontWeight: 500,
				},
			],
			'paragraph-01': [
				'13px',
				{
					lineHeight: '18px',
					fontWeight: 400,
					letterSpacing: '0.1px',
				},
			],
			'micro-01': [
				'11px',
				{
					lineHeight: '12px',
					fontWeight: 400,
					letterSpacing: '0.15px',
				},
			],
			'micro-01-medium': [
				'11px',
				{
					lineHeight: '12px',
					fontWeight: 500,
					letterSpacing: '0.15px',
				},
			],
			'micro-02': [
				'8px',
				{
					lineHeight: '10px',
					fontWeight: 400,
					letterSpacing: '0.15px',
				},
			],
			'micro-02-medium': [
				'8px',
				{
					lineHeight: '10px',
					fontWeight: 500,
					letterSpacing: '0.15px',
				},
			],
		},
		screens: {
			xs: '0px',

			sm: '760px',

			md: '1100px',

			lg: '1250px',

			xl: '1600px',
		},
		fontFamily: {
			display: ['Marfa', 'DM Sans', 'Arial'],
			body: ['Marfa', 'Lato', 'Arial'],
			numeral: ['Marfa', 'Courier New'],
			accent: ['Chakra Petch', 'DM Sans', 'Arial'],
			mono: ['Marfa Mono', 'monospace'],
		},
		extend: {
			cursor: {
				help: 'help',
			},
			colors: {
				'light-theme': {},
				'dark-theme': {
					'stat-1': '#857DF6',
					'stat-2': '#ff8933',
					'stat-3': '#15ffab',
				},
				...DESIGN_SYSTEM_COLORS.allColors,
				...DESIGN_SYSTEM_COLORS.semanticClasses,
			},
			keyframes: {
				shake: {
					'0%, 100%': {
						transform: 'rotate(0deg)',
					},
					'20%, 60%': {
						transform: 'rotate(6deg)',
					},
					'40%, 80%': {
						transform: 'rotate(-6deg)',
					},
				},
			},
			animation: {
				shake: 'shake 0.4s linear 4',
			},
			spacing: {
				xxs: '2px',
				xs: '4px',
				s: '8px',
				m: '12px',
				l: '16px',
				xl: '24px',
			},
			boxShadow: {
				'elevation-00': '0px 4px 20px 0px #0000004D',
				'elevation-10': '0px 4px 10px 0px #00000080',
				'elevation-20': '0px 4px 50px 0px #00000099',
			},
			backgroundImage: {
				...DESIGN_SYSTEM_COLORS.gradients,
			},
			gridTemplateColumns: {
				'health-modal': 'minmax(90px,8fr) minmax(80px,5fr) minmax(80px,5fr)',
				'health-modal-mobile':
					'minmax(100px,6fr) minmax(80px,4fr) minmax(80px,4fr)',
			},
		},
	},
	variants: {
		extend: {
			cursor: ['hover', 'focus', 'disabled'],
			opacity: ['disabled'],
			backgroundColor: ['disabled'],
			textColor: ['disabled'],
		},
	},
};
