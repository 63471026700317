import {
	BaseMessageSignerWalletAdapter,
	SendTransactionOptions,
	WalletName,
	WalletReadyState,
} from '@solana/wallet-adapter-base';
import {
	Connection,
	PublicKey,
	SendOptions,
	TransactionSignature,
	TransactionVersion,
} from '@solana/web3.js';
import { Chain } from 'wagmi';

// 🚨 TODO
type Todo = any;

export interface AppKitProvider {
	// Metadata
	name: string;
	publicKey?: PublicKey;
	icon?: string;
	chains: Chain[];
	type: Todo;
	auth?: Todo;

	// Methods
	connect: () => Promise<string>;
	disconnect: () => Promise<void>;
	signMessage: (message: Uint8Array) => Promise<Uint8Array>;
	signTransaction: <T extends Todo>(transaction: T) => Promise<T>;
	signAllTransactions: <T extends Todo>(transactions: T) => Promise<T>;
	signAndSendTransaction: (
		transaction: Todo,
		options?: SendOptions
	) => Promise<TransactionSignature>;
	sendTransaction: (
		transaction: Todo,
		connection: Connection,
		options?: SendTransactionOptions
	) => Promise<TransactionSignature>;
}

export class AppKitAdapter extends BaseMessageSignerWalletAdapter {
	signMessage(_message: Uint8Array): Promise<Uint8Array> {
		throw new Error('Method not implemented.');
	}
	url: string;
	readyState: WalletReadyState;
	connecting: boolean;
	supportedTransactionVersions?: ReadonlySet<TransactionVersion>;

	private provider: AppKitProvider;

	public name = 'unset' as WalletName;
	public icon = undefined;
	public publicKey: PublicKey;

	private _connected = false;

	public get connected() {
		return this._connected;
	}

	public set connected(value) {
		this._connected = value;
	}

	public constructor(appKitProvider: AppKitProvider) {
		super();
		this.provider = appKitProvider;
		this.name = appKitProvider.name as WalletName;
		this.icon = appKitProvider.icon;
		this.publicKey = appKitProvider.publicKey;
	}

	public async signTransaction(tx) {
		return this.provider.signTransaction(tx);

		return tx;
	}

	public async signAllTransactions(txes) {
		return this.provider.signAllTransactions(txes);
	}

	// Extra step required for this adapter:
	public connect = async () => {
		await this.provider.connect();
		this._connected = true;
		this.emit('connect', this.publicKey);
		return;
	};

	public disconnect = async () => {
		await this.provider.disconnect();
		this._connected = false;
		this.emit('disconnect');
		return;
	};
}

const createAppKitAdapter = (appkitWalletProvider: AppKitProvider) => {
	const adapter = new AppKitAdapter(appkitWalletProvider);
	return adapter;
};

export default createAppKitAdapter;
