'use client';

import React, { memo, useEffect, useRef, useState } from 'react';
import { ArrowLeft } from '@drift-labs/icons';
import Text from 'src/components/Text/Text';
import WormholeWidget from '../WormholeWidget';
import { useRouter } from 'next/navigation';
import Button from '../Button';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import BridgePanelLayout from '../Layouts/BridgePanelLayout';
import { PublicKey } from '@solana/web3.js';
import useSPLTokenBalance from '../../hooks/useSPLTokenBalance';

const USDCetMint = new PublicKey(
	'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM'
);

export const WormholeConnectPanelBase = ({
	hideArrowBack,
}: {
	hideArrowBack?: boolean;
}) => {
	const connected = useWalletIsConnected();
	const router = useRouter();
	const [walletHasUSDCetBalance] = useSPLTokenBalance(USDCetMint);
	const lastUSDCetBalance = useRef(walletHasUSDCetBalance);
	const [readyToCaptureUSDCetBalance, setReadyToCaptureUSDCetBalance] =
		useState(false);
	const { captureEvent } = usePostHogCapture();

	const handleClickedJupiter = () => {
		captureEvent('clicked_jupiter_swap_link');
	};

	const handleClickedPortal = () => {
		captureEvent('clicked_portal_redeem_link');
	};

	const handleDone = () => {
		captureEvent('clicked_done_bridging_button');
		router.back();
	};

	// Wait 10 seconds before reporting USDCet balance changes
	// To ignore the initial load of the hook changing the balance
	useEffect(() => {
		const timeout = setTimeout(() => {
			setReadyToCaptureUSDCetBalance(true);
		}, 10000);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	// Capture events when user's USDCet balance changes on this page
	useEffect(() => {
		if (readyToCaptureUSDCetBalance) {
			if (lastUSDCetBalance.current < walletHasUSDCetBalance) {
				// user received USDCet
				captureEvent('bridge_USDCet_balance_increased');
			} else {
				// user sent/swapped/bridged USDCet out
				captureEvent('bridge_USDCet_balance_decreased');
			}
		}

		lastUSDCetBalance.current = walletHasUSDCetBalance;
	}, [walletHasUSDCetBalance, readyToCaptureUSDCetBalance]);

	return (
		<>
			<div className="flex flex-row items-center justify-start">
				{!hideArrowBack && (
					<button
						onClick={() => router.back()}
						className="text-text-default hover:text-text-emphasis hover:cursor-pointer p-1.5 rounded-full border border-container-border mr-4 flex items-center"
					>
						<ArrowLeft size={24} />
					</button>
				)}
				<Text.H1 className="text-text-emphasis">
					Bridge with Wormhole (Beta):
				</Text.H1>
			</div>
			<div className="flex flex-row items-center justify-between text-text-default">
				<Text.BODY1 className="leading-normal">
					After you&apos;ve bridged your assets, you can{' '}
					<a
						href="https://jup.ag/swap/USDCet-USDC"
						rel="noreferrer"
						target="_blank"
						onClick={handleClickedJupiter}
					>
						swap with Jupiter
					</a>{' '}
					for native USDC and deposit it onto Drift as collateral.
				</Text.BODY1>
			</div>
			<div className="mt-4 border border-container-border">
				<WormholeWidget />
			</div>
			<div className="flex flex-row w-full mt-8 space-x-4">
				<a
					href="https://jup.ag/swap/USDCet-USDC"
					rel="noreferrer"
					target="_blank"
					className="flex-shrink block w-full hover:opacity-100"
				>
					<Button.Secondary
						size={'MEDIUM'}
						disabled={!connected || !walletHasUSDCetBalance}
						highlight
						onClick={handleClickedJupiter}
						className="w-full"
					>
						Swap USDCet for USDC
					</Button.Secondary>
				</a>
				<Button.Secondary
					size="MEDIUM"
					onClick={handleDone}
					className="flex-shrink w-full"
				>
					Done
				</Button.Secondary>
			</div>
			<div className="w-full mt-4 text-center">
				<Text.BODY2>
					If you have difficulties claiming your tokens, visit{' '}
					<a
						href="https://www.portalbridge.com/#/redeem"
						target="_blank"
						rel="noreferrer"
						onClick={handleClickedPortal}
					>
						portal bridge
					</a>{' '}
					to claim.
				</Text.BODY2>
			</div>
		</>
	);
};

const WormholeConnectPanel = () => {
	return (
		<BridgePanelLayout>
			<WormholeConnectPanelBase />
		</BridgePanelLayout>
	);
};

export default memo(WormholeConnectPanel);
