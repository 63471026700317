'use client';

import { DriftClient } from '@drift-labs/sdk';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const useDriftClient = (): DriftClient => {
	return useDriftStore((s) => s.driftClient.client);
};

export default useDriftClient;
