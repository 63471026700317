import React from 'react';
import { ChevronUp } from '@drift-labs/icons';

const Chevron = ({
	direction,
	colour,
	size,
	...props
}: {
	className?: string;
	colour?: string;
	direction: 'up' | 'down' | 'left' | 'right';
	size?: number;
}) => {
	return (
		<ChevronUp
			className={`${
				direction === 'up'
					? 'rotate-0'
					: direction === 'right'
					? 'rotate-90'
					: direction === 'down'
					? 'rotate-180'
					: '-rotate-90'
			} ${props.className} transition-all`}
			color={colour}
			size={size}
		/>
	);
};

export default Chevron;
