import { ReactNode } from 'react';
import { UIOrderType } from '@drift/common';
import { PerpPosition } from '@drift-labs/sdk';

export enum TradeFormMessageType {
	info = 'info',
	warn = 'warn',
	error = 'error',
}

export type TradeFormState = {
	orderType: UIOrderType;
	priceBoxHeading: string;
	priceBoxEnabled: boolean;
	showSlippageTolerance: boolean;
	shouldShowMessage: boolean;
	messageType: TradeFormMessageType;
	messageTitle: string;
	message: string | ReactNode;
	messageLink?: string;
	messageLinkDescription?: string;
	skipMessageInConfirmationModal: boolean;
	showSecondaryPriceBox: boolean;
	showReduceOnly: boolean;
	showPostOnly: boolean;
	showLeverageSlider: boolean; // TODO : Update this to handle Env.depositAndTradeEnabled
	tradeButtonIsDisabled: boolean;
	currentPosition: PerpPosition;
	maxQuoteAvailable: number;
	maxBaseAvailable: number;
	currentMarketPrice: number;
	showReducePositionSlider: boolean;
	maxLeverageAvailable: number;
	messageId?: string;
	marketInSettlement?: boolean;
	showMaxButton: boolean; // TODO : Update this to handle Env.depositAndTradeEnabled
	showPriceEstimateOracleDivergenceWarning: boolean;
};

export type MaxFundsAvailableBreakdown = {
	newSide: {
		baseAmount: number;
		quoteAmount: number;
	};
	currentSide: {
		baseAmount: number;
		quoteAmount: number;
	};
};

export type PerpTradeFormState = TradeFormState & {
	maxFundAvailableBreakdown: MaxFundsAvailableBreakdown;
	isInDepositAndTradeState: boolean;
};
