"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultClient = void 0;
const sdk_1 = require("@drift-labs/sdk");
const anchor_1 = require("@coral-xyz/anchor");
const competitions_sdk_1 = require("@drift-labs/competitions-sdk");
const addresses_1 = require("./addresses");
const web3_js_1 = require("@solana/web3.js");
const spl_token_1 = require("@solana/spl-token");
const bytes_1 = require("@coral-xyz/anchor/dist/cjs/utils/bytes");
const math_1 = require("./math");
class VaultClient {
    constructor({ driftClient, program, cliMode, userMapConfig, }) {
        this.driftClient = driftClient;
        this.program = program;
        this.cliMode = !!cliMode;
        if (!userMapConfig) {
            this.vaultUsers = new sdk_1.UserMap({
                driftClient: driftClient,
                subscriptionConfig: {
                    type: 'polling',
                    frequency: 1000,
                    commitment: 'processed',
                },
            });
        }
        else {
            this.vaultUsers = new sdk_1.UserMap(userMapConfig);
        }
    }
    /**
     * Unsubscribes from the vault users map. Call this to clean up any dangling promises.
     */
    async unsubscribe() {
        await this.vaultUsers.unsubscribe();
    }
    async getVault(vault) {
        return await this.program.account.vault.fetch(vault);
    }
    async getVaultAndSlot(vault) {
        const vaultAndSlot = await this.program.account.vault.fetchAndContext(vault);
        return {
            vault: vaultAndSlot.data,
            slot: vaultAndSlot.context.slot,
        };
    }
    async getVaultDepositor(vaultDepositor) {
        return await this.program.account.vaultDepositor.fetch(vaultDepositor);
    }
    async getVaultDepositorAndSlot(vaultDepositor) {
        const vaultDepositorAndSlot = await this.program.account.vaultDepositor.fetchAndContext(vaultDepositor);
        return {
            vaultDepositor: vaultDepositorAndSlot.data,
            slot: vaultDepositorAndSlot.context.slot,
        };
    }
    getVaultProtocolAddress(vault) {
        return (0, addresses_1.getVaultProtocolAddressSync)(this.program.programId, vault);
    }
    async getVaultProtocol(vaultProtocol) {
        return await this.program.account.vaultProtocol.fetch(vaultProtocol);
    }
    async getVaultProtocolAndSlot(vaultProtocol) {
        const vaultProtocolAndSlot = await this.program.account.vaultProtocol.fetchAndContext(vaultProtocol);
        return {
            vaultProtocol: vaultProtocolAndSlot.data,
            slot: vaultProtocolAndSlot.context.slot,
        };
    }
    async getAllVaultDepositorsWithNoWithdrawRequest(vault) {
        const filters = [
            {
                // discriminator = VaultDepositor
                memcmp: {
                    offset: 0,
                    bytes: bytes_1.bs58.encode(anchor_1.BorshAccountsCoder.accountDiscriminator('VaultDepositor')),
                },
            },
            {
                // vault = vault
                memcmp: {
                    offset: 8,
                    bytes: vault.toBase58(),
                },
            },
            {
                // last_withdraw_request.shares (u128) = 0
                memcmp: {
                    offset: 112,
                    bytes: bytes_1.bs58.encode(new Uint8Array(16).fill(0)),
                },
            },
        ];
        // @ts-ignore
        return (await this.program.account.vaultDepositor.all(filters));
    }
    async getAllVaultDepositors(vault) {
        const filters = [
            {
                // discriminator = VaultDepositor
                memcmp: {
                    offset: 0,
                    bytes: bytes_1.bs58.encode(anchor_1.BorshAccountsCoder.accountDiscriminator('VaultDepositor')),
                },
            },
            {
                // vault = vault
                memcmp: {
                    offset: 8,
                    bytes: vault.toBase58(),
                },
            },
        ];
        // @ts-ignore
        return (await this.program.account.vaultDepositor.all(filters));
    }
    async getSubscribedVaultUser(vaultDriftUserAccountPubKey) {
        return this.vaultUsers.mustGet(vaultDriftUserAccountPubKey.toBase58(), {
            type: 'websocket',
        });
    }
    /**
     *
     * @param vault pubkey
     * @param factorUnrealizedPNL add unrealized pnl to net balance
     * @returns vault equity, in USDC
     */
    async calculateVaultEquity(params) {
        try {
            // defaults to true if undefined
            let factorUnrealizedPNL = true;
            if (params.factorUnrealizedPNL !== undefined) {
                factorUnrealizedPNL = params.factorUnrealizedPNL;
            }
            let vaultAccount;
            if (params.address !== undefined) {
                // @ts-ignore
                vaultAccount = await this.program.account.vault.fetch(params.address);
            }
            else if (params.vault !== undefined) {
                vaultAccount = params.vault;
            }
            else {
                throw new Error('Must supply address or vault');
            }
            const user = await this.getSubscribedVaultUser(vaultAccount.user);
            const netSpotValue = user.getNetSpotMarketValue();
            if (factorUnrealizedPNL) {
                const unrealizedPnl = user.getUnrealizedPNL(true, undefined, undefined);
                return netSpotValue.add(unrealizedPnl);
            }
            else {
                return netSpotValue;
            }
        }
        catch (err) {
            console.error('VaultClient ~ err:', err);
            return sdk_1.ZERO;
        }
    }
    /**
     *
     * @param vault pubkey
     * @param factorUnrealizedPNL add unrealized pnl to existing equity
     * @returns total vault equity, in spot deposit asset
     */
    async calculateVaultEquityInDepositAsset(params) {
        let vaultAccount;
        if (params.address !== undefined) {
            vaultAccount = await this.program.account.vault.fetch(params.address);
        }
        else if (params.vault !== undefined) {
            vaultAccount = params.vault;
        }
        else {
            throw new Error('Must supply address or vault');
        }
        const vaultEquity = await this.calculateVaultEquity({
            vault: vaultAccount,
            factorUnrealizedPNL: params.factorUnrealizedPNL,
        });
        const spotMarket = this.driftClient.getSpotMarketAccount(vaultAccount.spotMarketIndex);
        const spotOracle = this.driftClient.getOracleDataForSpotMarket(vaultAccount.spotMarketIndex);
        const spotPrecision = sdk_1.TEN.pow(new sdk_1.BN(spotMarket.decimals));
        return vaultEquity.mul(spotPrecision).div(spotOracle.price);
    }
    /**
     * @param params
     * @returns vault depositor equity, in spot market value (which is usually USDC)
     */
    async calculateWithdrawableVaultDepositorEquity(params) {
        let vaultAccount;
        if (params.vaultAddress !== undefined) {
            vaultAccount = await this.program.account.vault.fetch(params.vaultAddress);
        }
        else if (params.vault !== undefined) {
            vaultAccount = params.vault;
        }
        else {
            throw new Error('Must supply vaultAddress or vault');
        }
        let vaultDepositorAccount;
        if (params.vaultDepositorAddress !== undefined) {
            vaultDepositorAccount = await this.program.account.vaultDepositor.fetch(params.vaultDepositorAddress);
        }
        else if (params.vaultDepositor !== undefined) {
            vaultDepositorAccount = params.vaultDepositor;
        }
        else {
            throw new Error('Must supply vaultDepositorAddress or vaultDepositor');
        }
        const vaultEquity = await this.calculateVaultEquity({
            vault: vaultAccount,
            factorUnrealizedPNL: false,
        });
        return (0, math_1.calculateRealizedVaultDepositorEquity)(vaultDepositorAccount, vaultEquity, vaultAccount);
    }
    async calculateWithdrawableVaultDepositorEquityInDepositAsset(params) {
        let vaultAccount;
        if (params.vaultAddress !== undefined) {
            vaultAccount = await this.program.account.vault.fetch(params.vaultAddress);
        }
        else if (params.vault !== undefined) {
            vaultAccount = params.vault;
        }
        else {
            throw new Error('Must supply vaultAddress or vault');
        }
        let vaultDepositorAccount;
        if (params.vaultDepositorAddress !== undefined) {
            vaultDepositorAccount = await this.program.account.vaultDepositor.fetch(params.vaultDepositorAddress);
        }
        else if (params.vaultDepositor !== undefined) {
            vaultDepositorAccount = params.vaultDepositor;
        }
        else {
            throw new Error('Must supply vaultDepositorAddress or vaultDepositor');
        }
        let vaultProtocol = undefined;
        if (vaultAccount.vaultProtocol) {
            vaultProtocol = await this.program.account.vaultProtocol.fetch(this.getVaultProtocolAddress(vaultAccount.pubkey));
        }
        const vaultEquity = await this.calculateVaultEquity({
            vault: vaultAccount,
            factorUnrealizedPNL: false,
        });
        const vdEquity = (0, math_1.calculateRealizedVaultDepositorEquity)(vaultDepositorAccount, vaultEquity, vaultAccount, vaultProtocol);
        const spotMarket = this.driftClient.getSpotMarketAccount(vaultAccount.spotMarketIndex);
        const spotOracle = this.driftClient.getOracleDataForSpotMarket(vaultAccount.spotMarketIndex);
        const spotPrecision = sdk_1.TEN.pow(new sdk_1.BN(spotMarket.decimals));
        return vdEquity.mul(spotPrecision).div(spotOracle.price);
    }
    async calculateVaultProtocolEquity(params) {
        const vaultAccount = await this.program.account.vault.fetch(params.vault);
        const vaultTotalEquity = await this.calculateVaultEquity({
            vault: vaultAccount,
        });
        const vaultProtocol = this.getVaultProtocolAddress(params.vault);
        const vpAccount = await this.program.account.vaultProtocol.fetch(vaultProtocol);
        return (0, sdk_1.unstakeSharesToAmount)(vpAccount.protocolProfitAndFeeShares, vaultAccount.totalShares, vaultTotalEquity);
    }
    async initializeVault(params) {
        const { vaultProtocol: vaultProtocolParams, ...vaultParams } = params;
        const vault = (0, addresses_1.getVaultAddressSync)(this.program.programId, params.name);
        const tokenAccount = (0, addresses_1.getTokenVaultAddressSync)(this.program.programId, vault);
        const driftState = await this.driftClient.getStatePublicKey();
        const spotMarket = this.driftClient.getSpotMarketAccount(params.spotMarketIndex);
        if (!spotMarket) {
            throw new Error(`Spot market ${params.spotMarketIndex} not found on driftClient`);
        }
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vault);
        const userKey = (0, sdk_1.getUserAccountPublicKeySync)(this.driftClient.program.programId, vault);
        const accounts = {
            driftSpotMarket: spotMarket.pubkey,
            driftSpotMarketMint: spotMarket.mint,
            driftUserStats: userStatsKey,
            driftUser: userKey,
            driftState,
            vault,
            tokenAccount,
            driftProgram: this.driftClient.program.programId,
        };
        if (vaultProtocolParams) {
            const vaultProtocol = this.getVaultProtocolAddress((0, addresses_1.getVaultAddressSync)(this.program.programId, params.name));
            const _params = {
                ...vaultParams,
                vaultProtocol: vaultProtocolParams,
            };
            return await this.program.methods
                .initializeVaultWithProtocol(_params)
                .preInstructions([
                web3_js_1.ComputeBudgetProgram.setComputeUnitLimit({
                    units: 400000,
                }),
                web3_js_1.ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 300000,
                }),
            ])
                .accounts({
                ...accounts,
                vaultProtocol,
            })
                .rpc();
        }
        else {
            const _params = vaultParams;
            return await this.program.methods
                .initializeVault(_params)
                .preInstructions([
                web3_js_1.ComputeBudgetProgram.setComputeUnitLimit({
                    units: 400000,
                }),
                web3_js_1.ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 300000,
                }),
            ])
                .accounts(accounts)
                .rpc();
        }
    }
    /**
     * Updates the delegate address for a vault. The delegate address will be allowed to trade
     * on behalf of the vault.
     * @param vault vault address to update
     * @param delegate delegate address to update to
     * @returns
     */
    async updateDelegate(vault, delegate) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        return await this.program.methods
            .updateDelegate(delegate)
            .preInstructions([
            web3_js_1.ComputeBudgetProgram.setComputeUnitLimit({
                units: 400000,
            }),
            web3_js_1.ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: 300000,
            }),
        ])
            .accounts({
            vault: vault,
            driftUser: vaultAccount.user,
            driftProgram: this.driftClient.program.programId,
        })
            .rpc();
    }
    /**
     * Updates the vault margin trading status.
     * @param vault vault address to update
     * @param enabled whether to enable margin trading
     * @returns
     */
    async updateMarginTradingEnabled(vault, enabled) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        return await this.program.methods
            .updateMarginTradingEnabled(enabled)
            .accounts({
            vault: vault,
            driftUser: vaultAccount.user,
            driftProgram: this.driftClient.program.programId,
        })
            .rpc();
    }
    /**
     *
     * @param vault vault address to deposit to
     * @param amount amount to deposit
     * @returns
     */
    async managerDeposit(vault, amount) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        const driftSpotMarket = this.driftClient.getSpotMarketAccount(vaultAccount.spotMarketIndex);
        if (!driftSpotMarket) {
            throw new Error(`Spot market ${vaultAccount.spotMarketIndex} not found on driftClient`);
        }
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        return await this.program.methods
            .managerDeposit(amount)
            .accounts({
            vault,
            vaultTokenAccount: vaultAccount.tokenAccount,
            driftUser: await (0, sdk_1.getUserAccountPublicKey)(this.driftClient.program.programId, vault),
            driftProgram: this.driftClient.program.programId,
            driftUserStats: (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vault),
            driftState: await this.driftClient.getStatePublicKey(),
            driftSpotMarketVault: driftSpotMarket.vault,
            userTokenAccount: (0, spl_token_1.getAssociatedTokenAddressSync)(driftSpotMarket.mint, this.driftClient.wallet.publicKey),
            tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
        })
            .remainingAccounts(remainingAccounts)
            .rpc();
    }
    async managerRequestWithdraw(vault, amount, withdrawUnit) {
        this.program.idl.types;
        // @ts-ignore
        const vaultAccount = (await this.program.account.vault.fetch(vault));
        if (!this.driftClient.wallet.publicKey.equals(vaultAccount.manager)) {
            throw new Error(`Only the manager of the vault can request a withdraw.`);
        }
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vault);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const accounts = {
            vault,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
        };
        if (this.cliMode) {
            return await this.program.methods
                // @ts-ignore, 0.29.0 anchor issues..
                .managerRequestWithdraw(amount, withdrawUnit)
                .accounts(accounts)
                .remainingAccounts(remainingAccounts)
                .rpc();
        }
        else {
            const requestWithdrawIx = this.program.instruction.managerRequestWithdraw(
            // @ts-ignore
            amount, withdrawUnit, {
                accounts: {
                    manager: this.driftClient.wallet.publicKey,
                    ...accounts,
                },
                remainingAccounts,
            });
            return await this.createAndSendTxn([requestWithdrawIx]);
        }
    }
    async managerCancelWithdrawRequest(vault) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vault);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const accounts = {
            manager: this.driftClient.wallet.publicKey,
            vault,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
        };
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        if (this.cliMode) {
            return await this.program.methods
                .mangerCancelWithdrawRequest()
                .accounts(accounts)
                .remainingAccounts(remainingAccounts)
                .rpc();
        }
        else {
            const cancelRequestWithdrawIx = this.program.instruction.mangerCancelWithdrawRequest({
                accounts: {
                    ...accounts,
                    manager: this.driftClient.wallet.publicKey,
                },
                remainingAccounts,
            });
            return await this.createAndSendTxn([cancelRequestWithdrawIx]);
        }
    }
    async managerWithdraw(vault) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        if (!this.driftClient.wallet.publicKey.equals(vaultAccount.manager)) {
            throw new Error(`Only the manager of the vault can request a withdraw.`);
        }
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        const spotMarket = this.driftClient.getSpotMarketAccount(vaultAccount.spotMarketIndex);
        if (!spotMarket) {
            throw new Error(`Spot market ${vaultAccount.spotMarketIndex} not found on driftClient`);
        }
        const ix = this.program.instruction.managerWithdraw({
            accounts: {
                vault,
                manager: this.driftClient.wallet.publicKey,
                vaultTokenAccount: vaultAccount.tokenAccount,
                driftUser: await (0, sdk_1.getUserAccountPublicKey)(this.driftClient.program.programId, vault),
                driftProgram: this.driftClient.program.programId,
                driftUserStats: (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vault),
                driftState: await this.driftClient.getStatePublicKey(),
                driftSpotMarketVault: spotMarket.vault,
                userTokenAccount: (0, spl_token_1.getAssociatedTokenAddressSync)(spotMarket.mint, this.driftClient.wallet.publicKey),
                driftSigner: this.driftClient.getStateAccount().signer,
                tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
            },
            remainingAccounts,
        });
        return this.createAndSendTxn([ix], {
            cuLimit: 1000000,
        });
    }
    async managerUpdateVault(vault, params) {
        const ix = this.program.instruction.updateVault(params, {
            accounts: {
                vault,
                manager: this.driftClient.wallet.publicKey,
            },
        });
        return this.createAndSendTxn([ix], {
            cuLimit: 600000,
            cuPriceMicroLamports: 10000,
        });
    }
    async getApplyProfitShareIx(vault, vaultDepositor) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const spotMarket = this.driftClient.getSpotMarketAccount(vaultAccount.spotMarketIndex);
        if (!spotMarket) {
            throw new Error(`Spot market ${vaultAccount.spotMarketIndex} not found on driftClient`);
        }
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        const accounts = {
            vault,
            vaultDepositor,
            manager: this.driftClient.wallet.publicKey,
            driftUserStats: (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vault),
            driftUser: await (0, sdk_1.getUserAccountPublicKey)(this.driftClient.program.programId, vault),
            driftState: await this.driftClient.getStatePublicKey(),
            driftSigner: this.driftClient.getStateAccount().signer,
            driftProgram: this.driftClient.program.programId,
        };
        return this.program.instruction.applyProfitShare({
            accounts: {
                ...accounts,
            },
            remainingAccounts,
        });
    }
    createInitVaultDepositorIx(vault, authority) {
        const vaultDepositor = (0, addresses_1.getVaultDepositorAddressSync)(this.program.programId, vault, authority || this.driftClient.wallet.publicKey);
        const accounts = {
            vaultDepositor,
            vault,
            authority: authority || this.driftClient.wallet.publicKey,
        };
        const initIx = this.program.instruction.initializeVaultDepositor({
            accounts: {
                ...accounts,
                payer: authority || this.driftClient.wallet.publicKey,
                rent: web3_js_1.SYSVAR_RENT_PUBKEY,
                systemProgram: web3_js_1.SystemProgram.programId,
            },
        });
        return initIx;
    }
    /**
     * Initializes the vault depositor account. This account is used to deposit funds into a vault.
     * @param vault the vault address to deposit into
     * @param authority the authority allowed to make deposits into the vault
     * @returns
     */
    async initializeVaultDepositor(vault, authority) {
        const vaultDepositor = (0, addresses_1.getVaultDepositorAddressSync)(this.program.programId, vault, authority || this.driftClient.wallet.publicKey);
        const accounts = {
            vaultDepositor,
            vault,
            authority: authority || this.driftClient.wallet.publicKey,
        };
        if (this.cliMode) {
            return await this.program.methods
                .initializeVaultDepositor()
                .accounts(accounts)
                .rpc();
        }
        else {
            const initIx = this.createInitVaultDepositorIx(vault, authority);
            return await this.createAndSendTxn([initIx]);
        }
    }
    async prepDepositTx(vaultDepositor, amount, initVaultDepositor) {
        let vaultPubKey;
        if (initVaultDepositor) {
            vaultPubKey = initVaultDepositor.vault;
        }
        else {
            const vaultDepositorAccount = await this.program.account.vaultDepositor.fetch(vaultDepositor);
            vaultPubKey = vaultDepositorAccount.vault;
        }
        const vaultAccount = await this.program.account.vault.fetch(vaultPubKey);
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vaultPubKey);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vaultPubKey);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const spotMarket = this.driftClient.getSpotMarketAccount(vaultAccount.spotMarketIndex);
        if (!spotMarket) {
            throw new Error(`Spot market ${vaultAccount.spotMarketIndex} not found on driftClient`);
        }
        const accounts = {
            vault: vaultPubKey,
            vaultDepositor,
            vaultTokenAccount: vaultAccount.tokenAccount,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
            driftSpotMarketVault: spotMarket.vault,
            userTokenAccount: (0, spl_token_1.getAssociatedTokenAddressSync)(spotMarket.mint, this.driftClient.wallet.publicKey, true),
            driftProgram: this.driftClient.program.programId,
            tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
        };
        return {
            vaultAccount,
            accounts,
            remainingAccounts,
        };
    }
    /**
     * Creates a transaction to deposit funds into the specified vault.
     * Uses the associated token account of the vault depositor authority and spot market mint,
     * and assumes it exists before calling this function.
     * @param vaultDepositor
     * @param amount
     * @param initVaultDepositor If true, will initialize the vault depositor account
     * @returns transaction
     */
    async createDepositTx(vaultDepositor, amount, initVaultDepositor, txParams) {
        const { vaultAccount, accounts, remainingAccounts } = await this.prepDepositTx(vaultDepositor, amount, initVaultDepositor);
        const ixs = [];
        if (initVaultDepositor) {
            ixs.push(this.createInitVaultDepositorIx(vaultAccount.pubkey, initVaultDepositor.authority));
        }
        const depositIx = await this.program.methods
            .deposit(amount)
            .accounts({
            authority: this.driftClient.wallet.publicKey,
            ...accounts,
        })
            .remainingAccounts(remainingAccounts)
            .instruction();
        ixs.push(depositIx);
        return await this.createTxn(ixs, txParams);
    }
    /**
     * Depositor funds into the specified vault.
     * @param vaultDepositor
     * @param amount
     * @param initVaultDepositor If true, will initialize the vault depositor account
     * @param txParams
     * @returns
     */
    async deposit(vaultDepositor, amount, initVaultDepositor, txParams) {
        if (this.cliMode) {
            const { vaultAccount, accounts, remainingAccounts } = await this.prepDepositTx(vaultDepositor, amount, initVaultDepositor);
            if (initVaultDepositor) {
                await this.initializeVaultDepositor(vaultAccount.pubkey, initVaultDepositor.authority);
            }
            return await this.program.methods
                .deposit(amount)
                .accounts(accounts)
                .remainingAccounts(remainingAccounts)
                .rpc();
        }
        else {
            const depositTxn = await this.createDepositTx(vaultDepositor, amount, initVaultDepositor, txParams);
            return this.sendTxn(depositTxn, txParams === null || txParams === void 0 ? void 0 : txParams.simulateTransaction);
        }
    }
    async requestWithdraw(vaultDepositor, amount, withdrawUnit, txParams) {
        const vaultDepositorAccount = await this.program.account.vaultDepositor.fetch(vaultDepositor);
        const vaultAccount = await this.program.account.vault.fetch(vaultDepositorAccount.vault);
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vaultDepositorAccount.vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vaultDepositorAccount.vault);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const accounts = {
            vault: vaultDepositorAccount.vault,
            vaultDepositor,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
        };
        if (this.cliMode) {
            return await this.program.methods
                // @ts-ignore
                .requestWithdraw(amount, withdrawUnit)
                .accounts(accounts)
                .remainingAccounts(remainingAccounts)
                .rpc();
        }
        else {
            const requestWithdrawIx = this.program.instruction.requestWithdraw(
            // @ts-ignore
            amount, withdrawUnit, {
                accounts: {
                    authority: this.driftClient.wallet.publicKey,
                    ...accounts,
                },
                remainingAccounts,
            });
            return await this.createAndSendTxn([requestWithdrawIx], txParams);
        }
    }
    async withdraw(vaultDepositor, txParams) {
        var _a;
        const vaultDepositorAccount = await this.program.account.vaultDepositor.fetch(vaultDepositor);
        const vaultAccount = await this.program.account.vault.fetch(vaultDepositorAccount.vault);
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        const vaultProtocol = this.getVaultProtocolAddress(vaultDepositorAccount.vault);
        if (!vaultProtocol.equals(web3_js_1.SystemProgram.programId)) {
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vaultDepositorAccount.vault);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const spotMarket = this.driftClient.getSpotMarketAccount(vaultAccount.spotMarketIndex);
        if (!spotMarket) {
            throw new Error(`Spot market ${vaultAccount.spotMarketIndex} not found on driftClient`);
        }
        const userAta = (0, spl_token_1.getAssociatedTokenAddressSync)(spotMarket.mint, this.driftClient.wallet.publicKey, true);
        let createAtaIx = undefined;
        const userAtaExists = await this.driftClient.connection.getAccountInfo(userAta);
        if (userAtaExists === null) {
            createAtaIx = (0, spl_token_1.createAssociatedTokenAccountInstruction)(this.driftClient.wallet.publicKey, userAta, this.driftClient.wallet.publicKey, spotMarket.mint);
        }
        const accounts = {
            vault: vaultDepositorAccount.vault,
            vaultDepositor,
            vaultTokenAccount: vaultAccount.tokenAccount,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
            driftSpotMarketVault: spotMarket.vault,
            driftSigner: this.driftClient.getStateAccount().signer,
            userTokenAccount: userAta,
            driftProgram: this.driftClient.program.programId,
            tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
        };
        if (this.cliMode) {
            if (createAtaIx) {
                return await this.program.methods
                    .withdraw()
                    .accounts(accounts)
                    .remainingAccounts(remainingAccounts)
                    .preInstructions([createAtaIx])
                    .rpc();
            }
            else {
                return await this.program.methods
                    .withdraw()
                    .accounts(accounts)
                    .remainingAccounts(remainingAccounts)
                    .rpc();
            }
        }
        else {
            const ixs = [
                await this.program.methods
                    .withdraw()
                    .accounts({
                    authority: this.driftClient.wallet.publicKey,
                    ...accounts,
                })
                    .remainingAccounts(remainingAccounts)
                    .instruction(),
            ];
            if (createAtaIx) {
                ixs.unshift(createAtaIx);
            }
            return await this.createAndSendTxn(ixs, {
                cuLimit: ((_a = txParams === null || txParams === void 0 ? void 0 : txParams.cuLimit) !== null && _a !== void 0 ? _a : 650000) + (createAtaIx ? 100000 : 0),
                ...txParams,
            });
        }
    }
    async forceWithdraw(vaultDepositor) {
        const vaultDepositorAccount = await this.program.account.vaultDepositor.fetch(vaultDepositor);
        const vaultAccount = await this.program.account.vault.fetch(vaultDepositorAccount.vault);
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vaultDepositorAccount.vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vaultDepositorAccount.vault);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const spotMarket = this.driftClient.getSpotMarketAccount(vaultAccount.spotMarketIndex);
        if (!spotMarket) {
            throw new Error(`Spot market ${vaultAccount.spotMarketIndex} not found on driftClient`);
        }
        const accounts = {
            manager: this.driftClient.wallet.publicKey,
            vault: vaultDepositorAccount.vault,
            vaultDepositor,
            vaultTokenAccount: vaultAccount.tokenAccount,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
            driftSpotMarketVault: spotMarket.vault,
            driftSigner: this.driftClient.getStateAccount().signer,
            userTokenAccount: (0, spl_token_1.getAssociatedTokenAddressSync)(spotMarket.mint, vaultDepositorAccount.authority, true),
            driftProgram: this.driftClient.program.programId,
            tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
        };
        if (this.cliMode) {
            return await this.program.methods
                .forceWithdraw()
                .preInstructions([
                web3_js_1.ComputeBudgetProgram.setComputeUnitLimit({
                    units: 500000,
                }),
                web3_js_1.ComputeBudgetProgram.setComputeUnitPrice({
                    microLamports: 50000,
                }),
            ])
                .accounts(accounts)
                .remainingAccounts(remainingAccounts)
                .rpc();
        }
        else {
            const forceWithdrawIx = this.program.instruction.forceWithdraw({
                accounts: {
                    ...accounts,
                },
                remainingAccounts,
            });
            return await this.createAndSendTxn([forceWithdrawIx]);
        }
    }
    async cancelRequestWithdraw(vaultDepositor, txParams) {
        const vaultDepositorAccount = await this.program.account.vaultDepositor.fetch(vaultDepositor);
        const vaultAccount = await this.program.account.vault.fetch(vaultDepositorAccount.vault);
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vaultDepositorAccount.vault);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const accounts = {
            vault: vaultDepositorAccount.vault,
            vaultDepositor,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
        };
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vaultDepositorAccount.vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        if (this.cliMode) {
            return await this.program.methods
                .cancelRequestWithdraw()
                .accounts(accounts)
                .remainingAccounts(remainingAccounts)
                .rpc();
        }
        else {
            const cancelRequestWithdrawIx = this.program.instruction.cancelRequestWithdraw({
                accounts: {
                    authority: this.driftClient.wallet.publicKey,
                    ...accounts,
                },
                remainingAccounts,
            });
            return await this.createAndSendTxn([cancelRequestWithdrawIx], txParams);
        }
    }
    /**
     * Liquidates (become delegate for) a vault.
     * @param
     * @param
     * @returns
     */
    async liquidate(vaultDepositor, txParams) {
        const vaultDepositorAccount = await this.program.account.vaultDepositor.fetch(vaultDepositor);
        const vaultPubKey = vaultDepositorAccount.vault;
        const vaultAccount = await this.program.account.vault.fetch(vaultPubKey);
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vaultPubKey);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const accounts = {
            vault: vaultPubKey,
            vaultDepositor,
            vaultTokenAccount: vaultAccount.tokenAccount,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
            driftProgram: this.driftClient.program.programId,
        };
        if (this.cliMode) {
            return await this.program.methods
                .liquidate()
                .accounts(accounts)
                .remainingAccounts(remainingAccounts)
                .rpc();
        }
        else {
            const liquidateIx = this.program.instruction.liquidate({
                accounts: {
                    authority: this.driftClient.wallet.publicKey,
                    ...accounts,
                },
                remainingAccounts,
            });
            return await this.createAndSendTxn([liquidateIx], txParams);
        }
    }
    async createTxn(vaultIxs, txParams) {
        var _a, _b, _c;
        const ixs = [
            web3_js_1.ComputeBudgetProgram.setComputeUnitLimit({
                units: (_a = txParams === null || txParams === void 0 ? void 0 : txParams.cuLimit) !== null && _a !== void 0 ? _a : 400000,
            }),
            web3_js_1.ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: (_b = txParams === null || txParams === void 0 ? void 0 : txParams.cuPriceMicroLamports) !== null && _b !== void 0 ? _b : 1000000,
            }),
            ...vaultIxs,
        ];
        return (await this.driftClient.txHandler.buildTransaction({
            connection: this.driftClient.connection,
            instructions: ixs,
            lookupTables: (_c = txParams === null || txParams === void 0 ? void 0 : txParams.lookupTables) !== null && _c !== void 0 ? _c : [],
            preFlightCommitment: 'confirmed',
            forceVersionedTransaction: true,
            txVersion: 0,
            fetchMarketLookupTableAccount: this.driftClient.fetchMarketLookupTableAccount.bind(this.driftClient),
        }));
    }
    async sendTxn(transaction, simulateTransaction) {
        var _a;
        let txSig = bytes_1.bs58.encode(transaction.signatures[0]);
        if (simulateTransaction) {
            try {
                const resp = await this.driftClient.connection.simulateTransaction(transaction, {
                    sigVerify: false,
                    commitment: this.driftClient.connection.commitment,
                });
                console.log(`Simulated transaction:\n${JSON.stringify(resp, null, 2)}`);
            }
            catch (e) {
                const err = e;
                console.error(`Error simulating transaction: ${err.message}\n:${(_a = err.stack) !== null && _a !== void 0 ? _a : ''}`);
            }
        }
        else {
            const resp = await this.driftClient.sendTransaction(transaction, [], this.driftClient.opts);
            if (resp.txSig !== txSig) {
                console.error(`Transaction signature mismatch with self calculated value: ${resp.txSig} !== ${txSig}`);
                txSig = resp.txSig;
            }
        }
        return txSig;
    }
    /**
     * Used for UI wallet adapters compatibility
     */
    async createAndSendTxn(vaultIxs, txParams) {
        const tx = await this.createTxn(vaultIxs, txParams);
        const txSig = await this.sendTxn(tx, txParams === null || txParams === void 0 ? void 0 : txParams.simulateTransaction);
        return txSig;
    }
    /**
     * Initializes an insurance fund stake for the vault.
     * @param vault vault address to update
     * @param spotMarketIndex spot market index of the insurance fund stake
     * @returns
     */
    async initializeInsuranceFundStake(vault, spotMarketIndex) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        const ifStakeAccountPublicKey = (0, sdk_1.getInsuranceFundStakeAccountPublicKey)(this.driftClient.program.programId, vault, spotMarketIndex);
        const spotMarket = this.driftClient.getSpotMarketAccount(spotMarketIndex);
        if (!spotMarket) {
            throw new Error(`Spot market ${spotMarketIndex} not found on driftClient`);
        }
        return await this.program.methods
            .initializeInsuranceFundStake(spotMarketIndex)
            .accounts({
            vault: vault,
            driftSpotMarket: spotMarket.pubkey,
            insuranceFundStake: ifStakeAccountPublicKey,
            driftUserStats: vaultAccount.userStats,
            driftState: await this.driftClient.getStatePublicKey(),
            driftProgram: this.driftClient.program.programId,
        })
            .rpc();
    }
    /**
     * Initializes a DriftCompetitions Competitor account for the vault.
     * @param vault vault address to initialize Competitor for
     * @param competitionName name of the competition to initialize for
     * @returns
     */
    async initializeCompetitor(vault, competitionsClient, competitionName) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        const encodedName = (0, sdk_1.encodeName)(competitionName);
        const competitionAddress = (0, competitions_sdk_1.getCompetitionAddressSync)(competitionsClient.program.programId, encodedName);
        const competitorAddress = (0, competitions_sdk_1.getCompetitorAddressSync)(competitionsClient.program.programId, competitionAddress, vault);
        return await this.program.methods
            .initializeCompetitor()
            .accounts({
            vault: vault,
            competitor: competitorAddress,
            driftCompetitions: competitionAddress,
            driftUserStats: vaultAccount.userStats,
            driftCompetitionsProgram: competitionsClient.program.programId,
        })
            .rpc();
    }
    async protocolRequestWithdraw(vault, amount, withdrawUnit) {
        // @ts-ignore
        const vaultAccount = (await this.program.account.vault.fetch(vault));
        const vp = this.getVaultProtocolAddress(vault);
        const vpAccount = (await this.program.account.vaultProtocol.fetch(vp));
        if (!this.driftClient.wallet.publicKey.equals(vpAccount.protocol)) {
            throw new Error(`Only the protocol of the vault can request a withdraw.`);
        }
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vault);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const accounts = {
            vault,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
        };
        if (this.cliMode) {
            return await this.program.methods
                // @ts-ignore, 0.29.0 anchor issues..
                .managerRequestWithdraw(amount, withdrawUnit)
                .accounts(accounts)
                .remainingAccounts(remainingAccounts)
                .rpc();
        }
        else {
            const requestWithdrawIx = this.program.instruction.managerRequestWithdraw(
            // @ts-ignore
            amount, withdrawUnit, {
                accounts: {
                    manager: this.driftClient.wallet.publicKey,
                    ...accounts,
                },
                remainingAccounts,
            });
            return await this.createAndSendTxn([requestWithdrawIx]);
        }
    }
    async protocolCancelWithdrawRequest(vault) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        const userStatsKey = (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vault);
        const driftStateKey = await this.driftClient.getStatePublicKey();
        const accounts = {
            manager: this.driftClient.wallet.publicKey,
            vault,
            driftUserStats: userStatsKey,
            driftUser: vaultAccount.user,
            driftState: driftStateKey,
        };
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        if (this.cliMode) {
            return await this.program.methods
                .mangerCancelWithdrawRequest()
                .accounts(accounts)
                .remainingAccounts(remainingAccounts)
                .rpc();
        }
        else {
            const cancelRequestWithdrawIx = this.program.instruction.mangerCancelWithdrawRequest({
                accounts: {
                    ...accounts,
                    manager: this.driftClient.wallet.publicKey,
                },
                remainingAccounts,
            });
            return await this.createAndSendTxn([cancelRequestWithdrawIx]);
        }
    }
    async protocolWithdraw(vault) {
        const vaultAccount = await this.program.account.vault.fetch(vault);
        if (!this.driftClient.wallet.publicKey.equals(vaultAccount.manager)) {
            throw new Error(`Only the manager of the vault can request a withdraw.`);
        }
        const user = await this.getSubscribedVaultUser(vaultAccount.user);
        const remainingAccounts = this.driftClient.getRemainingAccounts({
            userAccounts: [user.getUserAccount()],
            writableSpotMarketIndexes: [vaultAccount.spotMarketIndex],
        });
        if (vaultAccount.vaultProtocol) {
            const vaultProtocol = this.getVaultProtocolAddress(vault);
            remainingAccounts.push({
                pubkey: vaultProtocol,
                isSigner: false,
                isWritable: true,
            });
        }
        const spotMarket = this.driftClient.getSpotMarketAccount(vaultAccount.spotMarketIndex);
        if (!spotMarket) {
            throw new Error(`Spot market ${vaultAccount.spotMarketIndex} not found on driftClient`);
        }
        const ix = this.program.instruction.managerWithdraw({
            accounts: {
                vault,
                manager: this.driftClient.wallet.publicKey,
                vaultTokenAccount: vaultAccount.tokenAccount,
                driftUser: await (0, sdk_1.getUserAccountPublicKey)(this.driftClient.program.programId, vault),
                driftProgram: this.driftClient.program.programId,
                driftUserStats: (0, sdk_1.getUserStatsAccountPublicKey)(this.driftClient.program.programId, vault),
                driftState: await this.driftClient.getStatePublicKey(),
                driftSpotMarketVault: spotMarket.vault,
                userTokenAccount: (0, spl_token_1.getAssociatedTokenAddressSync)(spotMarket.mint, this.driftClient.wallet.publicKey),
                driftSigner: this.driftClient.getStateAccount().signer,
                tokenProgram: spl_token_1.TOKEN_PROGRAM_ID,
            },
            remainingAccounts,
        });
        return this.createAndSendTxn([ix], {
            cuLimit: 1000000,
        });
    }
}
exports.VaultClient = VaultClient;
