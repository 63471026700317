'use client';

import { getMaxNumberOfSubAccounts } from '@drift-labs/sdk';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';

export const useMaxSubaccountsReached = () => {
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	if (!driftClientIsReady) {
		return false;
	}

	const stateAccount = driftClient?.getStateAccount();

	const maxSubaccountReached =
		stateAccount?.numberOfSubAccounts.gte(
			getMaxNumberOfSubAccounts(stateAccount)
		) ?? false;

	return maxSubaccountReached;
};
