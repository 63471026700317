'use client';

import { useEffect, useState } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import WalletConnectionState from '../utils/WalletConnectionState';
import { singletonHook } from 'react-singleton-hook';

type WalletStateData = {
	instance: WalletConnectionState;
};

/**
 * This hook wraps the current wallet state in the store to make it easily accessible and useful
 * @returns
 */
const useCurrentWalletConnectionState_ = (): WalletStateData => {
	const walletConnectionState = useDriftStore((s) => s.wallet.connectionState);
	const walletConnectionStateVal = useDriftStore(
		(s) => s.wallet.connectionState.state
	);

	const currentAuthorityConnectionState = walletConnectionState;

	const updateStateData = () => ({
		instance: currentAuthorityConnectionState,
	});

	const [stateData, setStateData] = useState(updateStateData());

	useEffect(() => {
		const newStateData = updateStateData();
		setStateData(newStateData);
	}, [walletConnectionStateVal, currentAuthorityConnectionState.state]);

	return stateData;
};

const useCurrentWalletConnectionState = singletonHook(
	{ instance: new WalletConnectionState() },
	useCurrentWalletConnectionState_
);

export default useCurrentWalletConnectionState;
