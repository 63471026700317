'use client';

import React from 'react';
import { useTheme } from 'next-themes';

const SkeletonValuePlaceholder = (props: {
	className?: string;
	loading?: boolean;
}) => {
	const { theme } = useTheme();
	return (
		<div
			className={`skeleton-container-${theme} ${props.className ?? ''} ${
				props.loading ? `skeleton-animate-${theme}` : ''
			}`}
		></div>
	);
};

export default SkeletonValuePlaceholder;
