import { Magic } from 'magic-sdk';
import { SolanaExtension } from '@magic-ext/solana';
import {
	PublicKey,
	SignaturePubkeyPair,
	TransactionVersion,
} from '@solana/web3.js';
import Env from 'src/environmentVariables/EnvironmentVariables';
import {
	BaseMessageSignerWalletAdapter,
	WalletName,
	WalletReadyState,
} from '@solana/wallet-adapter-base';
import {
	DRIFT_CUSTOM_WALLET_ICONS,
	DRIFT_CUSTOM_WALLET_OPTIONS,
} from 'src/constants/wallets';

export interface MagicAuthLoginOptions {
	email?: string;
	socialPlatform?: 'google' | 'twitter' | 'apple';
}

export class MagicAuthAdapter extends BaseMessageSignerWalletAdapter {
	signMessage(_message: Uint8Array): Promise<Uint8Array> {
		throw new Error('Method not implemented.');
	}
	url: string;
	readyState: WalletReadyState;
	connecting: boolean;
	supportedTransactionVersions?: ReadonlySet<TransactionVersion>;

	public name =
		DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME as WalletName;
	public icon = DRIFT_CUSTOM_WALLET_ICONS.MAGIC_AUTH_WALLET_ADAPTER;

	// The type for magic is "InstanceWithExtensions" from magic-sdk
	// But they didn't export that type from the package :(
	private magic: any;

	public publicKey: PublicKey;
	public checkingLoginStatus: boolean;
	public isLoggedIn: boolean;
	public autoApprove = true;

	public constructor(rpcUrl) {
		super();

		this.magic = new Magic(Env.magicAuthApiKey, {
			extensions: [
				new SolanaExtension({
					rpcUrl,
				}),
			],
		});

		this.updateIsLoggedIn();
	}

	public async updateIsLoggedIn() {
		this.checkingLoginStatus = true;
		this.isLoggedIn = await this.magic.user.isLoggedIn();
		this.checkingLoginStatus = false;
	}

	public async signTransaction(tx) {
		const serializeConfig = {
			requireAllSignatures: false,
			verifySignatures: true,
		};

		const { signature } = await this.magic?.solana?.signTransaction(
			tx,
			serializeConfig
		);

		// The above signTransaction serializes the tx already, which we don't want to happen yet here, so just add the signatures it generated to the original tx and return that
		signature?.forEach(({ signature }: SignaturePubkeyPair) => {
			if (signature) {
				tx.addSignature(this.publicKey, signature);
			}
			// Also, retryTxSender.ts in the sdk expects the tx to be modified in place when the wallet signs it instead of getting a new one returned, so this also avoids needing to update the sdk
		});

		return tx;
	}

	public async signAllTransactions(txes) {
		const signedTxes = await Promise.all(
			txes.map(async (tx) => await this.signTransaction(tx))
		);
		return signedTxes;
	}

	// Extra step required for this adapter:
	// If the user selected magic as the wallet, instead of just calling connect, you have to allow the user to either enter their email or click one of the social login buttons
	// After they do that, then call connect and pass in the results (e.g. their email address or social auth token)
	public connect = async (options?: MagicAuthLoginOptions) => {
		let metaData;

		if (options.email) {
			const emailToSend = options.email.replace(/\+[^@]*/, '');
			await this.magic.auth.loginWithEmailOTP({
				// showUI: false,
				email: emailToSend,
			});
			metaData = await this.magic.user.getMetadata();
		} else if (options.socialPlatform) {
			await this.magic.oauth.loginWithRedirect({
				provider: options.socialPlatform,
				redirectURI: Env.oAuthRedirectURI,
			});
			const oAuthResult = await this.magic.oauth.getRedirectResult();
			metaData = oAuthResult?.magic?.userMetadata;
		}

		const newPubkey = new PublicKey(metaData.publicAddress);
		this.publicKey = newPubkey;
		this.emit('connect', newPubkey);

		return metaData;
	};

	public disconnect = async () => {
		await this.magic?.user?.logout();
		window.localStorage?.removeItem('preferredWallet');
		this.publicKey = undefined;
		this.isLoggedIn = false;
		this.emit('disconnect');
	};
}

const createMagicWalletAdapter = (rpcUrl: string) => {
	const adapter = new MagicAuthAdapter(rpcUrl);
	return adapter;
};

export default createMagicWalletAdapter;
