import { memo } from 'react';

function LedgerImage() {
	return (
		<div className="h-full relative w-full text-center">
			<div className="w-[90%] max-w-[380px] h-full bg-[url('/assets/images/ledger.webp')] bg-contain bg-no-repeat bg-center mx-auto"></div>
		</div>
	);
}

export default memo(LedgerImage);
