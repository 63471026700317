import {
	BigNum,
	BN,
	PERCENTAGE_PRECISION_EXP,
	PublicKey,
	QUOTE_PRECISION_EXP,
	ReferrerNameAccount,
	SPOT_MARKET_BALANCE_PRECISION_EXP,
	SpotBalanceType,
	SpotMarketConfig,
	User,
	ZERO,
} from '@drift-labs/sdk';
import {
	HistoryResolution,
	UIMatchedOrderRecordAndAction,
	UISerializableAccountSnapshot,
	UISerializableDepositRecord,
	UISerializableFundingPaymentRecord,
	UISerializableLiquidationRecord,
	UISerializableOrder,
	UISerializableOrderActionRecord,
	UISerializableSettlePnlRecord,
	UISerializableSwapRecord,
	UISnapshotHistory,
	UISerializableLPRecord,
	UISerializableInsuranceFundStakeRecord,
	OpenPosition,
	ENUM_UTILS,
	PropertyAndType,
	EQUALITY_CHECKS,
	DownloadFile,
} from '@drift/common';
import { PnLTimeSeriesDataPoint } from 'src/hooks/usePnlHistory';
import produce from 'immer';
import { create } from 'zustand';
import UI_UTILS from '../utils/uiUtils';
import { MarginInfo } from './driftStoreTypes';
import { LPOpenOrderData } from 'src/hooks/useOpenOrdersData';

const generateInitialSnapshot = (
	depositAmount: number
): UISerializableAccountSnapshot => ({
	authority: PublicKey.default,
	user: PublicKey.default,
	epochTs: UI_UTILS.nowTs(),
	totalAccountValue: BigNum.from(depositAmount, QUOTE_PRECISION_EXP),
	cumulativeDepositQuoteValue: BigNum.from(depositAmount, QUOTE_PRECISION_EXP),
	cumulativeWithdrawalQuoteValue: BigNum.zero(QUOTE_PRECISION_EXP),
	allTimeTotalPnl: BigNum.zero(QUOTE_PRECISION_EXP),
	allTimeTotalPnlPct: BigNum.zero(PERCENTAGE_PRECISION_EXP),
	cumulativeReferralReward: BigNum.zero(QUOTE_PRECISION_EXP),
	cumulativeReferralVolume: BigNum.zero(QUOTE_PRECISION_EXP),
	cumulativeReferralCount: 0,
	cumulativeSettledPerpPnl: BigNum.zero(QUOTE_PRECISION_EXP),
});

export type AccountSpotBalance = {
	asset: SpotMarketConfig;
	balance: BigNum;
	balanceType: SpotBalanceType;
	quoteValue: BigNum;
	accountId: number;
	accountName: string;
	accountKey: string;
};

export const INITIAL_MARGIN_INFO = (): MarginInfo => {
	return {
		collateral: ZERO,
		unrealisedPnL: undefined,
		unrealisedFundingPnL: ZERO,
		totalCollateral: ZERO,
		totalMaintenanceCollateral: ZERO,
		freeCollateral: ZERO,
		maintenanceFreeCollateral: ZERO,
		leverage: 0,
		marginRatioPct: 0,
		maintenanceReq: ZERO,
		initialReq: ZERO,
		totalUnsettledPnl: ZERO,
		netUsdValue: ZERO,
		quoteInOpenOrders: ZERO,
		quoteInLpOrders: ZERO,
	};
};

export const ACCOUNT_INITIAL_STATE = (
	userKey?: string,
	opts?: {
		initialDepositAmount?: number;
	}
): AccountData => {
	return {
		userKey: userKey ?? null,
		userId: null,
		name: '',
		pubKey: null,
		authority: null,
		isSubscribed: false,
		client: null,
		marginInfo: INITIAL_MARGIN_INFO(),
		openPerpPositions: [],
		openOrders: [],
		openLpOrders: [],
		tradeHistory: {
			loadedUserTradeHistory: [],
			userTradeHistoryTotalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
		},
		predictionsTradeHistory: {
			loadedUserTradeHistory: [],
			userTradeHistoryTotalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
		},
		orderHistory: {
			loadedUserOrderHistory: [],
			userOrderHistoryTotalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
			marketOrderCounts: {
				perp: 0,
				spot: 0,
			},
			maxRecordLimit: 1000,
		},
		predictionsOrderHistory: {
			loadedUserOrderHistory: [],
			userOrderHistoryTotalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
			marketOrderCounts: {
				prediction: 0,
			},
			maxRecordLimit: 1000,
		},
		fundingHistory: {
			records: [],
			totalCount: 0,
			loading: false,
			cumulative: 0,
			initialHistoryLoaded: false,
		},
		liquidationHistory: {
			liquidations: [],
			totalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
		},
		depositWithdrawalHistory: {
			depositsWithdrawals: [],
			totalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
			maxRecordLimit: 1000,
		},
		swapHistory: {
			swaps: [],
			totalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
			maxRecordLimit: 1000,
		},
		settlePnlHistory: {
			settlePnls: [],
			totalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
		},
		lpHistory: {
			lpRecords: [],
			totalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
		},
		ifStakeHistory: {
			ifStakeRecords: [],
			totalCount: 0,
			loading: false,
			initialHistoryLoaded: false,
			maxRecordLimit: 1000,
		},
		pnlHistory: {
			[HistoryResolution.DAY]: opts?.initialDepositAmount
				? [generateInitialSnapshot(opts.initialDepositAmount)]
				: [],
			[HistoryResolution.WEEK]: opts?.initialDepositAmount
				? [generateInitialSnapshot(opts.initialDepositAmount)]
				: [],
			[HistoryResolution.MONTH]: opts?.initialDepositAmount
				? [generateInitialSnapshot(opts.initialDepositAmount)]
				: [],
			[HistoryResolution.ALL]: opts?.initialDepositAmount
				? [generateInitialSnapshot(opts.initialDepositAmount)]
				: [],
			dailyAllTimePnls: [],
			initialHistoryLoaded: false,
		},
		snapshotHistory: undefined,
		currentPnlPoint: null,
		latestSnapshot: null,
		pnlInfo: {
			leverageBeforeClose: null,
			collateralBeforeClose: null,
		},
		positionsLoaded: false,
		ordersLoaded: false,
		balanceCount: 0,
		marginEnabled: false,
		delegate: undefined,
		spotBalances: [],
		rent: {
			amount: BigNum.zero(SPOT_MARKET_BALANCE_PRECISION_EXP),
			loaded: false,
		},
	};
};

const EMPTY_BALANCES = [];

export type AccountData = {
	userKey: string;
	userId: number;
	name: string;
	pubKey: PublicKey;
	authority: PublicKey;
	isSubscribed: boolean;
	client: User | undefined;
	marginInfo: MarginInfo;
	openPerpPositions: OpenPosition[];
	openOrders: UISerializableOrder[];
	openLpOrders: LPOpenOrderData[];
	tradeHistory: {
		loadedUserTradeHistory: UISerializableOrderActionRecord[];
		userTradeHistoryTotalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
	};
	predictionsTradeHistory: {
		loadedUserTradeHistory: UISerializableOrderActionRecord[];
		userTradeHistoryTotalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
	};
	orderHistory: {
		loadedUserOrderHistory: UIMatchedOrderRecordAndAction[];
		userOrderHistoryTotalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
		marketOrderCounts: {
			spot: number;
			perp: number;
		};
		maxRecordLimit: number;
	};
	predictionsOrderHistory: {
		loadedUserOrderHistory: UIMatchedOrderRecordAndAction[];
		userOrderHistoryTotalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
		marketOrderCounts: {
			prediction: number;
		};
		maxRecordLimit: number;
	};
	fundingHistory: {
		records: UISerializableFundingPaymentRecord[];
		totalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
		cumulative: number;
	};
	liquidationHistory: {
		liquidations: UISerializableLiquidationRecord[];
		totalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
	};
	depositWithdrawalHistory: {
		depositsWithdrawals: UISerializableDepositRecord[];
		totalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
		maxRecordLimit: number;
	};
	swapHistory: {
		swaps: UISerializableSwapRecord[];
		totalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
		maxRecordLimit: number;
	};
	settlePnlHistory: {
		settlePnls: UISerializableSettlePnlRecord[];
		totalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
	};
	lpHistory: {
		lpRecords: UISerializableLPRecord[];
		totalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
	};
	ifStakeHistory: {
		ifStakeRecords: UISerializableInsuranceFundStakeRecord[];
		totalCount: number;
		loading: boolean;
		initialHistoryLoaded: boolean;
		maxRecordLimit: number;
	};
	pnlHistory: UISnapshotHistory & { initialHistoryLoaded: boolean };
	snapshotHistory: UISnapshotHistory;
	currentPnlPoint: PnLTimeSeriesDataPoint;
	latestSnapshot: UISerializableAccountSnapshot;
	pnlInfo: {
		leverageBeforeClose: number | null;
		collateralBeforeClose: BN | null;
	};
	positionsLoaded: boolean;
	ordersLoaded: boolean;
	balanceCount: number;
	marginEnabled: boolean;
	delegate: PublicKey;
	isDelegatedTo?: boolean;
	isDelegatedAway?: boolean;
	spotBalances: AccountSpotBalance[];
	rent: { amount: BigNum; loaded: boolean };
};

export interface DriftAccountsStore {
	set: (x: (s: DriftAccountsStore) => void) => void;
	get: () => DriftAccountsStore;
	currentUserKey: string;
	currentUserExists: boolean;
	accounts: { [userKey: string]: AccountData };
	/**
	 * Referrer names created by the current authority
	 * Because only subaccount 0 can create these, not putting it under each account.
	 */
	referrerNameAccounts: {
		accounts: (ReferrerNameAccount & {
			decodedName: string;
		})[];
		// No total count because it's probably not important?
		// But we can add it later if needed
		loading: boolean;
		initialReferrerNameAccountsLoaded: boolean;
	};
	referredAccounts: {
		accounts: PublicKey[];
		loading: boolean;
		initialReferredAccountsLoaded: boolean;
	};
	currentPointsTs: number;
	userAccountNotInitialized: boolean;
	downloadFiles: {
		initialHistoryLoaded: boolean;
		newAvailableDetected: boolean;
		records: { pending: DownloadFile[]; available: DownloadFile[] };
		counts: {
			pending: number;
			available: number;
		};
	};
	getAggregateAccountsSpotBalances: () => AccountSpotBalance[];
	// TODO: this can actual be undefined
	getCurrentUserAccount: () => AccountData;
}

const INITIAL_ACCOUNTS_STATE = {
	currentUserKey: '',
	currentUserExists: false,
	accounts: {},
	referrerNameAccounts: {
		accounts: [],
		loading: false,
		initialReferrerNameAccountsLoaded: false,
	},
	referredAccounts: {
		accounts: [],
		loading: false,
		initialReferredAccountsLoaded: false,
	},
	currentPointsTs: 0,
	userAccountNotInitialized: false,
	downloadFiles: {
		initialHistoryLoaded: false,
		newAvailableDetected: false,
		records: { pending: [], available: [] },
		counts: {
			pending: 0,
			available: 0,
		},
	},
};

const useDriftAccountStore = create<DriftAccountsStore>((set, get) => {
	return {
		set: (fn) => {
			set(produce(fn));
		},
		get: () => get(),
		...INITIAL_ACCOUNTS_STATE,
		getAggregateAccountsSpotBalances: () => {
			const allAccountsBalances = Object.values(get().accounts)
				.map((account) => account.spotBalances)
				.flat();

			return allAccountsBalances.length > 0
				? allAccountsBalances
				: EMPTY_BALANCES;
		},
		getCurrentUserAccount: () => {
			return get().accounts[get().currentUserKey];
		},
	};
});

export const checkIfMarginInfosAreEqual = (
	marginInfo1: MarginInfo,
	marginInfo2: MarginInfo
): boolean => {
	const propertiesToCompare: PropertyAndType<keyof MarginInfo>[] = [
		['netUsdValue', 'bn'],
		['collateral', 'bn'],
		['unrealisedPnL', 'bn'],
		['unrealisedFundingPnL', 'bn'],
		['totalCollateral', 'bn'],
		['totalMaintenanceCollateral', 'bn'],
		['freeCollateral', 'bn'],
		['maintenanceFreeCollateral', 'bn'],
		['leverage', 'primitive'],
		['marginRatioPct', 'primitive'],
		['maintenanceReq', 'bn'],
		['initialReq', 'bn'],
		['totalUnsettledPnl', 'bn'],
		['quoteInOpenOrders', 'bn'],
		['quoteInLpOrders', 'bn'],
		['advancedLp', 'primitive'],
	];

	return EQUALITY_CHECKS.arePropertiesEqual(
		marginInfo1,
		marginInfo2,
		propertiesToCompare
	);
};

const checkIfTwoAccountSpotBalanceAreEqual = (
	balance1: AccountSpotBalance,
	balance2: AccountSpotBalance
): boolean => {
	return (
		balance1.asset.marketIndex === balance2.asset.marketIndex &&
		balance1.balance.eq(balance2.balance) &&
		ENUM_UTILS.match(balance1.balanceType, balance2.balanceType) &&
		balance1.quoteValue.eq(balance2.quoteValue) &&
		balance1.accountId === balance2.accountId &&
		balance1.accountName === balance2.accountName &&
		balance1.accountKey === balance2.accountKey
	);
};

export const checkIfAccountsSpotBalancesAreEqual = (
	balances1: AccountSpotBalance[],
	balances2: AccountSpotBalance[]
): boolean => {
	if (balances1.length !== balances2.length) {
		return false;
	}

	return balances1.every((balance1, index) => {
		return checkIfTwoAccountSpotBalanceAreEqual(balance1, balances2[index]);
	});
};

export default useDriftAccountStore;
