import { DRIFT_WALLET_CONNECT_PROJECT_ID } from '../constants/constants';
import { createWeb3Modal, defaultSolanaConfig } from '@web3modal/solana/react';
import { solana, solanaDevnet } from '@web3modal/solana/chains';
import { DriftEnv } from '@drift-labs/sdk';
import Env from 'src/environmentVariables/EnvironmentVariables';

const setupAppKitWallet = (_env: DriftEnv) => {
	const chains = [solanaDevnet, solana];

	const metadata = {
		name: 'Drift Protocol',
		description:
			'Drift is the leading perpetuals DEX on Solana with the most CEX-like trader experience',
		url: 'https://app.drift.trade/',
		icons: [
			'https://drift-public.s3.eu-central-1.amazonaws.com/DriftLogoCircular.png',
		],
	};

	const solanaConfig = defaultSolanaConfig({
		metadata,
		chains,
		projectId: DRIFT_WALLET_CONNECT_PROJECT_ID,
		auth: {
			email: true,
			socials: [
				'google',
				'x',
				'discord',
				'farcaster',
				'github',
				'apple',
				'facebook',
			],
			walletFeatures: true,
			showWallets: false,
		},
	});

	createWeb3Modal({
		solanaConfig,
		projectId: DRIFT_WALLET_CONNECT_PROJECT_ID,
		// metadata: ConstantsUtil.Metadata,
		chains,
		enableAnalytics: false,
		termsConditionsUrl: 'https://walletconnect.com/terms',
		privacyPolicyUrl: 'https://walletconnect.com/privacy',
		// customWallets: ConstantsUtil.CustomWallets,
		enableSwaps: false,
	});
};

setupAppKitWallet(Env.sdkEnv);
