export const XLARGE = 1600;
export const LARGE = 1250;
export const MEDIUM = 1100;
export const SMALL = 760;
export const XSMALL = 0;

const BREAKPOINTS = {
	XLARGE,
	LARGE,
	MEDIUM,
	SMALL,
	XSMALL,
};

export default BREAKPOINTS;
