'use client';

import useLocalStorageState from './useLocalStorageState';

const usePrefferredWalletSetting = () => {
	const prefferedWalletSetting = useLocalStorageState('walletName', '');

	return prefferedWalletSetting;
};

export default usePrefferredWalletSetting;
