'use client';

import { useEffect } from 'react';
import useCurrentWalletAdapter from './useCurrentWalletAdapter';
import useCurrentWalletConnectionState from './useCurrentWalletConnectionState';
import { DriftWindow } from '../window/driftWindow';

const useWalletIsConnected = (props?: {
	onConnectedCallback?: () => void;
	onDisconnectedCallback?: () => void;
}) => {
	try {
		const currentWallet = useCurrentWalletAdapter();
		const walletConnectionState = useCurrentWalletConnectionState();
		const walletIsConnected =
			currentWallet?.publicKey &&
			currentWallet?.connected &&
			walletConnectionState.instance.FullyConnected;

		useEffect(() => {
			if (walletIsConnected) {
				DriftWindow.recordStartupTimeMetric('userFullyConnected');
				props?.onConnectedCallback?.();
				return;
			}
			if (!walletIsConnected) {
				props?.onDisconnectedCallback?.();
				return;
			}
		}, [walletIsConnected]);

		return walletIsConnected;
	} catch (err) {
		console.log(err);
		return false;
	}
};

/**
 * Used to check if the wallet adapter is connected.
 * Does not check if balances and subaccount subscriptions are loaded.
 */
export const useWalletAdapterIsConnected = () => {
	const walletConnectionState = useCurrentWalletConnectionState();

	return walletConnectionState.instance.is('AdapterConnected');
};

export default useWalletIsConnected;
