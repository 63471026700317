'use client';

import useDriftTheme from 'src/hooks/useDriftTheme';
import Env, { DriftTheme } from '../environmentVariables/EnvironmentVariables';
import { allColors } from 'src/constants/figmaGenerated';
import tailwindConfig from 'tailwind.config';
import useIsMainnet from 'src/hooks/useIsMainnet';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { useEffect } from 'react';

const WORMHOLE_PKG_VERSION = Env.wormholePackageVersion;

import type {
	ChainName,
	PaletteColor,
	WormholeConnectConfig,
	Theme,
} from '@wormhole-foundation/wormhole-connect';
import SkeletonValuePlaceholder from './SkeletonValuePlaceholder/SkeletonValuePlaceholder';

// Don't know how the PaletteColor type works... should be documented?
// It doesn't match the color type from MUI docs.
// When all the keys are the same value, it looks okay so far...
const PaletteColorKeys = [
	'50',
	'100',
	'200',
	'300',
	'400',
	'500',
	'600',
	'700',
	'800',
	'900',
	'A100',
	'A200',
	'A400',
	'A700',
];

const makePaletteColor = (color: string) => {
	const paletteColor = {};
	PaletteColorKeys.forEach((key) => {
		paletteColor[key] = color;
	});
	return paletteColor as PaletteColor;
};

const fontFamily = tailwindConfig.theme.fontFamily.display.join(',');

const customThemeLight: Theme = {
	primary: makePaletteColor(allColors.lightBlue[90]),
	secondary: makePaletteColor(allColors.neutrals[50]),
	divider: allColors.lightBlue[20],
	background: {
		default: allColors.neutrals[0],
	},
	text: {
		primary: allColors.lightBlue[90],
		secondary: allColors.neutrals[50],
	},
	error: makePaletteColor(allColors.red[70]),
	info: makePaletteColor(allColors.neutrals[0]),
	success: makePaletteColor(allColors.green[40]),
	warning: makePaletteColor(allColors.yellow[50]),
	button: {
		primary: allColors.neutrals[0],
		primaryText: allColors.lightBlue[60],
		disabled: allColors.neutrals[0],
		disabledText: allColors.lightBlue[40],
		action: allColors.neutrals[0],
		actionText: allColors.lightBlue[60],
		hover: allColors.neutrals[0],
	},
	options: {
		hover: allColors.lightBlue[10],
		select: allColors.lightBlue[10],
	},
	card: {
		background: allColors.lightBlue[10],
		secondary: allColors.lightBlue[0],
		elevation: 'none',
	},
	popover: {
		background: allColors.lightBlue[10],
		secondary: allColors.lightBlue[0],
		elevation: 'none',
	},
	modal: {
		background: 'rgba(255,255,255,0.5)',
	},
	font: {
		primary: fontFamily,
		header: fontFamily,
	},
};

const customThemeDark: Theme = {
	primary: makePaletteColor(allColors.neutrals[20]),
	secondary: makePaletteColor(allColors.neutrals[30]),
	divider: allColors.darkBlue[60],
	background: {
		default: allColors.darkBlue[100],
	},
	text: {
		primary: allColors.neutrals[20],
		secondary: allColors.neutrals[30],
	},
	error: makePaletteColor(allColors.red[50]),
	info: makePaletteColor(allColors.darkBlue[60]),
	success: makePaletteColor(allColors.green[60]),
	warning: makePaletteColor(allColors.yellow[60]),
	button: {
		primary: allColors.darkBlue[60],
		primaryText: allColors.neutrals[20],
		disabled: allColors.darkBlue[60],
		disabledText: allColors.neutrals[60],
		action: allColors.darkBlue[60],
		actionText: allColors.neutrals[20],
		hover: allColors.darkBlue[50],
	},
	options: {
		hover: allColors.darkBlue[50],
		select: allColors.darkBlue[50],
	},
	card: {
		background: allColors.darkBlue[90],
		secondary: allColors.darkBlue[60],
		elevation: 'none',
	},
	popover: {
		background: allColors.darkBlue[90],
		secondary: allColors.darkBlue[60],
		elevation: 'none',
	},
	modal: {
		background: 'rgba(0,0,0,0.5)',
	},
	font: {
		primary: fontFamily,
		header: fontFamily,
	},
};

function WormholeWidget() {
	const rpcUrl = useDriftStore((s) => s.rpcUrl);
	const isMainnet = useIsMainnet();
	const isLightTheme = useDriftTheme() === DriftTheme.light;

	const config: WormholeConnectConfig = {
		env: isMainnet ? 'mainnet' : 'testnet',
		networks: (isMainnet
			? ['ethereum', 'solana']
			: ['goerli', 'solana']) as ChainName[],
		tokens: isMainnet
			? ['USDCeth', 'USDT', 'ETH', 'WBTC']
			: ['ETH', 'WETH', 'USDC'],
		mode: isLightTheme ? 'light' : 'dark',
		customTheme: isLightTheme ? customThemeLight : customThemeDark,
		rpcs: {
			solana: rpcUrl,
		},
	};

	// Add script tags only when wormhole widget is rendered, to avoid downloading 11 mb bundle on every page load
	// Also this is adding a new script/css every time this component gets rendered! Bad!
	useEffect(() => {
		if (!rpcUrl) {
			return;
		}

		const script = document.createElement('script');
		script.src = `https://www.unpkg.com/@wormhole-foundation/wormhole-connect@${WORMHOLE_PKG_VERSION}/dist/main.js`;
		script.async = true;

		document.body.appendChild(script);

		return () => {
			try {
				document.body.removeChild(script);
			} catch (err) {
				// Can get here while hot reloading but should not be relevant in production
			}
			try {
				document
					.querySelector('head')
					.removeChild(document.querySelector('[data-emotion="css-global"]'));
			} catch (err) {
				// hopefully we don't get here
			}
		};
	}, [rpcUrl]);

	return (
		// @ts-ignore
		<div
			id="wormhole-connect"
			// @ts-ignore
			config={JSON.stringify(config)}
			className="w-full sm:w-[600px] max-w-full min-h-[925px]"
		>
			<div className="flex flex-col justify-center items-center w-full h-[925px]">
				<SkeletonValuePlaceholder loading className="w-[50%] h-2" />
			</div>
		</div>
	);
}

export default WormholeWidget;
