import { UIOrderType } from '@drift/common';
import { MarketType } from '@drift-labs/sdk';

type DepositToTradeConfig = {
	enabledOrderTypes: UIOrderType[];
	enabledMarketTypes: MarketType[];
};

export const DEPOSIT_TO_TRADE_CONFIG: DepositToTradeConfig = {
	enabledOrderTypes: ['limit', 'market'],
	enabledMarketTypes: [MarketType.PERP],
};
