import produce from 'immer';
import { create } from 'zustand';
import { PriceInfo } from './types';
import { MarketId } from '@drift/common';

export interface OraclePriceStore {
	set: (x: (s: OraclePriceStore) => void) => void;
	get: (x: any) => OraclePriceStore;
	getMarketPriceData: (market: MarketId) => PriceInfo;
	symbolMap: {
		[index: string]: PriceInfo;
	};
}

const useOraclePriceStore = create<OraclePriceStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	getMarketPriceData: (market: MarketId) => get().symbolMap[market.key],
	symbolMap: {},
}));

export default useOraclePriceStore;
