import useLocalStorageState from './useLocalStorageState';
import { singletonHook } from 'react-singleton-hook';

export enum TradeMode {
	PRO = 'pro',
	LITE = 'lite',
}

const useTradeModeSetting = () =>
	useLocalStorageState<TradeMode>('tradeMode', TradeMode.PRO);

export default singletonHook([undefined, undefined], useTradeModeSetting);
