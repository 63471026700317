'use client';

import Button from 'src/components/Button';
import Text from 'src/components/Text/Text';
import useIsMobile from 'src/hooks/useIsMobileScreenSize';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type TableActionButtonProps = {
	handleClick: () => void;
	label: string | ReactNode;
	positive: boolean;
	neutral?: boolean;
	key?: string;
	id?: string;
	hideOnMobile?: boolean;
	disabled?: boolean;
	className?: string;
	buttonClassName?: string;
};

const TableActionButton = React.forwardRef<
	HTMLDivElement,
	TableActionButtonProps &
		React.DetailedHTMLProps<
			React.HTMLAttributes<HTMLDivElement>,
			HTMLDivElement
		>
>(
	(
		{
			handleClick,
			label,
			positive,
			neutral,
			key,
			id,
			hideOnMobile,
			disabled,
			className,
			buttonClassName,
			...props
		},
		ref
	) => {
		const isMobile = useIsMobile();

		if (hideOnMobile && isMobile) return null;

		return (
			<div
				ref={ref}
				key={key}
				className={twMerge(
					`flex items-center justify-center px-2 py-2 text-xs`,
					className
				)}
				{...props}
			>
				<Button.Secondary
					size="SMALL"
					className={twMerge(
						neutral
							? undefined
							: `flex items-center justify-between border-none px-4 py-1 font-display`,
						buttonClassName
					)}
					onClick={(e) => {
						e.stopPropagation();
						handleClick();
					}}
					negativeRed={!neutral && !positive}
					positiveGreen={!neutral && positive}
					id={id}
					disabled={disabled}
				>
					<Text.H5>{label}</Text.H5>
				</Button.Secondary>
			</div>
		);
	}
);

TableActionButton.displayName = 'TableActionButton';

export default TableActionButton;
