'use client';

import { useTheme } from 'next-themes';
import { memo, useEffect } from 'react';
import {
	DRIFT_MAYAN_REFERRER_ADDRESS,
	PageRoute,
} from 'src/constants/constants';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariables';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import useDriftActions from 'src/hooks/useDriftActions';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useSafePush from 'src/hooks/useSafePush';
import { notify } from 'src/utils/notifications';
import { getRpcToUse } from 'src/utils/rpcUtils/rpcUtils_api';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';

/**
 * Documentation for Mayan widget can be found here.
 *
 * https://docs.mayan.finance/integration/swap-widget
 */

/** Styles other than colors can be edited in mayan.css */
const DARK_THEME_COLORS = {
	mainBox: 'var(--main-bg)',
	primary: '#132236', // buttons that are not toggled yet; using color variables lead to an error; --button-secondary-bg
	primaryGradient: '#132236', // explorer button; using color variables lead to an error; --button-secondary-bg
	N900: '#d4d4d8', // text color
	N700: 'var(--text-default)', // arrow color
	// N600: '', // token amount input text
	N500: 'var(--container-bg-hover)', // button hovers
	N300: 'var(--button-secondary-bg)', // buttons that are already toggled
	N100: 'var(--container-bg)', // secondary pages background
	N000: 'var(--container-bg)', // txn submitted page background
	green: 'var(--positive-green)', // success message
};

const LIGHT_THEME_COLORS = {
	mainBox: 'var(--main-bg)',
	primary: '#ffffff', // buttons that are not toggled yet; using color variables lead to an error; --button-secondary-bg
	primaryGradient: '#ffffff', // explorer button; using color variables lead to an error; --button-secondary-bg
	N900: '#0b1d38', // text color
	N700: 'var(--text-default)', // arrow color
	// N600: '', // token amount input text
	N500: 'var(--container-bg-hover)', // button hovers
	N300: 'var(--container-bg-hover)', // buttons that are already toggled
	N100: 'var(--container-bg)', // secondary pages background
	N000: 'var(--container-bg)', // txn submitted page background
	green: 'var(--positive-green)', // success message
};

export const SWAP_TO_TOKENS_WHITELIST = [
	'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v', // usdc
	'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB', // native USDT
	'3NZ9JMVBmGAqocybic2c7LQCJScmgsAZ6vQqTDzcqmJh', // portal wrapped BTC
	'7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs', // portal wrapped ETH
	'0x0000000000000000000000000000000000000000', // native sol
];

const MAYAN_CONFIG = {
	appIdentity: {
		name: 'Swap',
		icon: './logo.webp',
		uri: 'https://app.drift.trade',
	},
	sourceChains: ['ethereum', 'arbitrum', 'bsc'],
	destinationChains: ['solana'],
	defaultGasDrop: {
		solana: 0.05,
	},
	rpcs: {
		solana: process.env.NEXT_PUBLIC_DEV_MAINNET_RPC,
		ethereum: 'https://rpc.ankr.com/eth',
		arbitrum: 'https://1rpc.io/arb',
		bsc: 'https://bsc-dataseed1.binance.org/',
	},
	tokens: {
		from: {
			ethereum: [
				'0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', // usdc
				'0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', // WBTC
				'0xdac17f958d2ee523a2206206994597c13d831ec7', // USDT
				'0xD31a59c85aE9D8edEFeC411D448f90841571b89c', // portal wrapped SOL
				'0x0000000000000000000000000000000000000000', // ethereum
			],
			arbitrum: ['0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8'], // usdc
			bsc: ['0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d'], // for testing purposes only
		},
		to: {
			solana: SWAP_TO_TOKENS_WHITELIST,
		},
	},
	colors: DARK_THEME_COLORS,
	destinationWallets: {},
	referrerAddress: DRIFT_MAYAN_REFERRER_ADDRESS,
};

const MayanWidget = ({
	setSwapInfo,
}: {
	setSwapInfo: ({ hash, toToken }: { hash: string; toToken: string }) => void;
}) => {
	const theme = useTheme().theme;
	const isDarkMode = theme === DriftTheme.dark;
	const actions = useDriftActions();
	const safePush = useSafePush();
	const { captureEvent } = usePostHogCapture();
	const wallet = useCurrentWalletAdapter();

	const initMayan = () => {
		// @ts-ignore
		window.MayanSwap?.init('swap_widget', MAYAN_CONFIG);

		// @ts-ignore
		window.MayanSwap?.setSwapInitiateListener((swapInfo) => {
			captureEvent('initiated_mayan_swap', swapInfo);
			return setSwapInfo({ hash: swapInfo.hash, toToken: swapInfo.toToken });
		});

		// @ts-ignore
		window.MayanSwap?.setSwapCompleteListener(() =>
			notify({
				id: 'checkSuccessfulMayanSwap',
				type: 'success',
				message: 'Your cross-chain swap is complete!',
				description: `Deposit collateral to start trading today.`,
				action: {
					label: 'Deposit',
					callback: () => {
						// need to use window object here instead of next's router because the router will not be updated if this component unmounts
						const route = window.location.href.split(window.location.host)[1];
						route.includes(PageRoute.bridge) && safePush('/');
						actions.showModal('showDepositModal');
					},
				},
				showUntilCancelled: true,
			})
		);
	};

	useEffect(() => {
		const url = getRpcToUse().value;
		MAYAN_CONFIG.rpcs.solana = url;
		MAYAN_CONFIG.colors = isDarkMode ? DARK_THEME_COLORS : LIGHT_THEME_COLORS;
		MAYAN_CONFIG.destinationWallets = {
			solana: wallet.publicKey?.toBase58() ?? '',
		};

		// @ts-ignore
		if (window.MayanSwap && window.MayanSwap.init) {
			console.log('MayanSwap already exists');
			try {
				initMayan();
			} catch (err) {
				console.error("Couldn't initialize MayanSwap, retrying...", err);
				setTimeout(() => {
					initMayan();
				}, 1000);
			}
		} else {
			const script = document.createElement('script');
			script.src = 'https://cdn.mayan.finance/mayan_widget_v_1_2_0.js';
			script.integrity = 'sha256-xg2EIE9pWR7nYXqX9IE+d2Lajrd34w0aKbuJHFH2+aw=';
			script.crossOrigin = 'anonymous';
			script.onload = initMayan;
			document.body.appendChild(script);

			return () => {
				document.body.removeChild(script);
			};
		}
	}, [theme, captureEvent, wallet?.publicKey]);

	return (
		<div className="flex items-center justify-center w-full min-h-[564px]">
			<div id="swap_widget">
				<div className="flex flex-col justify-center items-center w-full h-[564px]">
					<SkeletonValuePlaceholder loading className="w-[50%] h-2" />
				</div>
			</div>
		</div>
	);
};

export default memo(MayanWidget);
