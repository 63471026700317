'use client';

import { getTokenMessengerContractAddress } from 'src/utils/cctp';
import { useAccount, useContractWrite, usePrepareContractWrite } from 'wagmi';
import { tokenMessengerABI } from './generated';
import { useCallback } from 'react';
import { notify } from 'src/utils/notifications';
import { BigNum, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import { DomainUiConfig } from 'src/constants/cctp';
import usePostHogCapture from '../posthog/usePostHogCapture';
import { LoadingStep } from 'src/components/Toasts/Notification';

// The token messenger contract is used to burn the token on the source chain, and specify the recipient on the destination chain.
// More information about the token messenger contract can be found here: https://developers.circle.com/stablecoin/docs/cctp-tokenmessenger
export const useTokenMessenger = ({
	amount,
	allowance,
	sourceDomainUiConfig,
	destinationDomainUiConfig,
	mintRecipient,
	burnTokenAddress,
	toastId = 'cctp-deposit-for-burn',
}: {
	amount: bigint;
	allowance: bigint;
	sourceDomainUiConfig: DomainUiConfig;
	destinationDomainUiConfig: DomainUiConfig;
	mintRecipient: string;
	burnTokenAddress: string;
	toastId?: string;
}) => {
	const { captureEvent } = usePostHogCapture();
	const { address } = useAccount();

	const tokenMessengerContractAddress = getTokenMessengerContractAddress(
		sourceDomainUiConfig.value
	);

	// prepare config for "depositForBurn" function from TokenMessenger contract
	const { config } = usePrepareContractWrite(
		amount === 0n || allowance < amount
			? undefined
			: {
					address: tokenMessengerContractAddress,
					abi: tokenMessengerABI,
					functionName: 'depositForBurn',
					args: [
						amount,
						destinationDomainUiConfig.value,
						mintRecipient,
						burnTokenAddress,
					],
			  }
	);

	// create "depositForBurn" function from TokenMessenger contract
	const { write, data: depositForBurnResult } = useContractWrite({
		...config,
		onSuccess(data) {
			captureEvent('cctp_deposit_for_burn_init', {
				amount: Number(amount),
				sourceDomain: sourceDomainUiConfig.value,
				destinationDomain: destinationDomainUiConfig.value,
				sourceChain: sourceDomainUiConfig.label,
				destinationChain: destinationDomainUiConfig.label,
				sourceWallet: address,
				mintRecipient: mintRecipient,
				sourceTxHash: data.hash,
			});
		},
		onError(error) {
			notify({
				type: 'error',
				message: 'Failed to initiate USDC transfer',
				description: 'Please try again later.',
				id: toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});
			console.error(error);
		},
	});
	const depositForBurn = useCallback(() => {
		notify({
			type: 'info',
			message: 'Initiating USDC transfer',
			description: (
				<LoadingStep>
					{`Transferring ${BigNum.from(
						amount.toString(),
						QUOTE_PRECISION_EXP
					).toNum()} USDC from ${sourceDomainUiConfig.label} to ${
						destinationDomainUiConfig.label
					}`}
				</LoadingStep>
			),
			id: toastId,
			showUntilCancelled: true,
		});
		write();
	}, [write]);

	return {
		depositForBurn,
		depositForBurnTxHash: depositForBurnResult?.hash,
	};
};
