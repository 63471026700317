'use client';

import { MarketId } from '@drift/common';
import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DriftStoreActions from 'src/stores/DriftStore/driftStoreActions';
import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountsStore, {
	DriftAccountsStore,
} from 'src/stores/useDriftAccountsStore';
import usePriorityFeeStore from 'src/stores/usePriorityFeeStore';
import useWalletBalancesStore from 'src/stores/useWalletBalancesStore';
import useMarketStateStore from '../stores/useMarketStateStore';
import useIfDataStore from 'src/stores/ifDataStore/useIfDataStore';
import usePostHogCapture from './posthog/usePostHogCapture';
import { useRouter } from 'next/navigation';

function useDriftActions(): ReturnType<typeof DriftStoreActions> {
	const getDriftStore = useDriftStore((s) => s.get);
	const getAccountsStore = useDriftAccountsStore((s) => s.get);
	const getWalletBalancesStore = useWalletBalancesStore((s) => s.get);
	const getPriorityFeeToUse = usePriorityFeeStore((s) => s.getPriorityFeeToUse);
	const getIfStore = useIfDataStore((s) => s.get);
	const router = useRouter();
	const { captureEvent } = usePostHogCapture();

	const getMarketDataForMarket = useMarketStateStore(
		(s) => s.getMarketDataForMarket
	);

	const getMarketTradePriceData = useMemo(
		() => (marketId: MarketId) => getMarketDataForMarket(marketId).derivedState,
		[getMarketDataForMarket]
	);

	const getMarketOracleData = useMemo(
		() => (marketId: MarketId) => {
			const data = getMarketDataForMarket(marketId).oracle;
			return {
				price: data.price.val,
				slot: data.slot,
				confidence: data.confidence,
				hasSufficientNumberOfDataPoints: data.hasSufficientNumberOfDataPoints,
				twap: data.twap?.val,
				twapConfidence: data.twapConfidence,
				maxPrice: data.maxPrice?.val,
			};
		},
		[getMarketDataForMarket]
	);

	const setDriftStore = useDriftStore((s) => s.set);
	const setAccountsStore = useDriftAccountsStore((s) => s.set);

	const getActions = useCallback(() => {
		return DriftStoreActions(
			getDriftStore,
			// @ts-ignore
			(fn: DriftStore) => setDriftStore(produce(fn), produce),
			getAccountsStore,
			(fn: DriftAccountsStore) =>
				//@ts-ignore
				setAccountsStore(produce(fn), produce),
			getMarketTradePriceData,
			getMarketOracleData,
			getWalletBalancesStore,
			getIfStore,
			getPriorityFeeToUse,
			captureEvent,
			router
		);
	}, [
		getDriftStore,
		setDriftStore,
		getAccountsStore,
		setAccountsStore,
		getMarketTradePriceData,
		getPriorityFeeToUse,
		router,
	]);

	const [actions, setActions] = useState(getActions());

	useEffect(() => {
		setActions(getActions());
	}, [getActions]);

	return actions;
}

export default useDriftActions;
