'use client';

import { useRouter } from 'next/navigation';
import {
	PropsWithChildren,
	createContext,
	useCallback,
	// useRef
} from 'react';
// import { dlog } from '../dev';

export const SafePushContext = createContext((_path: string) => {});

/**
 * safe push context is used to avoid route pushing errors when users click multiple times
 * or when the network is slow:  "Error: Abort fetching component for route".
 *
 * Initial code is referenced from here: https://stackoverflow.com/questions/73343986/next-js-abort-fetching-component-for-route-login/75872313#75872313
 */
const SafePushProvider = (props: PropsWithChildren<any>) => {
	// const onChangingRef = useRef(false);
	// const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
	const router = useRouter();

	// const handleRouteChange = () => {
	// 	dlog(`router_bug`, `handleRouteChange`);
	// 	onChangingRef.current = false;
	// 	if (timeoutRef.current) {
	// 		clearTimeout(timeoutRef.current);
	// 	}
	// };

	const safePush = useCallback(
		(path: string) => {
			// console.debug(`Safepushing ${path}`);
			// if (onChangingRef.current) return;
			// onChangingRef.current = true;
			router.push(path);

			// // Temp solution to fix the routing bug
			// timeoutRef.current = setTimeout(handleRouteChange, 1000);
		},
		[router]
	);

	return <SafePushContext value={safePush}>{props.children}</SafePushContext>;
};

export default SafePushProvider;
