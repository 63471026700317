'use client';

import { useEffect, useRef, useState } from 'react';
import { CctpState } from 'src/@types/types';
import { BytesLike, Interface, Log, TransactionReceipt } from 'ethers';
import {
	SUPPORTED_SOURCE_DOMAINS,
	SUPPORTED_DESTINATION_DOMAINS,
	SOLANA_DOMAIN,
	MINIMUM_SOL_NEEDED_FOR_CCTP,
} from 'src/constants/cctp';
import { useTransaction, useWaitForTransaction } from 'wagmi';
import { HexString } from 'src/@types/evm';
import { getMessageHashFromLogs } from 'src/utils/evm';
import { useCctpAttestation } from 'src/hooks/cctp/useCctpAttestation';
import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { useMessageTransmitterSolana } from './useMessageTransmitterSolana';
import {
	LAMPORTS_PER_SOL,
	PublicKey,
	RpcResponseAndContext,
	SimulatedTransactionResponse,
	VersionedTransaction,
} from '@solana/web3.js';
import useDriftClient from '../useDriftClient';
import useDriftClientIsReady from '../useDriftClientIsReady';
import useLocalStorageState from '../useLocalStorageState';
import { getChainFromDomain, getUsdcContractAddress } from 'src/utils/cctp';
import { getTokenAddress } from '@drift/common';
import { useDebounce } from 'react-use';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { tokenMessengerABI } from './generated';
import { BigNum, QUOTE_PRECISION, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import { notify } from '../../utils/notifications';
import useNotificationStore from '../../stores/useNotificationStore';

const REFETCH_INTERVAL_MS = 5_000; // this works for testnet, may need more time for mainnet
const TX_HASH_QUERY_PARAM = 'txHash';
const SOURCE_DOMAIN_QUERY_PARAM = 'sourceDomain';
const FUNDS_ALREADY_RECEIVED_ERROR_CODE = 'NonceAlreadyUsed';
const CCTP_TX_HASH_CONTINUATION_KEY = 'cctp-stored-tx';

const DEPOSIT_FOR_BURN_FN_AMOUNT_INPUT_INDEX = 0; // check the ABI in the src/hooks/cctp/generated.ts, find the function 'depositForBurn'

/**
 * This hook is a state engine for the CCTP (Cross-Chain Transfer Protocol) process.
 * It only handles the bridging of USDC from EVM chains to Solana, and NOT the other way around.
 * Below is a high-level step-by-step of the process:
 *
 * 1. User connects wallet on source chain (e.g. Ethereum), and approves a max spending limit for USDC (handled in <TransferDetails />)
 * 2. User initiates a 'depositForBurn' transaction to burn the USDC on the source chain. The 'depositForBurn' transaction is handled by the source chain's TokenMessenger contract.
 * 3. Client will check if transaction is finalized, and extract the 'message hash' and 'message bytes' from the transaction.
 * 4. Client will use the 'message hash' to poll for the attestation (a proof hash) from a Circle-provided API.
 * 5. Once the attestation is obtained, the client will use the attestation and 'message bytes' to create a 'ReceiveMessage' transaction on the destination chain (fixed as Solana for now) to receive the USDC.
 * The 'ReceiveMessage' transaction is handled by the destination chain's MessageTransmitter contract.
 *
 * The state engine handles 2 scenarios:
 * 1. User manually going thru the transfer process
 * 2. Simulate using depositForBurn tx hash + sourceDomain from either query params or local storage
 * 		- this is useful for users who refresh the page after initiating a depositForBurn tx
 * 		- requires both txHash and sourceDomain to be present in the query params/local storage
 */
export const useCctpStateEngine = () => {
	const router = useRouter();
	const searchParams = useSearchParams();
	const pathname = usePathname();

	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const connection = useDriftStore((s) => s.connection.current);
	const hideNotification = useNotificationStore((s) => s.hideNotificationById);

	const solanaPubKey = useDriftStore((s) => s.wallet.current.adapter.publicKey); // TODO : Check this
	const queryParamTxHash = searchParams.get(TX_HASH_QUERY_PARAM);
	const queryParamSourceDomain = searchParams.get(SOURCE_DOMAIN_QUERY_PARAM);

	const [cctpState, setCctpState] = useState<CctpState>(
		CctpState.InitiatingTransfer
	);
	const [storedTx, setStoredTx] = useLocalStorageState(
		CCTP_TX_HASH_CONTINUATION_KEY,
		{
			txHash: '',
			sourceDomain: 0,
		}
	);

	// form states
	const [usdcAmount, setUsdcAmount] = useState('');
	const [sourceDomain, setSourceDomain] = useState(
		SUPPORTED_SOURCE_DOMAINS[0].value
	);
	const [destinationDomain, setDestinationDomain] = useState(
		SUPPORTED_DESTINATION_DOMAINS[0].value
	);
	const [destinationWalletAddress, setDestinationWalletAddress] = useState('');
	const [destinationWalletPassPreReq, setDestinationWalletPassPreReq] =
		useState(true);
	const [userUsdcTokenAccount, setUserUsdcTokenAccount] = useState<
		PublicKey | undefined
	>();

	// variables derived from submitted transaction
	const [depositForBurnTx, setDepositForBurnTx] = useState<{
		hash: string;
		toastId?: string;
	}>({
		hash: '',
	});
	const [messageHash, setMessageHash] = useState(''); // message hash != transaction hash; used for attestation id
	const [messageBytes, setMessageBytes] = useState<BytesLike>('');
	const [usdcAlreadyReceived, setUsdcAlreadyReceived] = useState(false);

	const numOfDepositForBurnErrors = useRef(0);

	const usdcSolanaAddressStr = getUsdcContractAddress(SOLANA_DOMAIN);

	// checks the depositForBurn tx status. this logic is lifted into
	// the cctp state engine instead of the useTokenMessenger hook
	// because it can be triggered by the simulating from a depositForBurn txHash
	const {
		isLoading: isDepositForBurnApproving,
		refetch: refetchDepositForBurnTx,
	} = useWaitForTransaction({
		chainId: getChainFromDomain(sourceDomain).id,
		hash: depositForBurnTx.hash as HexString,
		onSuccess(data) {
			depositForBurnTx.toastId &&
				!queryParamTxHash &&
				!depositForBurnTx.hash &&
				notify({
					type: 'success',
					message: 'Initiated USDC Transfer',
					description: 'Please wait while the transfer is being finalized.',
					id: depositForBurnTx.toastId,
					updatePrevious: true,
					showUntilCancelled: false,
				});
			extractMessageInfoFromTransaction(data);
		},
		onError(error) {
			retryDepositForBurnTxFetchOnce(error);
		},
	});

	// polls for attestation hex using message hash
	const { attestationHex } = useCctpAttestation(messageHash);

	const { createReceiveMessageTxn } = useMessageTransmitterSolana({
		sourceDomain,
		attestationHex,
		messageBytes: messageBytes as string,
	});

	const result = useTransaction({
		hash: depositForBurnTx.hash as HexString,
		chainId: getChainFromDomain(sourceDomain).id,
	});

	// decodes the depositForBurn tx to get the burn amount
	useEffect(() => {
		if (!result || !result.data) return;

		const tokenMessengerInterface = new Interface(tokenMessengerABI);
		const decodedData = tokenMessengerInterface.decodeFunctionData(
			'depositForBurn',
			result.data.input
		);
		const burnAmount = decodedData[
			DEPOSIT_FOR_BURN_FN_AMOUNT_INPUT_INDEX
		] as bigint;

		setUsdcAmount(
			BigNum.fromPrint(burnAmount.toString(), QUOTE_PRECISION_EXP)
				.div(QUOTE_PRECISION)
				.toNum()
				.toString()
		);
	}, [result]);

	useDebounce(
		() => {
			// check if there is SOL balance in the destination wallet address
			if (!connection || !destinationWalletAddress) {
				setDestinationWalletPassPreReq(true);
				return;
			}

			connection
				.getBalance(new PublicKey(destinationWalletAddress))
				.then((balance) => {
					if (balance > MINIMUM_SOL_NEEDED_FOR_CCTP * LAMPORTS_PER_SOL) {
						setDestinationWalletPassPreReq(true);
					} else {
						setDestinationWalletPassPreReq(false);
					}
				});
		},
		500,
		[destinationWalletAddress]
	);

	useEffect(() => {
		(async () => {
			if (!destinationWalletAddress || !usdcSolanaAddressStr) return;

			let destinationWalletAddressPubKey: PublicKey;

			try {
				destinationWalletAddressPubKey = new PublicKey(
					destinationWalletAddress
				);
			} catch (err) {
				// ignore invalid destination wallet address
			}

			if (!destinationWalletAddressPubKey) {
				setUserUsdcTokenAccount(undefined);
				return;
			}

			const userUsdcTokenAccount = await getTokenAddress(
				usdcSolanaAddressStr,
				destinationWalletAddressPubKey.toString()
			);
			setUserUsdcTokenAccount(userUsdcTokenAccount);
		})();
	}, [destinationWalletAddress, usdcSolanaAddressStr]);

	// simulates from depositForBurn tx hash using query param
	useEffect(() => {
		if (queryParamTxHash && !isNaN(+queryParamSourceDomain)) {
			setDepositForBurnTx({
				hash: queryParamTxHash,
			});
			setSourceDomain(+queryParamSourceDomain);
		}
	}, [queryParamTxHash, queryParamSourceDomain]);

	useEffect(() => {
		if (!!storedTx.txHash && storedTx.txHash !== depositForBurnTx.hash) {
			// continues from a tx hash stored in local storage
			setDepositForBurnTx((prev) => ({
				...prev,
				hash: storedTx.txHash,
			}));
			setSourceDomain(storedTx.sourceDomain);
		} else if (!storedTx.txHash && depositForBurnTx.hash) {
			// stores the current tx hash in local storage
			setStoredTx({ txHash: depositForBurnTx.hash, sourceDomain });
		}
	}, [storedTx, depositForBurnTx.hash, sourceDomain]);

	useEffect(() => {
		if (solanaPubKey) {
			setDestinationWalletAddress(solanaPubKey.toString());
		}
	}, [solanaPubKey]);

	// this effect hook acts as a state machine engine to determine the current cctp state
	// it is ordered from the last state to the first state
	useEffect(() => {
		if (usdcAlreadyReceived) {
			setCctpState(CctpState.Completed);
		} else if (attestationHex !== '' && attestationHex !== 'PENDING') {
			// attestation hex is only obtained when the burn tx is finalized on the source chain,
			// inferring that funds are ready to receive on the destination chain
			setCctpState(CctpState.ReadyToReceive);
		} else if (messageHash && messageBytes) {
			// message hash/bytes are only obtained from a successful depositForBurn tx
			setCctpState(CctpState.PollingAttestationInProgress);
		} else {
			setCctpState(CctpState.InitiatingTransfer);
		}
	}, [attestationHex, messageHash, messageBytes, usdcAlreadyReceived]);

	// checks if usdc is already received after attestation hex is obtained.
	// used when simulating from a derived txHash
	useEffect(() => {
		if (driftClientIsReady && driftClient && messageBytes && attestationHex) {
			(async () => {
				const receiveMessageTxn = (await createReceiveMessageTxn(
					undefined,
					true
				)) as VersionedTransaction;
				const res = await driftClient.connection.simulateTransaction(
					receiveMessageTxn
				);
				const isUsdcAlreadyReceived = checkIfUsdcAlreadyReceived(res);
				setUsdcAlreadyReceived(isUsdcAlreadyReceived);
			})();
		}
	}, [
		driftClientIsReady,
		driftClient,
		messageBytes,
		attestationHex,
		sourceDomain,
		setUsdcAlreadyReceived,
	]);

	useEffect(() => {
		// sometimes the notification doesn't get removed when the state changes, so we remove it here
		if (cctpState !== CctpState.InitiatingTransfer) {
			hideNotification('cctp-deposit-for-burn');
		}

		if (cctpState === CctpState.Completed) {
			resetState();
		}
	}, [cctpState]);

	function extractMessageInfoFromTransaction(data: TransactionReceipt) {
		const { status, logs } = data;

		if (status !== 1 && (status as unknown as string) !== 'success') {
			return;
		}

		const { messageBytes, messageHash } = getMessageHashFromLogs(logs as Log[]);

		setMessageHash(messageHash);
		setMessageBytes(messageBytes);
	}

	function retryDepositForBurnTxFetchOnce(error: any) {
		if (numOfDepositForBurnErrors.current === 1) {
			notify({
				type: 'error',
				message: 'Failed to initiate USDC transfer',
				description: 'Please try again.',
				id: depositForBurnTx.toastId,
				updatePrevious: true,
				showUntilCancelled: false,
			});
			console.error(error);

			resetState();
		} else {
			numOfDepositForBurnErrors.current += 1;
			console.log(
				`Failed to fetch depositForBurn tx, retrying in ${REFETCH_INTERVAL_MS}ms for tx:`,
				depositForBurnTx.hash
			);
			setTimeout(() => {
				refetchDepositForBurnTx();
			}, REFETCH_INTERVAL_MS);
		}
	}

	function handleRemoveQueryParams() {
		router.replace(`${pathname}`);
	}

	function checkIfUsdcAlreadyReceived(
		simulationResponse: RpcResponseAndContext<SimulatedTransactionResponse>
	) {
		const logs = simulationResponse.value.logs;
		for (const log of logs) {
			if (log.includes(FUNDS_ALREADY_RECEIVED_ERROR_CODE)) {
				return true;
			}
		}

		return false;
	}

	function resetState(includeCctpState?: boolean) {
		handleRemoveQueryParams();
		numOfDepositForBurnErrors.current = 0;
		setDepositForBurnTx({
			hash: '',
		});
		setMessageHash('');
		setMessageBytes('');
		setUsdcAmount('');
		setStoredTx({
			txHash: '',
			sourceDomain: 0,
		});

		if (includeCctpState) {
			setCctpState(CctpState.InitiatingTransfer);
			setUsdcAlreadyReceived(false);
		}
	}

	return {
		cctpState,
		usdcAmount,
		setUsdcAmount,
		sourceDomain,
		setSourceDomain,
		destinationDomain,
		setDestinationDomain,
		destinationWalletAddress,
		setDepositForBurnTx,
		isDepositForBurnApproving,
		destinationUsdcTokenAccount: userUsdcTokenAccount,
		setDestinationWalletAddress,
		depositForBurnTx,
		setMessageHash,
		setMessageBytes,
		messageBytes,
		attestationHex,
		setUsdcAlreadyReceived,
		resetState,
		destinationWalletPassPreReq,
	};
};
