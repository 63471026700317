import React, { PropsWithChildren, ReactNode } from 'react';
import Utility from './Utility';

const LabelledInput = (
	props: PropsWithChildren<{
		label: string | ReactNode;
	}>
) => {
	return (
		<div className="flex flex-col items-start w-full">
			<Utility.FORMLABEL01 label={props.label} className="w-full" />
			<Utility.VERTSPACERXS />
			{props.children}
		</div>
	);
};

export default LabelledInput;
