'use client';

import {
	syncGetSavedString,
	syncSaveData,
} from 'src/hooks/useCurrentLocalSettings';
import React, { ReactNode, useEffect, useState, memo } from 'react';
import { twMerge } from 'tailwind-merge';
import { ArrowRight, Close } from '@drift-labs/icons';

export type MessageProps = {
	type: 'info' | 'warn' | 'error' | 'success';
	message?: string | ReactNode;
	messageTitle?: string | ReactNode;
	link?: string;
	linkDescription?: string;
	messageId?: string;
	className?: string;
};

const InfoMessage = ({
	type,
	message,
	messageTitle,
	link,
	linkDescription,
	messageId,
	className,
}: MessageProps) => {
	// Store whether message should be hidden based on saved message ID state
	const [hidden, setHidden] = useState(false);

	// Update hidden state if message ID changes
	useEffect(() => {
		const savedMessageIdVal = messageId ? syncGetSavedString(messageId) : false;

		// If there is a saved value (a timestamp), the message should stay hidden if the timestamp was less than 1 hour ago
		setHidden(
			savedMessageIdVal
				? parseInt(savedMessageIdVal ?? '0') + 1000 * 60 * 60 > Date.now()
				: false
		);
	}, [messageId]);

	// Save current timestamp to messageID when close button is pressed
	const handleHide = () => {
		if (!messageId) return;
		syncSaveData(messageId, Date.now());
		setHidden(true);
	};

	if (hidden) return <></>;

	return (
		<div
			className={twMerge(
				`w-full flex rounded overflow-hidden items-stretch text-xs`,
				type === 'error' ? 'bg-button-secondary-bg' : 'bg-input-bg',
				className
			)}
			style={{
				background:
					type === 'error'
						? 'var(--negative-bg-gradient'
						: type === 'warn'
						? 'var(--warning-bg-gradient)'
						: '',
			}}
		>
			<div
				style={{
					borderLeft: `${
						type === 'error'
							? `3px solid var(--negative-red)`
							: type === 'warn'
							? `3px solid var(--warn-yellow)`
							: type === 'success'
							? `3px solid var(--positive-green)`
							: `3px solid var(--neutrals-40)`
					}`,
				}}
				className="flex w-full py-2 pl-4 pr-2"
			>
				<div className="flex flex-col w-full">
					{messageTitle && (
						<div
							className={`${'text-text-emphasis'}`}
							style={{ fontSize: '13px' }}
						>
							{messageTitle}
						</div>
					)}

					{message && (
						<div
							className={`text-text-default ${
								link && linkDescription && 'mb-1'
							} ${messageTitle && 'mt-1'}`}
							style={{ fontSize: '11px' }}
						>
							{message}
						</div>
					)}

					{link && linkDescription && (
						<a
							className={`flex items-center space-x-1 ${
								type === 'error'
									? 'text-negative-red'
									: type === 'warn'
									? 'text-text-emphasis'
									: 'text-text-emphasis'
							}`}
							style={{ fontSize: '11px' }}
							href={link}
							// open in same window if app.drift.trade, separate if other
							target={link.includes('app.drift.trade') ? '_self' : '_blank'}
							rel="noreferrer"
						>
							<div>{linkDescription}</div>
							<ArrowRight
								color={
									type === 'error'
										? 'var(--negative-red)'
										: type === 'warn'
										? 'white'
										: 'white'
								}
								size={12}
							/>
						</a>
					)}
				</div>
				{type === 'warn' && messageId && (
					<button
						className="flex flex-col hover:cursor-pointer hover:opacity-80"
						onClick={handleHide}
					>
						<Close color="white" />
					</button>
				)}
			</div>
		</div>
	);
};

export default memo(InfoMessage);
