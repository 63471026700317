import React, { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

export const getColourClassForValue = (value: number, noRed?: boolean) =>
	value === 0
		? 'text-text-label'
		: value < 0
		? noRed
			? `text-text-label`
			: `text-negative-red`
		: value > 0
		? `text-positive-green`
		: ``;

export const getColourForValue = (value: number) =>
	value < 0 ? `var(--red-50)` : value > 0 ? `var(--green-60)` : ``;

const ColourCodedValue = (
	props: PropsWithChildren<{
		value?: number;
		className?: string;
		noRed?: boolean;
	}>
) => {
	return (
		<span
			className={twMerge(
				getColourClassForValue(props.value, props.noRed),
				props.className
			)}
		>
			{props.children}
		</span>
	);
};

export default ColourCodedValue;
