import { WalletAdapterNetwork, WalletName } from '@solana/wallet-adapter-base';
import {
	Coin98WalletAdapter,
	CoinbaseWalletAdapter,
	MathWalletAdapter,
	SpotWalletAdapter,
	TrustWalletAdapter,
	WalletConnectWalletAdapter,
	PhantomWalletAdapter,
	SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { DRIFT_WALLET_CONNECT_PROJECT_ID } from './constants';
import Env from '../environmentVariables/EnvironmentVariables';

// # Set up AppKit wallet adapter
import '../utils/appKitWalletSetup';
import { SnapWalletAdapter } from '@drift-labs/snap-wallet-adapter';

/**
 * 🚨 ADDING NEW WALLETS?? : If you're trying to add a new "custom" wallet (not a regular solana/react one from @solana/wallet-adapter-wallets) then look at  newWallets_README.md
 */

// # Types
export type DriftCustomWalletName = 'Connect by Drift' | 'Email' | 'AppKit';

type CustomWalletNameKey =
	| 'SNAP_WALLET_ADAPTER_NAME'
	| 'MAGIC_AUTH_WALLET_ADAPTER_NAME'
	| 'APPKIT_WALLET_ADAPTER_NAME';

export const DRIFT_CUSTOM_WALLET_OPTIONS: Record<
	CustomWalletNameKey,
	DriftCustomWalletName
> = {
	SNAP_WALLET_ADAPTER_NAME: 'Connect by Drift',
	MAGIC_AUTH_WALLET_ADAPTER_NAME: 'Email',
	APPKIT_WALLET_ADAPTER_NAME: 'AppKit',
};
export type DriftWalletName = DriftCustomWalletName | WalletName;
export const WALLETS_WITH_NO_SIGN_MESSAGE_METHOD = [
	DRIFT_CUSTOM_WALLET_OPTIONS.MAGIC_AUTH_WALLET_ADAPTER_NAME,
	DRIFT_CUSTOM_WALLET_OPTIONS.SNAP_WALLET_ADAPTER_NAME,
];

// This is the name that the actual AppKit social wallet uses
export const APPKIT_SOCIAL_WALLET_PROVIDER_NAME = 'w3mAuth';

export const MAGIC_EDEN_WALLET_NAME = 'Magic Eden';

export const DRIFT_CUSTOM_WALLET_ICONS = {
	SNAP_WALLET_ADAPTER: SnapWalletAdapter.icon,
	MAGIC_AUTH_WALLET_ADAPTER: `/assets/icons/magic-auth-logo.svg`,
	APPKIT_WALLET_ADAPTER: '/assets/icons/reown-logo.svg',
};

// # Define solana/react wallet adapters
/**
 * A list of wallets to display in the wallet connection list.
 *
 * On SAGA the mobile wallet adapter will be added to the list automatically.
 *
 * The Wallet Provider should also be smart enough to add any other detected wallets to the list too.
 */
export const DRIFT_WALLET_PROVIDERS = [
	new PhantomWalletAdapter(),
	new SolflareWalletAdapter(),
	new CoinbaseWalletAdapter(),
	new WalletConnectWalletAdapter({
		network:
			Env.sdkEnv === 'mainnet-beta'
				? WalletAdapterNetwork.Mainnet
				: WalletAdapterNetwork.Devnet,
		options: {
			relayUrl: 'wss://relay.walletconnect.com',
			// example WC app project ID
			projectId: DRIFT_WALLET_CONNECT_PROJECT_ID,
			metadata: {
				name: 'Drift Protocol',
				description:
					'Drift is the leading perpetuals DEX on Solana with the most CEX-like trader experience',
				url: 'https://app.drift.trade/',
				icons: [
					'https://drift-public.s3.eu-central-1.amazonaws.com/DriftLogoCircular.png',
				],
			},
		},
	}),
	new SpotWalletAdapter(),
	new TrustWalletAdapter(),
	new MathWalletAdapter(),
	new Coin98WalletAdapter(),
];
