'use client';

import { singletonHook } from 'react-singleton-hook';
import useAccountData from './useAccountData';
import { useMemo } from 'react';
import UI_UTILS from 'src/utils/uiUtils';

/* Returns max margin ratio and max leverage conversion for the currently selected user account */
const useMaxMarginRatioAndLeverage = (): [number, number] => {
	const currentAccount = useAccountData();

	const customMarginRatio = useMemo(() => {
		return currentAccount?.client?.isSubscribed
			? currentAccount?.client?.getUserAccount()?.maxMarginRatio ?? undefined
			: undefined;
	}, [currentAccount]);

	const customMaxLeverage = useMemo(() => {
		return UI_UTILS.convertMarginRatioToLeverage(customMarginRatio, 2);
	}, [customMarginRatio]);

	return [customMarginRatio, customMaxLeverage];
};

export default singletonHook(
	[undefined, undefined],
	useMaxMarginRatioAndLeverage
);
