'use client';

import { useTheme } from 'next-themes';
import { DriftTheme } from '../../environmentVariables/EnvironmentVariables';
import { MARKET_ICON_PATH } from 'src/constants/constants';
import { COMMON_UI_UTILS } from '@drift/common';
import { twMerge } from 'tailwind-merge';
import { CSSProperties } from 'react';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import { PredictionMarketIcon } from 'src/components/PredictionMarkets/PredictionMarketIcon';

const DEFAULT_SIZE = 16;

export const getSpotMarketIconPath = (baseSymbol: string) => {
	const src = `${MARKET_ICON_PATH}/${baseSymbol.toLowerCase()}.${
		checkUsePngIcon(baseSymbol.toLowerCase(), false) ? 'webp' : 'svg'
	}`;

	return src;
};

const checkUsePngIcon = (baseSymbol: string, grey: boolean) => {
	baseSymbol = baseSymbol.toLowerCase();

	return (
		(baseSymbol === 'bonk' && !grey) ||
		baseSymbol === 'wif' ||
		baseSymbol === 'pepe'
	);
};

export const TokenPairLogos = ({
	fromTokenSymbol,
	toTokenSymbol,
	size,
	leftImgFront,
}: {
	fromTokenSymbol: string;
	toTokenSymbol: string;
	size?: number;
	leftImgFront?: boolean;
}) => {
	if (!fromTokenSymbol || !toTokenSymbol) return null;

	const fromTokenLogo = `${MARKET_ICON_PATH}/${fromTokenSymbol.toLowerCase()}.${
		checkUsePngIcon(fromTokenSymbol, false) ? 'webp' : 'svg'
	}`;
	const toTokenLogo = `${MARKET_ICON_PATH}/${toTokenSymbol.toLowerCase()}.${
		checkUsePngIcon(toTokenSymbol, false) ? 'webp' : 'svg'
	}`;

	return (
		<div className="flex flex-row">
			<div>
				<img
					src={fromTokenLogo}
					className={twMerge('rounded-full', leftImgFront && 'relative z-10')}
					width={size ? size : DEFAULT_SIZE}
				/>
			</div>
			<div className="-ml-[20%]">
				<img
					src={toTokenLogo}
					className="rounded-full"
					width={size ? size : DEFAULT_SIZE}
				/>
			</div>
		</div>
	);
};

// TODO : Remove the two different methods of specifying a size.
const MarketIcon = ({
	marketSymbol,
	className,
	grey,
	sizeClass,
	customHeight,
	customWidth,
	isSpot,
	style,
}: {
	marketSymbol: string;
	className?: string;
	grey?: boolean;
	sizeClass?: string;
	customHeight?: number;
	customWidth?: number;
	isSpot?: boolean;
	style?: CSSProperties;
}) => {
	const baseSymbol = COMMON_UI_UTILS.getBaseAssetSymbol(
		marketSymbol,
		true
	).toLowerCase();
	const theme = (useTheme().theme as DriftTheme) ?? DriftTheme.dark;

	// displays as pair logo between base token and USDC token
	if (isSpot) {
		return (
			<TokenPairLogos
				fromTokenSymbol={baseSymbol}
				toTokenSymbol="usdc"
				size={customWidth ?? 14}
				leftImgFront
			/>
		);
	}

	let src = `${MARKET_ICON_PATH}/${baseSymbol}${
		grey ? `_grey-${theme}` : ''
	}.svg`;

	if (baseSymbol === 'apt' && !grey) {
		src = `${MARKET_ICON_PATH}/${baseSymbol}-${theme}.svg`;
	} else if (checkUsePngIcon(baseSymbol, grey)) {
		src = src.replace('svg', 'webp');
	}

	const predictionMarketConfig =
		PredictionMarketConfigs.getConfigFromMarketSymbol(marketSymbol);

	if (predictionMarketConfig) {
		// apparently it needs both the classname and height/width to work for pngs and svgs
		return (
			<PredictionMarketIcon
				symbol={predictionMarketConfig.symbol}
				className={twMerge(sizeClass ?? 'h-[18px] w-[18px]', className)}
				small
			/>
		);
	}

	// Apparently it needs both the classname and height/width to work for pngs and svgs
	return (
		<img
			src={src}
			className={twMerge(sizeClass ?? 'h-[18px] w-[18px]', className)}
			width={customWidth ?? 18}
			height={customHeight ?? 18}
			alt={`${marketSymbol} icon`}
			style={style}
		/>
	);
};

export default MarketIcon;
