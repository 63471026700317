import { ChainClock } from '@drift-labs/sdk';
import { Subject } from 'rxjs';
import { MetricsEventBusMessage } from '../providers/metrics/metricsTypes';
import { LoggingMetrics } from '@drift-labs/logging-server';
import { OneWayValueJar } from '../utils/oneWayValueJar';
import { DevEventEmitter } from 'src/@types';
import EventEmitter from 'events';
import LoggingService from 'src/utils/LoggingService';

type StartupTimeMetrics = keyof LoggingMetrics['startupTimeMetrics'];

const autoConnectStartupTimeMetrics: Set<StartupTimeMetrics> = new Set([
	'userFullyConnected',
	'userHistoryLoaded',
]);
const tradePageStartupTimeMetrics: Set<StartupTimeMetrics> = new Set([
	'oraclePriceLoaded',
	'marketStatsLoaded',
	'candlesLoaded',
	'userHistoryLoaded',
]);

const alreadyRecordedStartupTimeMetrics: Set<StartupTimeMetrics> = new Set();

/**
 * Purpose of this is to function the same as the regular browser window object (an easy global place to store things) without being as
 * easily accessible by users who may open their console and try to screw with things. Static methods should make it easier to handle
 * race-conditions and other usability of this globally available stuff too.
 */
export class DriftWindow {
	public static chainClock = new ChainClock([]);
	private static _nonce = 0;
	public static metricsBus: Subject<MetricsEventBusMessage>;
	public static shouldTrackAutoConnectStartupTimeMetrics = new OneWayValueJar(
		false
	);
	public static shouldTrackTradePageStartupTimeMetrics = new OneWayValueJar(
		false
	);
	constructor() {}

	static initialise() {}

	public static getAndIncrementNonce() {
		return this._nonce++;
	}

	public static initialiseMetricsBus(
		metricsBus: Subject<MetricsEventBusMessage>
	) {
		this.metricsBus = metricsBus;
	}

	/*
	 * Record startup time metrics.
	 */

	public static STARTUP_TIME_MS = 0;

	public static recordStartupTimeMetric(
		key: keyof LoggingMetrics['startupTimeMetrics']
	) {
		if (!this.STARTUP_TIME_MS) return;
		if (!this.metricsBus) return;
		if (alreadyRecordedStartupTimeMetrics.has(key)) return;

		if (autoConnectStartupTimeMetrics.has(key)) {
			if (!this.shouldTrackAutoConnectStartupTimeMetrics.value) return;
		}

		if (tradePageStartupTimeMetrics.has(key)) {
			if (!this.shouldTrackTradePageStartupTimeMetrics.value) return;
		}

		alreadyRecordedStartupTimeMetrics.add(key);

		const timeElapsed = Date.now() - this.STARTUP_TIME_MS;

		this.metricsBus.next({
			type: 'passthrough_metric',
			value: {
				startupTimeMetrics: {
					[key]: timeElapsed,
				},
			},
		});
	}

	/**
	 * Dev Event Emitter
	 */
	public static devEventEmitter =
		new EventEmitter() as unknown as DevEventEmitter;

	/*
	 * DLOB testing toggles.
	 */
	public static BREAK_BLOCKCHAIN_DLOB_FETCHING = false;
	public static BREAK_DLOB_SERVER_POLLING = false;
	public static DISABLE_AUTO_SWITCH_DLOB_SOURCE = false;

	/**
	 * Used to force display the stale page warning notification.
	 */
	public static FORCE_DISPLAY_STALE_PAGE_WARNING = false;

	/**
	 * Enable logging service for dev logs.
	 */
	public static enableLoggingService = () => {
		LoggingService.setDevLogging(true);
	};

	public static marketOrderToastTestingContext = 'blockchain_event';
}
