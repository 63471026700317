'use client';

import React from 'react';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import useCurrentWalletIconSrc from 'src/hooks/useCurrentWalletIconSrc';
import { JSX } from 'react';

const CurrentWalletProviderIcon = (props: {
	className?: string;
	style?: React.CSSProperties;
}): JSX.Element => {
	const iconSrc = useCurrentWalletIconSrc();

	const isMagicAuth = useDriftStore((s) => s.wallet.isMagicAuth);

	const currentWallet = useDriftStore((s) => s.wallet.current);

	const connected = currentWallet.adapter.connected;

	if (!connected) return <div></div>;

	if (isMagicAuth) {
		return (
			<div className={props.className} style={props.style}>
				<img src={iconSrc} alt={'Magic Wallet'} className="w-full h-full" />
			</div>
		);
	}

	if (!currentWallet) return <div></div>;

	return (
		<div className={props.className} style={props.style}>
			{/* <Icon className="w-full h-full" /> */}
			<img src={iconSrc} className="w-full h-full" />
		</div>
	);
};

export default CurrentWalletProviderIcon;
