import { create } from 'zustand';
import produce from 'immer';
import { UserSettings } from '../environmentVariables/EnvironmentVariableTypes';
import { MarketId } from '@drift/common';
import { ComputeUnits, PriorityFee } from '../@types/types';

export type PriorityFeeStore = {
	set: (x: (s: PriorityFeeStore) => void) => void;
	get: () => PriorityFeeStore;
	ready: boolean;
	getPriorityFeeToUse: (
		feeTypeOverride?: UserSettings['priorityFee'],
		txComputeUnits?: ComputeUnits,
		marketId?: MarketId
	) => PriorityFee;
};

const usePriorityFeeStore = create<PriorityFeeStore>((set, get) => ({
	set: (fn) => set(produce(fn)),
	get: () => get(),
	ready: false,
	getPriorityFeeToUse: undefined,
}));

export default usePriorityFeeStore;
