'use client';

import useDriftAccountStore, {
	AccountData,
} from 'src/stores/useDriftAccountsStore';
import useWalletIsConnected from './useWalletIsConnected';
import { singletonHook } from 'react-singleton-hook';
import { useState } from 'react';
import useUiUpdateInterval from './useUiUpdateInterval';

const DEFAULT_STATE: AccountData = undefined;

/**
 * Use this hook only if immediate account updates are necessary
 */
const useAccountData = (): AccountData => {
	const isConnected = useWalletIsConnected();
	const currentAccount = useDriftAccountStore((s) => s.getCurrentUserAccount());

	const accountData =
		isConnected && currentAccount ? currentAccount : DEFAULT_STATE;

	return accountData;
};

const _useTickedAccountData = (): AccountData => {
	const currentAccountData = useAccountData();

	const [accountData, setAccountData] =
		useState<AccountData>(currentAccountData);

	const updateAccountData = () => {
		setAccountData(currentAccountData);
	};

	useUiUpdateInterval(updateAccountData, true, false);

	return accountData;
};

/**
 * This hook is used to get the current account data at intervals based on the `Env.uiEssentialUpdateIntervalMs` value.
 */
export const useTickedAccountData = singletonHook(
	undefined,
	_useTickedAccountData
);

export default singletonHook(undefined, useAccountData);
