import { EnvironmentConstants, RpcEndpoint } from '@drift/common';
import Env, {
	syncGetCurrentSettings,
} from '../../environmentVariables/EnvironmentVariables';
import { _GetRpcToUse } from './rpcUtils_logic';

/**
 * Util function to fetch the current RPC to use based on current environment and user settings
 * @returns
 */
export const getRpcToUse = (): RpcEndpoint => {
	const currentSettings = syncGetCurrentSettings();

	return _GetRpcToUse(
		{
			devnet: EnvironmentConstants.rpcs.dev,
			'mainnet-beta': EnvironmentConstants.rpcs.mainnet,
		},
		currentSettings.mainnetOverride,
		Env.sdkEnv,
		currentSettings.rpcEndpoint
	);
};
