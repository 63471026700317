'use client';

import { BigNum } from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import { useMemo } from 'react';
import useGetOraclePriceForMarket from './useGetOraclePriceForMarket';

const useMemoizedOraclePrice = (market: MarketId): BigNum => {
	const getOraclePrice = useGetOraclePriceForMarket();
	const oraclePriceBigNum = getOraclePrice(market);

	const oraclePrice = useMemo(() => {
		return oraclePriceBigNum;
	}, [oraclePriceBigNum?.toString?.()]);

	return oraclePrice;
};

export default useMemoizedOraclePrice;
