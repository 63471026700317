'use client';

import { singletonHook } from 'react-singleton-hook';
import { useBooleanLocalStorageState } from '../utils/localStorageUtils';

const useHasConnectedWalletBefore = () => {
	const state = useBooleanLocalStorageState(`hasConnectedWalletBefore`, false);

	return state;
};

export default singletonHook([false, () => {}], useHasConnectedWalletBefore);
