'use client';

import { CctpState } from 'src/@types/types';
import { AccordionPanel } from 'src/components/Accordion';
import GradientLoadingSpinner from 'src/components/Icons/LoadingSpinner/GradientLoadingSpinner';
import Text from 'src/components/Text/Text';
import { ArrowRight, SuccessFilled, Open } from '@drift-labs/icons';
import {
	getDomainUiConfig,
	getEstimatedTransferDurationSecs,
} from 'src/utils/cctp';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';
// import { useEffect, useState } from 'react';

export const TransferInProgress = ({
	cctpState,
	usdcAmount,
	expanded,
	sourceDomain,
	destinationDomain,
	depositForBurnTxHash,
}: {
	cctpState: CctpState;
	usdcAmount: string;
	expanded: boolean;
	sourceDomain: number;
	destinationDomain: number;
	depositForBurnTxHash: string;
}) => {
	// const [txnSentTime, setTxnSentTime] = useState<Date>(new Date());

	const sourceDomainUiConfig = getDomainUiConfig(sourceDomain);
	const destinationDomainUiConfig = getDomainUiConfig(destinationDomain);

	const estimatedDurationOfTransfer = `~${UI_UTILS.secondsToMinutes(
		getEstimatedTransferDurationSecs(sourceDomain),
		true
	)}`;

	// useEffect(() => {
	// 	if (cctpState === CctpState.PollingAttestationInProgress) {
	// 		setTxnSentTime(new Date());
	// 	}
	// }, [cctpState]);

	function handleOpenTxnLink() {
		UI_UTILS.openUrl(
			sourceDomainUiConfig.explorer + '/tx/' + depositForBurnTxHash
		);
	}

	return (
		<AccordionPanel
			header={expanded ? 'Transfer in Progress' : 'Transfer'}
			expanded={expanded}
			onClickHeader={() => {}} // do nothing
			CustomIcon={
				expanded
					? () => <GradientLoadingSpinner size={18} />
					: cctpState > CctpState.PollingAttestationInProgress
					? () => <SuccessFilled size={18} />
					: () => <></>
			}
			headerClassNames={twMerge(
				'bg-transparent [&>span]:text-[16px]',
				expanded ? 'text-text-emphasis pb-0' : 'text-text-secondary'
			)}
			content={
				<div className="flex flex-col w-full gap-2">
					<div className="flex justify-between">
						<Text.BODY1 className="text-text-label">
							Transferring {usdcAmount} USDC
						</Text.BODY1>
						<div className="flex items-center gap-2">
							{sourceDomainUiConfig.icon}
							<ArrowRight size={16} />
							{destinationDomainUiConfig.icon}
						</div>
					</div>
					{/* <div className="flex justify-between">
						<Text.BODY1 className="text-text-label">
							Transaction sent at
						</Text.BODY1>
						<Text.BODY1>{txnSentTime.toLocaleString()}</Text.BODY1>
					</div> */}
					<div className="flex justify-between">
						<Text.BODY1 className="text-text-label">
							Estimated transfer time
						</Text.BODY1>
						<Text.BODY1>{estimatedDurationOfTransfer}</Text.BODY1>
					</div>
					<div className="flex justify-between">
						<Text.BODY1 className="text-text-label">
							View transaction
						</Text.BODY1>
						<div
							className="flex items-center gap-1 cursor-pointer text-interactive-link hover:opacity-80"
							onClick={handleOpenTxnLink}
						>
							<Open />
							<Text.BODY1>View</Text.BODY1>
						</div>
					</div>
				</div>
			}
		/>
	);
};
