const BridgePanelLayout = ({ children }: { children: React.ReactNode }) => {
	return (
		<div className="w-full min-h-[100%] px-4 py-12 rounded-xl bg-container-bg flex flex-col justify-start items-center">
			<div className="max-w-[600px] flex flex-col justify-between w-full h-full text-text-default gap-4">
				{children}
			</div>
		</div>
	);
};

export default BridgePanelLayout;
