'use client';

import { useRouter } from 'next/navigation';
import BridgePanelLayout from '../Layouts/BridgePanelLayout';
import Text from '../Text/Text';
import useSafePush from 'src/hooks/useSafePush';
import { ChevronRight, Info, ArrowLeft } from '@drift-labs/icons';
import Tooltip from '../Tooltip/Tooltip';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import { BRIDGE_OPTIONS } from 'src/constants/constants';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

export const BridgeCard = ({
	imageUrl,
	title,
	description,
	route,
	bridge,
}: {
	imageUrl: string;
	title: string;
	description: string;
	route?: string;
	bridge: string;
}) => {
	const safePush = useSafePush();
	const { captureEvent } = usePostHogCapture();

	const handleClick = () => {
		captureEvent(`selected_bridge`, { bridge });

		if (route) {
			safePush(route);
		}
	};

	return (
		<div
			onClick={handleClick}
			className="flex justify-between gap-4 p-3 border rounded-md cursor-pointer border-container-border bg-container-bg-hover"
		>
			<div className="flex gap-3">
				<div className="min-w-[24px] min-h-[24px]">
					<img src={imageUrl} className="w-6 h-6" />
				</div>
				<div className="flex flex-col gap-1">
					<Text.H5 className="text-text-default">{title}</Text.H5>
					<Text.BODY3 className="text-text-default">{description}</Text.BODY3>
				</div>
			</div>
			<span className="text-text-default">
				<ChevronRight size={18} />
			</span>
		</div>
	);
};

const BridgePanel = () => {
	const router = useRouter();
	const countryCode = useDriftStore((s) => s.countryCode);

	// filter CCTP if user is russian bc they block russian requests and the flow can't be completed
	const bridgeOptions = BRIDGE_OPTIONS.filter(
		(bridgeOption) => bridgeOption.bridge !== 'cctp' || countryCode !== 'RU'
	);

	return (
		<BridgePanelLayout>
			<div className="flex flex-row items-center justify-start">
				<button
					onClick={() => router.back()}
					className="text-text-default hover:text-text-emphasis hover:cursor-pointer p-1.5 rounded-full border border-container-border mr-4 flex items-center"
				>
					<ArrowLeft size={24} />
				</button>
				<Text.XL className="text-text-emphasis">Cross Chain Deposits</Text.XL>
			</div>
			<div className="flex">
				<div className="min-w-[55px]">
					{/** Faux container to simulate column taken by back button */}
				</div>
				<div className="flex flex-col gap-6">
					<Text.BODY2 className="leading-normal">
						You’ll need SOL in your wallet for transaction fees and collateral
						in your Drift Account for trades.{' '}
						<span className="font-bold">
							Only deposit funds using the Solana network
						</span>
						<Tooltip
							content={
								<div
									className={
										'z-50 bg-tooltip-bg flex flex-col gap-2 text-left whitespace-nowrap rounded-[4px]'
									}
								>
									<a
										href="https://www.drift.trade/learn/dex-deposit-withdraw"
										target="_blank"
										rel="noreferrer"
									>
										<Text.BODY2 className="text-text-interactive">
											How to deposit from a CEX
										</Text.BODY2>
									</a>
									<a
										href="https://www.drift.trade/learn/how-to-bridge-assets"
										target="_blank"
										rel="noreferrer"
									>
										<Text.BODY2 className="text-text-interactive">
											How to bridge assets
										</Text.BODY2>
									</a>
								</div>
							}
							allowHover
							className="relative inline-flex ml-[2px] top-1"
						>
							<span className="text-text-label">
								<Info size={16} />
							</span>
						</Tooltip>
					</Text.BODY2>
					<div className="flex flex-col gap-3">
						{bridgeOptions.map((bridgeOption) => (
							<BridgeCard
								key={bridgeOption.bridge}
								imageUrl={bridgeOption.imageUrl}
								title={bridgeOption.title}
								description={bridgeOption.description}
								route={bridgeOption.route}
								bridge={bridgeOption.bridge}
							/>
						))}
					</div>
				</div>
				<div className="min-w-[54px]">
					{/** Faux container to simulate right padding with width of the column taken by back button  */}
				</div>
			</div>
		</BridgePanelLayout>
	);
};

export default BridgePanel;
