'use client';

import useLocalStorageState from './useLocalStorageState';

const useMagicAuthSavedEmail = () => {
	const magicAuthSavedEmail = useLocalStorageState('magicAuthSavedEmail', '');

	return magicAuthSavedEmail;
};

export default useMagicAuthSavedEmail;
