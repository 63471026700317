'use client';

import useDriftAccountStore from 'src/stores/useDriftAccountsStore';

const useAccountExists = () => {
	const currentAccountExists = useDriftAccountStore((s) => s.currentUserExists);

	return currentAccountExists;
};

export default useAccountExists;
