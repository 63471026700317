'use client';

import { ArrowLeft } from '@drift-labs/icons';
import BridgePanelLayout from '../../Layouts/BridgePanelLayout';
import Text from 'src/components/Text/Text';
import { TransferDetails } from './TransferDetails';
import { CctpState } from 'src/@types/types';
import { TransferInProgress } from './TransferInProgress';
import { ReceiveUsdc } from './ReceiveUsdc';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariables';
import useDriftTheme from 'src/hooks/useDriftTheme';
import { useCctpStateEngine } from 'src/hooks/cctp/useCctpStateEngine';
import Button from 'src/components/Button';
import useSafePush from 'src/hooks/useSafePush';
import useDriftActions from 'src/hooks/useDriftActions';
import { useRouter } from 'next/navigation';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

/*
 * CCTP - Cross-Chain Transfer Protocol
 *
 * How this works is that a user will connect the wallet on the source chain (e.g. Ethereum),
 * deposit an amount of USDC, and Circle will burn the USDC on the source chain. Circle provides
 * an API to call to check the status of the burn (i.e. the block of the burn transaction is finalized
 * on the source chain). Once the burn is finalized, the API will return a status 'success' + an
 * attestation hex (a proof hash), and the user can use this attestation hex to create a transaction
 * on the destination chain (e.g. Solana) to then receive the USDC.
 */

export const CctpPanelBase = ({
	hideArrowBack,
}: {
	hideArrowBack?: boolean;
}) => {
	const router = useRouter();
	const safePush = useSafePush();
	const theme = useDriftTheme();
	const driftActions = useDriftActions();
	const set = useDriftStore((s) => s.set);

	const isDarkTheme = theme === DriftTheme.dark;

	const {
		cctpState,
		usdcAmount,
		setUsdcAmount,
		sourceDomain,
		setSourceDomain,
		destinationDomain,
		setDestinationDomain,
		destinationUsdcTokenAccount,
		destinationWalletAddress,
		setDepositForBurnTx,
		isDepositForBurnApproving,
		setDestinationWalletAddress,
		depositForBurnTx,
		setMessageHash,
		setMessageBytes,
		messageBytes,
		attestationHex,
		setUsdcAlreadyReceived,
		resetState,
		destinationWalletPassPreReq,
	} = useCctpStateEngine();

	function handleDepositIntoDrift() {
		safePush('/');
		setTimeout(() => {
			driftActions.showModal('showDepositModal');
		}, 2000); // allow buffer time for main ui to load
	}

	const showTxHashModal = () => {
		set((s) => {
			s.modals.showCctpTxHashModal = true;
		});
	};

	return (
		<>
			<div className="flex flex-row items-center justify-start">
				{!hideArrowBack && (
					<button
						onClick={() => router.back()}
						className="text-text-default hover:text-text-emphasis hover:cursor-pointer p-1.5 rounded-full border border-container-border mr-4 flex items-center"
					>
						<ArrowLeft size={24} />
					</button>
				)}
				<div className="flex flex-col items-start gap-2">
					<img
						src={
							isDarkTheme
								? '/assets/icons/circle-text-logo-dark.svg'
								: '/assets/icons/circle-text-logo.svg'
						}
						alt="Circle logo"
						className="h-4"
					/>
					<Text.H1 className="text-text-emphasis">
						Cross-Chain Transfer Protocol:
					</Text.H1>
				</div>
			</div>
			<div className="flex">
				{!hideArrowBack && (
					<div className="min-w-[55px]">
						{/** Faux container to simulate column taken by back button */}
					</div>
				)}
				<div className="flex flex-col gap-4">
					<div className="flex flex-row items-center justify-between text-text-default">
						<Text.BODY1 className="leading-normal">
							Circle&apos;s{' '}
							<a
								href="https://www.circle.com/en/cross-chain-transfer-protocol"
								target="_blank"
								rel="noopener noreferrer"
							>
								cross-chain transfer protocol
							</a>{' '}
							enables USDC to be sent across blockchains without needing to be
							converted. All transfers are permissionless and executed on chain.{' '}
							<a className="hover:cursor-pointer" onClick={showTxHashModal}>
								Have a transfer in progress that&apos;s not showing up?
							</a>
						</Text.BODY1>
					</div>

					<TransferDetails
						sourceDomain={sourceDomain}
						setSourceDomain={setSourceDomain}
						destinationDomain={destinationDomain}
						setDestinationDomain={setDestinationDomain}
						usdcAmount={usdcAmount}
						setUsdcAmount={setUsdcAmount}
						setMessageHash={setMessageHash}
						setMessageBytes={setMessageBytes}
						setDepositForBurnTx={setDepositForBurnTx}
						expanded={cctpState === CctpState.InitiatingTransfer}
						destinationWalletAddress={destinationWalletAddress}
						destinationUsdcTokenAccount={destinationUsdcTokenAccount}
						setDestinationWalletAddress={setDestinationWalletAddress}
						isDepositForBurnApproving={isDepositForBurnApproving}
						destinationWalletPassPreReq={destinationWalletPassPreReq}
					/>

					<TransferInProgress
						cctpState={cctpState}
						usdcAmount={usdcAmount}
						expanded={cctpState === CctpState.PollingAttestationInProgress}
						sourceDomain={sourceDomain}
						destinationDomain={destinationDomain}
						depositForBurnTxHash={depositForBurnTx.hash}
					/>

					<ReceiveUsdc
						cctpState={cctpState}
						attestationHex={attestationHex}
						messageBytes={messageBytes}
						destinationWalletAddress={destinationWalletAddress}
						expanded={cctpState === CctpState.ReadyToReceive}
						sourceDomain={sourceDomain}
						destinationDomain={destinationDomain}
						usdcAmount={usdcAmount}
						setUsdcAlreadyReceived={setUsdcAlreadyReceived}
						depositForBurnTxHash={depositForBurnTx.hash}
					/>

					{cctpState === CctpState.Completed && (
						<div className="flex w-full gap-2">
							<Button.Secondary
								size="MEDIUM"
								className="w-full"
								onClick={() => resetState(true)}
							>
								Bridge again
							</Button.Secondary>
							<Button.Secondary
								size="MEDIUM"
								className="w-full"
								onClick={handleDepositIntoDrift}
								highlight
							>
								Deposit into Drift
							</Button.Secondary>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

const CctpPanel = () => {
	return (
		<BridgePanelLayout>
			<CctpPanelBase />
		</BridgePanelLayout>
	);
};

export default CctpPanel;
