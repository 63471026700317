'use client';

import React, {
	MouseEvent,
	PropsWithChildren,
	forwardRef,
	useMemo,
} from 'react';
import CurrentWalletProviderIcon from './Icons/CurrentWalletProviderIcon';
import { Delegation } from '@drift-labs/icons';
import RoundedGradientBorderBox from './RoundedGradientBorderBox';
import GradientText from './Utils/GradientText';
import { twMerge } from 'tailwind-merge';

type ButtonSize = 'LARGE' | 'MEDIUM' | 'SMALL' | 'XSMALL';

type ButtonProps = PropsWithChildren<{
	size: ButtonSize;
	onClick?: (x?) => void;
	href?: string;
	onMouseEnter?: (x?) => void;
	onMouseLeave?: (x?) => void;
	highlight?: boolean;
	negativeRed?: boolean;
	positiveGreen?: boolean;
	iconOnly?: boolean;
	disabled?: boolean;
	selected?: boolean;
	textClass?: string;
	className?: string;
	extClassName?: string;
	roundedGradientBorder?: boolean;
	id?: string;
	style?: React.CSSProperties;
	borderRadius?: string;
}>;

type ButtonGroupProps = {
	options: {
		label: string;
		value: string | number;
		color?: string;
	}[];
	selected: string | number;
	size: ButtonSize;
	selectAction: (selectedOption: string | number) => any;
	spaced?: boolean;
	className?: string;
	extClassName?: string;
	optionClassName?: string;
	id?: string;
	style?: React.CSSProperties;
};

const Button = (props: ButtonProps) => {
	const memoedClassName = useMemo(
		() =>
			twMerge(
				`inline-flex justify-center items-center px-6 py-2 rounded-md focus:outline-none disabled:cursor-not-allowed`,
				props.className
			),
		[props.className]
	);

	return (
		<button
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			disabled={props.disabled}
			className={`${memoedClassName} ${props.extClassName ?? ''}`}
		>
			{props.children}
		</button>
	);
};

export const ButtonGroup = (props: ButtonGroupProps) => {
	return <span className={props.className}></span>;
};

export const Primary = (props: ButtonProps) => {
	let sizeClasses = '';
	let paddingClasses = '';

	if (props.size == 'LARGE') {
		sizeClasses = 'h-[44px] uppercase';
		paddingClasses = 'py-[12px] px-[4px]';
	} else if (props.size == 'MEDIUM') {
		sizeClasses = 'h-[32px]';
		paddingClasses = 'py-[8px] px-[16px]';
	} else {
		// not supported
		return;
	}

	return (
		<button
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			disabled={props.disabled}
			className={twMerge(
				sizeClasses,
				paddingClasses,
				props.highlight && !props.disabled
					? `highlightButtonBg`
					: `bg-positive-green`,
				props.selected && `border border-neutrals-40`,
				props.highlight
					? 'text-text-gradient-button'
					: 'text-text-primary-button',
				`disabled:cursor-not-allowed disabled:bg-button-disabled disabled:text-text-disabled inline-flex whitespace-nowrap rounded-sm font-display items-center justify-center`,
				props.className
			)}
			id={props.id}
			style={props.style}
		>
			{props.children}
		</button>
	);
};

Button.Primary = Primary;

export const Secondary = forwardRef((props: ButtonProps, ref) => {
	let sizeClasses = '';
	let paddingClasses = '';

	// large secondary will be new "hero" button
	if (props.size == 'LARGE') {
		sizeClasses = 'w-full h-[44px] uppercase text-l';
		paddingClasses = 'py-[6px] px-[12px]';
	} else if (props.size == 'MEDIUM') {
		sizeClasses = 'h-[32px] text-sm';
		paddingClasses = props.iconOnly
			? 'py-[4px] px-[8px]'
			: 'py-[8px] px-[12px]';
	} else if (props.size == 'SMALL') {
		sizeClasses = 'h-[26px] text-sm';
		paddingClasses = props.iconOnly ? 'py-[2px] px-[2px]' : 'py-[4px] px-[8px]';
	} else if (props.size == 'XSMALL') {
		sizeClasses = 'h-[16px] text-xs';
		paddingClasses = 'px-[4px]';
	} else {
		// not supported
		return;
	}

	const defaultTextColor = props.highlight
		? 'text-text-gradient-button'
		: props.negativeRed
		? 'text-text-negative-red-button'
		: props.positiveGreen
		? 'text-text-positive-green-button'
		: 'text-text-secondary-button';

	const bgColorClasses =
		props.highlight && !props.disabled
			? `bg-primary-gradient text-text-gradient-button`
			: props.negativeRed && !props.disabled
			? `bg-negative-red-secondary-bg hover:opacity-80 `
			: props.positiveGreen && !props.disabled
			? 'bg-positive-green-secondary-bg hover:opacity-80 '
			: `bg-button-secondary-bg hover:bg-button-secondary-bg-hover`;

	const memoedClassName = useMemo(
		() =>
			twMerge(
				`font-display rounded-md focus:outline-none disabled:cursor-not-allowed`,
				props.size === 'LARGE'
					? 'w-full h-12 uppercase text-l'
					: 'hover:bg-button-secondary-hover',
				props.size === 'LARGE' &&
					(props.disabled
						? `text-text-disabled hover:cursor-not-allowed`
						: `text-text-label hover:bg-button-secondary-hover hover:cursor-pointer`),
				paddingClasses,
				props.className
			),
		[props.size, props.disabled, paddingClasses, props.className]
	);

	return !props.roundedGradientBorder ? (
		<button
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			disabled={props.disabled}
			className={twMerge([
				'space-x-2 disabled:cursor-not-allowed disabled:bg-button-disabled disabled:hover:bg-button-disabled disabled:text-text-disabled inline-flex rounded-sm font-display items-center justify-center transition-all',
				sizeClasses,
				paddingClasses,
				props.textClass ?? defaultTextColor,
				bgColorClasses,
				props.selected ? `border border-container-border` : ``,
				props.className,
			])}
			id={props.id}
			style={props.style}
			// @ts-ignore
			ref={ref}
		>
			{props.children}
		</button>
	) : (
		<RoundedGradientBorderBox
			className={`${memoedClassName} ${props.extClassName ?? ''}`}
			borderColour={
				props.selected ? `var(--app-gradient)` : 'border-button-border'
			}
			borderWidth="1px"
			borderHoverClass={`${props.disabled ? `` : `var(--app-gradient)`}`}
			borderRadius={props.borderRadius ?? '0.125rem'}
			active={props.selected}
			{...props}
		>
			<button
				className={twMerge(
					`flex items-center justify-center h-full w-full box-border bg-button-secondary-bg hover:border-transparent dark:border disabled:cursor-not-allowed`,
					paddingClasses,
					props.selected
						? 'border-transparent'
						: 'border-button-border hover:bg-button-secondary-bg-hover',
					props.disabled
						? `text-text-disabled hover:cursor-not-allowed`
						: `${
								props.selected ? 'text-text-emphasis' : 'text-text-label'
						  } hover:cursor-pointer`
				)}
				onClick={props.onClick}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
				disabled={props.disabled}
			>
				{props.children}
			</button>
		</RoundedGradientBorderBox>
	);
});

Secondary.displayName = 'Button.Secondary';
Button.Secondary = Secondary;

const SecondaryTab = (props: ButtonProps) => {
	const classNameToUse = twMerge(
		'hover:bg-tabs-hover',
		props.selected && 'bg-tabs-selected',
		props.className
	);
	return (
		<Button.Secondary {...props} className={classNameToUse}>
			{props.children}
		</Button.Secondary>
	);
};

Button.SecondaryTab = SecondaryTab;

export const Wallet = (props: ButtonProps & { isDelegate?: boolean }) => {
	let sizeClasses = '';
	let paddingClasses = '';

	if (props.size == 'MEDIUM') {
		sizeClasses = 'h-[32px] text-xs';
		paddingClasses = 'py-[2px] px-[12px]';
	} else {
		// not supported
		return;
	}

	return (
		// This component is presently _only_ used as a child of another component which renders a button, causing an error due to rendering a button inside another button. To fix this for now, rendering this element as a div instead.
		<div
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			className={`${props.className} ${sizeClasses} ${paddingClasses} ${
				props.highlight && !props.disabled
					? `highlightButtonBg`
					: 'bg-button-secondary-bg hover:bg-button-secondary-bg-hover'
			} ${
				props.selected ? 'border border-container-border' : ``
			} disabled:cursor-not-allowed disabled:bg-button-disabled disabled:text-text-disabled focus:border-container-border inline-flex text-text-default border-button-border rounded-sm font-display items-center justify-between border`}
			id={props.id}
			style={props.style}
		>
			{props.isDelegate ? (
				<Delegation className="h-[22px] w-[22px]" />
			) : (
				<CurrentWalletProviderIcon className="h-[22px] w-[22px]" />
			)}

			{props.children}
		</div>
	);
};

Button.Wallet = Wallet;

export const Ghost = (props: ButtonProps) => {
	let sizeClasses = '';
	let paddingClasses = '';

	if (props.size == 'LARGE') {
		sizeClasses = 'w-full h-[44px] text-l';
		paddingClasses = 'py-[6px] px-[12px]';
	} else if (props.size == 'MEDIUM') {
		sizeClasses = 'h-[32px] text-sm';
		paddingClasses = props.iconOnly
			? 'py-[4px] px-[8px]'
			: 'py-[8px] px-[12px]';
	} else if (props.size == 'SMALL') {
		sizeClasses = 'h-[26px] text-sm';
		paddingClasses = 'py-[4px] px-[8px]';
	} else if (props.size == 'XSMALL') {
		sizeClasses = 'h-[16px] text-xs';
		paddingClasses = 'px-[4px]';
	} else {
		// not supported
		return;
	}

	const memoedClassName = useMemo(
		() =>
			twMerge(
				'disabled:cursor-not-allowed bg-transparent hover:bg-button-secondary-bg-hover disabled:text-text-disabled rounded-sm font-display items-center justify-center inline-flex',
				props.textClass ?? 'text-text-default',
				sizeClasses,
				paddingClasses,
				props.className
			),
		[sizeClasses, paddingClasses, props.className, props.textClass]
	);

	return (
		<button
			onClick={(event: MouseEvent) => {
				props?.onClick?.(event);
				if (props.href) {
					window.open(props.href, '_blank', 'noopener noreferrer');
				}
			}}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			disabled={props.disabled}
			className={`${memoedClassName}`}
			id={props.id}
			style={props.style}
		>
			{props.children}
		</button>
	);
};

Button.Ghost = Ghost;

export const ButtonGroupSegmented = (props: ButtonGroupProps) => {
	let sizeClasses = '';
	let spacingClass = '';
	let textClass = '';

	if (props.size == 'LARGE') {
		sizeClasses = 'h-[40px]';
		spacingClass = props.spaced ? 'ml-4' : '';
		textClass = 'text-md';
	} else if (props.size == 'MEDIUM') {
		sizeClasses = 'h-[29px]';
		spacingClass = props.spaced ? 'ml-2' : '';
		textClass = 'text-sm';
	} else if (props.size == 'SMALL') {
		spacingClass = props.spaced ? 'ml-2' : '';
		textClass = 'text-xs';
	} else {
		// not supported
		return;
	}

	return (
		<div
			className={`${props.className} ${sizeClasses} inline-flex items-end justify-between overflow-hidden border rounded-sm border-container-border`}
			id={props.id}
			style={props.style}
		>
			{props.options.map((option) => (
				<div
					className={twMerge(
						`flex h-full pt-0.5 justify-center items-center capitalize text-sm border-r border-container-border last:border-0`,
						option.value === props.selected
							? 'bg-container-bg-selected'
							: 'bg-container-bg',
						spacingClass,
						props.optionClassName
					)}
					key={`${option.label}`}
					onClick={() => {
						props.selectAction(option.value);
					}}
				>
					<div
						className={`${textClass} flex items-center justify-center text-center px-3 py-1 hover:cursor-pointer`}
					>
						{option.value === props.selected ? (
							<GradientText>
								<span className="mt-1 text-text-interactive">
									{option.label}
								</span>
							</GradientText>
						) : (
							<GradientText onHover>
								<span className="mt-1 text-text-interactive">
									{option.label}
								</span>
							</GradientText>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

ButtonGroup.Segmented = ButtonGroupSegmented;

export const BigSemantic = (
	props: Pick<
		ButtonProps,
		| 'onClick'
		| 'onMouseEnter'
		| 'onMouseLeave'
		| 'disabled'
		| 'children'
		| 'className'
	> & { positive?: boolean; neutral?: boolean }
) => {
	const memoedClassName = useMemo(
		() =>
			twMerge(
				`w-full disabled:cursor-not-allowed disabled:bg-button-disabled disabled:text-text-disabled border disabled:border-container-border border-transparent whitespace-nowrap text-text-primary-button rounded-md font-display items-center justify-center py-3 text-lg tracking-normal`,
				!props.disabled &&
					(props.positive
						? 'bg-positive-green hover:bg-buy-hover active:bg-buy-pressed'
						: props.neutral
						? 'bg-button-secondary-bg hover:bg-button-secondary-bg-hover text-text-secondary-button'
						: 'bg-negative-red hover:bg-sell-hover active:bg-sell-pressed')
			),
		[props.disabled, props.positive]
	);

	return (
		<button
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			disabled={props.disabled}
			className={twMerge(memoedClassName, props.className)}
		>
			{props.children}
		</button>
	);
};

Button.BigSemantic = BigSemantic;

export const GradientButton = React.forwardRef(function GradientButton(
	{
		style,
		...props
	}: ButtonProps & {
		type?: 'app-gradient' | 'brand-gradient' | 'prize' | 'points';
	},
	ref: React.Ref<HTMLButtonElement>
) {
	const bgColor =
		props.type === 'app-gradient'
			? 'var(--app-gradient)'
			: props.type === 'points'
			? 'var(--drift-points-gradient)'
			: props.type === 'prize'
			? 'var(--prize-gradient)'
			: undefined; // the HIGHLIGHT prop will make it use the brand gradient as a fallback

	return (
		<Secondary
			highlight
			style={{
				...style,
				background: bgColor,
			}}
			{...props}
			ref={ref}
		/>
	);
});

Button.Gradient = GradientButton;

export const BigInputButton = (
	props: Omit<ButtonProps, 'size'> & {
		highlighted?: boolean;
		className?: string;
	}
) => {
	return (
		<RoundedGradientBorderBox
			className={twMerge(`w-full mb-3`, props.className)}
			borderColour={
				props.highlighted && !props.disabled
					? `var(--app-gradient)`
					: `var(--input-border)`
			}
			borderWidth="1px"
			borderHoverClass={!props.disabled && `var(--app-gradient)`}
			borderRadius="0.125rem"
		>
			<button
				className={`flex w-full bg-input-bg p-2`}
				onClick={!props.disabled && props.onClick}
			>
				{props.children}
			</button>
		</RoundedGradientBorderBox>
	);
};

Button.BigInput = BigInputButton;

export default Button;
