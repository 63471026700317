export class OneWayValueJar<T> {
	private _value: T;
	private unset = true;

	constructor(private defaultValue: T) {}

	public set(value: T): void {
		if (this.unset) {
			this._value = value;
			this.unset = false;
		}
	}

	get value(): T {
		if (this.unset) {
			return this.defaultValue;
		}

		return this._value;
	}
}
