'use client';

import { WalletReadyState } from '@solana/wallet-adapter-base';
import { Wallet } from '@solana/wallet-adapter-react';
import { PhantomWalletName } from '@solana/wallet-adapter-wallets';
import { useMemo } from 'react';
import { MAGIC_EDEN_WALLET_NAME } from 'src/constants/wallets';
import useWalletContext from 'src/hooks/useWalletContext';

const WALLETS_WITH_AUTOCONFIRM: string[] = [PhantomWalletName];

const FEATURED_WALLETS: string[] = [];

// the order of these wallets determines their priority in the UI
const PRIORITIZED_WALLETS: string[] = [
	PhantomWalletName,
	MAGIC_EDEN_WALLET_NAME,
];

export const TIPLINK_WALLET_NAME = 'Google via TipLink';

export type WalletToDisplayInfo = {
	provider: Wallet;
	featuresAutoConfirm?: boolean;
	featuredHighlight?: boolean;
};

export const HISTORICALLY_SELECTED_WALLETS_STORAGE_KEY =
	'historicallySelectedWallets';
export const getHistoricallySelectedWallets = () => {
	return JSON.parse(
		localStorage.getItem(HISTORICALLY_SELECTED_WALLETS_STORAGE_KEY) || '[]'
	);
};

const WALLETS_TO_PUT_IN_HIDDEN_SECTION = ['WalletConnect'];

const walletHasBeenSelectedBefore = (name: string) => {
	const previouslySelectedWallets = getHistoricallySelectedWallets();
	return previouslySelectedWallets.includes(name);
};

const useWalletsToDisplay = (numberOfWalletsToShow?: number) => {
	const walletContext = useWalletContext();

	const memoizedWallets = useMemo(() => {
		let walletsToShow: WalletToDisplayInfo[] = [];
		const hiddenWallets: WalletToDisplayInfo[] = [];

		const solanaMobileWalletAdapter = walletContext.wallets.find((wallet) => {
			return wallet.adapter.name === 'Mobile Wallet Adapter';
		});

		if (
			solanaMobileWalletAdapter &&
			(solanaMobileWalletAdapter.readyState === WalletReadyState.Installed ||
				solanaMobileWalletAdapter.readyState === WalletReadyState.Loadable)
		) {
			walletsToShow = [{ provider: solanaMobileWalletAdapter }];
		} else {
			if (walletContext?.wallets && walletContext?.wallets?.length > 0) {
				walletContext.wallets
					.slice()
					.sort((walletA, walletB) => {
						// Move phantom to the top of the list
						const prioritizedIndexA = PRIORITIZED_WALLETS.indexOf(
							walletA.adapter?.name
						);
						const prioritizedIndexB = PRIORITIZED_WALLETS.indexOf(
							walletB.adapter?.name
						);

						if (prioritizedIndexA !== -1 && prioritizedIndexB !== -1) {
							return prioritizedIndexA - prioritizedIndexB;
						}

						if (prioritizedIndexA !== -1) {
							return -1;
						}

						if (prioritizedIndexB !== -1) {
							return 1;
						}

						return 0;
					})
					.forEach((walletProvider) => {
						const walletDisplayInfo: WalletToDisplayInfo = {
							provider: walletProvider,
						};

						if (
							WALLETS_WITH_AUTOCONFIRM.includes(walletProvider.adapter.name)
						) {
							walletDisplayInfo.featuresAutoConfirm = true;
						}

						if (FEATURED_WALLETS.includes(walletProvider.adapter.name)) {
							walletDisplayInfo.featuredHighlight = true;
						}

						if (PRIORITIZED_WALLETS.includes(walletProvider.adapter.name)) {
							walletsToShow.push(walletDisplayInfo);
							return;
						}

						const walletShouldBeHidden =
							WALLETS_TO_PUT_IN_HIDDEN_SECTION.includes(
								walletProvider.adapter.name
							);

						const walletInstalled =
							walletProvider.readyState === WalletReadyState.Installed ||
							walletProvider.readyState === WalletReadyState.Loadable;

						const limitWallets = numberOfWalletsToShow !== undefined;

						if (walletProvider.adapter.name === 'MetaMask') {
							// This hides OTHER metamask adapters from the UI, because we manually display our own adapter without depending on this hook
							return;
						}

						// we recommend TipLink for now
						if (walletProvider.adapter.name === TIPLINK_WALLET_NAME) {
							// Tiplink wallet shows as "always installed" - we only want to highlight it if the user has actually selected it before
							if (walletHasBeenSelectedBefore(TIPLINK_WALLET_NAME)) {
								walletsToShow.push(walletDisplayInfo);
							} else {
								hiddenWallets.push(walletDisplayInfo);
							}
							return;
						}

						if (
							walletProvider.adapter.name === 'Solflare' &&
							walletProvider.readyState !== WalletReadyState.Installed
						) {
							// Solflare does something weird where it's "always loadable no matter what", we don't want to highlight it if the user hasn't actually installed it
							hiddenWallets.push(walletDisplayInfo);
							return;
						}

						if (walletShouldBeHidden || !walletInstalled) {
							hiddenWallets.push(walletDisplayInfo);
							return;
						}

						if (!limitWallets) {
							walletsToShow.push(walletDisplayInfo);
							return;
						}

						if (numberOfWalletsToShow < walletsToShow.length) {
							walletsToShow.push(walletDisplayInfo);
							return;
						}

						hiddenWallets.push(walletDisplayInfo);
					});
			}
		}

		return {
			walletsToShow,
			hiddenWallets,
		};
	}, [numberOfWalletsToShow, walletContext.wallets]);

	return memoizedWallets;
};

export default useWalletsToDisplay;
