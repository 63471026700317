import React from 'react';

const LightningIcon = ({
	...props
}: {
	className?: string;
	style?: React.CSSProperties;
}) => {
	return (
		<svg
			width="5"
			height="5"
			viewBox="0 0.7 4.5 5"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M2.59351 1.12027C2.60814 0.988588 2.44409 0.916824 2.35731 1.01695L0.250346 3.44807C0.17911 3.53026 0.229352 3.65864 0.337456 3.67066L1.71973 3.82424C1.79384 3.83248 1.84723 3.89922 1.839 3.97332L1.70494 5.1799C1.6903 5.31159 1.85435 5.38335 1.94113 5.28322L4.04809 2.85211C4.11933 2.76991 4.06909 2.64153 3.96098 2.62952L2.57871 2.47593C2.5046 2.4677 2.45121 2.40095 2.45944 2.32685L2.59351 1.12027Z"
				fill="url(#paint0_linear_14052_93087)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_14052_93087"
					x1="0.0820313"
					y1="3.15013"
					x2="4.21641"
					y2="3.15013"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F9CF7C" />
					<stop offset="1" stopColor="#9162F6" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default LightningIcon;
