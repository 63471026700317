'use client';

import { useCallback, useMemo } from 'react';
import useLocalStorageState from './useLocalStorageState';

type AcknowledgedTermsState = {
	hasPreviouslyAcknowledgedTerms: boolean;
	shouldShowTermsReminder: boolean;
};

// Remind user to agree to terms once every 30 days
const TERMS_REMINDER_INTERVAL = 1000 * 60 * 60 * 24 * 30;

const useHasAcknowledgedTerms = () => {
	const [lastAcknowledgedTerms, setLastAcknowledgedTerms] =
		useLocalStorageState('lastAcknowledgedTerms', '');

	const acknowledgedTermsState = useMemo(() => {
		return {
			hasPreviouslyAcknowledgedTerms: !!lastAcknowledgedTerms,
			shouldShowTermsReminder:
				lastAcknowledgedTerms &&
				parseFloat(lastAcknowledgedTerms) &&
				parseFloat(lastAcknowledgedTerms) <
					Date.now() - TERMS_REMINDER_INTERVAL,
		};
	}, [lastAcknowledgedTerms]);

	const updateLastAcknowledgedTerms = useCallback(() => {
		const currentMs = Date.now();

		setLastAcknowledgedTerms(currentMs.toString());
	}, [acknowledgedTermsState, lastAcknowledgedTerms]);

	const returnState = useMemo(() => {
		return [acknowledgedTermsState, updateLastAcknowledgedTerms];
	}, [acknowledgedTermsState]);

	return returnState as [AcknowledgedTermsState, () => void];
};

export default useHasAcknowledgedTerms;
