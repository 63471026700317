'use client';

import { useMemo } from 'react';
import { useSettingsContext } from '../providers/settingsProvider';
import { UserSettings } from '../environmentVariables/EnvironmentVariables';

const useCurrentSettings = (): [
	UserSettings,
	(newSettings: Partial<UserSettings>) => void
] => {
	const currentSettingsContext = useSettingsContext();

	const updateSettings = currentSettingsContext?.settingsClient?.updateSettings;

	const returnValue = useMemo(() => {
		return [currentSettingsContext?.currentSettings, updateSettings];
	}, [currentSettingsContext?.currentSettings, updateSettings]);

	// @ts-ignore
	return returnValue;
};

export default useCurrentSettings;
