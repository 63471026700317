'use client';

import { BytesLike } from 'ethers';
import { ReactNode } from 'react';
import { AccordionPanel } from 'src/components/Accordion';
import Button from 'src/components/Button';
import { useMessageTransmitterSolana } from 'src/hooks/cctp/useMessageTransmitterSolana';
import Text from 'src/components/Text/Text';
import { getDomainUiConfig } from 'src/utils/cctp';
import { CctpState } from 'src/@types/types';
import { SuccessFilled } from '@drift-labs/icons';
import { twMerge } from 'tailwind-merge';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { usePathname } from 'next/navigation';
import useSafePush from 'src/hooks/useSafePush';

const Row = ({ label, value }: { label: string; value: ReactNode }) => (
	<div className="flex items-center justify-between">
		<Text.BODY1 className="text-text-label">{label}</Text.BODY1>
		{value}
	</div>
);

export const ReceiveUsdc = ({
	cctpState,
	expanded,
	attestationHex,
	messageBytes,
	destinationWalletAddress,
	sourceDomain,
	destinationDomain,
	usdcAmount,
	setUsdcAlreadyReceived,
	depositForBurnTxHash,
}: {
	cctpState: CctpState;
	expanded: boolean;
	messageBytes: BytesLike;
	attestationHex: string;
	destinationWalletAddress: string;
	sourceDomain: number;
	destinationDomain: number;
	usdcAmount: string;
	setUsdcAlreadyReceived: (usdcAlreadyReceived: boolean) => void;
	depositForBurnTxHash: string;
}) => {
	const setDriftStore = useDriftStore((s) => s.set);
	const pathname = usePathname();
	const safePush = useSafePush();

	const { receiveUsdc, isTxnLoading } = useMessageTransmitterSolana({
		sourceDomain,
		attestationHex,
		messageBytes: messageBytes as string,
		eventTracking: {
			depositForBurnTxHash: depositForBurnTxHash,
		},
	});

	const sourceDomainUiConfig = getDomainUiConfig(sourceDomain);
	const destinationDomainUiConfig = getDomainUiConfig(destinationDomain);

	const handleGoToConnectRoute = () => {
		setDriftStore((s) => {
			s.wallet.lastRouteBeforeConnect = pathname;
		});
		safePush('/onboarding/connect');
	};

	async function handleReceiveUsdc() {
		if (!destinationWalletAddress) {
			handleGoToConnectRoute();
			return;
		}

		const isSuccess = await receiveUsdc();
		if (!isSuccess) return;
		setUsdcAlreadyReceived(true);
	}

	return (
		<AccordionPanel
			header="Receive"
			expanded={expanded}
			onClickHeader={() => {}} // do nothing
			CustomIcon={
				cctpState > CctpState.ReadyToReceive
					? () => <SuccessFilled size={18} />
					: () => <></>
			}
			headerClassNames={twMerge(
				'bg-transparent [&>span]:text-[16px]',
				expanded ? 'text-text-emphasis pb-0' : 'text-text-secondary'
			)}
			content={
				<div className="flex flex-col w-full gap-2">
					<Row
						label="Source Chain"
						value={
							<div className="flex items-center gap-2">
								<span>{sourceDomainUiConfig.icon}</span>
								<span>{sourceDomainUiConfig.label}</span>
							</div>
						}
					/>
					<Row
						label="Destination Chain"
						value={
							<div className="flex items-center gap-2">
								<span>{destinationDomainUiConfig.icon}</span>
								<span>{destinationDomainUiConfig.label}</span>
							</div>
						}
					/>
					<Row
						label={`${destinationDomainUiConfig.label} Wallet`}
						value={
							destinationWalletAddress ? (
								destinationWalletAddress
							) : (
								<Text.BODY3
									className="cursor-pointer text-interactive-link"
									onClick={handleGoToConnectRoute}
								>
									Connect {destinationDomainUiConfig.label} Wallet
								</Text.BODY3>
							)
						}
					/>
					<Row
						label="Amount"
						value={
							<div className="flex items-center gap-2">
								<img src="/assets/icons/markets/usdc.svg" className="w-4 h-4" />
								<Text.BODY1>{usdcAmount} USDC</Text.BODY1>
							</div>
						}
					/>

					<Button.Secondary
						size="MEDIUM"
						className="mt-4"
						onClick={handleReceiveUsdc}
						highlight
						disabled={isTxnLoading}
					>
						{!destinationWalletAddress ? 'Connect Wallet' : 'Receive'}
					</Button.Secondary>
				</div>
			}
		/>
	);
};
