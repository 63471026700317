'use client';

import React, { useState, memo } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Text from '../Text/Text';
import useAccountExists from 'src/hooks/useAccountExists';
import { useRouter } from 'next/navigation';
import useWalletIsConnected from '../../hooks/useWalletIsConnected';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useSafePush from 'src/hooks/useSafePush';
import { BridgeCard } from './BridgePanel';
import { BRIDGE_OPTIONS } from 'src/constants/constants';
import { CctpPanelBase } from './CctpPanel/CctpPanel';
import { WormholeConnectPanelBase } from './WormholeConnectPanel';
import { MayanSwapPanelBase } from './MayanSwapPanel';
import Button from '../Button';

function FundYourWalletFromNonSolanaChain(props: {
	handleNext: () => void;
	handleFundFromAnotherWallet: () => void;
}) {
	const { captureEvent } = usePostHogCapture();
	const router = useRouter();
	const safePush = useSafePush();
	const accountExists = useAccountExists();
	const connected = useWalletIsConnected();
	const [lastRouteBeforeConnect, solBalance] = useDriftStore((s) => [
		s.wallet.lastRouteBeforeConnect,
		s.wallet.currentSolBalance,
	]);
	const [selectedBridge, setSelectedBridge] =
		useState<(typeof BRIDGE_OPTIONS)[0]['bridge']>('');

	if (!connected) {
		router.replace('/onboarding/connect');
	}

	if (accountExists) {
		safePush('/');
	}

	const handleSkipDeposit = () => {
		captureEvent('onboarding_skip_deposit');
		safePush(lastRouteBeforeConnect || '/');
	};

	const renderContent = () => {
		switch (selectedBridge) {
			case 'cctp':
				return <CctpPanelBase hideArrowBack />;
			case 'wormhole':
				return <WormholeConnectPanelBase hideArrowBack />;
			case 'mayan':
				return <MayanSwapPanelBase hideArrowBack />;
			default:
				return (
					<div className="flex flex-col gap-3">
						{BRIDGE_OPTIONS.map((bridgeOption) => (
							<div
								onClick={() => setSelectedBridge(bridgeOption.bridge)}
								key={bridgeOption.bridge}
							>
								<BridgeCard
									imageUrl={bridgeOption.imageUrl}
									title={bridgeOption.title}
									description={bridgeOption.description}
									bridge={bridgeOption.bridge}
								/>
							</div>
						))}
					</div>
				);
		}
	};

	return (
		<div className="w-full h-full">
			{renderContent()}
			<div className="my-8">
				<Button.Secondary
					highlight
					className="w-full"
					size={'MEDIUM'}
					disabled={solBalance.eqZero()}
					onClick={props.handleNext}
				>
					Next: Deposit to Drift
				</Button.Secondary>
				<div className="flex flex-col items-center justify-around p-2 mt-4 sm:flex-row">
					<button onClick={props.handleFundFromAnotherWallet}>
						<Text.BODY1 className="text-text-label">
							Fund from another wallet
						</Text.BODY1>
					</button>
					<button onClick={handleSkipDeposit}>
						<Text.BODY1 className="text-text-label">Maybe Later</Text.BODY1>
					</button>
				</div>
			</div>
		</div>
	);
}

export default memo(FundYourWalletFromNonSolanaChain);
