'use client';

import styled from '@emotion/styled';
import useWindowSizeString from 'src/hooks/useWindowSize';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, {
	MouseEventHandler,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import RoundedGradientBorderBox from './RoundedGradientBorderBox';
import Text from './Text/Text';
import { brandGradientStops } from 'src/constants/figmaGenerated';
import useIsMobile from 'src/hooks/useIsMobileScreenSize';
import Tooltip from './Tooltip/Tooltip';
import { twMerge } from 'tailwind-merge';

type StyledSliderProps = {
	enableTransition?: boolean;
	disabled?: boolean;
	type: string;
	isMobile: boolean;
};

const { Handle } = Slider;

const BORDER_WIDTH = 1;
const SLIDER_WIDTH = 50 - 2 * BORDER_WIDTH;

const BORDER_WIDTH_LARGE = 1;
const SLIDER_WIDTH_LARGE = 70 - 1 * BORDER_WIDTH_LARGE;

const handle = (props) => {
	const { type, handleVal, displayValue, largeFont, disabled, ...restProps } =
		props;

	const handleText = (
		<div
			className="absolute flex items-center -translate-x-1/2 -translate-y-1/2 left-1/2 text-text-input whitespace-nowrap"
			style={{
				top: '50%',
				marginTop: '0px',
			}}
		>
			{displayValue
				? displayValue
				: type === 'percent'
				? `${Math.round(handleVal)}`
				: `${(handleVal / 100).toFixed(2)}`}
			{type === 'percent' ? '%' : 'x'}
		</div>
	);

	return (
		<Handle
			value={handleVal}
			{...restProps}
			className="absolute top-0 z-30"
			style={{ top: `${16}px` }}
			dragging="false"
		>
			{disabled ? (
				<div
					className={`absolute cursor-not-allowed slider-knob z-50 border border-container-border rounded rounded-md bg-container-bg shadow`}
					style={{
						width: largeFont ? SLIDER_WIDTH_LARGE : SLIDER_WIDTH,
						bottom: `-2px`,
						left: `${
							((handleVal - props.min) / (props.max - props.min)) *
							-(largeFont ? SLIDER_WIDTH_LARGE : SLIDER_WIDTH)
						}px`,
					}}
				>
					{largeFont ? (
						<div className="h-[26px] px-2">
							<Text.BODY1 className="opacity-50">{handleText}</Text.BODY1>
						</div>
					) : (
						<div
							style={{ width: '46px', height: '22px' }}
							className="slider-knob-inner"
						>
							<Text.BODY3 className="opacity-50">{handleText}</Text.BODY3>
						</div>
					)}
				</div>
			) : (
				<RoundedGradientBorderBox
					className={`absolute cursor-move slider-knob z-50`}
					borderColour={`var(--app-gradient)`}
					borderWidth={
						largeFont ? `${BORDER_WIDTH_LARGE}px` : `${BORDER_WIDTH}px`
					}
					borderHoverClass={`var(--app-gradient)`}
					borderRadius="4px"
					style={{
						width: largeFont ? SLIDER_WIDTH_LARGE : SLIDER_WIDTH,
						bottom: `-2px`,
						left: `${
							((handleVal - props.min) / (props.max - props.min)) *
							-(largeFont ? SLIDER_WIDTH_LARGE : SLIDER_WIDTH)
						}px`,
					}}
				>
					{largeFont ? (
						<div className="h-[26px] px-2 bg-container-bg">
							<Text.BODY1>{handleText}</Text.BODY1>
						</div>
					) : (
						<div
							style={{ width: '46px', height: '22px' }}
							className="slider-knob-inner bg-container-bg"
						>
							<Text.BODY3>{handleText}</Text.BODY3>
						</div>
					)}
				</RoundedGradientBorderBox>
			)}
		</Handle>
	);
};

const StyledSlider = styled(Slider)<StyledSliderProps>`
	.rc-slider-rail {
		height: 4px;
		border-radius: 9999px;
		background-color: var(--stroke-secondary);
	}
	.rc-slider-track {
		${({ enableTransition }) => enableTransition && 'transition: all 500ms;'}
		background: var(--app-gradient);
		border: none;
		border-width: 0;
		z-index: 5;
	}
	.rc-slider-step {
		display: none;
	}
	.rc-slider-handle {
		width: 16px;
		height: 16px;
		background: none;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
		margin-top: -6px;
		${({ enableTransition }) => enableTransition && 'transition: all 500ms;'}
		border: unset;
		z-index: 20;
	}
	.rc-slider-mark {
		position: relative;
		width: calc(100% - 6px);
		left: 2px;
		top: ${({ isMobile }) => (isMobile ? '0px' : '18px')};
	}
	.rc-slider-mark-text {
		transition: all 300ms;
		font-family: 'numeral';
		color: var(--neutrals-40);
		font-size: 10px;
		z-index: 10;
	}
	.rc-slider-mark-text:last-of-type {
		right: 0;
	}
	${({ disabled }) => disabled && 'background-color: transparent'}
`;

type TrackMarkProps = {
	sliderValue: number;
	styleValue: number;
	colorStop: string;
	style?: React.CSSProperties;
};

const TrackMark = ({
	sliderValue,
	styleValue,
	colorStop,
	style,
}: TrackMarkProps) => {
	return (
		<button
			className={twMerge(
				'absolute h-3 w-[3px] z-0 bg-stroke-secondary bottom-[-9px] sm:bottom-[10px]'
			)}
			style={{
				background:
					sliderValue >= styleValue ? colorStop : 'var(--stroke-secondary)',
				...style,
			}}
		></button>
	);
};

type SliderProps = {
	onDrop: (newVal: number) => any;
	onMove: (newVal: number) => any;
	step: number;
	value: number;
	disabled: boolean;
	max?: number;
	maxButtonTransition?: boolean;
	customButtonOptions?: { val: number; label?: string }[];
	min?: number;
	type: 'percent' | 'leverage';
	displayValue?: string;
	largeFont?: boolean;
	noTrackMarks?: boolean;
};

const SliderButton = (props: {
	onClick: MouseEventHandler<HTMLDivElement>;
	children?: ReactNode;
	style?: Record<string, any>;
	className?: string;
}) => {
	return (
		<div
			className={twMerge(
				`border border-button-border bg-button-secondary-bg hover:bg-button-secondary-bg-hover flex items-center justify-center flex-shrink text-text-default py-0.5 cursor-pointer mr-1 last:mr-0`,
				props.className
			)}
			onClick={props.onClick}
			style={props.style}
		>
			<Text.BODY3 className="py-[2px] leading-3">{props.children}</Text.BODY3>
		</div>
	);
};

const AmountSlider = ({
	onDrop,
	onMove,
	step,
	value,
	disabled,
	max = 100,
	maxButtonTransition,
	customButtonOptions,
	min = 0,
	type = 'percent',
	displayValue,
	largeFont,
	noTrackMarks,
}: SliderProps) => {
	const [enableTransition, setEnableTransition] = useState(false);

	useEffect(() => {
		if (maxButtonTransition) {
			setEnableTransition(true);
		}
	}, [maxButtonTransition]);

	useEffect(() => {
		if (enableTransition) {
			const transitionTimer = setTimeout(() => {
				setEnableTransition(false);
			}, 500);
			return () => clearTimeout(transitionTimer);
		}
	}, [enableTransition]);

	const handleSliderButtonClick = (value) => {
		onDrop(value);
		setEnableTransition(true);
	};

	const windowSize = useWindowSizeString();

	let handleVal = Math.min(value, max);
	handleVal = handleVal < 0 ? 0 : handleVal;
	const isMobile = useIsMobile();

	const buttonWidthPercent = customButtonOptions
		? (1 / customButtonOptions.length) * 100
		: 25;

	return (
		<div className="relative font-numeral">
			<StyledSlider
				isMobile={isMobile}
				min={Math.ceil(min)}
				max={Math.floor(max)}
				value={handleVal}
				onChange={(e) => {
					onMove(e);
				}}
				onAfterChange={(e) => {
					onDrop(e);
				}}
				step={step}
				enableTransition={enableTransition}
				disabled={disabled}
				marks={
					noTrackMarks
						? undefined
						: {
								[0 * max]: (
									<TrackMark
										styleValue={0 * max}
										sliderValue={value}
										style={{ left: '-2px' }}
										colorStop={brandGradientStops[0]}
									/>
								),
								[0.25 * max]: (
									<TrackMark
										styleValue={0.25 * max}
										sliderValue={value}
										colorStop={brandGradientStops[25]}
									/>
								),
								[0.5 * max]: (
									<TrackMark
										styleValue={0.5 * max}
										sliderValue={value}
										colorStop={brandGradientStops[50]}
									/>
								),
								[0.75 * max]: (
									<TrackMark
										styleValue={0.75 * max}
										sliderValue={value}
										colorStop={brandGradientStops[75]}
									/>
								),
								[1 * max]: (
									<TrackMark
										styleValue={1 * max}
										sliderValue={value}
										style={{ right: '-4px' }}
										colorStop={brandGradientStops[100]}
									/>
								),
						  }
				}
				type={type}
				// This is strange syntax but the library only passes specific props forward to the handle - so we have to do this to shoehorn the 'type' property in as well :)
				// Needing to pass in value seperately as "handleVal" because for some odd reason the library sometimes deducts 1 from the value
				handle={(props) =>
					handle({
						...props,
						handleVal: handleVal,
						displayValue: displayValue,
						type,
						largeFont,
						disabled,
					})
				}
			/>
			{windowSize !== 'xs' && (
				<div className="flex flex-row justify-between w-full mt-3">
					{customButtonOptions ? (
						customButtonOptions.map((option) => {
							return option.val == 100 ? (
								<SliderButton
									key={option.val}
									onClick={() =>
										handleSliderButtonClick((option.val / 100) * max)
									}
									style={{ width: `${buttonWidthPercent}%` }}
								>
									<Tooltip
										placement="bottom"
										content={
											<div>
												Note that selecting 100% leverage will attempt to open
												the maximum size position the protocol allows for your
												account; size may vary slightly from estimate.
											</div>
										}
									>
										{option.label ?? `${option.val}%`}
									</Tooltip>
								</SliderButton>
							) : (
								<SliderButton
									key={option.val}
									onClick={() =>
										handleSliderButtonClick((option.val / 100) * max)
									}
									style={{ width: `${buttonWidthPercent}%` }}
								>
									{option.label ?? `${option.val}%`}
								</SliderButton>
							);
						})
					) : (
						<>
							<SliderButton
								className="w-[25%]"
								onClick={() => handleSliderButtonClick(25)}
							>
								25%
							</SliderButton>
							<SliderButton
								className="w-[25%]"
								onClick={() => handleSliderButtonClick(50)}
							>
								50%
							</SliderButton>
							<SliderButton
								className="w-[25%]"
								onClick={() => handleSliderButtonClick(75)}
							>
								75%
							</SliderButton>
							<SliderButton
								className="w-[25%]"
								onClick={() => handleSliderButtonClick(100)}
							>
								100%
							</SliderButton>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default React.memo(AmountSlider);
