import { BigNum, BN, PublicKey } from '@drift-labs/sdk';

/**
 *  for use with React.memo, compare old and new props that include BigNums, BNs, and PublicKeys
 *
 * We should limit this to those for now to keep this function simple and efficient.
 *
 * If we're passing in other types of objects we should probably break them down or just use a custom compare function for that individual component.
 */
export function compareDriftProps(
	oldProps: Record<string, any>,
	newProps: Record<string, any>
): boolean {
	try {
		for (const key in newProps) {
			const oldValue = oldProps[key];
			const newValue = newProps[key];
			const oldType = typeof oldValue;
			const newType = typeof newValue;

			// Returning false means the component SHOULD re-render

			if (oldType !== newType) {
				return false;
			}

			if (
				(oldType !== 'object' || oldValue === null) &&
				oldValue !== newValue
			) {
				return false;
			}

			if (oldType === 'object' && newType === 'object') {
				if (oldValue?.constructor === BN && !oldValue.eq(newValue)) {
					return false;
				}

				if (oldValue?.constructor === BigNum && !oldValue.eq(newValue)) {
					return false;
				}

				if (oldValue?.constructor === PublicKey && !oldValue.equals(newValue)) {
					return false;
				}
			}
		}

		return true;
	} catch (err) {
		// I don't know if we'll ever get here but just in case, make the component re-render if there's an error
		return false;
	}
}
